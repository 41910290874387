@import './variable';



.ov-row{
    .col-md-4{
        padding-right: 40px !important;
        padding-left: 40px !important;
    }
}

.ov-line{
    -webkit-box-flex: 1;
    border-bottom: 1px solid $gray;
    display: block;
    -ms-flex: auto;
    flex: auto;
    margin: auto 0;
}