@import './font';
@import './button';
@import './spacing';
@import './variable';
@import './font';

/* Mobile devices */
@media only screen and (max-width: 767px) {

    /* Container */
    .ov-container {
        padding: 50px 0px 50px;
    }

    .mt-100 {
        margin-top: 40px !important;
    }

    .mmb-20 {
        margin-bottom: 20px !important;
    }

    /* Grid */
    .ov-row {
        .col-md-4 {
            padding-right: 15px !important;
            padding-left: 15px !important;
        }
    }

    .ov_button {
        &.primary-button {
            width: auto;
        }
    }

    /* Fonts */
    .ov-heding {

        text-transform: uppercase;

        &.primary {
            font-size: 20px !important;
            font-family: $primary-font;
        }

        &.secondry {
            font-size: 15px !important;
            letter-spacing: 10px;
            color: $secondry-color;
            font-family: $primary-font;
        }

        &.teritory {
            font-size: 16px;
            color: $black;
            font-weight: bold;
            font-family: $primary-font;
        }
    }

    .ov-font-30 {
        font-size: 20px !important;
    }

    .font-25 {
        font-size: 16px !important;
    }

    .ov-font-25 {
        font-size: 25px !important;
    }

    .ov-navbar {
        a {
            color: $white !important;
        }

        .menu li .menu-item-has-children:before {
            color: $black !important;
        }
    }

    .mobile-font-16 {
        font-size: 16px !important;
    }

    .mobile-font-14 {
        font-size: 14px !important;
    }

    .menu {
        background-color: $black;
    }

    .ov-paragraph {
        font-size: 14px;
        color: $black;
        line-height: 1.1rem;
        font-family: $primary-font;

    }

    /* Card */
    .ov-card {
        border: solid 1px $border;
        margin-bottom: 20px;
        border-radius: 5px;

    }

    .custom-swiper-1 {
        padding: 0 0 10px 0 !important;
    }

    .custom-slick-slider .ov-left-arrow {
        position: absolute;
        right: calc(100vw - 80%) !important;

    }

    .custom-slick-slider .ov-right-arrow {
        position: absolute;
        left: calc(100vw - 80%) !important;

    }

    .ov-bold-none {
        font-weight: normal !important;
    }

    .ov-crypto {
        .ov-heding {
            line-height: 25px !important;
        }

        .mt-90 {
            margin-top: 0 !important;
        }

        .mt-80 {
            margin-top: 30px !important;
        }

        .ov-medium-container {
            margin-top: -156px !important;
            border: none !important;
        }

        .p-5 {
            padding: 1rem !important;
        }
    }

    .ov-about-us {
        .mt-5 {
            margin-top: 0 !important;
        }

        .ov-img-medium {
            height: 40vh;
            object-fit: cover;
        }

        .ov-hero-slider1 {
            height: 30vh;
        }

        .ov-home-slider1 {
            height: 30vh;
        }

        .ov-home-slider2 {
            height: 30vh;
        }

        .ov-hero-slider2 {
            height: 30vh;
        }

        .ov-hero-slider3 {
            height: 30vh;
        }

        .ov-slider-description {
            margin-right: 0;
            padding-top: 15%;
            padding-left: 5%;
            width: auto;


        }

        .ov-medium-container {
            margin-top: -65px !important;
        }

        .ov-mobile-img {
            margin-top: 20px;
        }

        .ov-tnx-img {
            height: 30vh;
            background-position: center;
        }

        .w-75 {
            width: auto;
        }

        .mmt-100 {
            margin-top: 100px !important;
        }

        .slick-prev,
        .slick-next {
            display: none !important;
        }

        .pt-130 {
            padding-top: 40px;
        }

        .row {
            margin: 0 !important;
        }

        .col-md-4 {
            margin: 0 !important;
            padding: 0 !important;
        }

        .mr-4 {
            margin-right: 0 !important;
        }

        .ov-heding.medium {
            font-size: 16px !important;
            font-family: "Bembo Regular" !important;
            line-height: 1.2rem !important;
        }

        .mt-130 {
            margin-top: 40px;
        }

        .mt-5 {
            margin-top: 0 !important;
        }

        .mt-130 {
            .p-5 {
                padding: 2rem !important;
            }
        }

        .custom-wrapper {
            .d-flex {
                display: grid !important;
            }
        }


    }

    .ov-home-page {
        .custom-wrapper {
            .d-flex {
                display: grid !important;
            }
        }

    }

    .ov-leaf-vector {
        .pl-40 {
            padding-left: 0 !important;
        }
    }

    .ov-slider-heading-custom {
        position: absolute;
        bottom: 0;
        z-index: 999;
        color: $white !important;
        left: 0;
        font-size: 14px !important;
        right: 0;

    }

    .ov-villa-slider {

        .ov-villa-detail-slider {
            padding-left: 0 !important;
            padding-right: 0 !important;


        }

    }

    .ov-video {
        overflow: unset !important;

        video {
            width: 390px !important;
            left: 0 !important;
        }
    }

    .ov-home-slider-custom {
        .ov-font-25 {
            font-size: 14px !important;
        }
    }

    .ov-slider-counter {
        position: absolute;
        margin-top: -43px;
        margin-left: 140px;
        color: #fff;
        background: none !important;
        font-size: 14px;
    }
    .ov-slider-counter-resort {
        position: absolute;
        margin-top: -43px;
        margin-left: 140px;
        color: #fff;
        background: none !important;
        font-size: 14px;
        left: 38%;
    }

    // .ov-slider-icon .ov-slider-icon-custom .fa-angle-left {
    //     left: 90px;
    // }
    // .ov-slider-icon .ov-slider-icon-custom .fa-angle-right {
    //     right: 80px;
    // }
    .ov-slider-icon .fa-angle-right {
        right: -20px;

    }

    .ov-slider-icon .fa-angle-left {
        left: -20px;

    }

    .ov-slider-icon .ov-slider-icon-custom .fa-angle-left {
        left: 90px;
    }

    .ov-slider-icon .ov-slider-icon-custom .fa-angle-right {
        right: 40px;
    }

    .ov-slider-icon-resort .ov-slider-icon .ov-slider-icon-custom .bi-chevron-left {
        left: 90px;
    }

    .ov-slider-icon-resort .ov-slider-icon .ov-slider-icon-custom .bi-chevron-right {
        right: 40px;
    }

    .ov-slider-icon-resort {
        .ov-slider-icon-custom {
            .bi-chevron-right {
                font-size: 30px;
                background: none;
                left: 50%;
                padding: 10px;
                z-index: 9999;
                top: 76%;
                position: absolute;
                cursor: pointer;
            }

            .bi-chevron-right:before {

                color: $white;
                font-size: 15px;
                padding: 10px;
                background: none !important;
                border: none !important;
            }

            .bi-chevron-left {
                font-size: 30px;
                padding: 10px;
                z-index: 9999;
                right: 50%;
                top: 73%;
                position: absolute;
                cursor: pointer;
            }

            .bi-chevron-left:before {

                color: $white;
                font-size: 15px;
                padding: 10px;
                background: none !important;
                border: none !important;


            }
        }
        
    }
    .ov-slider-icon-resort .ov-slider-icon .fa-angle-right:before {
        content: "\f054";
        color: $white;
        font-size: 15px;
        background: none;
        padding: 10px;
        border: none !important;
    }
    .ov-slider-icon-resort .ov-slider-icon .fa-angle-left:before {
        content: "\f053";
        color: $white;
        font-size: 15px;
        background: none;
        padding: 10px;
        border: none !important;
    
    }
    .ov-slider-icon-resort .ov-slider-icon .bi-chevron-left {
        top: 65% !important;
        left: 32%;
    }
    .ov-slider-icon-resort .ov-slider-icon .bi-chevron-right {
        top: 68% !important;
        right: 32%;
    }
    .ov-slider-icon-resort .ov-slider-icon .bi-chevron-left:before {
        background: none;
        color: $white !important;
        border: none !important;
    }
    .ov-slider-icon-resort .ov-slider-icon .bi-chevron-right:before {
        background: none;
        color: $white !important;
        border: none !important;
    }
    .ov-floating-label {
      left: 16px;
      top: 30px;
       
    }
    .ov-floating-label-custom{
        left: 0 !important;
    }
    .pl-60 {
        padding-left: 0 !important;
    }

    .resort-title-pdn {
        .ov-heding.secondry {
            letter-spacing: 0 !important;
        }
    }

    .ov-background-black,
    .ov-background-black-height {
        height: auto;
        margin-bottom: 30px;
        margin-top: 0;
    }

    .ov-blog {
        .ov-blog-container {
            margin-top: -60px !important;
            padding: 50px 25px !important;
        }

        .mt-5 {
            margin-top: 2rem !important;
        }

        .mt-75 {
            margin-top: 50px !important;
        }

        .d-flex {
            display: grid !important;
        }

        .ov-blog-icon {
            .d-flex {
                display: flex !important;
            }

            .ov-font-25 {
                font-size: 20px !important;
            }
        }

        .ml-5 {
            margin-left: 0 !important;
        }

        .mobile-top {
            margin-top: 20px !important;
        }

        h1 {
            font-size: 25px !important;
        }

        h2 {
            font-size: 20px !important;
            line-height: 30px !important;

            span {
                font-size: 20px !important;
                line-height: 30px !important;

                a {
                    font-size: 20px !important;
                    line-height: 30px !important;
                }
            }
        }

        h3 {
            line-height: 30px !important;
        }

        h4 {
            a {
                font-size: 15px !important;

                span {
                    font-size: 15px !important;
                }
            }
        }

        .ov-blog-padding {
            margin-left: 5px !important;
            margin-right: 5px !important;
        }

        ul {

            padding-left: 30px;

            li {
                margin-bottom: 10px !important;
                line-height: 30px !important;
            }
        }

    }

    .ov-custom-schedule-form {
        height: 552px !important;
        padding: 0 !important;
        width: 388px !important;

        .close {
            top: 70px !important;
            color: $black !important;

            .enquiry-close-icon {
                font-family: $primary-font ;
                padding-right: 0px !important;
                color: $black !important;

            }
        }

        .PhoneInputInput {
            font-size: 16px;
            color: $black;
        }

        .ov-description.teritory {
            font-size: 16px;
        }
    }

    .ov-ref-form {
        .ov-ref-body {

            .pl-5,
            .pr-5 {
                padding-left: 2rem !important;
            }

            .MuiFormControl-root.MuiTextField-root {
                margin: 10px 0px !important;
            }

            .ag-range {
                margin-top: 0px !important;
            }
        }

    }

    .ov-slider-wrapper {
        .slick-dots {
            bottom: 3px !important;

            li {
                margin: 0 5px !important;

                button:before {
                    font-size: 8px !important;
                    color: $white ;
                }
            }
        }

    }


    .ov-blog-refer {
        .ov-medium-container {
            margin-top: -100px !important;
        }

        .p-5 {
            padding: 2rem !important;
        }
    }

    .ov-flex {
        margin-top: 20px;

        .d-flex {
            display: grid !important;
        }

        .ml-3 {
            margin-left: 0 !important;
        }

        .custom-ov-button {
            padding: 0 !important;
            margin-top: 10px;
            width: 139px !important;

            a {
                width: 340px !important;
                margin-top: 10px;
            }
        }

        .ov-blog-button {
            width: 340px !important;
        }
    }

    .ov-hint-popup {
        display: none !important;
    }

    .ov-mobile-popup {
        .close {
            top: 40px;
            right: 30px;
        }
    }

    .ov-clamp {
        overflow: hidden;
        text-overflow: ellipsis;

        -webkit-box-orient: inherit;
    }

    .ov-blog-parralex {
        .react-parallax-bgimage {
            height: 220px !important;
        }

        .blo-g1 {
            height: 200px !important;
        }
    }

    .blog-middle-video {
        display: none;
    }

    .ov-bg-label {
        margin-top: 0;
        padding: 0 !important;

        a {
            font-size: 12px !important;
        }
    }

    .ov-language-box {
        top: 140px;
        width: 250px;
        right: 0px;

        .ov-language-box-avatar {
            height: 70px;
            width: 60px;
        }

        .ov_button.primary-button {
            padding: 8px 12px;
        }

        .ov-description.primary {
            font-size: 14px !important;
        }
    }

    .ov-villa-detail-box {
        height: 320px !important;
    }

    .ov-resort-detail {
        .ov-detail-icon {
            height: 25px;
        }
        .ov-detail-icon-new {
            height: 18px !important;
        }
    }
}

/* Tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    /* Styles for tablets */
}

/* Desktops and larger screens */
@media only screen and (min-width: 1024px) {
    /* Styles for desktops */


}