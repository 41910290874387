@import './variable';
@import './font';

.ov_button {
    font-size: 14px !important;
    cursor: pointer;
    text-transform: uppercase;
    display: inline-block;
    letter-spacing: 0;
    font-style: normal;
    letter-spacing: 1px;
    font-weight: 500;
    background: none !important;


    &.primary-button {
        padding: 13px 12px;
        display: inline-block;
        font-family: $secondary-font;
        text-transform: uppercase;
        color: $black;
        overflow: hidden;
        letter-spacing: 0px;
        cursor: pointer;
        border: 1px solid $black;
        border-radius: 0;
        line-height: 16px;
         width: 210px;
         text-align: center !important;


        &:hover{
           background: $black !important; 
           color: $white !important;
           transition: all .3s cubic-bezier(.24,.5,.22,.89);
        }
    }
    & a {
        padding: 13px 12px;
        display: inline-block;
        font-family: $secondary-font;
        text-transform: uppercase;
        color: $black;
        overflow: hidden;
        letter-spacing: 0px;
        cursor: pointer;
        border: 1px solid $black;
        border-radius: 0;
        line-height: 16px;
         width: 210px;
         text-align: center !important;


        &:hover{
           background: $black !important; 
           color: $white !important;
           transition: all .3s cubic-bezier(.24,.5,.22,.89);
        }
    }

    &.white-btn{
        background: none !important; 
        color: $white !important;
        border: 1px solid $white !important;
    }
    &.red-btn{
        background: none !important; 
        color: $red !important;
        border: 1px solid $red !important;
        padding: 13px 12px;
        display: inline-block;
        font-family: $secondary-font;
        text-transform: uppercase;
        color: $black;
        overflow: hidden;
        letter-spacing: 0px;
        cursor: pointer;
        border-radius: 0;
        line-height: 16px;
    }

    &.secondry-button {
        border: 1px solid $black;
        border-radius: 50px;
        padding: 5px 15px;
        color: $black;
        font-weight: 700;
        line-height: 20px;
        font-family: $primary-font ;

    }

    &.link-button {
        text-decoration: underline;
        color: $primary-color;
        background: none !important;
        font-family: $primary-font ;
    }

    &.arrow-button {
        text-decoration: underline;
        color: $black !important;
        background: none !important;
        font-family: $secondary-font;

        &::after {
            content: '\f101';
            font-family: 'FontAwesome';
            position: absolute;
            margin-left: 7px !important;
        }
    }
    &.arrow-button-prev {
        text-decoration: underline;
        color: $black !important;
        background: none !important;
        font-family: $secondary-font;

        &::before {
            content: '\f101';
            font-family: 'FontAwesome';
            position: absolute;
            margin-left: -15px !important;
            rotate: 180deg;
        }
    }
    &.down-arrow-button {
        text-decoration: underline;
        color: $black;
        background: none !important;
        font-family: $secondary-font;

        &::after {
            content: '\f101';
            font-family: 'FontAwesome';
            position: absolute;
            margin-left: 7px !important;
            transform: rotate(90deg);
        }
    }

    &.hashtag-button {
        color: $black;
        font-family: $primary-font;
        font-size: 12px !important;
        font-style: italic;
        text-transform: lowercase !important;

    }
}
// .slick-slider{
//     display: flex;
// }
// .ov-slider{
   
    
//        .slick-prev{
//         background: none;
//         left: 20%;
//         z-index: 999;
//         }
//         .slick-prev:before{
//             color: $black !important;
//             content: '\f053';
//             font-family: FontAwesome !important;
            
//         }
//        .slick-next{
//         background: none;
//         right: 20%;
//         z-index: 999;
//        }
//        .slick-next:before{
//         color: $black !important;
//         content: '\f054';
//         font-family: FontAwesome !important;
        
//     }
    
// }
.ov-slider{
    display: flex;
    align-items: center;
    
    .slick-list{
        display: list-item;
        
    }
    .fa{
        font-size: 35px;
        cursor: pointer;
    }
}

.hint-popup .login-close-icon {
    color: $black !important;
    border: 1px solid $black;
}
.ov-pointer{
    cursor:  pointer !important;
}
.ov-boader-none{
border: none !important;
}


// Phill

.ov-phill{
    background-color: $ash;
    padding: 2px 10px;
    border-radius: 20px;
    color: $black;
    margin-left: 15px;
    font-size: 14px;
}