@import './variable';

p {
    color: $black !important;
}

.ov-heding {

    text-transform: uppercase;
    color: $black;

    &.primary {
        font-size: 60px;
        font-family: $primary-font;
    }

    &.secondry {
        font-size: 18px;
        color: $secondry-color;
        font-family: $primary-font;
    }

    &.teritory {
        font-size: 18px;
        color: $black;
        font-weight: bold;
        font-family: $primary-font;
        // letter-spacing: 0.125rem;
    }

    &.small {
        font-size: 16px;
        color: $black;
        font-weight: bold;
        font-family: $primary-font;
    }

    &.xtra-small {
        font-size: 15px;
        color: $black;
        font-weight: bold;
        font-family: $primary-font;
    }

    &.large {
        font-size: 50px;
    }

    &.medium {
        font-size: 30px;
        font-family: $primary-font;
    }
}


.ov-link{
    text-decoration: underline;
     font-size: 16px;
   color: $black;
    line-height: 1.3rem;
    font-family: $primary-font;
    &:hover{
        text-decoration: underline !important;
    }
}

.ov-paragraph {
    font-size: 16px;
    color: $black;
    line-height: 1.3rem;
    font-family: $primary-font;

}

.ov-description {
    color: $black;
    line-height: 1.7rem;
    font-family: $primary-font;

    &.primary {
        font-size: 14px;
    }

    &.secondry {
        font-size: 16px;
    }

    &.teritory {
        font-size: 22px;
    }

}

.ov-label {
    color: $black;
    line-height: 1.7rem;
    font-family: $primary-font;

    &.primary {
        font-size: 13px;
    }

    &.secondry {
        font-size: 15px;
    }

}




// Text Decoration Styles Here

.text-line-through {
    text-decoration: line-through !important;
}

.text-bold {
    font-weight: bold;
}


// Colored Font

.font-gray {
    color: $gray !important;
}

.font-black {
    color: $black !important;
}

.font-red {
    color: $red !important;
}

// Styled Font

.ov-bold {
    font-weight: bold !important;
}

.ov-normal {
    font-weight: normal !important;
}

.ov-italic {
    font-style: italic !important;
}

.ov-lowercase {
    text-transform: lowercase !important;
}

.ov-uppercase {
    text-transform: uppercase !important;
}

.ov-initial {
    text-transform: initial !important;
}

.ov-list {
    line-height: 1.5em !important;
}

.ov-tab {
    letter-spacing: 0.3rem !important;
    font-size: 13px !important;
}

// Line Height Font
.ov-lh-xtra-small {
    line-height: 0.5rem !important;
}

.ov-lh-small {
    line-height: 1rem !important;
}

.ov-lh-medium {
    line-height: 1.3rem !important;
}

.ov-lh-large {
    line-height: 2rem !important;
}

// Letter Space
.ov-ls-25 {
    letter-spacing: 25px;
}

// List Items
.ov-font-12 {
    font-size: 12px !important;
}

.ov-font-10 {
    font-size: 10px !important;
}
.ov-font-13 {
    font-size: 13px !important;
}
.ov-font-14 {
    font-size: 14px !important;
}
.ov-font-15 {
    font-size: 15px !important;
}
.ov-font-16 {
    font-size: 16px !important;
}
.ov-font-17 {
    font-size: 17px !important;
}

.ov-font-18 {
    font-size: 18px !important;
}
.ov-font-20 {
    font-size: 20px !important;
}
.ov-font-25 {
    font-size: 25px !important;
}

.ov-font-30 {
    font-size: 30px !important;
}

.ov-font-50 {
    font-size: 50px !important;
}

.ov-font-40 {
    font-size: 40px !important;
}

.ov-clamp {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

// Position Items
.ov-relative {
    position: relative;
    z-index: 99999;
}