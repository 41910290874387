// Main Colors

$primary-color:#4C4442;
$secondry-color:#7E6E64;
$teritory-color:#999999;

// Action Colors

$black:#1c222f;
$black-50:#0039524f;

$white:#fff;
$yellow:#FFE40F;
$ash:#e3e3e3;
$ash-light:#eeeeee;
$ash-50:#eeeeee8c;
$gray:#949494;
$background:#f6f2ed;
$light-blue:#96c3d8;
$dark-blue:#055a80;
$sand:#d0c0b0;
$red:#dc3545;

// Border Colors
$border:#99999954;

// Animation Color


$primary-font:'Bembo Regular' !important;
$secondary-font:'Copperplate' !important;