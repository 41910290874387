@font-face {
    font-family: 'Gotham Thin';
    src: url('./fonts/gotham/gotham_thin-webfont.eot');
    src: url('./fonts/gotham/gotham_thin-webfont.eot?#iefix') format('embedded-opentype'), url('./fonts/gotham/gotham_thin-webfont.woff') format('woff'), url('./fonts/gotham/gotham_thin-webfont.ttf') format('truetype'), url('./fonts/gotham/gotham_thin-webfont.svg#Gotham Thin') format('svg');
    font-weight: "normal";
    font-style: "normal";
    font-stretch: normal
}

@font-face {
    font-family: 'Exodus Demo Sa';
    src: url('./fonts/ExodusDemo-Sharpen.otf') format('opentype');
    font-weight: "normal";
    font-style: "normal";
    font-stretch: normal
}

@font-face {
    font-family: 'Gotham Light';
    src: url('./fonts/gotham/gotham-light.eot');
    src: url('./fonts/gotham/gotham-light.eot?#iefix') format('embedded-opentype'), url('./fonts/gotham/gotham-light.woff') format('woff'), url('./fonts/gotham/gotham-light.ttf') format('truetype'), url('./fonts/gotham/gotham-light.svg#Gotham Light') format('svg');
    font-weight: "normal";
    font-style: "normal";
    font-stretch: normal
}

@font-face {
    font-family: 'Gotham Medium';
    src: url('./fonts/gotham/gotham-medium.eot');
    src: url('./fonts/gotham/gotham-medium.eot?#iefix') format('embedded-opentype'), url('./fonts/gotham/gotham-medium.woff') format('woff'), url('./fonts/gotham/gotham-medium.ttf') format('truetype'), url('./fonts/gotham/gotham-medium.svg#Gotham Medium') format('svg');
    font-weight: "normal";
    font-style: "normal";
    font-stretch: normal
}

@font-face {
    font-family: 'Gotham Bold';
    src: url('./fonts/gotham/gotham_bold-webfont.eot');
    src: url('./fonts/gotham/gotham_bold-webfont.eot?#iefix') format('embedded-opentype'), url('./fonts/gotham/gotham_bold-webfont.woff') format('woff'), url('./fonts/gotham/gotham_bold-webfont.ttf') format('truetype'), url('./fonts/gotham/gotham_bold-webfont.svg#Gotham Bold') format('svg');
    font-weight: "normal";
    font-style: "normal";
    font-stretch: normal
}

@font-face {
    font-family: 'Bembo Bold';
    src: local('Bembo Bold'), url(./fonts/Bembo-Bold.woff2) format('woff');
}

@font-face {
    font-family: 'Bembo Regular';
    src: local('Bembo Regular'), url(./fonts/Bembo-Regular.woff2) format('woff');
}

@font-face {
    font-family: 'Bembo Semibold';
    src: local('Semibold'), url(./fonts/Bembo-Semibold.woff2) format('woff');
}

@font-face {
    font-family: 'Copperplate Heavy';
    src: local('Copperplate Heavy'), url(./fonts/OPTICopperplate-Heavy.woff2) format('woff');
}

@font-face {
    font-family: 'Copperplate Light';
    src: local('Copperplate Light'), url(./fonts/OPTICopperplate-Light.woff2) format('woff');
}

@font-face {
    font-family: 'Copperplate';
    src: local('Copperplate'), url(./fonts/Copperplate.woff2) format('woff');
}

@font-face {
    font-family: 'Gatteway Signature';
    src: local('Gatteway Signature'), url(./fonts/Gatteway-Signature.woff2) format('woff');
}


/*****************************/
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@1,500&family=Laila:wght@300&family=Roboto+Condensed:wght@300;400;700&display=swap');

@media screen and (max-width: 2000px) {
    .eid-text-content {
        font-size: 14px !important;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1100px) {

    .width-70-moon-new {
        padding: 30% 0px !important;
    }
}

/**********************/

.page-transition__black {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    background: #fff;
    z-index: 1000000;
    transform: scale(1, 1);
}

.page-transition__red {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    background: #f5f3ee;
    z-index: 1000000;
    transform: scale(1, 1);
}

.transition__loding {
    text-transform: uppercase;
    font-family: sans-serif;
    font-size: 32px;
    position: absolute;
    z-index: 1;
    color: #fff;
    font-weight: bold;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
}

body {
    font-family: Arial, Helvetica, sans-serif;
    background-color: white;
    font-size: 15px;
    font-weight: 300;
    line-height: 24px;
    overflow-x: hidden;
    height: 100%;
    color: #262626;
    overflow: visible;
    overflow-x: hidden;
}

.body {
    width: 100%;
    /* padding-top: 117px; */
}

.masthead {
    height: 40px;
    background-color: #2B201A;
    z-index: 2;
}

.gray-bg-sections {
    background-color: #EFEEEE;
    position: relative;
    color: #7F7773;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #2B201A;
}

h4 {
    font-family: 'Gotham light', sans-serif, sans !important;
    font-weight: bold !important;
    font-size: 15px !important;
}

h4.new-title {
    color: black !important;
    font-size: 32px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
}

.call-icons {
    color: #2B201A;
    font-size: 16px;
}

a {
    color: #736C68 !important;
}

a:focus,
a:hover {
    color: #a99600;
}

a.read-more-btn {
    font-size: 13px;
    line-height: 36px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    border-top: 1px solid #aeaeae;
    border-bottom: 1px solid #aeaeae;
    padding: 15px 25px;
    margin-left: 10px;
}

hr.line {
    width: 30px;
    height: 1px;
    background: #aeaeae;
    bottom: 0;
    left: 0;
    margin-bottom: 45px;
}

.line-no-bottom {
    margin-bottom: 0px !important;
}

.resort-card {
    min-height: 180px;
}

hr.resort-line {
    width: 30px;
    height: 1px;
    background: #aeaeae;
    bottom: 0;
    left: 0;
    margin-top: 34px;
    margin-bottom: 38px;
}

.home-big-titles {
    font-family: 'Gotham Thin', sans-serif, sans;
    color: black;
    font-size: 50px;
    line-height: 50px;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding-top: 27px;
    padding-bottom: 27px;
    padding-left: 10px;
    padding-right: 10px;
}

.flash-text-new {
    color: #5e3055 !important;
    font-size: 32px !important;
    line-height: 58px;
}

.at-custom-side-wrapper.addthis-smartlayers.addthis-smartlayers-desktop {
    visibility: hidden;
}

.styled-select select {
    background: #ffffff;
    border: none;
    font-size: 15px;
    height: 50px;
    padding: 13px;
    font-weight: 100;
    width: 500px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    box-shadow: 4px 3px 20px 0px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.styled-select:after {
    color: #000000;
    text-align: center;
    pointer-events: none;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1px;
    font-family: Font Awesome\ 5 Pro;
    content: "\f078";
    top: 0px;
    left: -43px;
    font-size: 20px;
    position: relative;
}

.home-search-container {
    margin-top: -30px;
    padding: 7px;
    text-align: center;
}

p.social-media-header {
    padding-bottom: 0px;
}

p {
    font-family: Arial, Helvetica, sans-serif;
    font-size: .9375rem;
    line-height: 1.66;
    color: #262626;
    letter-spacing: .03125rem;

}

p.new,
.titlefont {
    font-size: .9375rem;
    line-height: 1.66;
    color: #262626 !important;
    letter-spacing: .03125rem;
    padding-bottom: 0;
}

.titleTag {
    font-size: 2rem;
    letter-spacing: .3125rem;
    margin-bottom: 1.125rem;
    line-height: 1.25;
}

.titleTag.exlarge {
    font-size: 5vw;
    line-height: 5vw;
}


.titleTag.large {
    font-size: 3.4375rem;
    line-height: 3.625rem;
}

.titleTag.lesspace {
    letter-spacing: .1125rem;
}

.titleTag.nobtmborder:after {
    display: none;
}

.newbutton {
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-size: .625rem;
    letter-spacing: .3em;
    line-height: 1.3em;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-weight: 700;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    min-width: 130px;
    padding: 12px 20px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: background-color .3s ease-out, color .3s ease-out, border-color .3s ease-out;
    transition: background-color .3s ease-out, color .3s ease-out, border-color .3s ease-out;
    background-color: transparent;
    border: 1px solid #2B201A;
    color: #2B201A;
    padding: 7px 20px;
}

.newbutton.text-white {
    border: 1px solid #fff;
}

.newbutton:hover {
    background: #2B201A;
    color: #fff !important;
}

.newbutton.text-white:hover {
    background: #fff;
    color: #2B201A !important;
}

.highlighted-text {
    font-family: 'Gotham light', sans-serif, sans !important;
    font-size: 15px;
    font-weight: 300 !important;
    line-height: 24px !important;
    color: #736C68 !important;
    padding-bottom: 15px;
    text-align: justify;
    padding-left: 10px;
    padding-top: 10px;
    padding-right: 10px;
}

.highlighted-text b,
i {
    font-style: italic;
    font-weight: normal;
}

.highlighted-text-offers {
    line-height: 33px !important;
    font-family: 'Gotham light', sans-serif, sans !important
}

.offer-offer-details {
    border: 1px solid #eee;
    border-top: none;
    padding: 40px;
}

.offer-terms-text {
    margin: 30px;
}

.error {
    color: #C30;
}



.navbar-default {
    background-color: #f8f8f8 !important;
    /* border-color: #f8f8f8 !important; */
}



.navbar-default .navbar-nav>.active>a {
    color: #FFFFFF;
}

.navbar-default .navbar-nav>li>a {
    color: #262626;
    text-align: center !important;
}

.navbar-default .navbar-nav>li:hover>a {
    color: rgb(133, 133, 133);
}

.navbar-default .navbar-nav>li .dropdown-menu.active {
    display: block;
    transform: translateY(0px);
    font-size: 12px;
    min-width: 280px;
}


.navbar-default .navbar-nav>.active>a:hover,
.navbar-default .navbar-nav>.active>a:focus {
    color: #FFFFFF;
    background-color: #246B80;
}

.navbar-nav {
    float: none !important;
    display: table;
    width: 100%;
    height: auto;
    margin: 0;
    text-transform: uppercase;
    font-size: 13px;
    /* letter-spacing: 1px; */
    min-height: 52px;

}

.navbar-nav .dropdown {
    cursor: pointer;
}

li.drop-menu {
    display: inline;
}

li#contact-us {
    border: 1px solid #f8f8f8;
    background-color: #f8f8f8;
    margin-left: 15px;
}

li.menu-item {
    font-size: 13px;
    letter-spacing: 0.1525em;
}

li#contact-us:hover,
li#contact-us:focus {
    border: 1px solid #dddede !important;
    background-color: #dcdcdc !important;
}

.ov-header {
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    height: 50px;
    width: 100%;
    z-index: 10000;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

@media only screen and (min-width:768px) {
    .ov-header {
        height: auto;
        box-shadow: none
    }
}

@media only screen and (min-width:1170px) {
    .ov-header {
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden
    }

    .ov-header.is-fixed {
        position: fixed;
        top: -140px;
        -webkit-transition: -webkit-transform .3s;
        -moz-transition: -moz-transform .3s;
        transition: transform .3s
    }

    .ov-header.is-visible {
        -webkit-transform: translate3d(0, 100%, 0);
        -moz-transform: translate3d(0, 100%, 0);
        -ms-transform: translate3d(0, 100%, 0);
        -o-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }
}

.ov-header.is-fixed .call-text,
.ov-header.is-fixed .call-icons,
.ov-header.is-fixed .contact-num {
    display: inline-block;
    padding: 20px;
}

.ov-header.is-fixed .call-text i,
.ov-header.is-fixed .call-icons i,
.ov-header.is-fixed .contact-num i {
    font-size: 18px;
}

.ov-header.is-fixed .contact-num i,
.ov-header.is-fixed .call-icons span {
    display: block !important;
}

.ov-header.is-fixed .contact-num {
    border: none;
    padding: 20px !important;
}

.ov-header.is-fixed li.call-us {
    padding: 0 10px !important;
}

.ov-header.is-fixed .call-text span,
.ov-header.is-fixed .contact-num span,
.ov-header.is-fixed .call-icons span {
    display: block;
    font-family: 'Bembo Regular' !important;
    line-height: 20px;
    font-size: 12px;
    letter-spacing: 2px;
    padding-top: 5px;
    color: #2c2c2c;
}

.ov-header.is-fixed .call-icons {
    padding: 10px;
}

.ov-header.is-fixed .call-us {
    margin: auto;
    overflow: hidden;
    padding: 20px;
    margin-top: 50px;
    position: relative;
    width: 200px;
}

.ov-header.is-fixed .call-us>div>div {
    float: left;
    position: relative;
    width: 200px;
    display: inline-block;
    height: 75px;
}

.ov-header.is-fixed .call-us>div {
    list-style: none;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 600px;
    transition: left .2s linear;
    -moz-transition: left .2s linear;
    -o-transition: left .2s linear;
    -webkit-transition: left .2s linear;
}

@-webkit-keyframes slide-animation {
    0% {
        opacity: 0;
    }

    2% {
        opacity: 1;
    }

    20% {
        left: 0px;
        opacity: 1;
    }

    22.5% {
        opacity: 0.6;
    }

    25% {
        left: -200px;
        opacity: 1;
    }

    47.5% {
        opacity: 0.6;
    }

    45% {
        left: -200px;
        opacity: 1;
    }

    50% {
        left: -400px;
        opacity: 1;
    }

    72.5% {
        opacity: 0.6;
    }

    70% {
        left: -400px;
        opacity: 1;
    }

    75% {
        left: -200px;
        opacity: 1;
    }

    95% {
        opacity: 1;
    }

    98% {
        left: -200px;
        opacity: 1;
    }

    100% {
        left: 0px;
        opacity: 1;
    }
}

.ov-header.is-fixed .call-us>div {
    -webkit-animation: slide-animation 20s infinite;
}

.ov-header.is-fixed .call-us>div:hover {
    -moz-animation-play-state: paused;
    -webkit-animation-play-state: paused;
}

.ov-header.is-fixed img#logo {
    margin-top: 15px !important;
    width: 80px;
}

#nav.affix {
    position: fixed !important;
    top: 0 !important;
    width: 100% !important;
    z-index: 10 !important;
}

#sidebar.affix-top {
    position: static !important;
}

#sidebar.affix {
    position: fixed !important;
    top: 80px !important;
}

a.left.carousel-control.main {
    position: absolute;
    top: 45%;
    z-index: 5;
    display: inline-block;
    margin-top: -10px;
    font-size: 50px;
}

a.right.carousel-control.main {
    position: absolute;
    top: 45%;
    z-index: 5;
    display: inline-block;
    margin-top: -10px;
    font-size: 50px;
}

a.right.carousel-control.main .fa-chevron-right:before {
    color: white !important;
}

a.left.carousel-control.main .fa-chevron-left:before {
    color: white !important;
}

p.small-menu {
    letter-spacing: 2px !important;
    font-size: 12px !important;
    color: #b48d48 !important;
    margin-left: 300px !important;
}

footer.footer {
    background-color: #efefef;
    padding-top: 20px;
}

p.info {
    font-family: 'Gotham light', sans-serif, sans !important;
    font-size: 13px !important;
    color: white !important;
    font-style: normal !important;
}

.btn-danger {
    color: #fff;
    background-color: #d9534f;
    border-color: #d43f3a;
    visibility: hidden;
}

.navbar {
    /* min-height: 116px !important; */
    padding-right: 15px;
    padding-left: 15px;
    z-index: 100 !important;
    background-color: #ffffff !important;
}

.navbar-default {
    width: 100% !important;
    background: white;
    /* border: 0px !important; */
    border-bottom: solid 0.2px #e3e3e3;
}



.dropdown-menu {
    border-radius: 0px 0px 0px !important;
    z-index: 2;
    width: 100% !important;
    padding: 0px;
    border: white;
    box-shadow: none;
    padding-top: 10px;
}

.nav>li.dropdown {
    position: static !important;
}

.nav>li.dropdown .dropdown-menu {
    position: fixed !important;
    max-width: 100% !important;
    text-align: center !important;
    border-top: 1px solid #dad7d7;
}

.dropdown:hover .dropdown-menu .dropdown:focus .dropdown-menu {
    display: block !important;
}

p.menu-text {
    height: 100px !important;
    border-top: none;
    border-bottom: none;
    border-left: none;
    text-align: center !important;
    line-height: 100px !important;
    font-size: 12px !important;
}

li.menu-col {
    border: 1px solid #dad7d7;
    border-top: none !important;
    border-bottom: none !important;
    border-left: none !important;
}

.bg-menu {
    height: 150px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}

#tawkchat-minified-container {
    background-color: #b48d48 !important;
}

.ov-sub-titles-xs {
    font-family: 'Gotham light', sans-serif, sans;
    font-size: 15px;
    color: #736C68;
    /*padding-bottom: 22px;*/
    margin-left: 10px;
    letter-spacing: 0px;
}

.water-villas {
    font-family: 'Gotham light', sans-serif, sans !important;
    font-size: 14px !important;
    color: black !important;
    padding-bottom: 15px;
    text-align: center;
    letter-spacing: 2px;
    margin-left: 10px;
    margin-top: 30px;
}

.hm-ov-desc {
    background-color: #ffffff;
    padding: 60px 30px 30px 30px;
    padding-bottom: 30px;
}

ul.holiday-type-list {
    font-family: 'Gotham light', sans-serif, sans;
    letter-spacing: 1px;
}

.home-hm-container {
    min-height: 490px;
    position: relative;
    margin-top: 30px;
    margin-bottom: 35px;
}

.cd-tabs-navigation li>a:hover {
    color: #b48d48;
    text-decoration: none;
}

.cd-tabs-navigation {
    padding: 0;
    list-style: none;
    margin-left: 15px;
    margin-top: 0px;
    font-family: 'Gotham light', sans-serif, sans !important;
    font-size: 10px !important;
    color: black !important;
}

.cd-tabs-navigation li {
    margin: 5px;
}

.cd-tabs-navigation li>a {
    display: block;
    font-size: 15px;
    color: #666;
    font-weight: 300;
}

.cd-tabs-navigation li>a.selected {
    background-color: #2B201A;
    color: white !important;
}

.hm-water-villa-holder {
    margin-top: 30px;
    margin-bottom: 3px;
}

.hm-water-villa-subtext {
    height: 400px !important;
}

.ov-hm-featured-goldbutton {
    color: black;
    font-size: 13px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    border-top: 1px solid #aeaeae;
    border-bottom: 1px solid #aeaeae;
    padding: 15px 25px;
    border-left: none;
    border-right: none;
}

.ov-hm-featured-goldbutton:hover {
    color: #0c242e !important;
    text-decoration: none;
    border-color: #0c242e !important;
}

.ov-holiday-type-container {
    background-color: #ffffff;
    padding: 40px 0px 20px 0px;
    margin-top: 40px !important;
}

.ai-icon {
    background-color: #fff;
    padding: 3px;
}

.ov-gold {
    color: #b1965b;
}

.offer-text-link {
    display: block;
    text-align: center;
    font-size: 14px;
    padding: 5px;
    color: #2B201A;
    padding-bottom: 39px !important;
    letter-spacing: 1px;
    font-family: 'Gotham light', sans-serif, sans;
}

.offer-text-link:hover {
    text-decoration: none;
    color: #b48d48;
}

.offer-resort {
    padding: 20px;
    background-color: #ffffff;
    margin-bottom: 1px;
}

.cd-tabs-content {
    margin: 0;
    padding: 0;
}

li.link {
    display: inline-block;
    color: black;
    border: 2px solid black;
    line-height: 32px;
    width: 32px;
    height: 32px;
    margin-top: 5px;
    text-align: center;
    margin-right: 20px;
    font-size: 16px;
    border-radius: 50%;
    padding-top: 5px;
}

p.social-text {
    display: inline-block;
    font-family: 'Gotham light', sans-serif, sans;
    margin: 0;
    color: #fff;
    font-size: 19px !important;
    vertical-align: middle;
    text-transform: uppercase;
    font-weight: normal;
    letter-spacing: 2px;
}

.cd-tabs-content li {
    display: none;
}

.cd-tabs-content li.selected {
    display: block;
    -webkit-animation: cd-fade-in 0.5s;
    -moz-animation: cd-fade-in 0.5s;
    animation: cd-fade-in 0.5s;
}

.breadcrumb {
    border-bottom: none;
    background-color: #f8f8f8;
    font-size: 12px;
    margin-left: 0px;
    color: #736C68;
}

.faqbox {
    padding-top: 20px;
    padding-bottom: 10px;
}

.faqlist {
    margin: 0px;
    list-style: none;
    padding: 0;
}

.wide-container {
    max-width: 100%;
    height: 380px;
    margin: 0 auto;
    margin-top: 1px;
    position: relative;
    text-align: center;
}

.nav-pills>li.active>a,
.nav-pills>li.active>a:hover,
.nav-pills>li.active>a:focus {
    background-color: #b48d48 !important;
    color: #ffffff;
}

a {
    font-family: 'Gotham light', sans-serif, sans;
}

.mv-header {
    border-bottom: 1px solid black;
    margin-bottom: 30px;
}

.mv-middle-col {
    padding: 20px;
    border-right: 1px solid #EFEEEE;
    border-left: 1px solid #EFEEEE;
}

.mv-left-col {
    padding-top: 20px;
    padding-right: 20px;
}

.mv-right-col {
    padding-top: 20px;
    padding-left: 20px;
}

.mv-h3 {
    text-align: center;
    border-top: 1px solid #EFEEEE;
    border-bottom: 2px solid #EFEEEE;
    padding: 10px 0;
    font-size: 16px;
    color: #000;
}

.icon-bg {
    padding: 20px;
    border: 1px solid #171930;
    border-radius: 50%;
    margin-bottom: 30px;
}

ul.top-bar {
    text-align: right;
    letter-spacing: 2px;
    font-size: 13px;
}

li.call-us {
    font-family: "Gotham Bold", sans-serif, sans;
    text-align: center;
    display: inline;
    color: #2B201A;
    font-weight: bold;
    line-height: 30px;
    background: #ffe40f !important;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 14px;
}


/*
  .afterverticalbackground:after{
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 27%;
    left: 7px;
    background:url(../images/pattern/09-paper-graphic.png) center top / contain no-repeat;

  }
  */

.call-text {
    font-size: 11px;
}

img#top-img {
    float: right;
}

#fullscreenvideo {
    width: 100%;
    height: calc(100vh - 117px);
    object-fit: cover;
    position: relative;
    left: 0px;
    top: 0px;
    z-index: -1;
}

a.contact-num {
    border: 1px solid #2B201A26;
    border-left: none;
    border-right: none;
    padding: 5px !important;
    display: block;
}

.mv-h4 {
    padding: 10px 0;
    font-family: 'Gotham light', sans-serif, sans;
    font-size: 14px;
    color: #666;
}

.mv-middle-para {
    padding-top: 10px;
    font-size: 11px;
    text-align: justify;
}

.mv-resort-list {
    display: none;
    padding: 20px 0px;
}

.mv-resort-listz {
    display: none;
    padding: 20px 0px;
}

.resort-tab-list {
    margin: 0;
    padding: 0 0 0 10px;
    list-style: none;
    font-size: 12px;
}

.resorts-listing-highlights {
    display: inline-block;
    padding: 10px;
    margin: 10px;
    text-align: center;
}

.resort-tab-list li {
    border-bottom: 1px dotted #EFEEEE;
    padding: 3px 0;
}

.resort-tab-list li:hover {
    background-color: #f6f6f6 !important;
    text-decoration: none !important;
}

.mv-blog-img {
    width: 150px;
    height: 150px;
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
}

.mv-tips-img {
    width: 60px;
    height: 60px;
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
}

.mv-tips-img-thumb {
    width: 120px;
    height: 120px;
    float: left;
    margin-right: 20px;
    margin-bottom: 10px;
}

.mv-faq-single-box {
    background-color: #ffffff;
    padding: 20px 40px;
    font-family: 'Gotham light', sans-serif, sans;
    font-size: 13px;
    text-align: center;
    border: double 3px #ffffff;
}

.breadcrumb>.active {
    color: #736C68 !important;
}

.faqlist>li {
    margin-bottom: 20px;
    font-size: 11px;
    border-bottom: 1px solid #EFEEEE;
}

#widget {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
}

a:focus,
a:hover {
    text-decoration: none !important;
}

ul.nav.nav-pills {
    font-size: 12px;
    padding: 5px;
}

.resort-tab-list li:hover {
    color: #b48d48;
}

.breadcrumb>li+li:before {
    padding: 0 5px !important;
    content: " / " !important;
}

.breadcrumb {
    list-style: none;
    background-color: #f8f8f800 !important;
    margin: 0px !important;
}

.breadcrumb>li.active a {
    color: #736C68 !important;
}

.breadcrumb a {
    color: #736C68;
}

.walllist>h3 {
    font-size: 18px;
    text-transform: uppercase;
    margin: 30px 0;
}

.blog-titles {
    font-family: 'Gotham medium', sans-serif, sans !important;
    letter-spacing: 1px;
    line-height: 24px;
    text-align: justify;
    text-transform: uppercase;
}

.blog-text {
    display: block;
}

.blog-read-more {
    text-transform: uppercase;
    font-family: 'Gotham Normal', sans-serif, sans;
    float: left;
    padding-top: 15px;
    font-size: 12px;
    line-height: 20px;
    position: relative;
    bottom: -57px;
    display: inline-block;
}

.blog-read-more:after {
    content: '';
    position: absolute;
    background: #383838;
    width: 100%;
    height: 1px;
    bottom: 2px;
    left: 0;
}

.ov-pagination>li:first-child>a,
.ov-pagination>li:first-child>span {
    border-left-width: 1px;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
}

.pull-right {
    float: right !important;
    list-style: none;
}

.ov-pagination>li>a,
.ov-pagination>li>span {
    float: left;
    padding: 4px 10px;
    line-height: 1.428571429;
    text-decoration: none;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-left-width: 0;
    font-size: 11px;
    color: #666;
    margin-top: 40px;
}

.ov-pagination>li {
    display: inline !important;
}

.resort-white-strip {
    background-color: #ffffff;
}

.ov-resort-listing-container {
    background-color: #fff;
    position: relative;
    width: 100%;
    border: 1px solid #EFEEEE;
    height: 480px !important;
}

.ov-resort-barefoot-container {
    border: none;
}

.ov-resort-listing-container-text {
    padding: 15px;
    width: 435px;
}

.resortThumb {
    width: 360px;
    height: 220px;
    background-color: #fff;
    margin-right: 25px;
    display: inline-block;
    float: left;
    position: relative;
}

.resortText {
    width: 420px;
    height: 220px;
    background-color: #fff;
    margin-right: 25px;
    display: inline-block;
    float: left;
    position: relative;
}

.resortTextx {
    width: 380px;
    height: 220px;
    background-color: #fff;
    margin-right: 25px;
    display: inline-block;
    float: left;
    position: relative;
}

.resortThumbR {
    width: 360px;
    height: 220px;
    background-color: #fff;
    margin-left: 0px;
    display: inline-block;
    float: right;
    position: relative;
}

.resortTextR {
    width: 420px;
    height: 220px;
    background-color: #fff;
    margin-right: 25px;
    display: inline-block;
    float: left;
    position: relative;
}

.resortQuoteR {
    width: 240px;
    height: 220px;
    background-color: #f3f1f1;
    display: inline-block;
    float: right;
    position: relative;
    font-size: 11px;
    padding-left: 30px;
    padding-top: 30px;
}

.resort-ups-list>li {
    font-size: 14px;
    text-align: left;
    line-height: inherit;
    margin-bottom: 10px;
}

.resort-list-titles {
    top: 150px;
    right: 124%;
    min-width: 356px;
    color: white;
    position: absolute;
    left: -735px;
    line-height: 36px;
    font-size: 32px !important;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    width: 800px;
}

.affordableluxury {
    position: relative;
    margin: 10px;
    padding: 3px;
    background-color: white;
}

.resort-list-ico {
    /* background-image: url(/ov/images/icon-resort-list.png); */
    display: inline-block;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    background-position: 30px 30px;
    background-repeat: no-repeat;
    cursor: pointer;
    margin-right: -1px !important;
    margin-left: -1px !important;
    padding: 0 !important;
}

.resort-list-ico-mp {
    background-position: 0 0;
}

.resort-list-ico-lc {
    background-position: -30px 0;
}

.resort-list-ico-tr {
    background-position: -60px 0;
}

.ov-resortlist-goldbutton {
    color: black;
    font-size: 13px;
    line-height: 36px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    border-top: 1px solid #aeaeae !important;
    border-bottom: 1px solid #aeaeae !important;
    padding: 15px 25px;
    background-color: transparent;
    border-left: none !important;
    border-right: none !important;
}

.ov-price>p {
    font-size: 18px;
    padding-left: 10px;
    color: white !important;
}

.ov-price {
    position: absolute;
    background-repeat: no-repeat;
    z-index: 1;
    width: 120px;
    height: 55px;
    display: block;
    left: 43px !important;
    top: 20px;
    font-family: 'Gotham light', sans-serif, sans;
    color: black !important;
}

.ov-priceR {
    color: white !important;
}

.border {
    height: 475px;
    margin-left: -14px;
}

.ov-priceR>p {
    padding-right: 10px;
    color: white !important;
    margin-left: 10px;
}

.ov-priceR {
    position: absolute;
    /* background-image: url(/ov/images/price-frame-right.png); */
    background-repeat: no-repeat;
    width: 120px;
    height: 55px;
    display: block;
    right: -5px;
    top: 20px;
    font-family: 'Gotham light', sans-serif, sans;
    color: #FFF;
    text-align: right;
}

.ov-resortlist-goldbutton:hover,
.ov-resortlist-goldbutton:focus {
    color: #b48d48 !important;
    text-decoration: none;
    border-color: #b48d48 !important;
}

.ov-experiences-listing-container-text {
    padding: 15px;
    font-size: 11px;
    width: 475px;
}

.spoimg {
    height: 180px;
    position: relative;
}

.spobox2017 {
    margin-bottom: 30px;
}

.spobox2017-wrpper {
    border: 1px solid #ccc;
}

.spobox2017-overlay-text {
    position: absolute;
    right: 10%;
    left: 10%;
    bottom: 0px;
    width: 80%;
    text-align: center;
    padding: 15px;
    z-index: 2;
    padding: 0;
    margin: 0;
    background-color: rgba(255, 255, 255, 0.4);
}

.spobox-mytitle {
    font-size: 18px;
    color: #000;
    font-family: 'Gotham light', sans-serif, sans;
    font-weight: 300;
}

.spobox2017-resortname {
    background-color: #EFEEEE;
    color: #000;
    padding: 10px;
    font-size: 11px;
    font-weight: bold;
}

.spobox2017-label {
    position: absolute;
    right: 10px;
    top: 10px;
    display: inline-block;
    font-size: 11px;
    padding: 5px;
    background-color: #71d2f2;
    color: #000;
    border-radius: 5px;
}

.spobox2017-action {
    padding: 10px;
}

button.ov-special-offers {
    color: black;
    font-size: 13px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    border-top: 1px solid #aeaeae;
    border-bottom: 1px solid #aeaeae;
    padding: 15px 25px;
    background-color: #f8f8f8;
    border-right: none;
    border-left: none;
}

.spobox2017-resortname {
    background-color: #EFEEEE;
    color: #000;
    padding: 10px;
    font-size: 11px;
    font-weight: bold;
    height: 130px;
}

.offer-right-box {
    margin-top: 30px;
}

img#offer-img {
    width: 262px;
}

.spo-cat-listing {
    display: block;
    font-family: 'Gotham light', sans-serif, sans;
    letter-spacing: 1px;
    text-decoration: none;
    border-bottom: 1px solid #ccc;
    line-height: 50px;
    text-align: right;
    color: #666;
    font-size: 15px;
    padding-right: 10px;
}

.spo-cat-listing:hover {
    text-decoration: none;
    background-color: white;
    color: #000;
}

.spoactive {
    text-decoration: none;
    background-color: #EFEEEE;
    color: #000;
}

.support-box {
    margin-top: 30px;
    min-height: 40px;
}

.support-box>h3 {
    margin: 0;
    padding: 5px;
    text-align: center;
    background-color: #ccc;
    font-size: 16px;
    text-transform: uppercase;
    color: #666;
    font-weight: normal;
    border-bottom: 1px solid #fff;
}

.bxslider-staff {
    width: 40px;
}

.call-us-spo {
    font-size: 11px;
    margin-top: 5px;
    text-align: right;
}

.support-box>h4 {
    margin: 0;
    padding: 5px;
    text-align: center;
    background-color: #666;
    font-size: 11px;
    color: #FFF;
    font-weight: normal;
    border-top: 1px solid #fff;
    letter-spacing: 1px;
}

button.ov-special-offers:hover,
button.ov-special-offers:focus {
    color: #b48d48;
    border-color: #b48d48;
}

.ov-sm-form-holder {
    position: relative;
    background-color: #eeeeee !important;
}

.ov-big-form-holder {
    margin-top: 20px;
    position: relative;
    background-color: #f6f6f6;
}

.ov-big-form-holder>h3 {
    margin: 0;
    background-color: #EFEEEE;
    padding: 10px;
    font-weight: 100;
    color: #333;
    text-align: center;
    font-weight: 100;
    font-size: 30px;
    width: 350px;
}

.ov-over-text {
    position: absolute;
    color: #FFF;
    font-size: 80px;
    font-family: sans-serif, sans;
    font-weight: bold;
    top: 0px;
    right: 0;
}

.ov-sm-form-holder>h3 {
    font-size: 14px;
    width: 100%;
    height: 75px;
    background-color: #5e3055;
    letter-spacing: 3;
    color: white !important;
}

.ov-dining-frame {
    min-height: 180px;
}

.myFormHeader {
    background-color: #eeeeee;
    color: #FFF;
    font-family: 'Gotham light', sans-serif, sans;
    font-size: 28px;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 2px;
    height: 92px;
    padding: 15px;
}

.ov-sm-form {
    padding: 10px;
}

.ov-light-input {
    background-color: #FFFFFF;
    border: 1px solid #cccccc
}

.readmore {
    position: absolute;
    bottom: 0;
    right: 0;
    display: inline-block;
    padding: 5px 10px;
    background-color: #dadada;
    font-size: 11px;
}

.ov-sm-form-holder>h3>span {
    font-size: 11px;
    font-weight: 100;
    text-transform: uppercase;
}

.ov-sm-form-holder {
    width: 362px;
    margin-left: 50px;
    z-index: 5;
    border-bottom: none;
}

.ov-sm-form-holder.offer {
    width: 362px;
    margin-top: 155px;
    z-index: 5;
    border-bottom: none;
    margin-left: 0px !important;
}

.resort-offer {
    border: 1px solid #2B201A;
    background-color: transparent;
    border-left: none;
    border-right: none;
    margin-top: 25px;
    width: 150px;
    line-height: 36px;
    font-weight: bold !important;
    font-size: 13px;
    text-align: center !important;
    padding-top: 5px;
    padding-bottom: 5px;
}

.fixedsection {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1000000;
    background: white;
    box-shadow: 0 0 12px rgba(48, 117, 157, .2);
}

#cover {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.9);
    z-index: 5;
    width: 100%;
    height: 100%;
    display: none;
    z-index: 9999998;
}

#one-big-cta-footer {
    margin: 70px 0 30px;
}

.cta-footer-heading {
    text-align: center;
    font-size: 32px !important;
    line-height: 38px !important;
    text-transform: uppercase;
    letter-spacing: 0.15em;
}

.fancybox-close-small {
    background-color: #171930;
}

.cta-action-box {
    display: inline-block;
    text-align: center;
    margin: 40px;
}

.cta-icon-text {
    font-size: 15px;
    line-height: 35px;
    color: #666;
    text-transform: uppercase;
}

blockquote {
    text-align: justify;
    font-family: 'Gotham Medium', sans-serif, sans;
    font-size: 13px;
    text-transform: uppercase;
    padding: 20px;
    margin: 0px;
    border-left: 0px solid #eee;
}

blockquote:before {
    font-family: Font Awesome\ 5 Pro !important;
    content: "\f10d" !important;
    position: absolute !important;
    left: 3px;
    margin-top: -5px;
    font-weight: 900;
    color: #000000ad;
    font-size: 16px;
}

blockquote:after {
    font-family: Font Awesome\ 5 Pro !important;
    content: "\f10e" !important;
    position: absolute !important;
    right: 0px;
    margin-top: -5px;
    font-weight: 900;
    color: #000000ad;
    font-size: 16px;
}

.cta-icon-text-sm {
    font-size: 13px;
    text-transform: capitalize;
}

.cta-icon-text i {
    font-size: 30px;
}

.white-text {
    color: #ffffff !important;
}

.offer-text-sub-titles {
    margin: 30px 0;
}

#SpoFormBox {
    position: fixed;
    width: 302px;
    z-index: 9999999;
    background-color: #666;
    left: 45%;
    display: none;
    border: 1px solid #333;
    margin-top: -166px;
    margin-left: -109px;
}

#weddingFormBox {
    position: fixed;
    width: 550px;
    z-index: 9999999;
    background-color: #666;
    left: 32%;
    display: none;
    border: 1px solid #000;
}

.ov-light-input {
    background-color: #FFFFFF;
    border: 1px solid #cccccc;
}

.small-input {
    font-size: 12px;
    padding: 2px;
    display: inline-block;
}

.resquickform tr td {
    font-size: 11px;
    color: #333;
    padding: 10px 0px;
    font-weight: normal !important;
}

.ov-request-list {
    color: black;
    font-size: 10px;
    line-height: 36px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    border-top: 1px solid #aeaeae !important;
    border-bottom: 1px solid #aeaeae !important;
    padding: 5px 25px;
    background-color: #EFEEEE;
}

.ov-request-list:hover {
    color: #b48d48;
    border-color: #b48d48 !important;
}

.fullscreen-uplayer {
    width: 360px;
    height: 100%;
    right: 15%;
    padding: 40px 20px;
}

.hm-fullscreen {
    position: relative;
    text-align: center;
    margin-top: 10px;
}

.fullscreen-uplayer {
    position: absolute;
    top: 0;
    right: 20%;
    background-color: rgba(255, 255, 255, 0.8);
    text-align: left;
    padding: 14px 20px !important;
}

.fs-heading {
    font-family: Arial;
    font-size: 16px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-weight: normal;
    line-height: 24px;
    letter-spacing: 1px;
    color: #b098a7;
}

.fs-text {
    font-family: 'Gotham light', sans-serif, sans;
    font-weight: 100;
    font-size: 10px !important;
    letter-spacing: 1px;
    color: #333;
}

.fs-text>span {
    font-size: 11px;
}

.fs-text-resort {
    font-size: 12px !important;
    height: 300px;
}

.hm-sliderbox {
    margin: 0 auto;
    width: 88%;
    padding: 10px 5px 5px 15px;
    border: 1px solid #EEE;
    -moz-box-shadow: 2px 2px 2px #d4d3d3;
    -webkit-box-shadow: 2px 2px 2px #d4d3d3;
    box-shadow: 2px 2px 2px #d4d3d3;
}

.hm-resortbox {
    padding: 5px;
    margin-bottom: 50px;
}

.hm-header-title {
    font-family: 'Gotham light', sans-serif, sans;
    font-weight: 300;
    text-align: center;
    font-size: 48px;
    line-height: 35px;
    margin: 40px 0 0 0;
    color: #333;
    border-bottom: 1px solid #EFEEEE;
}

.hm-header-subtitle {
    font-family: 'Gotham light', sans-serif, sans;
    font-weight: 300;
    text-align: left;
    font-size: 40px;
    line-height: 35px;
    margin: 20px 0 0 0;
    color: #333;
    border-bottom: 1px solid #EFEEEE;
    padding-bottom: 10px;
}

.hm-header-title>span {
    font-family: 'Gotham light', sans-serif, sans;
    font-size: 11px;
    color: #999;
}

.hm-header-text {
    text-align: center;
    font-family: 'Gotham light', sans-serif, sans;
    font-size: 14px;
    font-style: italic;
    color: #333;
    padding-top: 10px;
}

.hm-header-text-left {
    text-align: left;
    font-style: normal;
}

.hm-header-text>b {
    font-weight: normal;
}

.hm-header-text>strong {
    font-weight: normal;
}

.hm-header-text>i {
    font-style: normal;
}

.prof-quote {
    font-size: 12px;
    border: 1px solid #bfbfc7;
    border-left: none;
    border-right: none;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: -17px;
}

.roundednumber {
    position: relative;
    top: 24px;
    left: -20px;
    background-color: #EFEEEE;
    width: 50px;
    height: 50px;
    border: 1px solid #eee;
    display: block;
    font-family: 'Gotham light', sans-serif, sans;
    font-size: 26px;
    line-height: 50px;
    text-align: center;
    border-radius: 25px;
}

.hm-resort-title {
    padding: 5px 0 10px 40px;
}

.ov-packages {
    margin-top: 30px;
    padding-bottom: 40px;
}

.ov-testimonial-xs {
    font-family: 'Gotham light', sans-serif, sans;
    font-size: 13px;
    padding-top: 20px;
    /* background-image: url(/ov/images/testimonial.png); */
    background-repeat: no-repeat;
    background-position: right bottom;
    padding-bottom: 20px;
}

.ov-packages-box {
    background-color: #f1e9e9;
    padding: 0 0 40px 0;
    position: relative;
    border-bottom: 2px solid #e0d7d7;
}

.ov-packages-box>h4 {
    background-color: #e0d7d7;
    margin: 0;
    padding: 10px 20px;
    font-family: "Open Sans", arial;
    font-weight: 100;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.ov-packages-box>p {
    padding: 10px 20px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 100;
    font-size: 12px;
    min-height: 90px;
}

.ov-packages-box-ai {
    background-color: #f6f6f6;
    padding: 0 !important;
    min-height: 500px;
    position: relative;
    border-bottom: 2px solid #e1e1e1;
}

.ov-packages-box-ai>h4 {
    background-color: #aaa;
    margin: 0;
    padding: 20px 20px;
    font-family: "Open Sans", arial;
    font-weight: 100;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #FFF;
    line-height: 20px;
}

.ov-packages-box-ai>p {
    padding: 10px 20px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 100;
    font-size: 12px;
    min-height: 90px;
}

a.ov-honeymoon-list {
    color: black !important;
    font-size: 10px;
    line-height: 36px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    border-top: 1px solid #aeaeae;
    border-bottom: 1px solid #aeaeae;
    padding: 15px 25px;
}

.ov-right-corner {
    position: absolute;
    right: 172px;
    bottom: 17px;
}

img#honeymoon-img {
    width: 446px !important;
    height: 350px !important;
    margin-left: -30px;
}

a.ov-honeymoon-list:hover {
    color: #b48d48;
    border-color: #b48d48;
}

.resort-header-content {
    padding: 0px 60px;
    margin-top: 30px;
    background-color: #f6f6f6;
}

.fancy {
    line-height: 0.5;
    text-align: center
}

.fancy span {
    display: inline-block;
    position: relative;
}

.fancy span:before,
.fancy span:after {
    content: "";
    position: absolute;
    height: 3px;
    border-bottom: 1px solid #d0ba99;
    border-top: 1px solid #d0ba99;
    top: 0;
    width: 100%;
}

.fancy span:before {
    right: 100%;
    margin-right: 15px;
}

.fancy span:after {
    left: 100%;
    margin-left: 15px;
}

.resort-testimonial {
    font-family: Georgia, "Times New Roman", Times, serif;
    font-size: 16px;
    font-style: italic;
    padding: 60px 20px;
}

.resort-testimonial::before {
    color: #b29160;
    content: open-quote;
    display: block;
    float: left;
    font-size: 60px;
    margin: 5px 5px 0 0;
    padding-right: 10px;
    padding-top: 0px;
    text-shadow: 0 1px 1px #909090;
    line-height: 10px;
}

.resort-testimonial::after {
    color: #b29160;
    content: close-quote;
    float: right;
    font-size: 60px;
    margin-top: 30px;
    text-shadow: 0 1px 1px #909090;
    line-height: 10px;
}

.resort-sub-sections {
    text-align: center;
    border-top: 1px solid #EFEEEE;
    border-bottom: 1px solid #EFEEEE;
    padding: 10px 0px;
    color: #000;
    margin-bottom: 30px;
}

.white-panel {
    width: 31%;
    background: white;
    font-size: 14px;
    padding: 10px;
    margin: 10px;
    border: 1px solid #EFEEEE;
}

.white-panel-iteneraries {
    width: 48%;
    background: white;
    font-size: 14px;
    padding: 10px;
    margin: 1%;
    border: 1px solid #EFEEEE;
}

.itenerary-text {
    padding: 30px;
}

.white-panel h1 {
    font-size: 1em;
}

.white-panel h1 a {
    color: #A92733;
}

.pbContent {
    font-family: 'Gotham light', sans-serif, sans;
}

.pbTitles {
    padding: 30px 10px;
}

.pbHighlight {
    font-family: 'Gotham light', sans-serif, sans;
    color: #666 !important;
    font-size: 16px !important;
    line-height: 16px !important;
}

img.pbTopImg {
    width: 95%;
    margin: 10px 0;
}

img.pbBotImg {
    width: 100%;
    margin: 10px 0;
}

img.pbLeftImg {
    width: 40%;
    max-width: 60%;
    height: auto;
    margin-right: 10px;
    margin-bottom: 10px;
    float: left;
}

img.pbRightImg {
    width: 50%;
    max-width: 60%;
    height: auto;
    margin-left: 10px;
    margin-bottom: 10px;
    float: right;
}

.RoomTypeMenu {
    position: absolute;
    color: #fff;
    left: 50px;
    height: 40px;
    z-index: 1;
}

.btn-text {
    border: none !important;
    font-size: 15px;
    border-radius: 0px !important;
    color: white;
    padding: 10px;
    background-color: #2B201A;
    margin-left: -75px;
}

ul.dropdown-menu.dropdown-rooms.pull-right.drop {
    margin-left: -13px;
}

.dropdown-rooms li a {
    font-size: 14px;
    color: white !important;
    margin-left: 20px;
}

.dropdown-rooms {
    background-color: rgba(255, 255, 255, 0.7);
}

.dropdown-rooms li a {
    font-size: 14px;
    color: white !important;
}

.dropdown-menu>li>a:hover {
    background-color: transparent !important;
    background-image: none !important;
}

.btn-group.open .btn-success.dropdown-toggle {
    background-color: #eeeeee !important;
    color: black !important;
}

.list-villa-rooms {
    position: relative;
    display: block;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 5px;
    color: #FFF !important;
}

.villa-dine {
    position: relative;
    display: block;
    padding: 5px 30px 5px 15px;
    margin-bottom: 3px;
    font-size: 14px;
    font-weight: 300;
    color: #FFF !important;
}

a.list-villa-rooms:hover,
a.villa-dine:hover,
a.villa-dine:focus,
a.list-villa-rooms:focus {
    text-decoration: none;
    background-color: #666;
    color: #FFF;
}

a.list-villa-rooms.active,
a.villa-dine.active {
    z-index: 2;
    color: #FFFFFF;
    border-color: #dddddd;
    font-weight: 600;
    position: relative;
}

#roomDesc {
    font-size: 15px;
    font-weight: 300;
    line-height: 24px;
    text-align: justify;
    padding-top: 40px;
    padding-bottom: 0px;
}

.hero-text-room {
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -905%);
    color: white;
    font-size: 32px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    width: 800px;
}

.roomTextFac {
    padding: 20px 0 40px 0;
    position: relative;
}

.booknowpay-link {
    position: absolute;
    right: 20px;
    top: 0;
    padding-top: 10px;
}

.redtitle {
    color: #ff6464;
}

.clickhere {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    text-transform: uppercase;
}

.room-price {
    position: absolute;
    background-color: rgba(178, 145, 96, 0.9);
    z-index: 100;
    width: 173px;
    height: 50px;
    display: block;
    left: 20px;
    top: 30px;
    font-family: 'Gotham light', sans-serif, sans;
    color: #FFF;
    padding: 0;
}

.room-price>p {
    font-size: 28px;
    padding-left: 10px;
    padding-top: 2px;
    line-height: 50px;
    text-align: center;
}

.room-price>p>small {
    font-size: 11px;
}

.dineName {
    position: absolute;
    color: #FFF;
    right: 60px;
    width: 808px;
    padding-top: 40px;
}

.dineText {
    color: #000;
    padding-top: 70px;
    padding-right: 70px;
    padding-left: 70px;
}

#lm-offer-pop {
    position: fixed;
    right: 15px;
    top: 20%;
    width: 260px;
    padding: 0px;
    background-color: #EFEEEE;
    z-index: 200000;
}

#lm-offer-pop>h5 {
    background-color: #444a67;
    color: #FFF;
    text-align: center;
    margin: 0;
    padding: 15px 5px;
    font-family: 'Gotham light', sans-serif, sans;
    font-size: 18px;
    line-height: 24px;
    font-weight: 300;
}

.lmPopOfferSlide {
    margin: 0;
    padding: 0;
}

.lmPopOfferSlide li {
    padding: 30px 20px;
    width: 100% !important;
}

.lmPopOfferSlide li>h4 {
    font-family: 'Gotham light', sans-serif, sans;
    font-weight: normal;
    font-size: 16px;
    font-style: italic;
}

#close-lm-offers {
    color: #FFF;
}

.big {
    font-size: 20px !important;
}

#close-lm-offers:hover {
    color: #000;
}

.resort-usp-wrapper {
    padding: 40px;
}

.ov-lined-listx {
    list-style: none;
    text-align: left;
}

.ov-lined-listx li {
    font-size: 15px;
    margin-bottom: 20px;
    display: block;
    margin-right: 30px;
    text-align: left;
}

.ov-lined-listx li i {
    display: inline-block;
}

.ov-lined-new {
    margin: 0;
    text-align: left;
    min-height: 380px;
}

.ov-lined-new li {
    font-size: 15px;
    background-repeat: no-repeat;
    padding-left: 18px;
    margin-right: 20px;
    margin-bottom: 10px;
}

.room-pic-slider {
    margin: 0;
    padding: 0;
}

.room-details-wrapper {
    position: relative;
    padding: 60px;
}

.text-black {
    color: #000;
}

.new-resort-form {
    margin-left: -31px;
    margin-top: 48px;
}

.bxsliderReviews li {
    padding: 20px 100px;
}

.reviewimg {
    display: inline-block !important;
}

.resort-review-testimonial {
    margin: 1.5em 10px;
    padding: 0.5em 10px;
    quotes: "\201C" "\201D" "\2018" "\2019";
    text-align: center;
    font-family: Georgia, "Times New Roman", Times, serif;
    font-size: 15px;
    font-style: italic;
    color: #666;
}

.resort-review-testimonial:before {
    color: #ccc;
    content: "\201C";
    font-size: 6em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
}

*.resort-review-testimonial:after {
    color: #ccc;
    content: "\201D";
    font-size: 6em;
    line-height: 0.1em;
    margin-left: 0.25em;
    vertical-align: -0.6em;
}

.booknowBox {
    border-bottom: 1px solid #EFEEEE;
    border-left: 1px solid #EFEEEE;
    border-right: 1px solid #EFEEEE;
    padding: 20px;
    background-color: #f6f6f6;
}

.booknow-tb {
    margin-top: 20px;
}

.booknow-tb tr td {
    padding: 2px 20px 0px 2px;
    font-size: 12px;
}

.booknowinput {
    height: 30px;
}

.ui-datepicker {
    z-index: 90000000000;
}

.ov-blue {
    color: #171c35;
}

.booking-navi {
    list-style: none;
    font: Arial, Helvetica, sans-serif;
    font-size: 14px;
}

.numberrounded {
    display: inline-block;
    background-color: #EFEEEE;
    border-radius: 50px;
    font-weight: bold;
    padding: 5px 7px;
    margin-right: 15px;
}

.roundselected {
    background-color: #0C6;
    color: #fff;
}

.active-navi-tab {
    color: #0C6;
}

.booknowtitle {
    position: fixed;
    bottom: 0;
    padding: 1px;
    background-color: #ff6464 !important;
    color: #FFF !important;
    right: 10px;
    z-index: 20000000;
    width: 250px;
    border-radius: 8px 8px 0 0;
}

.font-arial {
    font-family: Arial, Helvetica, sans-serif;
}

.booknowfooter {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 100;
    font-size: 18px;
    display: block;
    padding: 5px 20px 5px 20px;
    color: #FFF;
}

.booknowfooter:hover,
.booknowfooter:active,
.booknowfooter:focus {
    color: #FFF;
    text-decoration: none;
}

#bookingForm {
    background-color: #eeeeee;
}

.resort-photo-tour {
    border: 1px solid #eeeeee;
    padding: 5px 10px;
    float: right;
    position: absolute;
    top: 210px;
    right: 55px;
}

.resortfaclist {
    text-align: center;
}

.resort-facilities-list-item {
    display: inline-block;
    color: #666;
    width: 5%;
    line-height: 11px;
    vertical-align: top;
}

#resort-facilities .img-container {
    height: 30px;
}

.resort-facilities-list-item img {
    width: 26px;
}

.ico-resort-fac {
    margin: 5px;
    opacity: 0.4;
    cursor: pointer;
    width: 30px;
}

div#DineMenu {
    margin-left: 14px;
}

.roomText {
    padding: 20px 30px 40px 30px;
    position: relative;
}

.btn-success {
    color: #fff;
    background-color: #666 !important;
    border-color: #666;
}

.bx-wrapper {
    position: relative;
    padding: 0;
}

.btn-group>.btn+.dropdown-toggle {
    padding-right: 8px;
    padding-left: 8px;
}

blockquote.offer-page {
    padding-top: 80px;
    padding-left: 100px;
    overflow: hidden;
    font-size: 14px !important;
    text-align: justify;
    width: 500px;
    line-height: 175%;
    color: black;
    font-family: 'Gotham light', sans-serif, sans;
    background-color: #eeeeee !important;
}

.ico-resort-fac:hover {
    opacity: 1.0;
}

#scrollup {
    display: none;
    position: fixed;
    bottom: 40px;
    right: 5px;
    width: 30px;
    height: 50px;
    padding: 10px 3px 3px 3px;
    background-color: #666666;
}

img#banner-img {
    margin-left: 24px;
    width: 1092px;
}

.vw-features>ul {
    margin: 0;
    padding: 2px 20px;
}

.vw-features {
    padding-top: 20px;
}

.pic-price {
    position: absolute;
    /* background-image: url(/ov/images/water-villas/picture-price.png); */
    background-repeat: no-repeat;
    width: 120px;
    height: 55px;
    display: block;
    left: 10px;
    top: 20px;
    font-family: 'Gotham light', sans-serif, sans;
    color: white !important;
}

.wv-bullet-frame>h3 {
    background-color: #F0F0F0;
    padding: 10px 30px;
    margin: 0;
    font-family: 'Gotham light', sans-serif, sans;
    font-size: 32px;
    color: #999;
    font-weight: 100;
    height: 80px;
    font-size: 25px;
}

.wv-bullet-frame {
    border: 1px solid #EFEEEE;
    padding: 0;
    min-height: 230px;
    position: relative;
}

.goldbutton {
    color: black !important;
    font-size: 13px;
    line-height: 36px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    border-top: 1px solid #aeaeae;
    border-bottom: 1px solid #aeaeae;
    padding: 15px 25px;
    border-left: none;
    border-right: none;
    background-color: #f8f8f8;
}

.wv-list-frame {
    height: 253px;
    padding: 20px;
}

.goldbutton:hover {
    color: #b48d48;
    border-color: #b48d48;
}

.wv-bullet-frame>h3 {
    font-family: 'Gotham light', sans-serif, sans;
    font-size: 32px;
    color: #999;
    font-weight: 100;
    font-size: 25px;
}

button.ov-resortlist-goldbutton {
    color: black !important;
    font-size: 13px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    border-top: 1px solid #aeaeae;
    border-bottom: 1px solid #aeaeae;
    padding: 6px 25px;
    background-color: transparent;
    border-left: none;
    border-right: none;
}

.pv-frame {
    border: 1px solid #EFEEEE;
    height: 278px;
    position: relative;
}

.pv-frame>h3 {
    background-color: #F0F0F0;
    padding: 10px 30px;
    margin: 0;
    font-family: 'Gotham light', sans-serif, sans;
    font-size: 32px;
    color: #999;
    font-weight: 100;
    height: 80px;
    font-size: 25px;
}

.villa-corner {
    position: absolute;
    right: 124px;
    bottom: 17px;
}

input.nl-txtbox.required.email {
    font-family: 'Gotham light', sans-serif, sans;
    border: 2px solid #fff !important;
    background-color: transparent !important;
    color: #fff;
    padding: 5px 10px !important;
    display: inline-block;
    vertical-align: middle;
    line-height: 40px;
    width: 290px !important;
    height: 40px !important;
    border-radius: 0;
}

img#footer-img {
    float: left;
    padding-top: 20px;
    padding-left: 25px;
}

.footer-button {
    display: inline;
    color: white;
    border: 1px solid #b1965b;
    background-color: #b1965b;
    vertical-align: middle;
    min-width: 135px;
    text-align: center;
    padding: 7px 10px;
    height: 40px !important;
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    margin-left: 10px !important;
    letter-spacing: 1px;
    margin-top: -10px;
}

.footer-button:hover,
.footer-button:focus {
    background-color: #b1965b !important;
    border-color: #b1965b !important;
}

h4.social-text {
    display: inline-block;
    font-family: 'Gotham light', sans-serif, sans;
    margin: 0;
    font-size: 25px;
    vertical-align: middle;
    text-transform: uppercase;
    font-weight: normal;
    float: left;
    padding-top: 9px;
    color: black !important;
    letter-spacing: 1px;
}

.ov-resortlist {
    color: black !important;
    font-size: 9px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    border-top: 1px solid #aeaeae;
    border-bottom: 1px solid #aeaeae;
    padding: 15px 25px;
    margin-left: -27px;
    border-left: none;
    border-right: none;
}

.resort-corner {
    margin-left: 36px;
}

.ov-resortlist:hover,
.ov-resortlist:focus {
    color: #b48d48;
    border-color: #b48d48;
}

.experience-btn {
    width: 240px;
    height: 220px;
    background-color: #f3f1f1;
    display: inline-block;
    float: right;
    position: relative;
    font-size: 11px;
    padding-left: 30px;
    padding-top: 90px;
}

.ai-price {
    position: absolute;
    /* background-image: url(/ov/images/all-inclusive/pricetag.png); */
    background-repeat: no-repeat;
    z-index: 1;
    width: 120px;
    height: 55px;
    display: block;
    left: -5px;
    top: 20px;
    color: #FFF;
}

.ai-content {
    padding: 20px;
}

.ov-sub-headings-xs {
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
    color: #333;
    letter-spacing: 2px;
}

.ai-planner {
    background-color: #d2d2d2;
}

.ai-planner-body {
    padding: 20px 30px;
    color: black !important;
}

.ai-inclusion {
    margin: 0;
    padding: 0 0 30px 0;
    list-style: none;
    font-size: 12px;
}

.ai-resortbox {
    padding: 5px;
    margin-bottom: 50px;
    margin-top: 50px;
    background-color: white;
}

.inclusive-text-resort {
    font-size: 12px !important;
}

.ai-inclusion>li {
    display: inline-block;
    padding: 2px 10px;
    border-right: 1px solid black;
    line-height: 15px;
}

.resort-corner {
    position: absolute;
    right: 70px;
    bottom: 17px;
}

.extra-ov-story {
    border: 1px solid #EFEEEE;
    background-color: white;
    padding-top: 25px;
    padding-right: 26px;
}

.bluecolor {
    color: #64bf66;
}

h3.heading {
    font-family: 'Gotham light', sans-serif, sans !important;
    letter-spacing: 2px;
    font-size: 13px !important;
}

p.desc {
    font-family: 'Gotham light', sans-serif, sans !important;
    color: white !important;
    font-size: 12px !important;
    font-style: normal !important;
}

p.order-list {
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 10px;
    text-align: center;
    border: 1px solid #b48d48;
    background-color: white;
}

p.order-list:hover {
    background-color: #b48d48;
    color: white !important;
}

h2.main-titles {
    text-transform: uppercase;
    letter-spacing: 2px;
    color: black !important;
    font-size: 23px;
}

img#resort-img {
    width: 350px;
    height: 257px;
}

ul.info {
    font-size: 13px;
}

.cool-link {
    display: inline-block;
    color: #2B201A;
    text-decoration: none;
}


li.dropdown:hover .cool-link {
    background-color: white !important;
}

li.head-top a {
    display: inline;
    float: right;
    padding-left: 30px;
    font-size: 12px;
    letter-spacing: 0px;
    color: white !important;
}

ul.top {
    padding-top: 10px;
    list-style: none;
}

.navbar {
    margin-bottom: 0px;
    border-radius: 0;
}

.head-top:hover {
    color: #171930;
}

.carousel-control {
    border: none !important;
    background: none !important;
}

.carousel-indicators.extras li {
    display: inline-block !important;
    float: none !important;
    width: 7px;
    height: 7px;
    margin-left: 15px;
    text-indent: -999px;
    background-color: rgba(255, 255, 255, 0.25);
    border-radius: 5px;
    border: none !important;
}

.carousel-indicators.extras .active {
    display: block;
    float: left;
    width: 7px;
    height: 7px;
    margin-left: 15px;
    text-indent: -999px;
    border-radius: 5px;
    background-color: #171930;
    margin-top: 4px !important;
}

div.resort-title {
    color: black;
    font-size: 32px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    padding-bottom: 27px;
    font-weight: 100;
    line-height: 1;
}

span.resort-title {
    text-transform: uppercase !important;
    font-weight: bold;
    letter-spacing: 1;
    color: black;
}

.resort-title-listing {
    text-transform: uppercase !important;
    font-weight: bold;
    letter-spacing: 1;
    color: #2B201A;
}

.resort-col {
    padding: 20px;
    padding-bottom: 25px !important;
}

.plus-sign {
    text-align: -webkit-center;
    text-align: center;
    padding-top: 30px;
}

.resort-subtext {
    padding-left: 20px;
    padding-right: 20px;
}

hr.line-villa {
    display: none;
    width: 30px;
    height: 0px;
    background: #aeaeae;
    bottom: 0;
    left: 0;
    margin-bottom: 45px;
    margin-top: 35px;
}

.mi-slider ul {
    bottom: 120px !important;
}

.image-title {
    font-size: 20px;
    padding-top: 50px;
    padding-bottom: 25px;
    text-transform: uppercase;
    font-weight: 500;
}

hr.sub-line {
    width: 30px;
    height: 1px;
    background: #aeaeae;
    bottom: 0;
    left: 0;
    margin-bottom: 35px;
}

.center {
    text-align: -webkit-center;
    text-align: center;
}

.hero-img {

    background-repeat: no-repeat !important;
    background-size: cover !important;
    position: relative;
    height: calc(100vh - 450px);
    display: none;
}

.hero-text {
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -732%) !important;
    color: white;
    font-family: 'Gotham Medium', sans-serif, sans;
    font-size: 32px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    line-height: 27px;
    width: 75%;
}

.hero-text-villa {
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -905%);
    color: white;
    font-size: 32px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    width: 800px;
}

.hero-resort-page {
    transform: translate(-50%, -550%);
    text-align: center;
    position: absolute;
    left: 50%;
    color: white;
    font-size: 32px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
}

.hero-text-resort {
    text-align: center;
    position: absolute;
    left: 57%;
    transform: translate(-50%, -905%);
    color: white;
    font-size: 32px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    width: 800px;
}

.resort-txt {
    padding-top: 15px !important;
    font-family: 'Gotham light', sans-serif, sans !important;
    font-size: 14px;
}

.packages-txt {
    padding-top: 15px !important;
    font-family: 'Gotham light', sans-serif, sans !important;
    text-transform: capitalize;
    font-size: 14px;
    position: absolute;
    top: 0px;
    left: 0px;
}

.row.center {
    border: solid transparent;
    border-width: thin;
}

.white-bg {
    background-color: white;
    padding-top: 20px;
    padding-bottom: 20px;
}

a.dropdown-toggle {
    font-family: 'Gotham light', sans-serif, sans;
}

a.cool-link {
    font-family: "Gotham Bold", sans-serif, sans;
    letter-spacing: 0px;
}

.no-padding {
    padding: 0px !important;
}

.ih-item.square {
    position: relative;
    width: 100% !important;
    height: 100% !important;
    border: none !important;
}

.resort-lists {
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.resort-title-container {
    text-align: left !important;
}

img#resort-list-img {
    height: 200px;
    width: 100%;
    padding-bottom: 20px;
    position: relative;
}

td.row-resort {
    padding: 10px;
    padding-bottom: 15px !important;
    font-size: 11px;
}

.resort-price {
    display: inline-block;
    border: 1px solid #2B201A;
    border-left: none;
    border-right: none;
    width: 150px;
    line-height: 36px;
    font-weight: bold;
    font-size: 13px;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
}

span.price-tag {
    text-align: center !important;
    background-color: #2B201A;
    color: white;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 50px;
    padding-right: 52px;
}

.whole-col {
    opacity: 1;
    display: block;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
}

.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%)
}

.resort-lists:hover .whole-col {
    opacity: 0.1;
}

.resort-lists:hover .middle {
    opacity: 1;
}

.black-button-links {
    background-color: #2B201A;
    color: white !important;
    font-size: 14px;
    padding: 10px 25px;
    width: 200px !important;
}

.resort-hover-list {
    text-align: left;
    width: 300px;
    padding: 20px;
}

a.sticky-button {
    font-family: 'Gotham light', sans-serif, sans;
    text-transform: uppercase;
    padding: 12px;
    padding-left: 30px;
    background-color: #171930;
    padding-right: 30px;
    font-weight: 700;
    border: none;
    height: 40px;
    line-height: 3;
}

#fixedbutton {
    position: fixed !important;
    bottom: 0px !important;
    right: 0px !important;
}

.navbar-fixed-bottom {
    bottom: 0;
    height: 40px !important;
    background-color: white;
}

.popup {
    width: 100%;
    height: 100%;
    display: none;
    position: fixed;
    top: 0px;
    left: 0px;
    background: rgba(0, 0, 0, 0.2);
    z-index: 10000000;
}

.popup-inner {
    max-width: 1200px;
    width: 90%;
    padding: 40px;
    padding-bottom: 10px !important;
    padding-top: 20px !important;
    position: absolute;
    top: 85%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 1);
    border-radius: 58px;
    background: #fff;
}

.popup-close {
    width: 30px;
    height: 30px;
    padding-top: 4px;
    color: white !important;
    display: inline-block;
    position: absolute;
    top: 0px;
    right: 0px;
    transition: ease 0.25s all;
    transition: ease 0.25s all;
    -webkit-transition: ease 0.25s all;
    -moz-transition: ease 0.25s all;
    -o-transition: ease 0.25s all;
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
    border-radius: 1000px;
    background: rgba(0, 0, 0, 0.5);
    font-family: Arial, Sans-Serif;
    font-size: 20px;
    text-align: center;
    line-height: 100%;
    color: #fff;
}

.popup-close:hover {
    -webkit-transform: translate(50%, -50%) rotate(180deg);
    transform: translate(50%, -50%) rotate(180deg);
    background: rgba(0, 0, 0, 1);
    text-decoration: none;
}

input {
    font-family: 'Gotham light', sans-serif, sans;
}

td.tbl-form {
    padding-left: 3px;
}

a.form-btn {
    padding: 7px;
    padding-left: 30px;
    background-color: #171930;
    padding-right: 30px;
    font-weight: 700;
    border: none;
    height: 40px;
    margin-left: 500px !important;
}

select {
    font-family: 'Gotham light', sans-serif, sans !important;
}

label {
    display: block;
    font-weight: normal;
}

.ov-form-input-holder {
    margin: 7px 0;
}

#inquirypopupNew .ov-form-input-holder,
#inquirypopupNewfrance .ov-form-input-holder,
#inquirypopupNewarabic .ov-form-input-holder,
#bookwhenever .ov-form-input-holder {
    margin: 3px 0;
}

.ov-long-form-textarea {
    height: 70px !important;
}

.input-ov {
    border-radius: 0;
    box-shadow: none;
    -webkit-box-shadow: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.btn-ov {
    color: #FFF;
    border-color: #2B201A;
    border-radius: 0;
    padding: 10px 20px;
    background: #2B201A;
    letter-spacing: 0;
    text-transform: uppercase;
}

.btn-ov:focus,
.btn-ov.focus {
    color: #FFF;
    background-color: #2B201A;
    border-color: #2B201A;
}

.btn-ov:hover {
    color: #2B201A;
    background-color: #171930;
    border-color: #171930;
}

.btn-ov:active,
.btn-ov.active,
.open>.dropdown-toggle.btn-ov {
    color: #FFF;
    background-color: #2B201A;
    border-color: #2B201A;
}

.btn-ov-cta {
    color: #fff !important;
    letter-spacing: 0px;
    width: 220px;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
}

.btn-ov-cta:hover {
    color: #000 !important;
}

img#villa-img {
    width: 420px;
    height: 350px;
    position: relative;
}

.subtitles {
    text-transform: uppercase;
    font-weight: 600;
    padding-top: 10px !important;
}

.text-img {
    position: absolute;
    z-index: 2;
    top: 15px;
    left: 100px;
    right: 100px;
    bottom: 15px;
    padding-top: 80px;
    padding-bottom: 40px;
    background: rgba(0, 0, 0, 0.4);
    color: white !important;
}

p.villa-img-text {
    color: white !important;
    padding-top: 10px;
    padding-bottom: 20px;
}

a.resort-button {
    color: #ffffff !important;
    font-size: 13px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    border-top: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
    padding: 15px 25px;
    margin-left: 10px;
    border-left: none;
    border-right: none;
}

.recommend {
    border: 1px solid #2B201A;
    color: white;
    background-color: #2B201A;
    padding: 8px;
    font-size: 23px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
}

div#myCarousel-resort {
    top: -41px !important;
}

hr.white-line {
    border-width: 3px;
}

span.yellow {
    color: #171930;
}

ul.bullet-style {
    padding: 20px !important;
}

.carousel-control.left {
    background: none;
}

.carousel-control.right {
    background: none;
    right: 0;
}

.carousel-control {
    position: relative;
    width: 15%;
    font-size: auto;
    color: #fff;
    text-align: center;
    opacity: 1;
    text-shadow: none;
    display: flex;
    justify-content: center !important;
    top: 0;
    margin-top: 0;
}

.carousel-control:hover {
    color: #000;
}

.carousel-indicators.extras {
    bottom: -50px;
}

.carousel-caption {
    position: absolute;
    width: 300px;
    height: 470px;
    right: 27px !important;
    left: 0px !important;
    bottom: 0;
    margin-left: 640px;
    z-index: 10;
    padding-top: 43px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
    background: rgba(0, 0, 0, 0.4);
}

img#restaurant-img {
    width: 917px !important;
    height: 470px !important;
}

p.ov-white-txt {
    color: white !important;
}

.grey-bg {
    background-color: #eeeeee;
    height: 566px;
    padding-top: 60px;
    padding-left: 90px;
    padding-right: 155px;
}

.bg-img {
    /* background-image: url(/ov/assets/images/bedroom.jpg); */
    background-repeat: no-repeat;
    background-size: cover;
    height: 600px;
    position: relative;
}

.white-txt {
    color: white !important;
}

p.blah {
    position: absolute;
    text-align: center;
    transform: translate(1000%, -45%) !important;
}

.carousel-control {
    width: 40px;
    height: 30px;
}

div#a {
    margin-top: 5px;
}

div.b {
    display: none;
    padding: 30px;
    height: 300px !important;
    width: 100%;
    text-indent: 5px;
    background-color: #ffffff !important;
}

div#a:hover div.b {
    display: block;
}

.b div {
    border: 1px solid #00000026;
    padding: 5px 0px;
    margin: 3px;
    line-height: 2;
}

div#a:hover img#resort-list-img {
    display: none !important;
}

div#a:hover div.resort-title {
    display: none !important;
}

div#a:hover span.resort-subtext {
    display: none !important;
}

div#a:hover hr.resort-line {
    display: none !important;
}

div#a:hover .filter {
    display: none !important;
}

div#a:hover .resort-price {
    display: none !important;
}

div#a:hover span.price-tag {
    display: none !important;
}

ul.resort-ups-list {
    background-color: white;
    text-align: left !important;
    padding-bottom: 40px;
    margin: 40px 0px 30px -20px;
}

.hotel-rate {
    text-align: right;
    margin: 20px 0px;
    line-height: 24px;
    letter-spacing: 3px;
    text-transform: uppercase;
    width: auto;
}

.experience-list {
    border: 1px solid #d6d6d6;
    padding-top: 50px;
    margin-top: -45px;
    padding-bottom: 40px;
}

.experience-list:hover {
    border: 1px solid #aeb597;
    background-color: #f5f3ee;
}

div.experience-text {
    line-height: 2;
    padding: 20px;
}

img#exp-img {
    border: 1px solid #d6d6d6;
}

div.barefoot-resort:active div.ov-resort-listing-container {
    border: 1px solid pink !important;
}

img#exp-img2 {
    border: 1px solid #d6d6d6;
    position: relative;
}

.resort-list-titles2 {
    font-weight: 300;
    letter-spacing: 2px;
    font-family: 'Gotham light', sans-serif, sans;
    text-transform: uppercase;
    position: absolute;
    top: 34.1%;
    left: 35.2%;
    background-color: rgba(0, 0, 0, 0.5);
    width: 347px;
    padding-bottom: 5px;
}

a.white-text {
    color: white !important;
}

.resort-list-titles3 {
    font-weight: 300;
    letter-spacing: 2px;
    font-family: 'Gotham light', sans-serif, sans;
    text-transform: uppercase;
    position: absolute;
    top: 28.4%;
    left: 20.2%;
    background-color: rgba(0, 0, 0, 0.5);
    width: 348px;
    padding-bottom: 5px;
}

img#top-pick {
    width: 234px;
    height: 285px;
    position: relative;
    padding-bottom: 10px;
}

h3.top-text {
    font-size: 14px;
    color: #2B201A;
    margin-top: 20px;
    margin-bottom: 20px;
}

.resort-type {
    font-weight: bold;
    padding-top: 20px;
    color: #15a228 !important;
}

.filter {
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 1;
    top: -50px;
}

.col-1 {
    left: 4.7% !important;
}

.col-2 {
    left: 0.7% !important;
}

.col-3 {
    right: 3.7% !important;
}

.affordable-price {
    font-weight: bold !important;
    font-size: 13px;
    text-align: center !important;
}

div.i {
    position: relative;
    display: inline-block;
    width: 9px;
    height: 9px;
    padding-bottom: 47px;
}

div.x {
    position: relative;
    display: inline-block;
    width: 9px;
    height: 9px;
    padding-bottom: 30px;
    margin-top: 47px;
}

span.bar-1 {
    position: absolute;
    display: block;
    top: 0;
    left: 4px;
    width: 1px;
    height: 9px;
    background: #2B201A;
}

span.bar-2 {
    position: absolute;
    display: block;
    top: 4px;
    left: 0;
    width: 9px;
    height: 1px;
    background: #2B201A;
}

.barefoot-resort {
    font-weight: bold;
    padding-top: 20px;
    color: #15a228 !important;
}

.top-padding {
    padding-top: 10px;
}

.platinum-color {
    background-color: #eeeeee;
}

.gold-color {
    background-color: white;
}

.silver-color {
    background-color: #eeeeee;
}

h3.villa-title {
    position: absolute;
    top: 300px;
    left: 295px;
    font-size: 22px;
    color: white;
    width: 550px;
    background-color: rgba(0, 0, 0, 0.5);
    font-weight: 100;
}

.villa-text.center {
    border: 1px solid #EFEEEE;
    padding: 20px;
}

.offer-category-titles {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    padding: 0 15px;
    color: #fff;
}

.special-offers-box {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
    border-top: 1px solid #e5e1e1;
    border-bottom: 1px solid #e5e1e1;
}

.special-offers-btn {
    background-color: transparent;
    border-style: none;
    border-top: 1px solid #2B201A;
    font-size: 14px;
    font-family: 'Gotham light', sans-serif, sans;
    border-bottom: 1px solid #2B201A;
    padding: 10px;
}

.special-offers-col {
    padding-top: 10px;
    padding-bottom: 10px;
    border-left: 1px solid #edeaea;
    border-right: 1px solid #edeaea;
}

.uppercase {
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1;
    padding: 20px;
}

.more-info {
    padding-top: 80px;
    padding-bottom: 80px;
}

div.offers {
    width: 90%;
    margin: 50px auto;
    -moz-column-width: 220px;
    -webkit-column-width: 220px;
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
    -moz-column-gap: 20px;
    -webkit-column-gap: 20px;
    column-gap: 64px;
}

a[title] figure img {
    width: 100%;
    height: auto;
    margin-top: 1rem;
}

div.offers a[title] {
    background-color: white;
    padding-bottom: 50px;
    text-decoration: none;
    color: #424242;
    display: inline-block;
    margin-bottom: 64px;
    transition: all .3s cubic-bezier(0.25, .8, .25, 1);
}

main.offers {
    width: 90%;
    margin: 50px auto;
    -moz-column-width: 220px;
    -webkit-column-width: 220px;
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
    -moz-column-gap: 20px;
    -webkit-column-gap: 20px;
    column-gap: 64px;
}

a[title] figure img {
    width: 100%;
    height: auto;
    margin-top: 1rem;
}

main.offers a[title] {
    background-color: white;
    padding-bottom: 50px;
    text-decoration: none;
    color: #424242;
    display: inline-block;
    margin-bottom: 64px;
    transition: all .3s cubic-bezier(0.25, .8, .25, 1);
}

img#grayscale {
    position: relative;
    padding-bottom: 10px;
}

img#grayscale:hover,
img#grayscale:focus {
    filter: gray;
    -webkit-filter: grayscale(1);
}

img#special-offers-img {
    width: 234px !important;
}

.color-layer {
    background-color: #eeeeee;
    padding-top: 30px;
    padding-bottom: 30px;
}

.offer-listing-section {
    margin-top: 100px;
}

.offer-listing-title {
    margin-top: 20px;
    display: inline-block;
}

.offer-listing-wrap {
    background-color: #fff;
    margin: 15px 0;
    position: relative;
    min-height: 450px;
}

.offer-listing-text {
    padding: 0px;
    position: relative;
}

.offer-listing-desc {
    margin-top: 15px;
    padding: 0;
}

.plus-sign-for-more-wrap {
    padding: 10px 0;
}

.spo-image-list-thumb {
    height: 250px;
}

.offer-listing-text h3 {
    font-family: Verdana, Geneva, sans-serif !important;
}

.offer-listing-action {
    position: absolute;
    right: 0;
    bottom: 20px;
}

.offer-listing-gimics {
    list-style: none;
    margin-top: 20px;
    color: #666;
}

.plus-sign-for-more {
    color: #666;
}

#category_list {
    margin-bottom: 50px;
    background-color: #f5f5f5;
}

.plus-sign-for-more .fa-plus:hover {
    transition: 0.2s;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

#cta-credit {
    margin-bottom: 30px;
    margin-top: 30px;
}

.credible-box {
    background-color: #EFEEEE;
    border-bottom: 1px solid #EFEEEE;
}

.credible-box h4 {
    background-color: #EFEEEE;
    padding: 10px;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold !important;
    font-size: 19px !important;
    margin: 0;
}

.credible-box-text {
    padding: 15px;
}

.bg-color {
    background-color: #eeeeee;
}

.bg-color-frm {
    background-color: #ffffff !important;
    padding-bottom: 10px !important;
}

figure {
    padding-left: 30px;
    padding-right: 30px;
}

hr.line-special-offers {
    width: 30px;
    height: 1px;
    background: #aeaeae;
    bottom: 0;
    left: 0;
}

div.flash-deal {}

a.flash-deal {
    padding: 0px !important;
}

article.flash-deal {
    padding-bottom: 20px;
}

.special-deal {
    padding: 20px;
    background-color: #eeeeee;
}

.special-deal-white {
    padding: 20px;
    padding-bottom: 50px !important;
    background-color: white !important;
}

.special-txt {
    color: black !important;
    font-size: 15px;
    line-height: 2;
    font-weight: 600;
}

.masonry {
    column-count: 4;
    column-gap: 1em;
}

.addthis_jumbo_share {
    padding: 30px;
}

.at4-jumboshare .at4-count,
.at4-jumboshare .at4-title {
    color: black !important;
}

.well-lg {
    width: 300px;
    background-color: white !important;
    padding: 30px;
}

.bg-container {
    background-color: #faf5f1;
}

h3.spo-box-offertitle-big {
    color: black !important;
    font-size: 20px;
    text-transform: capitalize;
    padding-bottom: 17px;
    line-height: 1.5;
    text-align: left;
    padding-right: 10px;
}

a.offer-button {
    border: 1px solid #2B201A;
    border-left: none;
    border-right: none;
    width: 150px;
    line-height: 36px;
    font-weight: bold !important;
    font-size: 13px;
    text-align: center !important;
    margin-left: 700px;
    padding: 8px;
}

.slider_option {
    position: relative;
    margin: 10px auto;
    width: 160px;
    font-size: 18px;
}

#slideshow>div {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
}

div#slideshow {
    position: relative !important;
}

.offer-details {
    padding: 20px;
}

.inner {
    padding-left: 50px;
    padding-right: 50px;
}

.left-padding {
    padding: 0px;
    padding-right: 25px;
    padding-top: 11px;
}

div#text-important {
    padding-top: 40px !important;
    padding-left: 40px;
    text-align: justify;
    padding-right: 40px;
}

img#offer-page-img {
    float: right;
}

.list-horizontal li {
    display: inline-block;
    font-size: 13px;
    color: #929292;
    line-height: 12px;
}

.list-horizontal li:before {
    content: '\00a0\2022\00a0\00a0';
    color: #999;
    color: rgba(0, 0, 0, 0.5);
    font-size: 11px;
}

.list-horizontals li {
    display: inline-block;
    line-height: 33px;
}

.list-horizontals li:before {
    content: '\00a0\2022\00a0\00a0';
    color: #999;
    color: rgba(0, 0, 0, 0.5);
    font-size: 11px;
}

input.offer-button {
    background-color: #171930 !important;
    border-radius: 0px;
    font-weight: bold !important;
    font-size: 13px;
    box-shadow: none;
    padding: 8px !important;
    height: 36px !important;
    background-color: transparent;
}

input.small-input,
select.small-input {
    font-size: 12px;
    border: 1px solid white !important;
    border-style: none;
    box-shadow: none;
    background-color: #eeeeee;
}

h4.form-title {
    position: absolute;
    text-align: center;
    background-color: white;
    top: -21px;
    left: 425px;
    padding-left: 20px;
    padding-right: 20px;
}

.frm-btn {
    position: absolute !important;
    background-color: white !important;
    top: 228px !important;
    left: 425px !important;
}

form.frm-new {
    padding-left: 242px !important;
    padding-right: 100px !important;
}

input.sample-btn {
    background-color: #171930 !important;
    border-radius: 0px;
    font-weight: bold !important;
    font-size: 13px;
    box-shadow: none;
    padding: 8px !important;
    width: 205px !important;
    height: 36px !important;
}

td {
    padding-right: 5px !important;
}

.pad-left {
    padding-left: 242px;
}

.full-form {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
}

blockquote.about-us-quote {
    font-size: 14px !important;
    text-align: justify;
    background-color: #eeeeee;
    padding: 40px;
    padding-bottom: 0px !important;
}

p.expect {
    line-height: 33px !important;
    text-align: center;
    padding: 40px;
}

p.imp-text {
    line-height: 33px !important;
    text-align: center;
    padding: 60px 80px;
}

#slider {
    position: relative;
    overflow: hidden;
    margin: 20px auto 0 auto;
    border-radius: 4px;
}

#slider ul {
    position: relative;
    margin: 0;
    padding: 0;
    height: 200px;
    list-style: none;
}

#slider ul li {
    position: relative;
    display: block;
    float: left;
    margin: 0;
    padding: 0;
    width: 864px !important;
    height: 300px;
    background: #eeeeee;
    text-align: center;
    line-height: 300px;
}

a.control_prev,
a.control_next {
    position: absolute;
    top: 40%;
    z-index: 1;
    display: block;
    padding: 4% 3%;
    width: auto;
    height: auto;
    background: rgba(42, 42, 42, 0.5);
    color: #fff !important;
    text-decoration: none;
    font-weight: 600;
    font-size: 18px;
    opacity: 0.8;
    cursor: pointer;
}

a.control_prev:hover,
a.control_next:hover {
    opacity: 1;
    -webkit-transition: all 0.2s ease;
}

a.control_prev {
    border-radius: 0 2px 2px 0;
}

a.control_next {
    right: 0;
    border-radius: 2px 0 0 2px;
}

img#offer-slider {
    height: 300px !important;
    width: 578px !important;
}

.slider {
    width: 100%;
    overflow: hidden;
    height: 300px;
    position: relative;
}

.slider ul {
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    width: 300%;
    height: 100%;
    top: 0;
}

.slider li {
    padding: 0;
    margin: 0;
    width: 33.333333%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    border: none;
}

.slider li img {
    border: none;
    width: 100%;
    min-height: 100%;
}

.slider button {
    position: absolute;
    display: block;
    box-sizing: border-box;
    border: none;
    outline: none;
    top: 0;
    bottom: 0;
    width: 10%;
    background-color: transparent;
    color: #fff;
    font-size: xx-large;
    margin: 0;
    padding: 0;
    text-align: center;
    z-index: 2;
}

.slider button.prev {
    left: 0;
}

.slider button.next {
    right: 0;
}

.slider button:hover,
.slider button:active {
    opacity: 1.0;
}

.slider .content {
    z-index: 3;
    bottom: -68px;
    right: 276px;
    text-align: center;
    width: 262px;
    height: 3em;
    position: inherit;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    line-height: 3em;
    padding: 0 1em;
    font-size: 17.5px;
    font-weight: bold;
}

.slider .content a {
    color: inherit;
}

.slider-txt {
    line-height: 33px;
    padding: 20px;
    padding-left: 100px !important;
    padding-right: 100px !important;
}

.codrops-top {
    line-height: 24px;
    font-size: 11px;
    background: #fff;
    background: rgba(255, 255, 255, 0.5);
    text-transform: uppercase;
    z-index: 9999;
    position: relative;
    box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.2);
}

.codrops-top a {
    padding: 0px 10px;
    letter-spacing: 1px;
    color: #333;
    display: inline-block;
}

.codrops-top a:hover {
    background: rgba(255, 255, 255, 0.8);
    color: #000;
}

.codrops-top span.right {
    float: right;
}

.codrops-top span.right a {
    float: left;
    display: block;
}


/* Demo Buttons Style */

.codrops-demos {
    float: right;
    padding-top: 10px;
}

.codrops-demos a {
    display: inline-block;
    margin: 10px;
    color: #666;
    font-weight: 700;
    line-height: 30px;
    border-bottom: 4px solid transparent;
}

.codrops-demos a:hover {
    color: #000;
    border-color: #000;
}

.codrops-demos a.current-demo,
.codrops-demos a.current-demo:hover {
    color: #aaa;
    border-color: #aaa;
}

.heading-title {
    margin-bottom: 100px;
}

.text-center {
    text-align: center;
}

.heading-title h3 {
    margin-bottom: 0;
    letter-spacing: 2px;
    font-weight: normal;
}

.p-top-30 {
    padding-top: 30px;
}

.half-txt {
    width: 60%;
    margin: 0 auto;
    display: inline-block;
    line-height: 25px;
    color: #7e7e7e;
}

.text-uppercase {
    text-transform: uppercase;
}

.team-member,
.team-member .team-img {
    position: relative;
}

.team-member {
    overflow: hidden;
}

.team-member,
.team-member .team-img {
    position: relative;
}

.team-hover {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0;
    border: 20px solid rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 0.90);
    opacity: 0;
    transition: all 0.3s;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
}

.team-member:hover .team-hover .desk {
    top: 35%;
}

.team-member:hover .team-hover,
.team-member:hover .team-hover .desk,
.team-member:hover .team-hover .s-link {
    opacity: 1;
}

.team-hover .desk {
    position: absolute;
    top: 0%;
    width: 100%;
    opacity: 0;
    -webkit-transform: translateY(-55%);
    -ms-transform: translateY(-55%);
    transform: translateY(-55%);
    -webkit-transition: all 0.3s 0.2s;
    transition: all 0.3s 0.2s;
    padding: 0 20px;
}

.desk,
.desk h4,
.team-hover .s-link a {
    text-align: center;
    color: black;
}

.team-member:hover .team-hover .s-link {
    bottom: 10%;
}

.team-member:hover .team-hover,
.team-member:hover .team-hover .desk,
.team-member:hover .team-hover .s-link {
    opacity: 1;
}

.team-hover .s-link {
    position: absolute;
    bottom: 0;
    width: 100%;
    opacity: 0;
    text-align: center;
    -webkit-transform: translateY(45%);
    -ms-transform: translateY(45%);
    transform: translateY(45%);
    -webkit-transition: all 0.3s 0.2s;
    transition: all 0.3s 0.2s;
    font-size: 35px;
}

.team-member .s-link a {
    margin: 0 10px;
    color: #333;
    font-size: 16px;
}

.team-title {
    position: static;
    padding: 20px 0;
    display: inline-block;
    letter-spacing: 2px;
    width: 100%;
}

.team-title h5 {
    margin-bottom: 0px;
    display: block;
    text-transform: uppercase;
}

.team-title span {
    font-size: 12px;
    text-transform: uppercase;
    color: black;
    letter-spacing: 1px;
}

img#our-team {
    width: 260px;
    height: 260px;
}

img#water-villa-img {
    position: relative;
    margin-left: 280px;
    width: 550px;
    height: 350px;
}

.carousel-caption2 {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0px;
    color: white;
    background: rgba(0, 0, 0, 0.75);
}

p.water-slider {
    text-align: center;
    color: white !important;
}

.carousel-indicators-villa {
    position: absolute;
    top: 520.5px;
    right: 45px;
    z-index: 5;
    margin: 0;
    list-style: none;
    background-color: #2B201A;
    height: 52px;
    width: 403px;
    padding-top: 23px;
    padding-left: 140px;
}

.carousel-indicators.extras {
    position: relative;
    z-index: 5;
    list-style: none;
    background-color: #2B201A;
    height: 52px;
    width: 405px;
    padding-top: 23px;
    /* padding-left: 162px; */
    /* margin-left: 51px; */
    bottom: 0;
    display: inline-block;
    left: 5px;
    top: -2 !important;
}

.carousel-indicators.extras.resort {
    position: absolute;
    top: 521px;
    right: 45px;
    z-index: 5;
    list-style: none;
    background-color: #2B201A;
    height: 52px;
    width: 405px;
    padding-top: 23px;
    padding-left: 162px;
    margin-left: 51px;
}

.carousel-indicators-villa li {
    display: block;
    float: left;
    width: 7px;
    height: 7px;
    margin-left: 15px;
    text-indent: -999px;
    background-color: rgba(255, 255, 255, 0.25);
    border-radius: 5px;
}

img#usp-img {
    height: 573px;
    width: 1000px;
}

.carousel-indicators-villa:active {
    color: white !important;
}

span.glyphicon.glyphicon-menu-right {
    color: #ffffff;
    font-size: 25px;
    /* margin-top: 260px; */
    z-index: 5;
    position: relative;
    background-color: #2B201A;
    height: 52px;
    /* padding-top: 13px; */
    width: 52px;
    /* right: -15px; */
    /* padding-right: 15px; */
    line-height: 52px;
    text-align: center;
    top: 1px !important;
}

span.glyphicon.glyphicon-menu-left {
    color: #ffffff;
    font-size: 25px;
    /*margin-top: 260px;*/
    z-index: 5;
    /*left: 506px;*/
    position: relative;
    background-color: #2B201A;
    height: 52px;
    padding-top: 13px;
    width: 52px;
    left: 0;
    top: 0px !important;
}

.carousel {
    margin-bottom: 0px !important;
}

.arrows {
    margin: 45px 0 0 0;
}

.arrows a .bar {
    position: absolute;
    width: 1px;
    display: block;
    background: #000;
}

.arrows a .bar-1 {
    top: 1px;
    left: 10px;
    height: 19px;
}

.arrows a .bar-2 {
    top: 10px;
    left: 6px;
    height: 10px;
}

.arrows a .bar-3 {
    top: 10px;
    left: 14px;
    height: 10px;
}

img#ds-honeymoon {
    position: absolute;
    left: 616px;
    top: 196px;
    width: 200;
}

.layer-1 {
    padding-right: 0px;
}

div#ds-honeymoon-txt {
    background-color: #eeeeee;
    position: relative;
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 50px;
    padding-bottom: 50px;
}

#bg {
    position: fixed;
    top: 40px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    background-color: black;
    opacity: 0.5;
    display: none;
}

.bx-controls .bx-pager.bx-default-pager {
    padding: 15px 40px !important
}

img#resort-form-img {
    height: 480px;
    width: 2000px;
    position: relative;
}

.resort-form {
    position: absolute;
    top: 50%;
    left: 10%;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 50px;
    color: white;
    width: 400px;
}

input.ov-resortlist-goldbutton {
    background-color: white;
    border-radius: 0px;
    font-weight: bold !important;
    font-size: 13px !important;
    box-shadow: none;
    text-transform: capitalize;
    padding: 0px;
    height: 36px !important;
    margin-left: -60px;
    letter-spacing: 0;
}

ul#bx-pagerxx {
    top: 82px !important;
    width: 225px !important;
    left: -75px;
    padding: 5px;
}

.resort-btn {
    line-height: 5;
    border-top: 1px solid #aeaeae;
    border-bottom: 1px solid #aeaeae;
    padding: 15px 25px;
    background-color: transparent;
    border-left: none;
    border-right: none;
    border: 1px solid #2B201A;
    border-left: none;
    border-right: none;
    font-weight: bold;
    font-size: 13px;
    padding-top: 15px;
    padding-bottom: 15px;
    text-transform: capitalize;
}

img#dine-img {
    width: 1013px !important;
}

form#frmContract {
    padding-top: 32px !important;
    padding-left: 50px !important;
    padding-right: 45px !important;
    padding-bottom: 50px !important;
    width: 100% !important;
    /* border-top: 1px solid white;*/
}

div#ui-datepicker-div {
    font-family: 'Gotham light', sans-serif, sans !important;
    font-size: 14;
}

h3.holiday-main {
    font-size: 14px;
    color: #2B201A;
    margin-top: 30px;
    margin-bottom: 40px;
}

.hero-text.offers {
    text-align: center;
    color: #2B201A;
    /*  font-family: 'Montserrat', sans-serif!important;*/
    font-size: 16px !important;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    font-weight: 800;
    background: whitesmoke;
    padding: 12px;
    bottom: -444px;
    width: 100%;
}

.title-white {
    color: white;
    padding-top: 90px;
}

.myFormOffer {
    background-color: #5e3055;
    color: #FFF;
    font-family: 'Gotham light', sans-serif, sans;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 2px;
    padding: 15px;
}

body .ui-tooltip {
    border-width: 0px;
    visibility: hidden;
}

ul.dropdown-menu.dropdown-rooms.pull-right {
    width: 340px !important;
    padding: 10px;
    left: 962px;
    margin-top: -150px !important;
    z-index: 99999;
    background-color: #2B201A;
    padding-bottom: 25px !important;
}

p#dining_txt {
    padding-top: 70px;
    padding-bottom: 70px;
    padding-left: 70px;
    padding-right: 100px;
}

.blog-main-img {
    border: 2px solid white;
}

.read-more-state {
    display: none;
    cursor: -webkit-grabbing;
    cursor: -webkit-grab;
}

.read-more-target {
    opacity: 0;
    max-height: 0;
    font-size: 0;
    transition: .25s ease;
}

.read-more-state:checked~.read-more-wrap .read-more-target {
    opacity: 1;
    font-size: inherit;
    max-height: 999em;
}

.read-more-state~.read-more-trigger:before {
    content: 'Show more';
}

.read-more-state:checked~.read-more-trigger:before {
    content: 'Show less';
}

.read-more-trigger {
    width: 100%;
    line-height: 36px;
    font-weight: bold !important;
    font-size: 13px;
    text-align: right;
    text-decoration: underline;
}

p.read-more-wrap {
    padding-top: 5px;
    padding-bottom: 5px;
}

.tile-narrow {
    text-align: left;
    font-size: 55px;
    text-transform: uppercase;
    padding-bottom: 57px;
    letter-spacing: 0px;
}

span.txt-big {
    font-size: 25px;
}

.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:focus {
    color: black !important;
    background-color: white !important;
}

a.list-villa-rooms::after,
a.villa-dine::after {
    content: " â€¢";
    color: #171930 !important;
}

.btn-group.open .dropdown-toggle {
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0) !important;
}

.select-menu {
    padding: 20px;
    margin-left: 21px;
    font-weight: bold;
    font-size: 15px;
}

.btn-dine {
    font-size: 15px;
    color: #000000;
    padding: 17px;
    padding-left: 113px !important;
    padding-top: 70px !important;
    border: 1px solid transparent;
    font-family: 'Gotham light', sans-serif, sans;
    line-height: 30px;
    margin-left: 770px !important;
    text-transform: capitalize;
    padding-right: 50px;
    font-weight: bold;
    background-color: transparent;
    border-radius: 0px !important;
    width: 600px;
}

a.list-villa-rooms::before,
a.villa-dine::before {
    content: "â€¢ ";
    color: #171930 !important;
}

.control-resort.no-padding {
    background-color: #2B201A;
    width: 507;
    height: 52px;
    right: 506px !important;
    top: 523px;
    padding-top: 15px !important;
}

.white-txt-new {
    padding-top: 95px;
    padding-bottom: 95px;
    color: white;
}

img#so-img {
    width: 420px;
    height: 220px;
    border: 1px solid white;
}

.dineNames {
    position: absolute;
    color: #FFF;
    right: -530px;
    margin-top: 50px;
    padding-top: 40px;
}

img#nxt-arrow {
    width: 20px;
    margin-top: 8px;
    margin-left: 10px;
}

.villa-read-more {
    border: 1px solid black;
    border-left: none;
    border-right: none;
    width: 150px;
    line-height: 36px;
    font-weight: bold !important;
    font-size: 13px;
    text-align: center !important;
    padding-top: 5px;
    padding-bottom: 5px;
}

img.blog-main-img.big {
    width: 600px;
    height: 220px;
}

img.blog-main-img.small {
    width: 440px;
    height: 220px;
}

.offer-btn {
    border: 1px solid #2B201A;
    /* border-left: none;
     border-right: none;*/
    width: 150px;
    line-height: 36px;
    font-weight: bold !important;
    font-size: 13px;
    text-align: center !important;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    letter-spacing: 4px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
}

.offer-btn:hover {
    background: #2B201A;
    color: whitesmoke !important;
}

img.hp-img {
    filter: grayscale(100%);
    position: relative;
    height: 1000px;
}

.hp-img-txt {
    margin-top: -65%;
    position: absolute;
}

.water-villa-hp-txt {
    position: absolute;
    margin-top: -48%;
    left: 12%;
}

.white {
    background-color: white;
    height: 296px;
    padding: 20px !important;
}

img#honeymoon-hp-img {
    position: relative;
    padding: 75px;
}

.hm-hp-txt {
    background-color: rgba(255, 255, 255, 0.51);
    width: 480px;
    position: absolute;
    top: 122%;
    left: 33.5%;
    outline-offset: -16px !important;
    outline: 2px solid #eeeeee;
    padding: 25px 50px 90px 50px;
}

.whitebg {
    background-color: white;
    padding: 45px;
    margin-left: 20;
    margin-right: 20;
    outline-offset: -16px !important;
    outline: 1px solid #eeeeee;
    min-height: 530px;
}

.margin-lft {
    margin-left: 16%;
}

.subtitle {
    text-transform: uppercase;
    padding-top: 80px;
}

img#ai-circle {
    position: absolute;
    left: 44px;
    top: -100px;
    width: 200;
    height: 200;
}

.offer-text {
    width: 490px;
    padding-top: 40px;
    text-align: center;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.51);
    left: 50%;
    transform: translate(-50%, -200%);
    color: black;
    /*    font-family: 'Montserrat', sans-serif;*/
    font-size: 32px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    outline-offset: -16px;
    outline: 1px solid white;
}

.offer-subtext {
    padding: 40px;
    letter-spacing: 0;
    text-transform: capitalize;
}

img#holiday-pkg {
    width: 340px;
    height: 200;
    outline-offset: -16px;
    outline: 1px solid white;
}

.holiday-text {
    text-align: center;
    padding: 5px;
    position: absolute;
    background-color: white;
    left: 50%;
    transform: translate(-50%, -325%);
    color: black;
    text-transform: uppercase;
}

.adventure-txt {
    background-color: #eeeeee;
    padding: 45px !important;
}

.ds-about-us {
    width: 100px;
    height: 100px;
}

.panel-default>.panel-heading {
    color: #333;
    background-color: #eeeeee;
    border-color: #ddd;
}

.ov-works {
    text-align: center;
}

p.services-txt {
    padding: 0px 50px 50px;
}

p.services-title {
    text-transform: uppercase;
    text-align: center;
    padding-top: 30px;
}

h2.tile-narrow.main {
    margin-top: 50px;
    padding-top: 57px;
    border: 1px solid black;
    border-left: none;
    border-right: none;
}

.icon {
    font-family: 'FontAwesome';
    -webkit-font-smoothing: antialiased;
}

.fa-chevron-right:before {
    content: "\f054";
    color: white;
}

.fa-chevron-left:before {
    content: "\f053";
    color: white;
}

li.mySliderImgRoom.imgLiquid_bgSize.imgLiquid_ready {
    width: 808px !important;
}

.resort-left {
    margin-left: -183px !important;
}

a.prevbtn {
    margin-left: -803px !important;
}

h2.home-big-titles.main {
    border: 1px solid black;
    border-left: none;
    border-right: none;
}


/* The Modal (background) */

.modal.resort {
    display: none;
    position: fixed;
    z-index: 999999999999999999999;
    padding-top: 141px;
    left: 0;
    top: 0;
    width: 146%;
    height: 100%;
    overflow: hidden;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

button#myBtn {
    position: fixed;
    z-index: 999;
    background-color: #5e3055;
    border: none;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    font-family: 'Gotham light', sans-serif, sans;
    text-transform: uppercase;
    margin-left: 60%;
    top: 56%;
    letter-spacing: 1;
    margin-top: -25px;
}


/* Modal Content */
/*
.modal-content {
    background-color: rgba(0, 0, 0, 0.4);
    margin-top: -150px;
    padding: 100px;
    padding-top: 205px!important;
    height: 200%;
    padding-left: 820px;
}
*/


/* The Close Button */

.close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

input.ov-resortlist-frmbtn {
    background-color: #5e3055 !important;
    border-radius: 0px;
    font-size: 13px !important;
    box-shadow: none;
    text-transform: capitalize;
    padding: 0px;
    height: 36px !important;
    margin-left: -60px;
    color: white;
    letter-spacing: 0;
}

.sub-title-resort {
    font-size: 14px;
    letter-spacing: 3;
    color: #5e3055;
    padding-top: 16px;
    padding: 17px;
    padding-left: 91px;
    font-family: 'Gotham light', sans-serif, sans;
    margin-top: -58px;
    line-height: 30px;
    margin-left: -83%;
    padding-right: 50px;
    padding-bottom: 39px;
    text-transform: uppercase;
}

.sub-title-dine {
    font-size: 14px;
    letter-spacing: 3;
    color: #5e3055;
    padding-top: 30px;
    line-height: 30px;
    margin-left: -83%;
    padding-right: 50px;
    text-transform: uppercase;
}

form#frmResortFooter {
    padding-left: 25px;
    padding-top: 30px;
    padding-right: 25px;
    padding-bottom: 50px;
    width: 100% !important;
    background-color: #eeeeee;
    border: 1px solid #eeeeee;
    border-radius: 10px;
}

button.accordion {
    background-color: #eee;
    color: #444;
    font-family: 'Gotham light', sans-serif, sans;
    color: black;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;
}

button.accordion.active,
button.accordion:hover {
    background-color: #eee;
}

button.accordion:after {
    content: '\002B';
    color: #777;
    font-weight: bold;
    float: right;
    margin-left: 5px;
}

button.accordion.active:after {
    content: "\2212";
}

div.panel {
    padding: 0 18px;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}

.policy-title {
    text-transform: uppercase;
    letter-spacing: 1px;
    padding-top: 23px;
    padding-bottom: 23px;
}

p.privacy-txt {
    text-align: justify;
    font-size: 13px !important;
    color: #666 !important;
}

.payment-tbl {
    border-top: 1px solid #CCC;
    border-left: 1px solid #CCC;
    width: 90%;
}

.payment-tbl tr th {
    background-color: #eeeeee !important;
    font-size: 14px;
    border-bottom: 1px solid #CCC;
    border-right: 1px solid #CCC;
    text-align: center;
    padding: 5px;
    font-weight: 600;
}

.payment-tbl tr td {
    font-size: 13px;
    border-bottom: 1px solid #CCC;
    border-right: 1px solid #CCC;
    padding: 10px;
}

.hero-text-offer {
    text-align: center;
    position: absolute;
    left: 30%;
    transform: translate(-50%, -300%);
    color: white;
    font-size: 15px;
    text-transform: uppercase;
    /* font-family: 'Montserrat', sans-serif;*/
    letter-spacing: 0.15em;
    width: 300;
    background-color: #0000007d;
    padding: 5px;
}

.li-style {
    padding-left: 64px;
    margin-top: -25px;
}

.about-us-steps {
    margin-left: 0;
    list-style-type: none;
    padding-left: 50px;
    background-color: #eeeeee;
    padding-right: 50px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.about-us-steps li {
    counter-increment: step-counter;
    padding: 20;
    text-align: justify;
}

.about-us-steps li::before {
    content: counter(step-counter);
    margin-right: 35px;
    font-size: 22px;
    background-color: #eeeeee;
    color: black;
}

div.scroll-so {
    height: 220px;
    overflow: scroll;
    overflow-x: hidden;
}

div.scroll-new {
    width: 752px;
    height: 345px;
    overflow: scroll;
    overflow-x: hidden;
}

div.scroll-usp {
    height: 345px;
    overflow: scroll;
    overflow-x: hidden;
}

div.scroll {
    width: 744px;
    height: 220px;
    overflow: scroll;
    overflow-x: hidden;
}

#style-3::-webkit-scrollbar-track,
.custom-options::-webkit-scrollbar-track,
#carousel-custom-nav::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar,
.custom-options::-webkit-scrollbar,
#carousel-custom-nav::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar-thumb,
.custom-options::-webkit-scrollbar-thumb,
#carousel-custom-nav::-webkit-scrollbar-thumb {
    background-color: #2B201A;
}

.masonrow {
    -moz-column-width: 18em;
    -webkit-column-width: 18em;
    -moz-column-gap: 1em;
    -webkit-column-gap: 1em;
}

.item-img {
    display: inline-block;
    padding: .25rem;
    width: 100%;
}

.well.new {
    background-color: transparent;
    border: none;
    position: relative;
    display: block;
    padding: 0px !important;
}

.popup-inner.new.private {
    height: 260px;
}

.popup-inner.new {
    height: 210px;
    border-radius: 10px !important;
    top: 86% !important;
}

blockquote.message {
    display: block;
    background: #fff;
    padding: 15px 20px 15px 45px;
    margin: 0 0 20px;
    position: relative;
    font-family: 'Gotham light', sans-serif, sans;
    text-align: justify;
    border-left: 3px solid #2B201A;
}

blockquote.message::before {
    content: "\201C";
    font-family: 'Gotham light', sans-serif, sans;
    font-size: 60px;
    font-weight: bold;
    color: #999;
    position: absolute;
    left: 10px;
    top: 5px;
}

blockquote.message::after {
    content: "\201D";
    font-family: 'Gotham light', sans-serif, sans;
    font-size: 60px;
    font-weight: bold;
    color: #999;
    position: absolute;
    padding-top: 37px;
}

.read-more-state {
    display: none;
}

.read-more-target {
    opacity: 0;
    max-height: 0;
    font-size: 0;
    transition: .25s ease;
}

.read-more-state:checked~.read-more-wrap-new .read-more-target {
    opacity: 1;
    font-size: inherit;
    max-height: 999em;
}

.read-more-state~.read-more-trigger-new:before {
    content: 'Read More';
}

.read-more-state:checked~.read-more-trigger-new:before {
    content: 'Close';
}

.read-more-trigger-new {
    cursor: pointer;
    display: inline-block;
    padding: 0 .5em;
    color: #2B201A;
    font-size: .8em;
    line-height: 2;
    border-radius: .25em;
    margin-bottom: 30px;
}

p.about-title {
    text-transform: uppercase;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 16px !important;
}

.read-more-wrap-new {
    padding-left: 3px;
    padding-right: 3px;
    width: 100%;
    color: #2B201A;
    padding-bottom: 5px;
}

.highlighted-text.header-txt {
    width: 1200px;
    background-color: white;
    position: absolute;
    top: 400px;
    left: 10%;
    padding: 50px;
    height: 500px;
}

.header-title {
    position: absolute;
    top: 355px;
    background-color: #2B201A;
    color: white;
    font-size: 25;
    padding: 10px 70px 10px 70px;
    left: 205px;
}

.image-title-sub {
    font-size: 20px;
    padding-top: 60px;
    padding-bottom: 20px;
    text-transform: uppercase;
    /*font-family: 'Montserrat', sans-serif;*/
    font-weight: bold;
}

.image-title-sub-offers {
    padding: 0px !important;
    font-family: 'Gotham light', sans-serif, sans !important;
}

iframe.about-us-video {
    position: absolute;
    left: 34%;
    top: 627px;
}

.header-title.sub {
    top: -44px;
    left: 185px;
}

.header-title.regional {
    top: 0px;
    left: -21%;
}

.ov-texture-bg {
    /* background-image:url('../images/ov-texture-bg.png');*/
    background-position: center;
}

.container-fluid.main-bg.bg-col {
    background-color: #eeeeee;
    padding: 93px;
    margin-top: -30px;
}

.text-highlight {
    background-color: #eeeeee;
    margin-left: auto;
    margin-right: auto;
    padding: 44px 20px;
    /*max-width: 685px;
    left: 24%;*/
}

.carousel-indicators li {
    border: none !important;
}

.carousel-indicators.carousel-indicators-numbers li {
    text-indent: 0;
    margin: 0 2px;
    width: 30px;
    height: 30px;
    border-bottom: 1px solid black !important;
    border-radius: 0;
    line-height: 30px;
    color: black;
    background-color: transparent;
    transition: all 0.25s ease;
}

.carousel-indicators.carousel-indicators-numbers li:hover {
    margin: 0 2px;
    width: 30px;
    height: 30px;
    background-color: transparent;
}

.carousel-indicators.carousel-indicators-numbers .active {
    text-indent: 0;
    margin: 0 2px;
    width: 30px;
    height: 30px;
    background-color: transparent;
    margin-top: 0px !important;
    border-bottom: 1px solid black !important;
    border-radius: 0;
    line-height: 30px;
    color: black;
}

ol.carousel-indicators.carousel-indicators-numbers {
    background-color: transparent;
    top: 75%;
    left: 78%;
}

.white-txtt {
    color: black !important;
    background-color: #eeeeee;
    padding: 50px;
}

.padded-txt {
    padding: 70px !important;
}

.text-undeline {
    text-decoration: underline;
}

.letter-spacingsmall {
    letter-spacing: 0.05em;
}

.letter-spacing3 {
    letter-spacing: 0.3em;
}

.pad-tb-100 {
    padding-top: 100px !important;
    padding-right: 0px !important;
    padding-bottom: 100px !important;
    padding-left: 0px !important;
}

.pad-tb-70 {
    padding-top: 70px !important;
    padding-right: 0px !important;
    padding-bottom: 70px !important;
    padding-left: 0px !important;
}

.pad-tb-50 {
    padding-top: 50px !important;
    padding-right: 0px !important;
    padding-bottom: 50px !important;
    padding-left: 0px !important;
}

.pad-tb-40 {
    padding-top: 40px !important;
    padding-right: 0px !important;
    padding-bottom: 40px !important;
    padding-left: 0px !important;
}

.pad-tb-30 {
    padding-top: 30px !important;
    padding-right: 0px !important;
    padding-bottom: 30px !important;
    padding-left: 0px !important;
}

.pad-tb-20 {
    padding-top: 20px !important;
    padding-right: 0px !important;
    padding-bottom: 20px !important;
    padding-left: 0px !important;
}

.pad-t-20 {
    padding-top: 20px !important;
}

.pad-t-30 {
    padding-top: 30px !important;
}

.pad-t-50 {
    padding-top: 50px !important;
}

.pad-t-70 {
    padding-top: 70px !important;
}

.pad-t-100 {
    padding-top: 100px !important;
}

.pad-b-20 {
    padding-bottom: 20px !important;
}

.pad-b-30 {
    padding-bottom: 30px !important;
}

.pad-b-50 {
    padding-bottom: 50px !important;
}

.pad-b-70 {
    padding-bottom: 70px !important;
}

.pad-b-100 {
    padding-bottom: 100px !important;
}

.pad-30 {
    padding-top: 30px !important;
    padding-right: 30px !important;
    padding-bottom: 30px !important;
    padding-left: 30px !important;

}

.pad-20 {
    padding-top: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 20px !important;
    padding-left: 20px !important;
}

.pad-50 {
    padding-top: 50px !important;
    padding-right: 50px !important;
    padding-bottom: 50px !important;
    padding-left: 50px !important;
}

.pad-70 {
    padding-top: 70px !important;
    padding-right: 70px !important;
    padding-bottom: 70px !important;
    padding-left: 70px !important;
}

.pad-100 {
    padding-top: 100px !important;
    padding-right: 100px !important;
    padding-bottom: 100px !important;
    padding-left: 100px !important;
}

.pad-t-0 {
    padding-top: 0px !important;
}

.mar-tb-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
}

.mar-tb-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
}

.mar-tb-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
}

.mar-tb-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

/* .new-bg {
    background-image: url(/assets/images/bg-final.png);
    padding-top: 80px!important;
    padding-bottom: 80px!important;
}

.room-bg {
    background-image: url(/ov/assets/images/bg-final.png);
}

.other-bg {
    background-image: url(/ov/assets/images/bg-final.png);
} */

img#about-slider {
    height: 573px;
}

.about-padding {
    height: 574px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 120px;
    padding-bottom: 137px;
}

.carousel.team {
    margin-bottom: 0;
    padding: 0 40px 30px 40px;
}


/*.carousel-control.team {
    left: -12px;
  }
  .carousel-control.right.team {
    right: -12px;
  }*/

.carousel-indicators.team {
    right: 50%;
    top: auto;
    bottom: 0px;
    margin-right: -19px;
    transition: opacity 0.5s ease-out !important;
}

.carousel-indicators.team li {
    background: #eeeeee;
}

.carousel-indicators.team .active {
    background: #333333;
}

img#ov-work-img {
    height: 450px;
}

iframe.about-video {
    width: 100%;
    height: 75%;
    margin-left: -40px;
}

.text-style {
    padding-top: 80px;
    padding-bottom: 80px;
    margin-left: 0;
    padding-left: 50px;
    background-color: #eeeeee;
    padding-right: 50px;
}

.new-padding {
    padding-top: 115px;
    padding-bottom: 115px;
    padding-left: 57px;
    padding-right: 90px;
}


/*.team{
    padding:75px 0;
  }*/

h6.description {
    font-weight: bold;
    letter-spacing: 2px;
    color: #999;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 5px;
}

.img-box ul {
    position: absolute;
    z-index: 2;
    bottom: 50px;
    text-align: center;
    width: 100%;
    padding-left: 0px;
    height: 0px;
    margin: 0px;
    opacity: 0;
}

.profile .img-box:after,
.img-box ul,
.img-box ul li {
    -webkit-transition: all 0.5s ease-in-out 0s;
    -moz-transition: all 0.5s ease-in-out 0s;
    transition: all 0.5s ease-in-out 0s;
}

.img-box ul i {
    font-size: 20px;
    letter-spacing: 10px;
}

.img-box ul li {
    width: 30px;
    height: 30px;
    text-align: center;
    margin: 2px;
    padding: 5px;
    display: inline-block;
}

.img-box a {
    color: #fff !important;
}

.img-box:hover:after {
    opacity: 1;
}

.img-box:hover ul {
    opacity: 1;
}

.img-box ul a {
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.img-box a:hover li {
    border-color: #fff;
    color: #171930;
}

.row.pt-md {
    background-color: #eeeeee;
    padding-top: 20px;
    padding-bottom: 20px;
}

img#about-icon {
    border: 2px solid #171930;
    border-radius: 50%;
    padding: 15px;
}

img#ds-icon {
    border: 8px solid #faf5f1;
    border-radius: 50%;
}

.about-fal-icon {
    /* border: 1px solid #2B201A  ; */
    border-radius: 50%;
    padding: 30px;
    font-size: 45px;
    color: #2B201A;
}

hr.line.padding-new {
    margin-bottom: 30px;
}

.main-title-pv {
    position: absolute;
    top: 369px;
    left: 500px;
}

img#pv-img {
    height: 340px;
    width: 100%;
}

img#pv-main-img {
    height: 500px;
    width: 100%;
    position: relative;
    outline-offset: -75px !important;
    outline: 4px solid white;
}

button.upcomingbtn {
    top: 55%;
}

.villa-bg {
    /* background-image: url(/ov/assets/images/bg-final.png); */
    padding-top: 150px !important;
    padding-bottom: 150px !important;
    background-color: #eeeeee;
    height: 573px;
}

.highlight {
    font-size: 20px !important;
    line-height: 28px;
    margin-bottom: 18px;
    position: relative;
    letter-spacing: 0px;
}

.hightlight::after {
    content: "";
    height: 1px;
    width: 50px;
    border-top: 1px solid #d8d5d6;
    position: absolute;
    top: 17px;
    right: -60px;
    z-index: 10000000000000000;
}

.footer-txt {
    text-transform: capitalize;
    font-size: 14px;
    font-family: 'Bembo Regular' !important;
}

li.link-footer {
    display: inline;
    margin: 20px;
}

.footer-whole {
    padding: 20px;
}

hr.footer-line {
    border-top: 1px solid #eeeeee2e !important;
    border-bottom: transparent;
}

.footer-copyright {
    font-size: 11px;
    padding: 10px 0 15px;
    color: black;
    font-family: 'Bembo Regular' !important;

}

.footer-link-wrapper {
    margin: 20px 0px;
}

.social-icon {
    font-size: 12px !important;
    text-align: center;
    width: 30px;
    background-color: #fff !important;
    border: 1px solid;
    text-decoration: none;
    border-radius: 50%;
    color: #2B201A57 !important;
    height: 30px;
    display: inline-block;
    line-height: 26px;
    margin: 10px;
}

.social-icon-fb:hover {
    background: #3B5998 !important;
    border: 1px solid #3b5998;
    color: white !important;
}

.social-icon-tw:hover {
    background: #55ACEE !important;
    border: 1px solid #55ACEE;
    color: white !important;
}

.social-icon-gp:hover {
    background: #dd4b39 !important;
    border: 1px solid #dd4b39;
    color: white !important;
}

.social-icon-ld:hover {
    background: #007bb5 !important;
    border: 1px solid #007bb5;
    color: white !important;
}

.social-icon-ig:hover {
    background: #125688 !important;
    border: 1px solid #125688;
    color: white !important;
}

input.footer-button {
    background-color: #171930;
    border-radius: 0;
    border: transparent;
}

.social-icon-fb:hover svg,
.social-icon-ig:hover svg,
.social-icon-ld:hover svg,
.social-icon-gp:hover svg,
.social-icon-tw:hover svg,
.social-icon-fb:hover svg {
    fill: #fff;
}

.tblRight {
    width: 50%;
    padding: 5px 30px 5px 30px;
    font-size: 14px;
}

button.sample-btn {
    background-color: #171930 !important;
    border-radius: 0px;
    font-weight: bold !important;
    font-size: 13px;
    font-family: 'Gotham light', sans-serif, sans !important;
    box-shadow: none !important;
    padding: 8px !important;
    margin-top: 30px;
    width: 205px !important;
    height: 36px !important;
    border: 1px solid #171930;
}

img#contact-awards {
    width: 150px;
    height: 75px;
    margin-bottom: 10px;
}

span.news-box {
    font-family: "Libre Baskerville";
    font-size: 15px !important;
    margin-bottom: 18px;
    position: relative;
    letter-spacing: 0px;
    color: #736C68;
}

.ov-news {
    /*border: 1px solid #2B201A  40;*/
    background-color: #fff;
    padding: 20px;
}

.contact-news {
    padding: 30px;
}

.blog-tags {
    text-align: justify;
    padding: 10px;
}

.blog-tags a {
    cursor: pointer;
    text-decoration: underline;
}

.tags_1 {
    font-size: 11px;
}

.tags_2 {
    font-size: 14px;
}

.tags_3 {
    font-size: 16px;
}

.tags_4 {
    font-size: 22px;
}

.wallimg {
    height: 50% !important;
}

.walllist {
    padding: 10px 5px 10px 10px;
    margin-bottom: 30px;
}

a.read-more-blog {
    background-color: transparent;
    border-style: none;
    border-top: 1px solid #2B201A;
    font-size: 14px;
    border-bottom: 1px solid #2B201A;
    padding: 10px;
    text-transform: capitalize;
}

.blog-story img {
    width: 100%;
}

.blog-right-row {
    padding: 0px 10px 10px 10px;
}

.archive-box {
    border: 1px solid #eeeeee;
    padding: 10px;
}

.blog-archive-list>li {
    padding: 5px 0;
    font-size: 14px;
    border-bottom: 1px dotted #eeeeee;
}

.right-row-titles {
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 10px;
    /*padding: 10px 0;*/
}

input#tagtext {
    border-radius: 0;
    width: 233px !important;
    height: 35px !important;
}

ul.ov-pagination.pull-right {
    font-family: 'Gotham light', sans-serif, sans !important;
    font-size: 16px;
    margin-bottom: 40px;
}

h2.side-offer {
    background-color: #eeeeee;
    letter-spacing: 1;
    padding: 10px;
    text-transform: uppercase;
}

article.offer {
    background-color: white;
    padding-bottom: 50px;
    margin-bottom: 50px;
    height: 650px;
}

a.offer-more {
    padding: 20px !important;
    font-size: 13px;
    font-weight: bold;
}

.myoffer-details p {
    text-align: center;
}

.myoffer-details-ul li {
    display: inline;
    padding-left: 15px;
    padding-right: 15px;
    color: #fff;
}

.w3-black,
.w3-hover-black:hover {
    color: black !important;
    background-color: #eeeeee !important;
    /* font-family: montserrat;*/
    font-size: 13px !important;
}

.w3-display-bottomleft {
    position: absolute;
    left: 122px !important;
    bottom: 0;
}

.image-header {
    background-color: whitesmoke;
    padding: 20px;
    width: 525px;
    text-align: left;
}

.purple-title {
    color: #5e3055 !important;
    text-transform: capitalize;
}

.purple-text {
    color: #5e3055 !important;
}

span.image-title-main {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 13px;
}

h4.image-title-main {
    text-transform: uppercase;
    font-weight: 600 !important;
    font-size: 13px;
}

span.image-title-sub {
    font-size: 20px;
    text-transform: uppercase;
    /*    font-family: 'Montserrat', sans-serif;*/
    font-weight: 500;
}

img#travel-img {
    width: 525px;
    height: 294px;
}

img#travel-img-2 {
    height: 294px;
    width: 248px;
}

.image-header:hover span.image-title-sub {
    color: white !important;
}

.image-header:hover span.image-title-main {
    color: white !important;
}

.image-header:hover {
    background-color: #2B201A !important;
}

a#test-hover:hover .image-header {
    background-color: #2B201A !important;
}

a#test-hover:hover span.image-title-sub {
    color: white !important;
}

a#test-hover:hover span.image-title-main {
    color: white !important;
}

.hero-text.homepage {
    transform: translate(-51%, -105%) !important;
}

#home-banner .hero-text {
    font-family: 'Gotham Thin', sans-serif, sans !important;
    font-size: 65px !important;
    line-height: 65px !important;
    letter-spacing: 2px;
}

a.help-btn {
    line-height: 30px;
    font-weight: bold !important;
    font-size: 13px !important;
    text-align: center !important;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    text-transform: capitalize;
    letter-spacing: 3px;
    /*margin-top: 20px!important;*/
    font-family: 'Gotham Light', sans-serif, sans;
    background-color: #171930;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
}

.image-header.next {
    width: 248px !important;
}

img#offer-hp {
    border: 4px solid #eeeeee;
}

a.villa-read-more {
    border: 1px solid #2B201A;
    border-left: none;
    border-right: none;
    width: 200px;
    line-height: 36px;
    font-weight: bold !important;
    font-size: 13px;
    text-align: center !important;
    padding-top: 5px;
    padding-left: 20px;
    padding-bottom: 5px;
    padding-right: 20px;
}

a.tab-villa {
    background-color: #2B201A;
    color: white !important;
}

.intro-tabs {
    text-align: center;
    padding: 15px;
}

.intro-tabs i {
    font-size: 34px;
    padding-bottom: 20px;
}

.info-secure {
    text-align: center;
    font-size: 11px;
    line-height: normal !important;
    background-color: #eeeeee;
    padding: 10px;
    margin-top: 15px;
}

.pop-call-us-text {
    padding: 30px 0px;
}

.pop-call-us-ico {
    padding: 15px 0;
}

.pop-call-us-ico i {
    margin: 1px 10px;
    font-size: 20px;
}

.amber {
    color: #c78939 !important;
}

.color-whatsapp {
    color: #25d366;
}

.color-viber {
    color: #7d51a0;
}

.color-text {
    color: #7f7e7e;
}

.color-messenger {
    color: #0084fe;
}

.color-phone {
    color: #bbc3cb;
}

.my_room_name {
    position: absolute;
    z-index: 100;
    width: 100%;
    margin-top: 25%;
    display: table-cell;
    vertical-align: central;
    text-align: center;
    color: white;
    font-size: 32px;
    text-transform: uppercase;
    /*    font-family: 'Montserrat', sans-serif;*/
    letter-spacing: 0.15em;
    text-align: center;
}

.room-navi-menu {
    position: absolute;
    background-color: #2B201A;
    z-index: 10;
    display: none;
}

.my-room-navi-items {
    list-style: none;
    padding: 30px 40px;
}

#show-room-menu {
    font-weight: bold;
}

.dining-text-wrap {
    padding: 70px;
}

.bxslid-ov-navi-wrapper {
    display: inline-block;
    width: 365px;
}

.slide-arrow-btn {
    font-size: 40px;
    display: inline-block;
    line-height: 50px;
    padding: 0px 10px;
    color: #FFF !important;
}

#dining-slider-nav {
    position: absolute;
    z-index: 20;
    bottom: 0px;
    left: 0;
    top: 50%;
    width: 100%;
    font-size: 50px;
}

#room-slider-nav {
    position: absolute;
    z-index: 20;
    bottom: 0px;
    left: 0;
    top: 50%;
    width: 100%;
    font-size: 50px;
}

.close-button {
    position: absolute;
    top: 8px;
    right: 15px;
    color: white;
    font-weight: bolder;
    font-size: 17px;
    cursor: pointer;
}

.movecursor {
    cursor: move;
}

#dining-slider-nav i {
    font-size: 50px;
}

.show-dining-menu {
    position: absolute;
    z-index: 21;
    left: 70.5%;
    top: 70px;
}

.dining-navi-menu {
    position: absolute;
    background-color: #2B201A;
    z-index: 21;
    left: 66.3%;
    display: none;
}

.list-dining-rooms {
    position: relative;
    display: block;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 5px;
    color: #FFF !important;
}

a.list-dining-rooms::after {
    content: " â€¢";
    color: #171930 !important;
}

a.list-dining-rooms::before {
    content: "â€¢ ";
    color: #171930 !important;
}

.list-room-rooms {
    position: relative;
    display: block;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 5px;
    color: #FFF !important;
}

a.list-room-rooms::after {
    content: " â€¢";
    color: #171930 !important;
}

a.list-room-rooms::before {
    content: "â€¢ ";
    color: #171930 !important;
}

#room-slider-nav {
    position: absolute;
    z-index: 20;
    bottom: 0px;
    right: 0px;
}

#usp-slider-nav {
    position: absolute;
    z-index: 20;
    bottom: 0px;
    left: 0px;
}

.holiday-text-wrapper {
    padding: 30px;
}

.holiday-action-buttons {
    text-align: center;
}

.morecontent span {
    display: none;
}

.morelink {
    display: block;
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .navbar-nav>li {
        padding: 10px 5px !important;
    }

    .navbar-nav {
        padding-top: 0px;
        margin-top: 0px !important;
    }

    .bx-controls .bx-pager.bx-default-pager {
        min-width: 355px !important;
    }
}

@media only screen and (min-width: 1824px) {
    .show-dining-menu {
        left: 69.2%;
        top: 70px;
    }

    .dining-navi-menu {
        left: 66.4%;
        display: none;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {}


/* Large screens ----------- */

@media only screen and (min-width: 1824px) {
    .home-big-titles {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .hero-text.homepage {
        /* transform: translate(-50%, -250%)!important; */
        transform: translate(-51%, -244%) !important;
    }

    #home-banner .hero-text {
        font-size: 60px !important;
        line-height: 55px;
        letter-spacing: 4px;
    }
}

#maldives .col-lg-4 {
    background-color: whitesmoke;
}

.mar-b-70 {
    margin-bottom: 70px !important;
}

.no-margin {
    margin: 0px !important;
}

.mar-b-40 {
    margin-bottom: 40px !important;
}

#why-seychelles .col-md-4 {
    background: #eeeeee;
}

#book-and-pay {
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
}

.grid {
    position: relative;
    margin: 0 auto;
    /*   padding: 1em 0 4em;
    max-width: 1000px;*/
    list-style: none;
    text-align: center;
}

.gridrow {
    margin-right: -5px;
    margin-left: -5px;
}

.gridrow-eid {
    padding-left: 5px;
    padding-right: 5px;
}

.gridrow>[class*="col-"] {
    padding: 5px;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
    background-color: #c6b5a7 !important;
}

.daterangepicker td.in-range {
    background-color: #c6b5a729;
}

.handle-counter .pos-abs a {
    height: 45px;
    display: inline-block;
    line-height: 45px;
    padding: 0 7px;
    font-size: 20px;
    font-weight: 800;
}

.PhoneInputInput {
    border: none;
    outline: none !important;
}

#msform {
    width: 100%;
    margin: 0;
    text-align: center;
    position: relative;
}

#msform fieldset {
    background: white;
    border: 0 none;
    border-radius: 0;
    padding: 20px 0px;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    text-align: left;
    position: relative;
}

#msform fieldset:not(:first-of-type) {
    display: none;
}

#msform input,
#msform textarea {
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-bottom: 10px;
    width: 100%;
    box-sizing: border-box;
    font-family: montserrat;
    color: #2C3E50;
    font-size: 13px;
}

#msform .action-button {
    width: 125px;
    background: #fff;
    font-weight: 300;
    color: #2B201A;
    border: 2px solid #2B201A;
    border-radius: 1px;
    cursor: pointer;
    padding: 5px;
    margin: 10px 0px;
    text-transform: uppercase;
    color: #000 !important;
    font-size: 12px;
}

.errrorsec {
    position: absolute;
    top: 15px;
    color: red;
    right: 20px;
    font-size: 12px;
}

/* Common style */

.grid figure {
    position: relative;
    float: left;
    overflow: hidden;
    /* margin: 10px 1%;
    min-width: 320px;
    max-width: 480px;*/
    /*max-height: 360px;*/
    width: 48%;
    /* background: #3085a3;*/
    text-align: center;
    cursor: pointer;
}

.grid figure img {
    position: relative;
    display: block;
    min-height: 100%;
    max-width: 100%;
    opacity: 0.8;
}

.grid figure figcaption {
    padding: 2em;
    color: #fff;
    text-transform: uppercase;
    font-size: 1.25em;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
    pointer-events: none;
}

.grid figure figcaption,
.grid figure figcaption>a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.grid figure figcaption>a {
    z-index: 1000;
    text-indent: 200%;
    white-space: nowrap;
    font-size: 0;
    opacity: 0;
}

.grid figure h2 {
    word-spacing: -0.15em;
    font-weight: 300;
}

.grid figure h2 span {
    font-weight: 800;
}

.grid figure h2,
.grid figure p {
    margin: 0;
}

.grid figure p {
    letter-spacing: 1px;
    font-size: 68.5%;
}

.effect-bubba {
    /* background: #9e5406;*/
}

.effect-bubba img {
    opacity: .85;
    -webkit-transition: opacity 0.35s;
    transition: opacity 0.35s;
}

.effect-bubba:hover img {
    opacity: 0.4;
}

.effect-bubba figcaption::before,
.effect-bubba figcaption::after {
    position: absolute;
    top: 30px;
    right: 30px;
    bottom: 30px;
    left: 30px;
    content: '';
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
}

.effect-bubba figcaption::before {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
}

.effect-bubba figcaption::after {
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;
    -webkit-transform: scale(1, 0);
    transform: scale(1, 0);
}

.effect-bubba .resort-title {
    position: absolute;
    top: 50px;
    width: 100%;
    text-align: center;
    color: #4f4f4fc9;
    /* padding-top: 30%;*/
    -webkit-transition: -webkit-transform 1s;
    transition: transform 0.35s;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    padding-right: 56px;
    padding-left: 20px;
    text-transform: uppercase !important;
    font-weight: bold;
    letter-spacing: 1;
    color: black;
    left: 32px;
}

.effect-bubba p {
    position: absolute;
    top: 120px;
    width: 100%;
    text-align: justify;
    /* padding: 20px 2.5em; */
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
    padding-right: 53px;
    padding-left: 21px;
}

.effect-bubba:hover figcaption::before,
.effect-bubba:hover figcaption::after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.effect-bubba:hover .resort-title,
.effect-bubba:hover p {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.ovofferscheck {
    text-align: center;
    width: 100%;
    color: #ffffff !important;
    margin: 17px 0px !important;
    display: block;
    background-color: #1414143b;
    border-radius: 0;
    padding: 4px 18px;
}

.whatwedo-home-slider-image {
    display: table-cell;
    float: none;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 750px;
}

.u-clearfix:before,
.u-clearfix:after {
    content: " ";
    display: table;
}

.u-clearfix:after {
    clear: both;
}

.u-clearfix {
    *zoom: 1;
}

.subtle {
    color: #747474;
}

.card-container {
    margin: 1px auto 0;
    position: relative;
    /*width: 600px;*/
}

.card {
    background-color: #fff;
    position: relative;
    /*  box-shadow: 0 0 5px rgba(75, 75, 75, .02);*/
    z-index: 1;
}

.card span {
    color: #1c222f ;
}

.card-body {
    display: inline-block;
    float: left;
    width: 100%;
}

.card-number {
    margin-top: 15px;
}

.card-circle {
    border: 1px solid #aaa;
    border-radius: 50%;
    display: inline-block;
    line-height: 22px;
    font-size: 12px;
    height: 25px;
    text-align: center;
    width: 25px;
}

.card-author {
    display: block;
    font-size: 10px;
    letter-spacing: .5px;
    margin: 5px 0 0;
    text-transform: uppercase;
    text-align: left;
}

.card-title {
    font-size: 24px;
    font-weight: 300;
    line-height: 24px;
    margin: 10px 0px 5px 0px;
    text-align: left;
    text-transform: uppercase;
}

.card-description {
    font-size: 18px;
    display: inline-block;
    font-weight: 300;
    line-height: 18px;
    margin: 10px 0px 5px 0px;
    text-align: left;
    width: 100%;
}

.card-read {
    cursor: pointer;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 3px;
    margin: 50px 0 20px;
    position: relative;
    text-align: right;
    text-transform: uppercase;
}

.card-read:after {
    background-color: #b8bddd;
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    top: 12px;
    width: 60%;
}

.card-tag {
    float: right;
    margin: 5px 0 0;
}

.card-media {
    float: right;
    width: 100%;
    height: 350px;
    background-size: cover;
    background-position: center;
    margin-bottom: 10px;
}

.blog-p {
    padding-bottom: 15px;
    text-align: justify;
    padding-left: 0px;
    padding-right: 0px;
}

.packages-img {
    width: 100%;
}

.cta-box {
    margin-top: 12px !important;
}

#intotextforresort .col-md-4 {
    border-right: none;
}

.fadedfx {
    visibility: hidden;
}

.fadeIn {
    animation-name: fadeIn;
    -webkit-animation-name: fadeIn;
    animation-duration: 1.5s;
    -webkit-animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
    visibility: visible !important;
}

@keyframes fadeIn {
    0% {
        opacity: 0.0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0.0;
    }

    100% {
        opacity: 1;
    }
}

.skyblue {
    color: #337ab7;
}

.red {
    color: #dd3531;
}

.green {
    color: #3c763d;
}

.lightgray {
    color: #373737;
}

#whychoseov .col-md-4 {
    border-right: none;
    display: table-cell;
    float: none;
}

#whychoseov .col-md-4:last-child {
    /*  border-right: 1px solid #bfbfbf;*/
}

section {
    /*margin: 40px 0px;*/
}

#home-offer {
    background-color: #f5f5f5;
}

#home-offer .col-md-4 {
    height: 600px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#home-offer .col-md-4:hover img {
    transform: scale(1.5) rotate(5deg);
}

.cookie-policy {
    font-size: 14px;
    padding: 20px 10px;
    background: rgba(0, 0, 0, 0.6);
    position: relative;
    z-index: 10000;
    left: 0;
    right: 0;
    overflow: hidden;
    transition: all 0.25s linear;
}

.cookie-policy.cp-show-top {
    top: 0;
}

.cookie-policy.cp-show-bottom {
    top: initial;
    bottom: 0;
}

.cookie-policy__container {
    margin: 0 auto;
    max-width: 960px;
}

.cookie-policy__container:after {
    content: "";
    display: table;
    clear: both;
}

.cookie-policy__text {
    width: 80%;
    float: left;
}

.cookie-policy__text p {
    color: #fff !important;
    font-size: 12px;
}

.cookie-policy__actions {
    width: auto;
    float: left;
    text-align: center;
    max-width: 20%;
}

.cookie-policy__actions button,
.cookie-policy__actions a {
    border: 0;
    display: block;
    background: transparent;
    padding: 0.4em 1.4em;
    font-size: 12px;
    border-radius: 0;
    transition: all 0.2s ease;
    cursor: pointer;
    position: relative;
    text-decoration: none;
    color: inherit;
    margin: auto;
    font-weight: 900;
}

.cookie-policy__actions .cp-btn__continue {
    background: #171930;
    color: #fff;
}

.cookie-policy__actions .cp-btn__link {
    font-size: 11px;
    color: rgba(0, 0, 0, 0.5);
    width: auto;
    display: inline-block;
    margin-top: 8px;
    padding: 0;
}

.cookie-policy__actions .cp-btn__link:after {
    content: "";
    display: block;
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
    width: 0;
    margin: auto;
    height: 1px;
    background: tranparent;
    transition: all 0.2s ease;
}

.cookie-policy__actions .cp-btn__link:hover {
    color: #000;
}

.cookie-policy__actions .cp-btn__link:hover:after {
    width: 100%;
    background: #000;
}


/* Common style */


.effect-apollo {
    position: relative;
    float: left;
    overflow: hidden;
    height: auto;
    text-align: center;
    cursor: pointer;
    position: relative;
}

.effect-apollo img {
    position: relative;
    display: block;
    /*min-height: 100%;*/
    max-width: 100%;
    opacity: 0.8;
}

.effect-apollo figcaption {
    padding: 2em;
    color: #fff;
    text-transform: uppercase;
    font-size: 1.25em;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.effect-apollo figcaption::before,
.effect-apollo figcaption::after {
    pointer-events: none;
}

.effect-apollo figcaption,
.effect-apollo figcaption>a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.effect-apollo figcaption>a {
    z-index: 1000;
    text-indent: 200%;
    white-space: nowrap;
    font-size: 0;
    opacity: 0;
}

.effect-apollo h2 {
    word-spacing: -0.15em;
    font-weight: 300;
}

.effect-apollo h2 span {
    font-weight: 800;
}

.effect-apollo h2,
.effect-apollo p {
    margin: 0;
}

#my-form-title {
    text-transform: uppercase;
}

.effect-apollo img,
.effect-apollo .bg-image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 1;
    -webkit-transition: opacity 0.35s, -webkit-transform 1s;
    transition: opacity 0.35s, transform 1s;
    transform: scale(1.1, 1.1);
    -webkit-transform: scale(1.1, 1.1);
    -moz-transform: scale(1.1, 1.1);
    -o-transform: scale(1.1, 1.1);
    -ms-transform: scale(1.1, 1.1);
}

.effect-apollo figcaption::before {
    position: absolute;
    top: -50px;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    content: '';
    -webkit-transition: -webkit-transform 0.6s;
    transition: transform 0.6s;
    -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
    transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
    -moz-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
    -o-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
    -ms-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
}

.effect-apollo p {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 23px;
    padding: 0 1em;
    max-width: 100%;
    border-right: 4px solid #fff;
    text-align: right;
    opacity: 0;
    -webkit-transition: opacity 1s;
    transition: opacity 1s;
    color: white !important;
    text-align: right;
}

.effect-apollo h2 {
    text-align: left;
}

.effect-apollo:hover img,
.effect-apollo:hover .bg-image,
.effect-apollo:focus figcaption::before,
.effect-apollo:active img,
.effect-apollo:active .bg-image {
    opacity: 0.6;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
}

.effect-apollo:hover figcaption::before,
.effect-apollo:focus figcaption::before,
.effect-apollo:active figcaption::before {
    -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 120%, 0);
    transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 120%, 0);
    -moz-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 120%, 0);
    -o-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 120%, 0);
    -ms-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 120%, 0);
}

.effect-apollo:hover p,
.effect-apollo:focus p,
.effect-apollo:active p {
    opacity: 1;
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
}


/*video*/

.cover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
}

.tv {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.tv .screen {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    margin: auto;
    opacity: 0;
    transition: opacity .5s;
}

.tv .screen.active {
    opacity: 1;
}

.offerpage-box {
    padding: 20px;
    border: 1px solid #0000002e;
    margin: 0px;
    /*box-shadow: 0px 0px 20px 0px;*/
}

.float-icon {
    position: fixed;
    width: 175px;
    height: 35px;
    bottom: 50%;
    right: 0;
    line-height: 35px;
    background-color: #171930;
    border-radius: 0;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
    z-index: 1000;
    letter-spacing: 1px;
    font-size: 12px;
    font-weight: bold;
}

a.float-icon {
    text-decoration: none;
}

.my-float {
    margin-top: 10px;
}

.zindex50 {
    z-index: 50 !important;
}

.imgfilter {
    -webkit-filter: contrast(1) saturate(0) sepia(.6);
    filter: contrast(1.4) saturate(1.8) sepia(.6);
}

.about-us-page-how-to-work {
    text-align: center;
    list-style: none;
}

.resort-logo .item img {
    position: relative;
    -webkit-backface-visibility: hidden;
    padding: 20px;
}

.resort-logo img {
    cursor: pointer;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.resort-logo img:hover,
.resort-logo img:focus {
    cursor: pointer;
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
}


/*new Layout*/

.font-gotham-Thin {
    font-family: 'Gotham Thin', sans-serif, sans;
}

.font-gotham-Light {
    font-family: 'Gotham Light', sans-serif, sans;
}

.font-gotham-Medium {
    font-family: 'Gotham Medium', sans-serif, sans;
}

.font-gotham-Bold {
    font-family: 'Gotham Bold', sans-serif, sans;
}

.font-railway {
    font-family: 'Gotham light', sans-serif, sans;
}

.text-cap {
    text-transform: capitalize;
}

.text-upper {
    text-transform: uppercase;
}

.font-70 {
    font-size: 70px;
    line-height: 59px;
}

.font-60 {
    font-size: 60px;
    line-height: 49px;
}

.font-50 {
    font-size: 50px;
    line-height: 46px;
}

.font-40 {
    font-size: 40px;
    line-height: 45px;
}

.font-w-100 {
    font-weight: 100;
}

.font-w-300 {
    font-weight: 300;
}

.font-w-400 {
    font-weight: 400;
}

.font-w-600 {
    font-weight: 600;
}

.font-w-700 {
    font-weight: 700;
}

.font-25 {
    font-size: 25px;
    line-height: 30px;
}

.new-ov-main-title-home {
    font-family: 'Gotham Light', sans-serif, sans;
    letter-spacing: 1px;
    font-size: 30px;
    line-height: 36px;
    text-transform: uppercase;
}

.new-ov-sub-title-home {
    font-family: "font-Baskerville", "Times New Roman", Times, Georgia, serif;
    letter-spacing: 1px;
    font-size: 36px;
    line-height: 40px;

}

.new-ov-main-title {
    font-family: 'Gotham Thin', sans-serif, sans;
    letter-spacing: 1px;
    font-weight: 100;
    font-size: 50px;
    line-height: 50px;
    text-transform: uppercase;
}

.new-ov-sub-title {
    font-family: 'Gotham Bold', sans-serif, sans !important;
    font-size: 50px !important;
    line-height: 50px !important;
    text-transform: uppercase;
}

.new-ov-main-para {
    font-family: 'Gotham Thin', sans-serif, sans;
    font-weight: 100;
    font-size: 33px;
    line-height: 33px;
    text-transform: uppercase;
}

.new-ov-sub-para {
    font-family: 'Gotham Bold', sans-serif, sans;
    letter-spacing: 1px;
    font-size: 33px;
    line-height: 33px;
    text-transform: uppercase;
}

.row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

#nine-ideas img {
    /* width: 624px;
    height: 423px;*/
}

#nine-ideas .resort-txt {
    display: none;
}

#home-blog .effect-apollo {
    /*background: #3498db;*/
}

#home-blog .resort-txt {
    display: none;
    width: 96%;
}

#home-blog .title {
    position: relative;
    top: -50px;
    left: 10px;
    color: white;
}

#home-blog .title p {
    color: white !important;
}

#home-blog img {
    width: 390px;
    height: 260px;
}

#home-blog .offer-category-titles {
    margin: 4px 0px;
}

#resort-listing .resort-txt {
    display: none;
}

#awards img {
    width: 500px;
    text-align: center;
    margin: auto;
}


/*
  insta feed
  */

.view div {
    overflow: hidden;
    background-color: rgba(127, 140, 141, .8);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    -webkit-transition: all .5s linear;
    -moz-transition: all .5s linear;
    -o-transition: all .5s linear;
    -ms-transition: all .5s linear;
    transition: all .5s linear;
    height: 300px;
    width: 100%;
    cursor: pointer;
    text-align: center;
}

.full-height {
    height: 100%;
}

.display-table {
    display: table !important;
}

.v-align-m {
    vertical-align: middle;
}

.table-cell {
    display: table-cell;
}

.resort-logo .owl-nav [class*='owl-'] {
    transition: all .3s ease;
}

.resort-logo .owl-nav [class*='owl-'].disabled:hover {
    background-color: #D6D6D6;
}

.resort-logo {
    position: relative;
}

.resort-logo .owl-next,
.resort-logo .owl-prev {
    width: 50px !important;
    height: 50px !important;
    line-height: 50px !important;
    position: absolute !important;
    top: 30% !important;
    font-size: 40px !important;
    color: #fff !important;
    text-align: center !important;
    background: #2B201A !important;
}

.resort-logo .owl-next:hover,
.resort-logo .owl-prev:hover {
    background: #171930 !important;
}

.resort-logo .owl-prev {
    left: -70px;
}

.resort-logo .owl-next {
    right: -70px;
}

#SPECIALISTS img {
    width: 100%;
    padding: 40px;
}


/*amcharts*/

.amcharts-map-image-london {
    -webkit-animation: am-pulsating 1s ease-out infinite;
    animation: am-pulsating 1s ease-out infinite;
}

@-webkit-keyframes am-pulsating {
    0% {
        transform: scale(0);
        opacity: 1;
    }

    100% {
        transform: scale(4);
        opacity: 0;
    }
}

@keyframes am-pulsating {
    0% {
        transform: scale(0);
        opacity: 1;
    }

    100% {
        transform: scale(4);
        opacity: 0;
    }
}

.amcharts-map-area:hover {
    fill: #e74c3c;
    stroke: #e74c3c;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out;
}

.map-marker {
    /* adjusting for the marker dimensions 
                    so that it is centered on coordinates */
    margin-left: -8px;
    margin-top: -8px;
}

path {
    position: absolute;
    top: -225px;
    left: -315px
}

tspan {
    color: #16a085 !important;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.img-wrapper {
    position: relative;

}

.img-wrapper img {
    width: 100%;
}

.img-overlay {
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
}

.img-overlay i {
    color: #fff;
    font-size: 3em;
}

#overlay {
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999999;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.overlay2:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    z-index: 1;
    background: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, .35)), color-stop(50%, transparent));
    background: linear-gradient(90deg, rgba(0, 0, 0, .35) 0, transparent 50%);
}

.overlay3:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    z-index: 1;
    background: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, .35)), color-stop(50%, transparent));
    background: linear-gradient(90deg, rgba(0, 0, 0, .35) 0, transparent 50%);
}

#overlay img {
    margin: 0;
    width: 80%;
    height: auto;
    -o-object-fit: contain;
    object-fit: contain;
    padding: 5%;
}

@media screen and (min-width: 769px) {
    .float-icon.btn-go-bottom-float {
        display: none !important;
    }
}

@media screen and (min-width: 768px) {
    #overlay img {
        width: 60%;
    }
}

@media screen and (min-width: 1200px) {
    #overlay img {
        width: 50%;
    }
}

#nextButton {
    color: #fff;
    font-size: 2em;
    transition: opacity 0.8s;
}

#nextButton:hover {
    opacity: 0.7;
}

@media screen and (min-width: 768px) {
    #nextButton {
        font-size: 3em;
    }
}

#prevButton {
    color: #fff;
    font-size: 2em;
    transition: opacity 0.8s;
}

#prevButton:hover {
    opacity: 0.7;
}

@media screen and (min-width: 768px) {
    #prevButton {
        font-size: 3em;
    }
}

#exitButton {
    color: #fff;
    font-size: 2em;
    transition: opacity 0.8s;
    position: absolute;
    top: 15px;
    right: 15px;
}

#exitButton:hover {
    opacity: 0.7;
}

@media screen and (min-width: 768px) {
    #exitButton {
        font-size: 3em;
    }
}

.testimonial-img {
    display: block;
    width: 100px;
    height: 95px;
    margin: auto;
    border-radius: 50%;
}

#testimonial {
    background: url(https://dl.dropbox.com/s/gt3h4mza2j55vaz/parallax.jpg);
    padding: 80px 0px 80px 0px;
    color: #fff;
    background-attachment: fixed;
    background-size: cover;
}

.awards-img {
    width: 500px;
    text-align: center;
    margin: auto;
}

.video-image {
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}


/*play button*/

.video-image {
    height: 600px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0.9;
}

.circle {
    stroke: #c78939;
    stroke-dasharray: 650;
    stroke-dashoffset: 650;
    -webkit-transition: all 0.5s ease-in-out;
    opacity: 0.3;
}

.playBut {
    /*  border: 1px solid red;*/
    display: inline-block;
    -webkit-transition: all 0.5s ease;
    top: 50%;
    right: 47.5%;
    position: absolute;
}

.playBut .triangle {
    -webkit-transition: all 0.7s ease-in-out;
    stroke-dasharray: 240;
    stroke-dashoffset: 480;
    stroke: #000;
    transform: translateY(0);
}

.playBut:hover .triangle,
.playBut:focus .triangle {
    stroke-dashoffset: 0;
    opacity: 1;
    stroke: #c78939;
    animation: nudge 0.7s ease-in-out;
}

@keyframes nudge {
    0% {
        transform: translateX(0);
    }

    30% {
        transform: translateX(-5px);
    }

    50% {
        transform: translateX(5px);
    }

    70% {
        transform: translateX(-2px);
    }

    100% {
        transform: translateX(0);
    }
}

.playBut:hover .circle .playBut:focus .circle {
    stroke-dashoffset: 0;
    opacity: 1;
}


/*---------------*/


/***** Duke *****/


/*---------------*/

.effect-duke {
    width: 100% !important;
    background: -webkit-linear-gradient(-45deg, #c78939 0%, #2B201A 100%) !important;
    background: linear-gradient(-45deg, #c78939 0%, #2B201A 100%) !important;
}

.effect-duke div,
.effect-duke p {
    -webkit-transition: opacity 1s, -webkit-transform 1s;
    transition: opacity 1s, transform 1s;
}

.effect-duke:hover div,
.effect-duke:focus div {
    opacity: 0.1;
    -webkit-transform: scale3d(1.5, 1.5, 1);
    transform: scale3d(1.5, 1.5, 1);
}

.effect-duke h2 {
    font-family: 'Gotham Thin', sans-serif, sans !important;
    -webkit-transition: -webkit-transform 1s;
    transition: transform 1s;
    -webkit-transform: scale3d(0.8, 0.8, 1);
    transform: scale3d(0.8, 0.8, 1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    position: relative;
    top: 66%;
}

.effect-duke p {
    font-family: 'Gotham light', sans-serif, sans !important;
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 20px;
    padding: 30px;
    /* border: 2px solid #fff;*/
    text-transform: none;
    font-size: 90%;
    opacity: 0;
    -webkit-transform: scale3d(0.8, 0.8, 1);
    transform: scale3d(0.8, 0.8, 1);
    -webkit-transform-origin: 50% -100%;
    transform-origin: 50% -100%;
    color: white !important;
    text-transform: uppercase;
}

.effect-duke:hover h2,
.effect-duke:hover p,
.effect-duke:focus h2,
.effect-duke:focus p {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
}

.effect-duke div {
    width: 100%;
    height: 400px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.effect-duke-outer-line {
    padding: 20px 10px;
}

.package-listing .package-title {
    padding-top: 10px !important;
    padding-right: 10px !important;
    padding-bottom: 40px !important;
    padding-left: 10px !important;
}


/*.col-lg-push-6 {
    left: 25%;
  }
  .col-lg-pull-6 {
    right: 75%;
  }*/

.breadcrumb {
    padding: 0px !important;
}

.ovrly17 {
    width: 100%;
    overflow: hidden;
    position: relative;
}

.ovrly17:hover .ovrly {
    top: 0;
}

.ovrly17 .ovrly {
    position: absolute;
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
    height: 100%;
    width: 100%;
    left: 0;
    top: 100%;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}

.destination-wedding-blog img {
    max-width: 90%;
    margin: auto;
}

.destination-wedding-blog p.weddingOptBox-text {
    padding: 30px 30px;
}

.new-txt-title {
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 10%;
    left: 0;
    text-align: center;
    cursor: default;
    color: white;
}

.new-txt-title span {
    font-weight: 300 !important;
    font-weight: 12px !important;
}

.card {
    position: relative;
    border: none;
    text-align: center;
}

.img-container {
    transition: transform .1s ease;
}

.card img {
    transform: translate(0, 2);
    transition: opacity 1s ease-in-out;
}

.view-resort-button,
.view-offer-button {
    font-family: 'Gotham Normal', sans-serif, sans;
    float: left;
    /* margin: 0px 40px; */
    padding-top: 15px;
    font-size: 12px;
    line-height: 20px;
    position: absolute;
    bottom: 40px;
    left: 40px;
    z-index: 100;
}

.view-resort-button:after,
.view-offer-button:after {
    content: '';
    position: absolute;
    background: #736C68;
    width: 100%;
    height: 1px;
    bottom: -7px;
    left: 0;
}

.view-resort-button.lne-white:after,
.view-offer-button .lne-white:after {
    background: #fff;
}

.view-offer-button {
    font-family: 'Gotham Normal', Helvetica, Arial, sans-serif;
    float: left;
    /* margin: 0px 40px; */
    padding-top: 15px;
    font-size: 12px;
    position: absolute;
    bottom: -179px;
    left: 0px;
    z-index: 66666;
}

.ovrly26 {
    margin: auto;
    /*width: 89%;
    height: 340px;*/
    overflow: hidden;
    position: relative;
}

.ovrly26:hover .ovrly {
    -webkit-transform: scale(3);
    -moz-transform: scale(3);
    -ms-transform: scale(3);
    -o-transform: scale(3);
    transform: scale(3);
}

.ovrly26 .ovrly {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    position: absolute;
    width: 100%;
    left: -50%;
    top: -50%;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
}

.ovrly26 .new-txt-title span.resort-txt {
    color: white !important;
}

#offerpagecombox {
    border: 1px solid #999;
    box-shadow: none;
}

select:active,
select:focus,
select:target,
select:visited,
select:checked {
    outline: none !important;
    border: none !important;
}

.pv-villa-options {
    text-align: center;
}

.pv-villa-options ul li {
    list-style: circle;
    display: inline-block;
    margin-right: 40px;
}

.pv-villa-options ul li:before {
    font-family: Font Awesome\ 5 Pro;
    content: "\f058";
    display: inline-block;
    margin-right: 10px;
    width: 1.3em;
}

.offerpagelist {
    text-align: center;
    list-style-type: none;
    list-style: none;
    flex-direction: align;
    padding: 5px;
    list-style-type: none;
}

.offerpagelist li {
    cursor: pointer;
    display: inline-block;
    border: 1px solid #2B201A;
    margin-right: 20px;
    width: 180px;
    margin: 10px 0px;
}

.offerpagelist li a {
    line-height: 30px;
    width: 100%;
    display: block;
    font-weight: bold !important;
    font-size: 12px;
    text-align: center !important;
    letter-spacing: 2px;
    text-transform: uppercase;
    padding: 5px;
}

.offerpagelist li:hover,
.offerpagelist>li>a:hover {
    background: #2B201A !important;
    color: white !important;
}

.offerpagelist .active {
    background: #2B201A !important;
    color: white !important;
}

.background {
    width: 100%;
    height: 80%;
    margin: 70px 0px 0px 0px;
}

.background__overlay {
    min-height: 50%;
    position: relative;
    z-index: 1;
}

.background__ytplayer {
    z-index: 0;
    opacity: 0;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 125%;
    height: 104%;
}

.offer-listing-desc-para {
    width: 82%;
    position: absolute;
    left: 10px;
}

.view-resort {
    bottom: -185px;
    left: 0;
}

#calluspopup .centain {
    width: 800px;
}

#inquirypopupNew .centain,
#inquirypopupNewfrance .centain,
#inquirypopupNewarabic .centain {
    width: 1200px;
}

#bookwhenever .centain {
    width: 900px;
}

#bookwhenever>.fancybox-close-small {
    z-index: 100000;
}

.page-myform {
    padding: 90px;
    background-color: #EFEEEE !important;
    color: #7F7773;
}

.new-ovss-home-title {

    letter-spacing: 1px !important;
    font-size: 40px !important;
    line-height: 40px !important;
    text-transform: uppercase;
}

.new-ovss-home-sub-title {

    font-size: 30px;
    line-height: 30px;
    text-align: center;
}

.new-ovss-main-title {

    letter-spacing: 1px !important;
    font-size: 15px !important;
    line-height: 18px !important;
    text-transform: uppercase;
    color: #262626;
}

.new-ovss-sub-title {

    font-size: 14px;
    line-height: 24px;
}

#intotextforresort .pad-tb-30 {
    height: 80px;
}

.tf-cta-desc {

    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 20px;
    color: #ffffff;
    text-align: center;
}

.tf-cta-desc h3 {

    text-transform: uppercase;
    color: #ffff;
    margin-bottom: 10px;
    margin-left: 50px;
    margin-right: 50px;

}

.tf-cta-text {
    display: inline-block;
    position: relative;
    font-size: 12px;
    line-height: 20px;
}

.tf-cta-text:after {
    left: 0;
    content: "";
    height: 1px;
    bottom: -5px;
    position: absolute;
    width: 100%;
    background: #fff;
}

.tf-cta-text.black:after {
    border-bottom: 1px solid #736C68;
    background: #736C68;
}

.cover {
    position: absolute;
}

.fnt-wgt-nml {
    font-size: 16px;
}

.ovrly23:hover .tf-cta-desc {
    bottom: 40px;
    transition: all .5s ease-in-out;
}

.arrow-left:after {
    content: '';
    width: 20px;
    background: white;
    position: absolute;
    height: 2px;
    top: 7px;
    left: 2px;
}

.ovrly23 {
    margin: auto;
    overflow: hidden;
    position: relative;
    padding: 2px;
}

.ovrly23:hover .ovrly {
    -webkit-transform: scale(3);
    -moz-transform: scale(3);
    -ms-transform: scale(3);
    -o-transform: scale(3);
    transform: scale(3);
}

.ovrly23 .ovrly {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background: rgba(6, 18, 66, 0.55);
    height: 100%;
    position: absolute;
    width: 100%;
    left: -50%;
    top: -50%;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

.text-Lgrey {
    color: #f5f3ee;
}

.text-yellow {
    color: #171930;
}

.fnt-wgt-Newthin {
    font-family: "Exodus Demo Sa", sans-serif, sans !important;
}

.fnt-wgt-thin {
    font-family: "Gotham Thin", sans-serif, sans !important;
}

.fnt-wgt-bld {
    font-family: "Gotham Bold", sans-serif, sans;
}

.padd-btm-15 {
    padding-bottom: 15px;
}

.sub-title {
    letter-spacing: .15em;
    font-weight: 700;
}

.padd-tp-5 {
    padding-top: 5px;
}

.ml-auto,
.mx-auto {
    margin-left: auto !important;
}

.mr-auto,
.mx-auto {
    margin-right: auto !important;
}

.d-block {
    display: block !important;
}

.lne-Thgt-cls {
    line-height: 0.1;
}

#step-row div h6 {
    height: 30px;
}

#services .about-title {
    margin: 10px 0px 15px 0px;
}

@media (min-width:900px) and (max-width:1390px) {
    li.call-us {
        padding-left: 10px;
        padding-right: 10px;
    }

    a.cool-link {
        width: 100% !important;
    }
}

@media (min-width:1300px) {
    .col-xlg-2 {
        margin: auto;
        width: 19.666667%;
    }
}

.col-half-offset {
    margin: auto;
}

.fnt-h2vw {
    font-size: 3vw;
}

.fnt-h1vw {
    font-size: 3.5vw;
}

.moving-background-image {
    background-size: cover;
    background-repeat: no-repeat;
    width: 100% !important;
    height: 50%;
}

.moving-background-image,
.moving-background-container {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    height: 23vw;
    min-height: 400px;
    max-height: 550px;
    min-width: 300px;
    color: #eee;
}

.moving-background-image:before,
.moving-background-container {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1, 1);
    transform: translateZ(0);
    background: #1b2030 url(../images/hotels-in.jpg) top center no-repeat;
    background-size: cover;
    /*background-attachment: fixed;*/
    animation: grow 60s linear 10ms infinite;
    transition: all 0.2s ease-in-out;
    z-index: -2;
}

@keyframes grow {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }
}

.img-filter:after {
    content: "";
    width: 100%;
    height: 55%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgb(6, 18, 66, 0.6) 100%);
}

.full-bg.img-filter:after {
    height: 100%;
    background: rgb(6, 18, 66, 0.86);
}

.door {
    color: white;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 100%;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    text-align: center;
}

.carousel-fade .carousel-inner .item {
    transition-property: opacity;
}

.carousel-fade .carousel-inner .item,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
    opacity: 0;
}

.carousel-fade .carousel-inner .active,
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
    opacity: 1;
}

.carousel-fade .carousel-inner .next,
.carousel-fade .carousel-inner .prev,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
    left: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.footer-img-mobile {
    display: none;
}

.price-title {
    float: left;
    margin: 5px 5px;
    width: 100%;
    display: block;
    text-align: left;
    color: #736C68;
}

#offer-body-sec-0 .home-big-titles {
    font-family: 'Gotham light', sans-serif, sans;
    font-size: 30px;
    line-height: 35px;
    letter-spacing: 0px;
}

#calluspopup123232 .owl-carousel .owl-item img {
    padding: 0px 50px;
}

#calluspopup123232 .fancybox-content {
    padding: 18px;
}

#calluspopup123232 .fa-chevron-left {
    width: 15px;
    height: 100px;
    position: absolute;
    top: 40%;
    margin-left: 0px;
    display: block !important;
    border: 0px solid black;
}

#calluspopup123232 .fa-chevron-right {
    width: 15px;
    height: 100px;
    position: absolute;
    top: 40%;
    right: 16px;
    display: block !important;
    border: 0px solid black;
}

#calluspopup123232 .fa-chevron-left:before,
#calluspopup123232 .fa-chevron-right:before {
    transform: scale(3, -4);
    color: #9a9a9a;
    font-size: 40px;
}

.animated {
    -webkit-animation-duration: 1000 ms;
    animation-duration: 1000 ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.owl-animated-out {
    z-index: 1
}

.owl-animated-in {
    z-index: 0
}

.fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.dynamic-grid {
    -moz-column-count: 3;
    -moz-column-gap: 3%;
    -moz-column-width: 30%;
    -webkit-column-count: 3;
    -webkit-column-gap: 3%;
    -webkit-column-width: 30%;
    column-count: 3;
    column-gap: 3%;
    column-width: 30%;
}

.grid-item {
    display: block;
    float: left;
}



/*neww dropdown*/

.cool-link::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #7E6E64;
    transition: width .3s;
}

.cool-link:hover::after {
    width: 100%;
    transition: width .3s;
}

.tf-secondary-nav {
    margin: 0 10px !important;

}

.tf-secondary-nav>li {
    margin: 0 0.8em;
    width: 120px;
}

.tf-top-nav li {
    display: inline-block;
    padding: 0 2em;
    position: relative;
}

.tf-top-nav li:after {
    content: '';
    border-left: 1px solid #1a1a1a80;
    position: absolute;
    width: 2px;
    height: 13px;
    right: 0;
}

.tf-top-nav li:last-child:after {
    display: none;
}

.tf-secondary-nav a {
    font-family: "Copperplate" !important;
    display: block;
    color: #7e6e64 !important;
    text-transform: uppercase;
    text-align: left;
    font-size: 14px;
    padding-bottom: 0;
}

.tf-secondary-nav a>span,
.btntypetext {
    display: block;
    font-size: .625rem;
    letter-spacing: 1px;
    font-weight: 400;
    line-height: 1.3em;
    font-family: "Copperplate";
    display: block;
    font-size: 13px;
    color: #999999;
    line-height: 12px;
}

@media (max-width:1200px) {
    #nav .navbar {
        padding: 0;
    }

    a.contact-num {
        letter-spacing: 0px;
    }

    a.cool-link {
        width: 100px !important;
    }

    .call-text,
    a.contact-num {
        font-size: 10px;
    }
}

.tf-primary-nav-trigger .tf-menu-text {
    color: #070606;
    text-transform: uppercase;
    font-weight: 700;
    /* hide the text on small devices */
    display: none;
}

.tf-primary-nav-trigger .tf-menu-icon {
    /* this span is the central line of the menu icon */
    display: inline-block;
    position: relative;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    width: 18px;
    height: 2px;
    background-color: #070606;
    -webkit-transition: background-color 0.3s;
    -moz-transition: background-color 0.3s;
    transition: background-color 0.3s;
    /* these are the upper and lower lines in the menu icon */
}

.tf-primary-nav-trigger .tf-menu-icon::before,
.tf-primary-nav-trigger .tf-menu-icon:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #070606;
    right: 0;
    -webkit-transition: -webkit-transform .3s, top .3s, background-color 0s;
    -moz-transition: -moz-transform .3s, top .3s, background-color 0s;
    transition: transform .3s, top .3s, background-color 0s;
}

.tf-primary-nav-trigger .tf-menu-icon::before {
    top: -5px;
}

.tf-primary-nav-trigger .tf-menu-icon::after {
    top: 5px;
}

.tf-primary-nav-trigger .tf-menu-icon.is-clicked {
    background-color: rgba(255, 255, 255, 0);
}

.tf-primary-nav-trigger .tf-menu-icon.is-clicked::before,
.tf-primary-nav-trigger .tf-menu-icon.is-clicked::after {
    background-color: white;
}

.tf-primary-nav-trigger .tf-menu-icon.is-clicked::before {
    top: 0;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}

.tf-primary-nav-trigger .tf-menu-icon.is-clicked::after {
    top: 0;
    -webkit-transform: rotate(225deg);
    -moz-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    -o-transform: rotate(225deg);
    transform: rotate(225deg);
}

@media only screen and (min-width: 768px) {
    .tf-primary-nav-trigger .tf-menu-icon {
        -webkit-transform: translateX(0) translateY(-50%);
        -moz-transform: translateX(0) translateY(-50%);
        -ms-transform: translateX(0) translateY(-50%);
        -o-transform: translateX(0) translateY(-50%);
        transform: translateX(0) translateY(-50%);
    }
}

.tf-primary-nav {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(2, 23, 37, 0.96);
    z-index: 2;
    text-align: center;
    padding: 50px 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    transition-property: transform;
    -webkit-transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    transition-duration: 0.4s;
}

.tf-primary-nav li {
    font-size: 22px;
    font-size: 1.375rem;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: .2em 0;
    text-transform: capitalize;
}

.tf-primary-nav a {
    display: inline-block;
    padding: .4em 1em;
    border-radius: 0.25em;
    -webkit-transition: background 0.2s;
    -moz-transition: background 0.2s;
    transition: background 0.2s;
}

.no-touch .tf-primary-nav a:hover {
    background-color: #e36767;
}

.tf-primary-nav .tf-label {
    color: #06446e;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    font-size: 0.875rem;
    margin: 2.4em 0 .8em;
}

.tf-primary-nav.is-visible {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
}

@media only screen and (min-width: 768px) {
    .tf-primary-nav {
        padding: 80px 0;
    }
}

@media only screen and (min-width: 1170px) {
    .tf-primary-nav li {
        font-size: 30px;
        font-size: 1.875rem;
    }

    .tf-primary-nav .tf-label {
        font-size: 16px;
        font-size: 1rem;
    }
}

.tf-header.is-fixed #secondary_bar {
    padding-top: 10px;
    padding-bottom: 10px;
}

.tf-header.is-fixed #secondary_bar h4 {
    font-size: 16px;
}

.tf-header.is-fixed #secondary_bar h3 {
    font-size: 18px;
}

.tf-header.is-fixed #secondary_bar .breadcrumb {
    display: none !important;
}

.tf-header #secondary_bar #flaoting_button {
    opacity: 0;
    position: relative;
    right: -20%;
    width: 0;
    padding: 0;
    margin: 0;
    -webkit-transition: right 0.3s, opacity 0.3s;
    -moz-transition: right 0.3s, opacity 0.3s;
    transition: right 0.3s, opacity 0.3s;
}

.tf-header.is-fixed #secondary_bar #flaoting_parent {
    width: auto;
    margin: 0 20px;
}

.tf-header.is-fixed #secondary_bar #flaoting_button {
    right: 5%;
    opacity: 1;
    width: auto;
    padding: 10px 30px;
    border: none;
}

.tf-call-to-action.tf-cta-2 {
    background: transparent;
    position: relative;
    overflow: hidden;
    display: block;
}

.tf-call-to-action.tf-cta-2 .img-filter {
    height: 320px;
    background-origin: inherit;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.tf-call-to-action.tf-cta-2 .tf-cta-button,
.tf-call-to-action.tf-cta-2 .tf-cta-desc {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 50px;
    z-index: 2;
    color: #fff;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}

.tf-call-to-action.tf-cta-2 .tf-cta-button {
    bottom: -30px;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    display: inline-block;
}

.tf-call-to-action.tf-cta-2 .tf-cta-button div {
    background: transparent;
    color: #fff;
    position: relative;
    font-size: .625rem;
    letter-spacing: .3em;
    line-height: 1.3em;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    font-weight: 700;
    justify-content: center;
    min-width: 130px;
    padding: 12px 20px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color .3s ease-out, color .3s ease-out, border-color .3s ease-out;
    background-color: transparent;
    border: 1px solid #ffffff;
    padding: 7px 20px;
}

.tf-call-to-action.tf-cta-2 .tf-cta-button div:before {
    width: 100%;
    /*background: #fff;*/
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    display: inline-block;
}

.tf-call-to-action.tf-cta-2 .tf-cta-button div:after,
.tf-call-to-action.tf-cta-2 .tf-cta-button div:before {
    content: "";
    left: 0;
    height: 1px;
    bottom: -10px;
    position: absolute;
}

.tf-call-to-action.tf-cta-2 .tf-cta-button div:after {
    width: 0;
    background: #fff;
}

.tf-call-to-action.tf-cta-2 .tf-cta-button div:hover {
    background: transparent;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    color: #fff;
}

.tf-call-to-action.tf-cta-2 .tf-cta-button div:hover:after {
    width: 100%;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}

.tf-call-to-action.tf-cta-2 .tf-cta-desc h3 {
    font-size: 1.50rem;
    text-transform: uppercase;
    color: #fff;
    line-height: 1.50rem;
    margin-bottom: 10px;
}

.tf-call-to-action.tf-cta-2:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: -webkit-linear-gradient(top, transparent 50%, rgba(0, 0, 0, 0.33));
    background-image: -o-linear-gradient(top, transparent 50%, rgba(0, 0, 0, 0.33) 100%);
    background-image: linear-gradient(180deg, transparent 50%, rgba(0, 0, 0, 0.33));
    background-repeat: repeat-x;
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#00000000", endColorstr="#FF000000", GradientType=0);
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1;
}

.tf-call-to-action.tf-cta-2:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    background: rgba(0, 0, 0, .4);
    z-index: 2;
}

.tf-call-to-action.tf-cta-2:hover .tf-cta-desc,
.tf-elm .bg-white:hover .tf-cta-2 .tf-cta-desc {
    bottom: 110px;
}

.tf-call-to-action.tf-cta-2:hover .tf-cta-button {
    bottom: 75px;
}

.tf-elm .bg-grey:hover .tf-cta-2 .tf-cta-button,
.tf-elm .bg-white:hover .tf-cta-2 .tf-cta-button {
    bottom: 75px;
}

.tf-call-to-action.tf-cta-2:hover:before {
    height: 100%;
}

.tf-elm .bg-grey:hover .tf-cta-2:before,
.tf-elm .bg-white:hover .tf-cta-2:before {
    height: 100%;
}

.img-filter {
    position: relative;
}

.img-filter img {
    position: relative;
}

.btn-link:hover {
    color: #741539;
}

.hotel-swiper .custom-pagination {
    position: relative;
    bottom: auto;
    padding: 50px 0 80px 0;
}

.hotel-swiper .custom-pagination .swiper-pagination-bullet,
#hotel_room_pagi .custom-pagination .swiper-pagination-bullet {
    width: auto;
    height: auto;
    border-radius: 0;
    background: transparent;
    opacity: 1;
    outline: none;
}

.hotel-swiper .custom-pagination .swiper-pagination-bullet,
.btn-border {
    padding: 15px 40px;
    background: transparent;
    border-bottom: 1px solid #212529;
    opacity: 1;
    margin: 0 30px;
}

#requestAQuote:hover .btn-border {
    background: #f3f3f3;
}

.hotels-section:hover .hotel-swiper .custom-pagination .swiper-pagination-bullet {
    background: #fcfcfc;
}

.hotel-swiper .custom-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active,
.btn-border {
    font-family: 'Gotham Bold', sans-serif, sans;
    border: 1px solid #d2d2d2;
    background: white;
    outline: none;
}

#hotel_room_pagi .custom-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    font-family: 'Gotham Bold', sans-serif, sans;
    outline: none;
}

#hotel_room_pagi .custom-pagination {
    z-index: 1;
}

.tf-bdr-button a:after,
.tf-bdr-button>div:after {
    content: '';
    position: absolute;
    background: #8f8f8f;
    width: 100%;
    height: 1.1px;
    bottom: -7px;
    left: 0;
}

.tf-secondary-nav li .dropdown-menu {
    left: -9999px;
    top: -9999px;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    display: block;
    transition: opacity 0.3s ease-in-out;
}

.dropdown-menu {
    opacity: 1;
    left: 0;
    top: 100%;
    visibility: visible;
    transition: opacity 0.3s ease-in-out;
}

.nav>li {
    position: inherit;
    display: block;
    display: table-cell;
    float: none;
    vertical-align: middle;
    padding-left: 15px;
    padding-right: 15px;
}

.overlay {
    position: absolute;
    width: 100%;
    height: 150vh;
    top: 100%;
    width: 100%;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.4s;
    z-index: 1000;
}

.tf-secondary-nav .overlay {
    z-index: -1;
}

.tf-secondary-nav:hover a+.overlay {
    opacity: 1;
}

#menu-background {
    position: fixed;
    width: 100%;
    height: 1000%;
    background: #0000007a;
    top: 0;
    left: 0;
    z-index: 2;
}

.tf-header.is-fixed .tf-secondary-nav>li {
    padding: 10px;
}

.menus-megamenus .mega-menus.active {
    opacity: 1;
    visibility: visible;
    display: block;
    margin: unset;
    -webkit-transition: opacity 0.8s ease-in-out;
    -moz-transition: opacity 0.8s ease-in-out;
    -o-transition: opacity 0.8s ease-in-out;
    transition: opacity 0.8s ease-in-out;
}

.mega-menus {
    opacity: 0;
    visibility: hidden;
    display: none;
    z-index: 1;
    position: absolute;
    top: 100% !important;
    left: 0 !important;
    right: auto;
    margin: auto;
    width: 100% !important;
    padding: 0px;
    background: transparent;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: opacity all 0.3s ease-in-out;
}

.mega-menus .mega-menu-column {
    width: 1200px;
    margin: auto;
}

.grid-container {
    -webkit-column-gap: 5px;
    column-gap: 5px;
    margin: 8px 5px;
}

@media only screen and (max-width: 768px) {

    .packages.container .row {
        display: block;
        margin: 0px;
    }

    .packages.container .row .width-60-left {
        width: 100%;
        margin-left: 0px;
        padding: 20px;
    }

    .packages.container .row .width-60-left h2 {
        text-align: left !important;
    }

    .heading2-style {
        font-size: 22px !important;
    }

    .packages {
        padding: 30px 0px !important;
    }

    .mobile-pdn {
        padding: 4px;
    }

    .padin-new {
        padding: 20px !important;
    }

    .padin-new .owl-item {
        width: 305px !important;
    }

    .padin-new .owl-nav {
        display: none;
    }

    #SnglOfrDesccovratEase {
        padding: 0px !important;
    }

    #SnglOfrDesccovratEase {
        text-align: left !important;
    }

}

@media only screen and (max-width: 480px) {
    .grid-container {
        -webkit-column-count: 1;
        column-count: 1;
    }

    .cta-underline-btn {
        font-size: 14px !important;
        padding: 11px 15px !important;

    }

    body #mainslider a.right.carousel-control.main {
        width: 45px;
        height: 43px;
        box-shadow: none !important;
        padding: 6px !important;
        font-size: 33px !important;
        border: none !important;
        line-height: 26px;
        right: 12px !important;
        background-color: #fff !important;
    }

    .you-will-see {
        margin-top: 60px;
    }

    .pdn-around {
        padding: 25px;
    }

    .pkg-itinerary .margin-n-top-80 {
        margin-top: 30px;
    }

    .flex-display-mobile {
        display: flex !important;
        flex-direction: column;
    }

    .flex-display-mobile .flex-order {
        order: 2;
    }

    .img-around-pdn {
        padding: 40px 25px !important;
    }

    .flex-display-mobile .width-60-left-without-m {
        width: 100%;
        padding: 20px !important;
    }

    .flex-display-mobile .width-60-left-without-m .new-font-style {
        text-align: left !important;
    }

    .react-multi-carousel-item {
        padding: 35px;
    }
}

@media only screen and (min-width: 481px) and (max-width: 620px) {
    .grid-container {
        -webkit-column-count: 2;
        column-count: 2;
    }
}

@media only screen and (min-width: 621px) and (max-width: 1023px) {
    .grid-container {
        -webkit-column-count: 3;
        column-count: 3;
    }
}

@media only screen and (min-width: 1024px) {
    .grid-container {
        -webkit-column-count: 3;
        column-count: 3;
    }
}

.grid-container .item {
    display: inline-block;
}

.call-us-popup {
    width: 680px;
}

.offer-inner-body p {
    padding: 0px;
}

#travel_ideas_list .ovrly23 {
    margin: 5px;
}

.site__wrapper {
    margin-left: auto;
    margin-right: auto;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
}

.site__info {
    clear: both;
    position: absolute;
    bottom: -20%;
    padding: 3em;
    display: block;
    font-variant: small-caps;
    letter-spacing: 2px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.grid {
    float: none;
    width: 100%;
    padding-left: 0.2em;
    padding-right: 0.2em;
    padding-bottom: 0.2em;
}

.grid {
    float: left;
    width: 32.333%;
}

.card__image {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    display: block;
    min-height: 240px;
    overflow: hidden;
    position: relative;
}

.card__image>img {
    display: block;
    margin-bottom: 0;
    width: 100%;
    -webkit-transition: all .25s ease-in-out;
    -moz-transition: all .25s ease-in-out;
    -ms-transition: all .25s ease-in-out;
    -o-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
}

.card__image:hover>img {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
}

.card__title {
    color: white;
    display: inline-block;
    font-size: 1.5em;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 0.75em;
    text-decoration: none;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.card__title:hover {
    color: rgba(255, 255, 255, 0.7);
    text-decoration: none;
}

.card__overlay {
    bottom: 0;
    display: block;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1;
}

.card__overlay--indigo {
    background-image: linear-gradient(to bottom, rgba(92, 107, 192, 0.1), rgba(92, 107, 192, 0.8));
    z-index: 2;
}

.card__overlay--blue {
    background-image: linear-gradient(to bottom, rgba(41, 182, 246, 0.1), rgba(41, 182, 246, 0.8));
    z-index: 2;
}

.card__overlay-content {
    position: absolute;
    bottom: 0;
    padding: 1.5em;
    z-index: 3;
}

.card__meta {
    margin: 0;
    margin-right: -1em;
    margin-bottom: 0.75em;
}

.card__meta.card__meta--last {
    margin-bottom: 0;
}

.card__meta li {
    color: #f8f8f8;
    display: inline;
    list-style-type: none;
    margin-bottom: 0;
    padding-right: 1em;
    font-size: 0.85em;
    font-variant: small-caps;
    letter-spacing: 1px;
}

.card__meta li:not(:last-child):after {
    content: "\25cf";
    font-style: normal;
    position: relative;
    color: rgba(255, 255, 255, 0.25);
    right: -0.6em;
}

.card__meta li>a {
    color: #f8f8f8;
}


/*end blog*/

#resort-facilities .facname {
    display: block;
    font-size: 11px;
    padding-top: 10px;
    text-transform: uppercase;
    color: #555555;
}

.form-control {
    height: 45px;
}

.form-control:focus {
    border-color: non;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #ffe40f80;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    background-color: #fff;
}

label.error {
    color: #C30;
    font-size: 12px;
    position: absolute;
    top: 0;
    right: 10px;
}

.fancybox-bg {
    background: #1e1e1e url(https://www.ovholidays.com/res/images/bg-logo-white.png) 150% / 50% no-repeat;
}

#innerContent {
    width: 300px;
    margin: auto;
}

.scrollbar {
    float: left;
    height: 350px;
    width: 100%;
    overflow-y: auto;
    padding-right: 15px;
}

.force-overflow {
    min-height: 340px;
}

#scrollbar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

#scrollbar::-webkit-scrollbar {
    width: 4px;
    background-color: #F5F5F5;
}

#scrollbar::-webkit-scrollbar-thumb {
    background-color: #171930;
}

#scrollbar ul {
    padding-left: 20px;
}

#scrollbar ul li {
    padding-left: 0;
}

.holiday-slider>div {
    width: 100% !important;
}

.twoColum>ul {
    -moz-column-count: 2;
    -moz-column-gap: 60px;
    -webkit-column-count: 2;
    -webkit-column-gap: 60px;
    column-count: 2;
    column-gap: 60px;
    color: #736C68;
}

@media(max-width:768px) {
    .twoColum>ul {
        -moz-column-count: 1;
        -moz-column-gap: 60px;
        -webkit-column-count: 1;
        -webkit-column-gap: 60px;
        column-count: 1;
        column-gap: 60px;
    }
}

.ov-top {
    display: inline-block;
    height: 40px;
    width: 40px;
    position: fixed;
    bottom: 40px;
    right: 10px;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    /* image replacement properties */
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    background: rgba(255, 228, 15, 0.65) url(https://www.ovholidays.com/res/images/ov-top-arrow.svg) no-repeat center 50%;
    visibility: hidden;
    opacity: 0;
    z-index: 10000;
    -webkit-transition: opacity .3s 0s, visibility 0s .3s, background-color .3s 0s;
    transition: opacity .3s 0s, visibility 0s .3s, background-color .3s 0s;
}

.ov-top.js-ov-whatsapp {
    display: none;
    opacity: 1;
    bottom: 20px;
    background: #075e54;
    left: 15px;
    right: auto;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.16) 0 2px 10px 0 !important;
    text-indent: 0;
    white-space: nowrap;
}

.ov-top.js-ov-whatsapp i {
    line-height: 60px;
    text-align: center;
    width: 60px;
    font-size: 30px;
    color: #ffffff;
}

.ov-top.ov-top--show,
.ov-top.ov-top--fade-out,
.ov-top:hover {
    -webkit-transition: opacity .3s 0s, visibility 0s 0s, background-color .3s 0s;
    transition: opacity .3s 0s, visibility 0s 0s, background-color .3s 0s;
}

.ov-top.ov-top--show {
    visibility: visible;
    opacity: 1;
}

.ov-top.ov-top--fade-out {
    opacity: .5;
}

.ov-top:hover {
    background-color: #171930;
    opacity: 1;
}

@media only screen and (min-width: 768px) {
    .ov-top {
        right: 20px;
        bottom: 20px;
    }
}

@media only screen and (min-width: 1024px) {
    .ov-top {
        right: 30px;
        bottom: 30px;
    }
}

.menu {
    list-style: none;
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100%;
    padding: 15px;
    background-color: #fff;
    transition: all .2s ease-in-out;
}

.menu li {
    position: relative;
}

.menu li a,
.menu li label {
    display: block;
    padding: 10px 0;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #484848;
    font-family: 'Gotham bold', sans-serif, sans;
}

.menu li a:hover {
    color: #171930;
}

.menu {
    padding-top: 20px;
}

.menu li .menu-item-has-children:before {
    position: absolute;
    content: "\f055";
    font-family: Font Awesome\ 5 Pro;
    font-size: 24px;
    line-height: 24px;
    padding: 10px 13px;
    top: -5px;
    right: -10px;
    z-index: 9999;
    color: #DED6D0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='50' height='50' fill='%23787474'%3E%3Cpath d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'%3E%3C/path%3E%3C/svg%3E");
    transition: all .2s ease-in-out;
}

.menu li.menu-open .menu-item-has-children:before {
    content: "\f056";
}

.sub-menu-new {
    list-style: none;
    margin: 0;
    padding: 0;
    height: 0;
    overflow: hidden;
    transition: transform 0.26s ease;
}

.sub-menu-new li a {
    padding-left: 10px;
    font-family: 'Gotham light', sans-serif, sans;
}


/*.menu .menu-item-has-children:hover + ul.sub-menu{display:block;}*/

.custom-selector-wrapper {
    position: relative;
    display: block;
    user-select: none;
}

.custom-selector-wrapper select {
    display: none;
}

.custom-selector {
    position: relative;
    display: inline-block;
    height: 45px;
    border-radius: 0;
}

#myform.page-myform .custom-selector,
#frmContract.page-myform .custom-selector {
    height: 46px;
    padding: 12px 12px;
}

.custom-selector-trigger {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    font-size: 14px;
    height: 100%;
    line-height: 2;
    border-radius: 0;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.custom-selector-trigger:after {
    position: absolute;
    display: block;
    content: '';
    width: 10px;
    height: 10px;
    top: 50%;
    right: 5px;
    margin-top: -3px;
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    transform: rotate(45deg) translateY(-50%);
    transition: all .35s ease-out;
    transform-origin: 50% 0;
}

.custom-selector.opened .custom-selector-trigger:after {
    margin-top: 3px;
    transform: rotate(-135deg) translateY(-50%);
}

.custom-options {
    position: absolute;
    display: block;
    top: 100%;
    left: 0%;
    right: 0;
    width: 100%;
    max-height: 245px;
    overflow-y: auto;
    margin: 0px 0;
    border-radius: 0;
    box-sizing: border-box;
    box-shadow: 0 2px 1px rgba(0, 0, 0, .1);
    background: #fff;
    transition: all .2s ease-in-out;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transform: translateY(-15px);
    border: 0;
    z-index: 1000;
}

.custom-selector.opened .custom-options {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    transform: translateY(0);
    -webkit-box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.05);
}

.option-hover:before {
    background: #f9f9f9;
}

.custom-option {
    position: relative;
    display: block;
    padding: 0 22px;
    font-size: 14px;
    font-weight: 600;
    color: #3c3c3c;
    line-height: 40px;
    cursor: pointer;
    transition: all .05s ease-in-out;
    border-bottom: 1px solid #f3f3f3;
}

.custom-option:hover,
.custom-option.selection {
    color: #fff;
    background-color: #171930;
}

.oluhuveliRes,
.offerChinese {
    position: fixed;
    top: 200px;
    right: 0;
    padding: 10px 20px;
    background: #171930;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
    font-size: 20px;
    text-transform: uppercase;
    font-family: 'Gotham bold', sans-serif, sans-serif;
    line-height: 18px;
    z-index: 10000;
}

.offerChinese {
    top: 450px;
}

.oluhuveliRes span,
.offerChinese span {
    display: block;
    font-size: 12px;
    letter-spacing: 11px;
    font-family: 'Gotham Thin', sans-serif, sans-serif;
}

.offerChinese span {
    letter-spacing: 4px;
}


/* Basics */

#map-search,
#map-search *,
#map-search *:after,
#map-search *:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

#map-search {
    position: relative;
    background-color: white;
    z-index: 100;
    border-radius: 3px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}

#map-search.noback,
.custom-selector.noback {
    background-color: transparent;
    z-index: auto;
    border: none;
    border-radius: 3px;
    box-shadow: none;
    border-bottom: 2px solid #2B201A;
    outline: none !important;
    border-radius: 0;
}

#map-search.show-results {
    border-radius: 3px 3px 0 0;
}

#map-search input[type='search'] {
    font-size: 14px;
    width: 100%;
    border: 0;
    background-color: transparent;
    height: 50px;
    margin: 0;
    color: rgba(0, 0, 0, .5);
    padding: 10px 10px 10px 60px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

#map-search input:focus {
    color: rgba(0, 0, 0, .75);
    outline: 0;
    box-shadow: none;
    outline: thin dotted\8;
}

#map-search .map-search-icon-close {
    opacity: 0;
    position: absolute;
    top: 15px;
    right: 15px;
}

#map-search.show-results .map-search-icon-close {
    opacity: 1;
}

#map-search .map-search-icon-search {
    position: absolute;
    top: 15px;
    left: 15px;
    font-size: 20px;
}

#map-search.show-results:after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background: #eaeaea;
}

#map-search .suggestions {
    position: absolute;
    top: 52px;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    z-index: 10000;
}

#map-search.show-results .suggestions {
    display: block;
    overflow-y: auto;
    max-height: 295px;
    background: white;
}

#map-search .suggestions li {
    width: 100%;
    position: relative;
    background: white;
    padding: 5px;
    cursor: pointer;
    display: none !important;
}

#map-search .suggestions li.Showed {
    display: list-item !important;
}

#map-search .suggestions:hover li,
#map-search .suggestions:hover li.active {
    background: #fff !important;
}

#map-search .suggestions li .map-search-icon {
    position: absolute;
    left: 10px;
    top: 12px;
}

#map-search .suggestions li.active,
#map-search .suggestions li:hover,
#map-search .suggestions li.active:hover {
    background: #eee !important;
}

#map-search .suggestions li .title {
    font-family: 'Gotham Bold', 'raleway', 'lato', sans-serif, sans-serif;
}

#map-search .suggestions li .sub {
    text-transform: uppercase;
    font-size: 11px;
    color: #999;
    margin-top: -2px;
}

#map-search .suggestions li:after {
    content: '';
    position: absolute;
    width: 96%;
    background: #eee;
    height: 1px;
    left: 2%;
    top: -1px;
}

#map-search .suggestions li:first-child:after {
    display: none;
}

#map-search .suggestions li:last-child {
    border-radius: 0 0 3px 3px;
}

@-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes rotate {
    from {
        -moz-transform: rotate(0deg);
    }

    to {
        -moz-transform: rotate(360deg);
    }
}

@-ms-keyframes rotate {
    from {
        -ms-transform: rotate(0deg);
    }

    to {
        -ms-transform: rotate(360deg);
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


/* icons */

.map-search-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    speak: none;
    background-repeat: no-repeat;
}

#map-search .suggestions li:before {
    content: '';
    width: 16px;
    height: 16px;
    position: absolute;
    left: 10px;
    top: 15px;
}

#map-search .suggestions li:before {
    content: '';
    width: 16px;
    height: 16px;
    position: absolute;
    left: 10px;
    top: 15px;
}

#map-search .suggestions::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

#map-search .suggestions::-webkit-scrollbar {
    width: 4px;
    background-color: #F5F5F5;
}

#map-search .suggestions::-webkit-scrollbar-thumb {
    background-color: #171930;
}

.Hidden {
    display: none;
}

.Showed {
    display: list-item !important;
}

#map-search .map-search-icon-search .badge {
    display: inline-block;
    opacity: 0;
    width: 15px;
    height: 15px;
    font-size: 45%;
    position: absolute;
    top: -10px;
    right: -10px;
    line-height: 12px;
    background-color: #171930;
    text-align: center;
    padding: 2px 0;
}

#map-search .map-search-icon-search .badge.Show {
    opacity: 1;
}

.map-search-icon-close {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxuczpzdmc9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMCAyMCIgdmVyc2lvbj0iMS4xIiBoZWlnaHQ9IjIwIiB3aWR0aD0iMjAiPg0KICA8cGF0aCBkPSJtNSA1IDAgMS41IDMuNSAzLjUtMy41IDMuNSAwIDEuNSAxLjUgMCAzLjUtMy41IDMuNSAzLjUgMS41IDAgMC0xLjUtMy41LTMuNSAzLjUtMy41IDAtMS41LTEuNSAwLTMuNSAzLjUtMy41LTMuNS0xLjUgMHoiIGZpbGw9IiMwMDAiLz4NCjwvc3ZnPg==);
}

@media (max-width:1024px) {
    #map-search .dis-tbl {
        display: table !important;
    }

    #map-search .dis-tbl-cell {
        display: table-cell !important;
    }
}

.text-white.no-marg.fnt-16 {
    text-transform: capitalize;
}

.ui-datepicker {
    position: relative;
    width: 25%;
    margin: 0;
    padding: 7em 15px 15px;
    border: 0 !important;
    background: #FFF;
    -webkit-filter: drop-shadow(0 0.25em 1.25em rgba(0, 0, 0, 0.33));
}

.ui-datepicker:before,
.ui-datepicker:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 100%;
    right: 1em;
    left: 1em;
    height: .75em;
    background: #9E9E9E;
}

.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
    width: 50%;
    float: left;
    background: transparent;
    border: none;
    display: inline-block;
}

.ui-datepicker:before {
    z-index: 2;
    right: .5em;
    left: .5em;
    height: .425em;
    background: #D9D9D9;
}

.ui-datepicker>.ui-datepicker-header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 7em;
    overflow: hidden;
    /*background: url(../images/datepicker.jpg) no-repeat center;*/
    background-size: 130% auto;
}

.ui-datepicker>.ui-datepicker-header>.ui-datepicker-prev,
.ui-datepicker>.ui-datepicker-header>.ui-datepicker-next {
    display: block;
    position: absolute;
    top: 30%;
    left: 0;
    width: 2.6em;
    height: 2.6em;
    /* margin: -1.6em 0 0; */
    /* padding: 2.6em 0 0; */
    overflow: hidden;
    border-radius: 1.6em;
    border: 0.3em solid rgba(255, 255, 255, 0.75);
    background: rgba(255, 255, 255, 0.5);
    cursor: pointer;
}

.ui-datepicker>.ui-datepicker-header>.ui-datepicker-prev:hover,
.ui-datepicker>.ui-datepicker-header>.ui-datepicker-next:hover {
    background: rgba(255, 255, 255, 0.75);
}

.ui-datepicker>.ui-datepicker-header>.ui-datepicker-next {
    left: auto;
    right: 0em;
}

.ui-datepicker>.ui-datepicker-header>.ui-datepicker-title {
    padding: 2em 0 0;
    text-align: center;
    text-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
    color: #FFF;
    width: 250px;
    margin: auto;
}

.ui-datepicker>.ui-datepicker-header>.ui-datepicker-title>.ui-datepicker-month {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.5em;
}

.ui-datepicker>.ui-datepicker-header>.ui-datepicker-title>.ui-datepicker-year {
    font-weight: 600;
    font-size: 1.5em;
}

.ui-datepicker>.ui-datepicker-calendar {
    width: 100%;
    margin: 1em 0 0;
}

.ui-datepicker>.ui-datepicker-calendar th {
    text-transform: uppercase;
    font-size: .8em;
    color: #CCC;
}

.ui-datepicker>.ui-datepicker-calendar td {
    font-weight: 400;
    font-size: 16px;
}

.ui-datepicker>.ui-datepicker-calendar td:first-child {
    width: auto;
}

.ui-datepicker>.ui-datepicker-calendar td a,
.ui-datepicker>.ui-datepicker-calendar td span {
    position: relative;
    display: block;
    padding: .75em 0;
    text-decoration: none;
    text-align: center;
    color: inherit;
    background: transparent;
    border: none;
}

.ui-datepicker>.ui-datepicker-calendar td span {
    color: #343434;
}

.ui-datepicker>.ui-datepicker-calendar td.ui-datepicker-today a,
.ui-datepicker>.ui-datepicker-calendar td a.ui-state-active {
    color: #CCC;
}

.ui-datepicker>.ui-datepicker-calendar td.ui-datepicker-today a:before,
.ui-datepicker>.ui-datepicker-calendar td a.ui-state-active:before {
    content: "";
    position: absolute;
    top: 45%;
    left: 45%;
    width: 2em;
    height: 2em;
    margin: -.9em 0 0 -.9em;
    border: .1em solid #171930;
    border-radius: 50%;
}

.ui-datepicker>.ui-datepicker-calendar td.ui-datepicker-today a:after,
.ui-datepicker>.ui-datepicker-calendar td a.ui-state-active:after {
    content: "";
    position: absolute;
    top: 16.5%;
    right: 16.5%;
    width: .4em;
    height: .4em;
    background: #171930;
    border-radius: 50%;
}

.ui-datepicker>.ui-datepicker-calendar td a.ui-state-active {
    color: #FFB98E;
}

.ui-datepicker>.ui-datepicker-calendar td a.ui-state-active:before {
    border-color: #FFAC76;
}

.ui-datepicker>.ui-datepicker-calendar td a.ui-state-active:after {
    background: #FFAC76;
}

.checkbox {
    display: inline-block;
    padding: 0;
    margin: 0;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

.checkbox label {
    cursor: pointer;
    padding-left: 0;
}

.checkbox input[type=checkbox],
.checkbox input[type=radio] {
    opacity: 0;
    position: absolute;
    margin: 0;
    z-index: -1;
    width: 0;
    height: 0;
    overflow: hidden;
    left: 0;
    pointer-events: none;
}

.checkbox .checkbox-material {
    vertical-align: middle;
    position: relative;
}

.checkbox .checkbox-material:before {
    position: absolute;
    left: 8px;
    top: 2px;
    content: "";
    background-color: rgba(0, 0, 0, 0.5);
    height: 4px;
    width: 4px;
    border-radius: 100%;
    z-index: 1;
    opacity: 0;
    margin: 0;
}

.checkbox .checkbox-material .check {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid;
    border-radius: 2px;
    overflow: hidden;
    z-index: 1;
}

.checkbox .checkbox-material .check:before {
    position: absolute;
    content: "";
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    display: block;
    margin-top: -4px;
    margin-left: 6px;
    margin-right: 5px;
    width: 0;
    height: 0;
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0 inset;
    -webkit-animation: checkbox-off 0.3s forwards ease-out;
    animation: checkbox-off 0.3s forwards ease-out;
}

.checkbox input[type=checkbox]:focus+.checkbox-material .check:after,
.checkbox input[type=radio]:focus+.checkbox-material .check:after {
    opacity: 0.2;
}

.checkbox input[type=checkbox]:checked+.checkbox-material .check:before,
.checkbox input[type=radio]:checked+.checkbox-material .check:before {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
    -webkit-animation: checkbox-on 0.3s forwards ease-out;
    animation: checkbox-on 0.3s forwards ease-out;
}

.checkbox input[type=checkbox]:not(:checked)+.checkbox-material:before,
.checkbox input[type=radio]:not(:checked)+.checkbox-material:before {
    -webkit-animation: rippleOff 700ms forwards ease-out;
    animation: rippleOff 700ms forwards ease-out;
}

.checkbox input[type=checkbox]:checked+.checkbox-material:before,
.checkbox input[type=radio]:checked+.checkbox-material:before {
    -webkit-animation: rippleOn 700ms forwards ease-out;
    animation: rippleOn 700ms forwards ease-out;
}

.checkbox input[type=checkbox]:not(:checked)+.checkbox-material .check:after,
.checkbox input[type=radio]:not(:checked)+.checkbox-material .check:after {
    -webkit-animation: rippleOff 700ms forwards ease-out;
    animation: rippleOff 700ms forwards ease-out;
}

.checkbox input[type=checkbox]:checked+.checkbox-material .check:after,
.checkbox input[type=radio]:checked+.checkbox-material .check:after {
    -webkit-animation: rippleOn 700ms forwards ease-out;
    animation: rippleOn 700ms forwards ease-out;
}

.checkbox input[type=checkbox][disabled]:not(:checked)~.checkbox-material .check:before,
.checkbox input[type=checkbox][disabled]+.circle,
.checkbox input[type=radio][disabled]:not(:checked)~.checkbox-material .check:before,
.checkbox input[type=radio][disabled]+.circle {
    opacity: 0.5;
}

.checkbox input[type=checkbox][disabled]+.checkbox-material .check:after,
.checkbox input[type=radio][disabled]+.checkbox-material .check:after {
    background-color: rgba(0, 0, 0, 0.84);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.checkbox input[type=checkbox]:checked~span#yes {
    display: inline-block;
}

.checkbox span#yes,
.checkbox span#no {
    display: none;
}

.checkbox input[type=checkbox]:checked~span#no {
    display: none;
}

.checkbox input[type=checkbox]~span#no {
    display: inline-block;
}

.navcontainer {
    display: table;
    width: 100%;
    padding: 10px;
}

.navcontainer>button,
.navcontainer>.owl-prev,
.navcontainer>.owl-next {
    display: table-cell;
}

.navcontainer>.owl-prev,
.navcontainer>.owl-next,
.navcontainer>button[type=submit] {
    width: 20%;
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    outline: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 2px solid #fff;
    border-radius: 0;
    color: #fff;
    background-color: #ffdd00;
    padding: 15px 12px;
}

.navcontainer>.owl-prev.disabled,
.navcontainer>.owl-next.disabled {
    opacity: 0.35;
}

.navcontainer>button[type=submit] {
    width: 60%;
}

.errorstep {
    position: relative;
    animation: shake .5s linear;
    animation-iteration-count: 3;
}

@keyframes shake {
    0% {
        left: -5px;
    }

    100% {
        right: -5px;
    }
}

.intl-tel-input {
    position: relative;
    display: block
}

.intl-tel-input * {
    box-sizing: border-box;
    -moz-box-sizing: border-box
}

.intl-tel-input .hide {
    display: none
}

.intl-tel-input .v-hide {
    visibility: hidden
}

.intl-tel-input input,
.intl-tel-input input[type=tel],
.intl-tel-input input[type=text] {
    position: relative;
    z-index: 0;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-right: 36px;
    margin-right: 0
}

.intl-tel-input .flag-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 1px
}

.intl-tel-input .selected-flag {
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 6px 0 8px
}

.intl-tel-input .selected-flag .iti-arrow {
    margin-left: 6px;
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 4px solid #555
}

.intl-tel-input .selected-flag .iti-arrow.up {
    border-top: none;
    border-bottom: 4px solid #555
}

.intl-tel-input .country-list {
    position: absolute;
    z-index: 2;
    list-style: none;
    text-align: left;
    padding: 0;
    margin: 0 0 0 -1px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, .2);
    background-color: #fff;
    border: 1px solid #ccc;
    white-space: nowrap;
    max-height: 200px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch
}

.intl-tel-input .country-list.dropup {
    bottom: 100%;
    margin-bottom: -1px
}

.intl-tel-input .country-list .flag-box {
    display: inline-block;
    width: 20px
}

@media (max-width:500px) {
    .intl-tel-input .country-list {
        white-space: normal
    }

    .slider-sub-text {
        font-size: 18px !important;
    }
}

.intl-tel-input .country-list .divider {
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #ccc
}

.intl-tel-input .country-list .country {
    padding: 5px 10px;
    outline: 0
}

.intl-tel-input .country-list .country .dial-code {
    color: #999
}

.intl-tel-input .country-list .country.highlight {
    background-color: rgba(0, 0, 0, .05);
    font-size: 17px !important;
    margin: 0;
}

.intl-tel-input .country-list .country-name,
.intl-tel-input .country-list .dial-code,
.intl-tel-input .country-list .flag-box {
    vertical-align: middle
}

.intl-tel-input .country-list .country-name,
.intl-tel-input .country-list .flag-box {
    margin-right: 6px
}

.intl-tel-input.allow-dropdown input,
.intl-tel-input.allow-dropdown input[type=tel],
.intl-tel-input.allow-dropdown input[type=text],
.intl-tel-input.separate-dial-code input,
.intl-tel-input.separate-dial-code input[type=tel],
.intl-tel-input.separate-dial-code input[type=text] {
    padding-right: 6px;
    padding-left: 58px;
    margin-left: 0
}

.intl-tel-input.allow-dropdown .flag-container,
.intl-tel-input.separate-dial-code .flag-container {
    right: auto;
    left: 0
}

.intl-tel-input.allow-dropdown .flag-container:hover {
    cursor: pointer
}

.intl-tel-input.allow-dropdown .flag-container:hover .selected-flag {
    background-color: rgba(0, 0, 0, .05)
}

.intl-tel-input.allow-dropdown input[disabled]+.flag-container:hover,
.intl-tel-input.allow-dropdown input[readonly]+.flag-container:hover {
    cursor: default
}

.intl-tel-input.allow-dropdown input[disabled]+.flag-container:hover .selected-flag,
.intl-tel-input.allow-dropdown input[readonly]+.flag-container:hover .selected-flag {
    background-color: transparent
}

.intl-tel-input.separate-dial-code .selected-flag {
    background-color: rgba(0, 0, 0, .05)
}

.intl-tel-input.separate-dial-code .selected-dial-code {
    margin-left: 6px
}

.intl-tel-input.iti-container {
    position: absolute;
    top: -1000px;
    left: -1000px;
    z-index: 1060;
    padding: 1px
}

.intl-tel-input.iti-container:hover {
    cursor: pointer
}

.iti-mobile .intl-tel-input.iti-container {
    top: 30px;
    bottom: 30px;
    left: 30px;
    right: 30px;
    position: fixed
}

.iti-mobile .intl-tel-input .country-list {
    max-height: 100%;
    width: 100%
}

.iti-mobile .intl-tel-input .country-list .country {
    padding: 10px 10px;
    line-height: 1.5em;
    line-height: 20px;
    font-size: 14px !important;
}

.iti-flag {
    width: 20px
}

.iti-flag.be {
    width: 18px
}

.iti-flag.ch {
    width: 15px
}

.iti-flag.mc {
    width: 19px
}

.iti-flag.ne {
    width: 18px
}

.iti-flag.np {
    width: 13px
}

.iti-flag.va {
    width: 15px
}

@media (-webkit-min-device-pixel-ratio:2),
(min-resolution:192dpi) {
    .iti-flag {
        background-size: 5652px 15px
    }
}

.iti-flag.ac {
    height: 10px;
    background-position: 0 0
}

.iti-flag.ad {
    height: 14px;
    background-position: -22px 0
}

.iti-flag.ae {
    height: 10px;
    background-position: -44px 0
}

.iti-flag.af {
    height: 14px;
    background-position: -66px 0
}

.iti-flag.ag {
    height: 14px;
    background-position: -88px 0
}

.iti-flag.ai {
    height: 10px;
    background-position: -110px 0
}

.iti-flag.al {
    height: 15px;
    background-position: -132px 0
}

.iti-flag.am {
    height: 10px;
    background-position: -154px 0
}

.iti-flag.ao {
    height: 14px;
    background-position: -176px 0
}

.iti-flag.aq {
    height: 14px;
    background-position: -198px 0
}

.iti-flag.ar {
    height: 13px;
    background-position: -220px 0
}

.iti-flag.as {
    height: 10px;
    background-position: -242px 0
}

.iti-flag.at {
    height: 14px;
    background-position: -264px 0
}

.iti-flag.au {
    height: 10px;
    background-position: -286px 0
}

.iti-flag.aw {
    height: 14px;
    background-position: -308px 0
}

.iti-flag.ax {
    height: 13px;
    background-position: -330px 0
}

.iti-flag.az {
    height: 10px;
    background-position: -352px 0
}

.iti-flag.ba {
    height: 10px;
    background-position: -374px 0
}

.iti-flag.bb {
    height: 14px;
    background-position: -396px 0
}

.iti-flag.bd {
    height: 12px;
    background-position: -418px 0
}

.iti-flag.be {
    height: 15px;
    background-position: -440px 0
}

.iti-flag.bf {
    height: 14px;
    background-position: -460px 0
}

.iti-flag.bg {
    height: 12px;
    background-position: -482px 0
}

.iti-flag.bh {
    height: 12px;
    background-position: -504px 0
}

.iti-flag.bi {
    height: 12px;
    background-position: -526px 0
}

.iti-flag.bj {
    height: 14px;
    background-position: -548px 0
}

.iti-flag.bl {
    height: 14px;
    background-position: -570px 0
}

.iti-flag.bm {
    height: 10px;
    background-position: -592px 0
}

.iti-flag.bn {
    height: 10px;
    background-position: -614px 0
}

.iti-flag.bo {
    height: 14px;
    background-position: -636px 0
}

.iti-flag.bq {
    height: 14px;
    background-position: -658px 0
}

.iti-flag.br {
    height: 14px;
    background-position: -680px 0
}

.iti-flag.bs {
    height: 10px;
    background-position: -702px 0
}

.iti-flag.bt {
    height: 14px;
    background-position: -724px 0
}

.iti-flag.bv {
    height: 15px;
    background-position: -746px 0
}

.iti-flag.bw {
    height: 14px;
    background-position: -768px 0
}

.iti-flag.by {
    height: 10px;
    background-position: -790px 0
}

.iti-flag.bz {
    height: 14px;
    background-position: -812px 0
}

.iti-flag.ca {
    height: 10px;
    background-position: -834px 0
}

.iti-flag.cc {
    height: 10px;
    background-position: -856px 0
}

.iti-flag.cd {
    height: 15px;
    background-position: -878px 0
}

.iti-flag.cf {
    height: 14px;
    background-position: -900px 0
}

.iti-flag.cg {
    height: 14px;
    background-position: -922px 0
}

.iti-flag.ch {
    height: 15px;
    background-position: -944px 0
}

.iti-flag.ci {
    height: 14px;
    background-position: -961px 0
}

.iti-flag.ck {
    height: 10px;
    background-position: -983px 0
}

.iti-flag.cl {
    height: 14px;
    background-position: -1005px 0
}

.iti-flag.cm {
    height: 14px;
    background-position: -1027px 0
}

.iti-flag.cn {
    height: 14px;
    background-position: -1049px 0
}

.iti-flag.co {
    height: 14px;
    background-position: -1071px 0
}

.iti-flag.cp {
    height: 14px;
    background-position: -1093px 0
}

.iti-flag.cr {
    height: 12px;
    background-position: -1115px 0
}

.iti-flag.cu {
    height: 10px;
    background-position: -1137px 0
}

.iti-flag.cv {
    height: 12px;
    background-position: -1159px 0
}

.iti-flag.cw {
    height: 14px;
    background-position: -1181px 0
}

.iti-flag.cx {
    height: 10px;
    background-position: -1203px 0
}

.iti-flag.cy {
    height: 14px;
    background-position: -1225px 0
}

.iti-flag.cz {
    height: 14px;
    background-position: -1247px 0
}

.iti-flag.de {
    height: 12px;
    background-position: -1269px 0
}

.iti-flag.dg {
    height: 10px;
    background-position: -1291px 0
}

.iti-flag.dj {
    height: 14px;
    background-position: -1313px 0
}

.iti-flag.dk {
    height: 15px;
    background-position: -1335px 0
}

.iti-flag.dm {
    height: 10px;
    background-position: -1357px 0
}

.iti-flag.do {
    height: 14px;
    background-position: -1379px 0
}

.iti-flag.dz {
    height: 14px;
    background-position: -1401px 0
}

.iti-flag.ea {
    height: 14px;
    background-position: -1423px 0
}

.iti-flag.ec {
    height: 14px;
    background-position: -1445px 0
}

.iti-flag.ee {
    height: 13px;
    background-position: -1467px 0
}

.iti-flag.eg {
    height: 14px;
    background-position: -1489px 0
}

.iti-flag.eh {
    height: 10px;
    background-position: -1511px 0
}

.iti-flag.er {
    height: 10px;
    background-position: -1533px 0
}

.iti-flag.es {
    height: 14px;
    background-position: -1555px 0
}

.iti-flag.et {
    height: 10px;
    background-position: -1577px 0
}

.iti-flag.eu {
    height: 14px;
    background-position: -1599px 0
}

.iti-flag.fi {
    height: 12px;
    background-position: -1621px 0
}

.iti-flag.fj {
    height: 10px;
    background-position: -1643px 0
}

.iti-flag.fk {
    height: 10px;
    background-position: -1665px 0
}

.iti-flag.fm {
    height: 11px;
    background-position: -1687px 0
}

.iti-flag.fo {
    height: 15px;
    background-position: -1709px 0
}

.iti-flag.fr {
    height: 14px;
    background-position: -1731px 0
}

.iti-flag.ga {
    height: 15px;
    background-position: -1753px 0
}

.iti-flag.gb {
    height: 10px;
    background-position: -1775px 0
}

.iti-flag.gd {
    height: 12px;
    background-position: -1797px 0
}

.iti-flag.ge {
    height: 14px;
    background-position: -1819px 0
}

.iti-flag.gf {
    height: 14px;
    background-position: -1841px 0
}

.iti-flag.gg {
    height: 14px;
    background-position: -1863px 0
}

.iti-flag.gh {
    height: 14px;
    background-position: -1885px 0
}

.iti-flag.gi {
    height: 10px;
    background-position: -1907px 0
}

.iti-flag.gl {
    height: 14px;
    background-position: -1929px 0
}

.iti-flag.gm {
    height: 14px;
    background-position: -1951px 0
}

.iti-flag.gn {
    height: 14px;
    background-position: -1973px 0
}

.iti-flag.gp {
    height: 14px;
    background-position: -1995px 0
}

.iti-flag.gq {
    height: 14px;
    background-position: -2017px 0
}

.iti-flag.gr {
    height: 14px;
    background-position: -2039px 0
}

.iti-flag.gs {
    height: 10px;
    background-position: -2061px 0
}

.iti-flag.gt {
    height: 13px;
    background-position: -2083px 0
}

.iti-flag.gu {
    height: 11px;
    background-position: -2105px 0
}

.iti-flag.gw {
    height: 10px;
    background-position: -2127px 0
}

.iti-flag.gy {
    height: 12px;
    background-position: -2149px 0
}

.iti-flag.hk {
    height: 14px;
    background-position: -2171px 0
}

.iti-flag.hm {
    height: 10px;
    background-position: -2193px 0
}

.iti-flag.hn {
    height: 10px;
    background-position: -2215px 0
}

.iti-flag.hr {
    height: 10px;
    background-position: -2237px 0
}

.iti-flag.ht {
    height: 12px;
    background-position: -2259px 0
}

.iti-flag.hu {
    height: 10px;
    background-position: -2281px 0
}

.iti-flag.ic {
    height: 14px;
    background-position: -2303px 0
}

.iti-flag.id {
    height: 14px;
    background-position: -2325px 0
}

.iti-flag.ie {
    height: 10px;
    background-position: -2347px 0
}

.iti-flag.il {
    height: 15px;
    background-position: -2369px 0
}

.iti-flag.im {
    height: 10px;
    background-position: -2391px 0
}

.iti-flag.in {
    height: 14px;
    background-position: -2413px 0
}

.iti-flag.io {
    height: 10px;
    background-position: -2435px 0
}

.iti-flag.iq {
    height: 14px;
    background-position: -2457px 0
}

.iti-flag.ir {
    height: 12px;
    background-position: -2479px 0
}

.iti-flag.is {
    height: 15px;
    background-position: -2501px 0
}

.iti-flag.it {
    height: 14px;
    background-position: -2523px 0
}

.iti-flag.je {
    height: 12px;
    background-position: -2545px 0
}

.iti-flag.jm {
    height: 10px;
    background-position: -2567px 0
}

.iti-flag.jo {
    height: 10px;
    background-position: -2589px 0
}

.iti-flag.jp {
    height: 14px;
    background-position: -2611px 0
}

.iti-flag.ke {
    height: 14px;
    background-position: -2633px 0
}

.iti-flag.kg {
    height: 12px;
    background-position: -2655px 0
}

.iti-flag.kh {
    height: 13px;
    background-position: -2677px 0
}

.iti-flag.ki {
    height: 10px;
    background-position: -2699px 0
}

.iti-flag.km {
    height: 12px;
    background-position: -2721px 0
}

.iti-flag.kn {
    height: 14px;
    background-position: -2743px 0
}

.iti-flag.kp {
    height: 10px;
    background-position: -2765px 0
}

.iti-flag.kr {
    height: 14px;
    background-position: -2787px 0
}

.iti-flag.kw {
    height: 10px;
    background-position: -2809px 0
}

.iti-flag.ky {
    height: 10px;
    background-position: -2831px 0
}

.iti-flag.kz {
    height: 10px;
    background-position: -2853px 0
}

.iti-flag.la {
    height: 14px;
    background-position: -2875px 0
}

.iti-flag.lb {
    height: 14px;
    background-position: -2897px 0
}

.iti-flag.lc {
    height: 10px;
    background-position: -2919px 0
}

.iti-flag.li {
    height: 12px;
    background-position: -2941px 0
}

.iti-flag.lk {
    height: 10px;
    background-position: -2963px 0
}

.iti-flag.lr {
    height: 11px;
    background-position: -2985px 0
}

.iti-flag.ls {
    height: 14px;
    background-position: -3007px 0
}

.iti-flag.lt {
    height: 12px;
    background-position: -3029px 0
}

.iti-flag.lu {
    height: 12px;
    background-position: -3051px 0
}

.iti-flag.lv {
    height: 10px;
    background-position: -3073px 0
}

.iti-flag.ly {
    height: 10px;
    background-position: -3095px 0
}

.iti-flag.ma {
    height: 14px;
    background-position: -3117px 0
}

.iti-flag.mc {
    height: 15px;
    background-position: -3139px 0
}

.iti-flag.md {
    height: 10px;
    background-position: -3160px 0
}

.iti-flag.me {
    height: 10px;
    background-position: -3182px 0
}

.iti-flag.mf {
    height: 14px;
    background-position: -3204px 0
}

.iti-flag.mg {
    height: 14px;
    background-position: -3226px 0
}

.iti-flag.mh {
    height: 11px;
    background-position: -3248px 0
}

.iti-flag.mk {
    height: 10px;
    background-position: -3270px 0
}

.iti-flag.ml {
    height: 14px;
    background-position: -3292px 0
}

.iti-flag.mm {
    height: 14px;
    background-position: -3314px 0
}

.iti-flag.mn {
    height: 10px;
    background-position: -3336px 0
}

.iti-flag.mo {
    height: 14px;
    background-position: -3358px 0
}

.iti-flag.mp {
    height: 10px;
    background-position: -3380px 0
}

.iti-flag.mq {
    height: 14px;
    background-position: -3402px 0
}

.iti-flag.mr {
    height: 14px;
    background-position: -3424px 0
}

.iti-flag.ms {
    height: 10px;
    background-position: -3446px 0
}

.iti-flag.mt {
    height: 14px;
    background-position: -3468px 0
}

.iti-flag.mu {
    height: 14px;
    background-position: -3490px 0
}

.iti-flag.mv {
    height: 14px;
    background-position: -3512px 0
}

.iti-flag.mw {
    height: 14px;
    background-position: -3534px 0
}

.iti-flag.mx {
    height: 12px;
    background-position: -3556px 0
}

.iti-flag.my {
    height: 10px;
    background-position: -3578px 0
}

.iti-flag.mz {
    height: 14px;
    background-position: -3600px 0
}

.iti-flag.na {
    height: 14px;
    background-position: -3622px 0
}

.iti-flag.nc {
    height: 10px;
    background-position: -3644px 0
}

.iti-flag.ne {
    height: 15px;
    background-position: -3666px 0
}

.iti-flag.nf {
    height: 10px;
    background-position: -3686px 0
}

.iti-flag.ng {
    height: 10px;
    background-position: -3708px 0
}

.iti-flag.ni {
    height: 12px;
    background-position: -3730px 0
}

.iti-flag.nl {
    height: 14px;
    background-position: -3752px 0
}

.iti-flag.no {
    height: 15px;
    background-position: -3774px 0
}

.iti-flag.np {
    height: 15px;
    background-position: -3796px 0
}

.iti-flag.nr {
    height: 10px;
    background-position: -3811px 0
}

.iti-flag.nu {
    height: 10px;
    background-position: -3833px 0
}

.iti-flag.nz {
    height: 10px;
    background-position: -3855px 0
}

.iti-flag.om {
    height: 10px;
    background-position: -3877px 0
}

.iti-flag.pa {
    height: 14px;
    background-position: -3899px 0
}

.iti-flag.pe {
    height: 14px;
    background-position: -3921px 0
}

.iti-flag.pf {
    height: 14px;
    background-position: -3943px 0
}

.iti-flag.pg {
    height: 15px;
    background-position: -3965px 0
}

.iti-flag.ph {
    height: 10px;
    background-position: -3987px 0
}

.iti-flag.pk {
    height: 14px;
    background-position: -4009px 0
}

.iti-flag.pl {
    height: 13px;
    background-position: -4031px 0
}

.iti-flag.pm {
    height: 14px;
    background-position: -4053px 0
}

.iti-flag.pn {
    height: 10px;
    background-position: -4075px 0
}

.iti-flag.pr {
    height: 14px;
    background-position: -4097px 0
}

.iti-flag.ps {
    height: 10px;
    background-position: -4119px 0
}

.iti-flag.pt {
    height: 14px;
    background-position: -4141px 0
}

.iti-flag.pw {
    height: 13px;
    background-position: -4163px 0
}

.iti-flag.py {
    height: 11px;
    background-position: -4185px 0
}

.iti-flag.qa {
    height: 8px;
    background-position: -4207px 0
}

.iti-flag.re {
    height: 14px;
    background-position: -4229px 0
}

.iti-flag.ro {
    height: 14px;
    background-position: -4251px 0
}

.iti-flag.rs {
    height: 14px;
    background-position: -4273px 0
}

.iti-flag.ru {
    height: 14px;
    background-position: -4295px 0
}

.iti-flag.rw {
    height: 14px;
    background-position: -4317px 0
}

.iti-flag.sa {
    height: 14px;
    background-position: -4339px 0
}

.iti-flag.sb {
    height: 10px;
    background-position: -4361px 0
}

.iti-flag.sc {
    height: 10px;
    background-position: -4383px 0
}

.iti-flag.sd {
    height: 10px;
    background-position: -4405px 0
}

.iti-flag.se {
    height: 13px;
    background-position: -4427px 0
}

.iti-flag.sg {
    height: 14px;
    background-position: -4449px 0
}

.iti-flag.sh {
    height: 10px;
    background-position: -4471px 0
}

.iti-flag.si {
    height: 10px;
    background-position: -4493px 0
}

.iti-flag.sj {
    height: 15px;
    background-position: -4515px 0
}

.iti-flag.sk {
    height: 14px;
    background-position: -4537px 0
}

.iti-flag.sl {
    height: 14px;
    background-position: -4559px 0
}

.iti-flag.sm {
    height: 15px;
    background-position: -4581px 0
}

.iti-flag.sn {
    height: 14px;
    background-position: -4603px 0
}

.iti-flag.so {
    height: 14px;
    background-position: -4625px 0
}

.iti-flag.sr {
    height: 14px;
    background-position: -4647px 0
}

.iti-flag.ss {
    height: 10px;
    background-position: -4669px 0
}

.iti-flag.st {
    height: 10px;
    background-position: -4691px 0
}

.iti-flag.sv {
    height: 12px;
    background-position: -4713px 0
}

.iti-flag.sx {
    height: 14px;
    background-position: -4735px 0
}

.iti-flag.sy {
    height: 14px;
    background-position: -4757px 0
}

.iti-flag.sz {
    height: 14px;
    background-position: -4779px 0
}

.iti-flag.ta {
    height: 10px;
    background-position: -4801px 0
}

.iti-flag.tc {
    height: 10px;
    background-position: -4823px 0
}

.iti-flag.td {
    height: 14px;
    background-position: -4845px 0
}

.iti-flag.tf {
    height: 14px;
    background-position: -4867px 0
}

.iti-flag.tg {
    height: 13px;
    background-position: -4889px 0
}

.iti-flag.th {
    height: 14px;
    background-position: -4911px 0
}

.iti-flag.tj {
    height: 10px;
    background-position: -4933px 0
}

.iti-flag.tk {
    height: 10px;
    background-position: -4955px 0
}

.iti-flag.tl {
    height: 10px;
    background-position: -4977px 0
}

.iti-flag.tm {
    height: 14px;
    background-position: -4999px 0
}

.iti-flag.tn {
    height: 14px;
    background-position: -5021px 0
}

.iti-flag.to {
    height: 10px;
    background-position: -5043px 0
}

.iti-flag.tr {
    height: 14px;
    background-position: -5065px 0
}

.iti-flag.tt {
    height: 12px;
    background-position: -5087px 0
}

.iti-flag.tv {
    height: 10px;
    background-position: -5109px 0;
    display: none;
}

.iti-flag.tw {
    height: 14px;
    background-position: -5131px 0
}

.iti-flag.tz {
    height: 14px;
    background-position: -5153px 0
}

.iti-flag.ua {
    height: 14px;
    background-position: -5175px 0
}

.iti-flag.ug {
    height: 14px;
    background-position: -5197px 0
}

.iti-flag.um {
    height: 11px;
    background-position: -5219px 0
}

.iti-flag.un {
    height: 14px;
    background-position: -5241px 0
}

.iti-flag.us {
    height: 11px;
    background-position: -5263px 0
}

.iti-flag.uy {
    height: 14px;
    background-position: -5285px 0
}

.iti-flag.uz {
    height: 10px;
    background-position: -5307px 0
}

.iti-flag.va {
    height: 15px;
    background-position: -5329px 0
}

.iti-flag.vc {
    height: 14px;
    background-position: -5346px 0
}

.iti-flag.ve {
    height: 14px;
    background-position: -5368px 0
}

.iti-flag.vg {
    height: 10px;
    background-position: -5390px 0
}

.iti-flag.vi {
    height: 14px;
    background-position: -5412px 0
}

.iti-flag.vn {
    height: 14px;
    background-position: -5434px 0
}

.iti-flag.vu {
    height: 12px;
    background-position: -5456px 0
}

.iti-flag.wf {
    height: 14px;
    background-position: -5478px 0
}

.iti-flag.ws {
    height: 10px;
    background-position: -5500px 0
}

.iti-flag.xk {
    height: 15px;
    background-position: -5522px 0
}

.iti-flag.ye {
    height: 14px;
    background-position: -5544px 0
}

.iti-flag.yt {
    height: 14px;
    background-position: -5566px 0
}

.iti-flag.za {
    height: 14px;
    background-position: -5588px 0
}

.iti-flag.zm {
    height: 14px;
    background-position: -5610px 0
}

.iti-flag.zw {
    height: 10px;
    background-position: -5632px 0
}

.iti-flag {
    height: 15px;
    box-shadow: 0 0 1px 0 #888;
    background-image: url(https://raw.githubusercontent.com/jackocnr/intl-tel-input/master/build/img/flags.png);
    background-repeat: no-repeat;
    background-color: #dbdbdb;
    background-position: 20px 0
}

@media (-webkit-min-device-pixel-ratio:2),
(min-resolution:192dpi) {
    .iti-flag {
        background-image: url(https://raw.githubusercontent.com/jackocnr/intl-tel-input/master/build/img/flags%402x.png)
    }
}

.iti-flag.np {
    background-color: transparent
}

.coloured .checkbox-material .check {
    color: #171930;
}

.coloured .checkbox-material:before {
    background-color: #171930;
}

.coloured input[type=checkbox]:checked+.checkbox-material .check {
    color: #171930;
}

@-webkit-keyframes checkbox-on {
    0% {
        box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px;
    }

    50% {
        box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px 2px 0 11px;
    }

    100% {
        box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
    }
}

@keyframes checkbox-on {
    0% {
        box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px;
    }

    50% {
        box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px 2px 0 11px;
    }

    100% {
        box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
    }
}

@-webkit-keyframes checkbox-off {
    0% {
        box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
    }

    25% {
        box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
    }

    50% {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        margin-top: -4px;
        margin-left: 6px;
        width: 0px;
        height: 0px;
        box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px, 0 0 0 0 inset;
    }

    51% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        margin-top: -2px;
        margin-left: -2px;
        width: 20px;
        height: 20px;
        box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0px 0px 0 10px inset;
    }

    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        margin-top: -2px;
        margin-left: -2px;
        width: 20px;
        height: 20px;
        box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0px 0px 0 0px inset;
    }
}

@keyframes checkbox-off {
    0% {
        box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
    }

    25% {
        box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
    }

    50% {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        margin-top: -4px;
        margin-left: 6px;
        width: 0px;
        height: 0px;
        box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px, 0 0 0 0 inset;
    }

    51% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        margin-top: -2px;
        margin-left: -2px;
        width: 20px;
        height: 20px;
        box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0px 0px 0 10px inset;
    }

    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        margin-top: -2px;
        margin-left: -2px;
        width: 20px;
        height: 20px;
        box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0px 0px 0 0px inset;
    }
}

@-webkit-keyframes rippleOn {
    0% {
        opacity: 0.5;
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(13, 13);
        transform: scale(13, 13);
    }
}

@keyframes rippleOn {
    0% {
        opacity: 0.5;
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(13, 13);
        transform: scale(13, 13);
    }
}

@-webkit-keyframes rippleOff {
    0% {
        opacity: 0.5;
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(13, 13);
        transform: scale(13, 13);
    }
}

@keyframes rippleOff {
    0% {
        opacity: 0.5;
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(13, 13);
        transform: scale(13, 13);
    }
}

.custom-selector-wrapper label.error,
.intl-tel-input label.error {
    top: -30px;
}

.custom-selector.budget .custom-options .custom-option {
    padding: 0 10px;
    font-size: 13px;
}

.withafter:after {
    content: '';
    background: #dadada;
    position: absolute;
    width: 500%;
    height: 100%;
    top: 0;
    right: 0;
}

.islanddesc {
    top: 40%
}

#googleReview span.icon-google,
#googleReview span.icon-facebook-f {
    background-color: #fff;
    width: 50px;
    height: 50px;
    line-height: 48px;
    text-align: center;
    color: #CF3D2E;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    border-radius: 50%;
    font-size: 20px;
    z-index: 100;
    padding: 0;
    position: relative;
    -webkit-box-shadow: -4px 1px 8px 0px rgba(0, 0, 0, 0.2);
    box-shadow: -4px 1px 8px 0px rgba(0, 0, 0, 0.2);
}

#googleReview a>span {
    display: inline-block;
    padding: 5px 10px;
    margin-top: 0px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    padding-left: 20px;
    text-align: left;
}


/*nav*/

header {
    background: white;
    padding: 20px;
    position: fixed;
    width: 100%;
    z-index: 44444444444;
}

.site-title {
    display: flex;
    width: 260px;
    height: 60px;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
}

.cta-underline-btn.header-btn-call {
    padding: 7px 10px !important;
    font-weight: 300;
}

nav {
    padding: 0;
}

.responsive-chat-icon {
    cursor: pointer;
    position: absolute;
    top: 28px;
    right: 65px;
    z-index: 999;
}

.responsive-chat-icon {
    color: #545454;
    display: block;
    text-align: center;
    border: #7E6E64 1px solid;
    border-radius: 50px;
    padding: 0;
    width: 35px;
    font-size: 13px;
    background-color: #7E6E64;
    height: 35px;
    line-height: 32px;
}

.openwide .responsive-chat-icon::before {
    content: "\f095";
}

.responsive-menu-icon {
    cursor: pointer;
    position: absolute;
    top: 28px;
    right: 10px;
    z-index: 999;
}

.special-5grid-sec>.tf-elm {
    width: 20%;
}

.menu {
    overflow: scroll;
}

.cdn-main-content {
    width: 400px;
    position: fixed;
    top: 450px;
    right: 0;
    z-index: 10000;
}

.cdn-btn {
    position: relative;
    display: inline-block;
    padding: 1.5em 2em;
    background-color: #ffffff;
    color: #ffffff;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5), 0 0 5px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5), 0 0 5px rgba(0, 0, 0, 0.1);
    -webkit-transition: -webkit-box-shadow 0.2s;
    transition: -webkit-box-shadow 0.2s;
    transition: box-shadow 0.2s;
    transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
}

.cdn-btn:hover {
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5), 0 0 20px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5), 0 0 20px rgba(0, 0, 0, 0.3);
}

@media only screen and (min-width: 1170px) {
    .cdn-main-content h1 {
        font-size: 3.2rem;
    }
}

.cdn-panel {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    visibility: hidden;
    -webkit-transition: visibility 0s 0.6s;
    transition: visibility 0s 0.6s;
    z-index: 100000;
}

.cdn-panel::after {
    /* overlay layer */
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    cursor: pointer;
    -webkit-transition: background 0.3s 0.3s;
    transition: background 0.3s 0.3s;
}

.cdn-panel.cdn-panel--is-visible {
    visibility: visible;
    -webkit-transition: visibility 0s 0s;
    transition: visibility 0s 0s;
}

.cdn-panel.cdn-panel--is-visible::after {
    background: rgba(0, 0, 0, 0.6);
    -webkit-transition: background 0.3s 0s;
    transition: background 0.3s 0s;
}

.cdn-panel__header {
    position: fixed;
    width: 90%;
    background: transparent;
    z-index: 2;
    -webkit-transition: -webkit-transform 0.3s 0s;
    transition: -webkit-transform 0.3s 0s;
    transition: transform 0.3s 0s;
    transition: transform 0.3s 0s, -webkit-transform 0.3s 0s;
    -webkit-transform: translateY(-50px);
    -ms-transform: translateY(-50px);
    transform: translateY(-50px);
}

.cdn-panel--from-right .cdn-panel__header {
    right: 0;
}

.cdn-panel--from-left .cdn-panel__header {
    left: 0;
}

.cdn-panel--is-visible .cdn-panel__header {
    -webkit-transition: -webkit-transform 0.3s 0.3s;
    transition: -webkit-transform 0.3s 0.3s;
    transition: transform 0.3s 0.3s;
    transition: transform 0.3s 0.3s, -webkit-transform 0.3s 0.3s;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
}

@media only screen and (min-width: 768px) {
    .cdn-panel__header {
        width: auto;
    }
}

@media only screen and (min-width: 1170px) {
    .cdn-panel__header {
        width: auto;
    }
}

.cdn-panel__close {
    position: absolute;
    top: 10px;
    right: 20px;
    height: 40px;
    width: 40px;
    display: inline-block;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    border-radius: 50%;
    background: white;
    box-shadow: 1px 1px 4px 2px #f8f7f7;
}

.cdn-panel__close::before,
.cdn-panel__close::after {
    content: '';
    position: absolute;
    top: 19px;
    left: 10px;
    height: 3px;
    width: 20px;
    background-color: #424f5c;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.cdn-panel__close::before {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.cdn-panel__close::after {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.cdn-panel__close:hover {
    background-color: #171930;
}

.cdn-panel__close:hover::before,
.cdn-panel__close:hover::after {
    background-color: #ffffff;
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
}

.cdn-panel__close:hover::before {
    -webkit-transform: rotate(220deg);
    -ms-transform: rotate(220deg);
    transform: rotate(220deg);
}

.cdn-panel__close:hover::after {
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
}

.cdn-panel--is-visible .cdn-panel__close::before {
    -webkit-animation: cdn-close-1 0.6s 0.3s;
    animation: cdn-close-1 0.6s 0.3s;
}

.cdn-panel--is-visible .cdn-panel__close::after {
    -webkit-animation: cdn-close-2 0.6s 0.3s;
    animation: cdn-close-2 0.6s 0.3s;
}

@-webkit-keyframes cdn-close-1 {

    0%,
    50% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}

@keyframes cdn-close-1 {

    0%,
    50% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}

@-webkit-keyframes cdn-close-2 {

    0%,
    50% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
}

@keyframes cdn-close-2 {

    0%,
    50% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
}

.cdn-panel__container {
    position: fixed;
    width: 90%;
    height: 100%;
    top: 0;
    background: #fff;
    z-index: 1;
    -webkit-transition: -webkit-transform 0.3s 0.3s;
    transition: -webkit-transform 0.3s 0.3s;
    transition: transform 0.3s 0.3s;
    transition: transform 0.3s 0.3s, -webkit-transform 0.3s 0.3s;
}

.cdn-panel--from-right .cdn-panel__container {
    right: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
}

.cdn-panel--from-left .cdn-panel__container {
    left: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
}

.cdn-panel--is-visible .cdn-panel__container {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

@media only screen and (min-width: 768px) {
    .cdn-panel__container {
        width: 70%;
    }
}

@media only screen and (min-width: 1170px) {
    .cdn-panel__container {
        width: 50%;
    }
}

.cdn-panel__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 25px 5%;
    overflow: auto;
    /* smooth scrolling on touch devices */
    -webkit-overflow-scrolling: touch;
}

.cdn-panel__content h2 {
    font-size: 22px !important;
    padding: 0 !important;
    margin-bottom: 15px !important;
}

.cdn-panel__content p {
    margin: 2em 0;
}

.cdn-panel__content p:first-of-type {
    margin-top: 0;
}

@media only screen and (min-width: 768px) {
    .cdn-panel__content p {
        font-size: 15px;
        margin: 20px 0;
    }
}

@media only screen and (max-width: 580px) {


    .more-info-test-resort {
        font-size: 14px !important;
        line-height: 1.4em !important;
    }

    .more-info-test-resort-btn {
        line-height: 1.3em;
        padding: 11px 34px !important;
    }

    .rich-pdn-header {
        padding: 0px 30px;
    }

    .cdn-main-content {
        width: 100%;
        top: 360px;
    }

    .cdn-panel {
        z-index: 10000000000;
    }
}

.cdn-panel__content ol {
    padding-left: 20px;
    margin-top: 12px;
}

.hidenews {
    position: absolute;
    top: 10px;
    right: 15px;
    z-index: 1000;
}

#overlay-button {
    position: relative;
    right: 0;
    top: 0;
    padding: 15px 0;
    z-index: 5;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    margin: 0;
}

#overlay-button span {
    height: 2px;
    width: 25px;
    border-radius: 0;
    background-color: #7e6e64;
    position: relative;
    display: block;
    transition: all .2s ease-in-out;
}

#overlay-button span:before {
    top: -10px;
    visibility: visible;
}

#overlay-button span:after {
    top: 10px;
}

#overlay-button span:before,
#overlay-button span:after {
    height: 2px;
    width: 25px;
    border-radius: 0;
    background-color: #7e6e64;
    position: absolute;
    content: "";
    transition: all .2s ease-in-out;
}

#overlay-button:hover span,
#overlay-button:hover span:before,
#overlay-button:hover span:after {
    background: #7e6e64;
}

#overlay-input[type=checkbox],
.overlay-dropdown[type=checkbox] {
    display: none;
}

#overlay-input[type=checkbox]:checked~#overlay-button:hover span,
#overlay-input[type=checkbox]:checked~#overlay-button span {
    background: transparent;
}

#overlay-input[type=checkbox]:checked~#overlay-button span:before {
    transform: rotate(45deg) translate(7px, 7px);
    opacity: 1;
}

#overlay-input[type=checkbox]:checked~#overlay-button span:after {
    transform: rotate(-45deg) translate(7px, -7px);
}

#overlay-input[type=checkbox]~#overlayMenu>ul {
    left: -100%;
    position: fixed;
}

#overlay-input[type=checkbox]:checked~#overlayMenu>ul {
    left: 0;
}

.overlay-dropdown[type=checkbox]:checked~ul.sub-menu-new {
    height: auto;
    overflow: auto;
}

.seperator:after {
    content: "";
    position: absolute;
    top: 3px;
    bottom: 2px;
    right: -10px;
    width: 1px;
    background-color: #E1E1E1;
}

.accordionTour {
    width: 100%;
}

.panelNew {
    background-color: #fff;
    border-bottom: 1px solid #ededed;
}

.panel__label {
    position: relative;
    display: block;
    width: 100%;
    background: none;
    border: none;
    text-align: left;
    padding: 25px 60px 25px 25px;
    font-weight: 500;
    font-size: 17px;
    font-family: inherit;
    -webkit-transition: color 0.2s linear;
    transition: color 0.2s linear;
    cursor: pointer;
}

.panel__label:focus {
    outline: none;
}

.panel__label:after,
.panel__label:before {
    content: '';
    position: absolute;
    right: 25px;
    top: 50%;
    width: 22px;
    height: 2px;
    background-color: #372717;
}

.panel__label:before {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transition: -webkit-transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
    transition: -webkit-transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
    transition: transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
    transition: transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1), -webkit-transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
}

.panelNew[aria-expanded='true'] .panel__content {
    opacity: 1;
}

.panelNew[aria-expanded='true'] .panel__label {
    color: #957029;
}

.panelNew[aria-expanded='true'] .panel__label svg {
    fill: #957029;
}

.panelNew[aria-expanded='true'] .panel__label:before {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}

.panel__inner {
    overflow: hidden;
    will-change: height;
    -webkit-transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
    transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
}

.panel__content {
    margin: 5px 25px 25px;
    opacity: 0;
    -webkit-transition: opacity 0.3s linear 0.18s;
    transition: opacity 0.3s linear 0.18s;
}

.panelNew:last-child {
    margin-bottom: 3px;
    border-bottom: none;
    ;
}

.Galgrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.gal-a {
    grid-column-start: 1;
    grid-column-end: 5;
}

.gal-b {
    grid-column-start: 1;
    grid-column-end: span 4;
}

.gal-c {
    grid-column-start: 1;
    grid-column-end: span 4;
}

.gal-d {
    grid-column-start: 1;
    grid-column-end: span 4;
}

.gal-e {
    grid-column-start: 1;
    grid-column-end: span 4;
}

.griditem:hover {
    transition: all 0.25s ease;
}

.griditem {
    transition: all 0.25s ease;
}

@media (min-width: 1011px) {
    .Galgrid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 143px 143px 143px 143px
    }

    .gal-0 {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 5;
    }

    .gal-1 {
        grid-column-start: 3;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 3;
    }

    .gal-2 {
        grid-column-start: 5;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 3;
    }

    .gal-3 {
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 3;
        grid-row-end: 5;
    }

    .gal-4 {
        grid-column-start: 5;
        grid-column-end: 4;
        grid-row-start: 3;
        grid-row-end: 5;
    }
}

.Maingrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.item-a {
    grid-column-start: 1;
    grid-column-end: 5;
}

.item-b {
    grid-column-start: 1;
    grid-column-end: span 4;
}

.item-c {
    grid-column-start: 1;
    grid-column-end: span 4;
}

.item-d {
    grid-column-start: 1;
    grid-column-end: span 4;
}

.item-e {
    grid-column-start: 1;
    grid-column-end: span 4;
}

.item-f {
    grid-column-start: 1;
    grid-column-end: span 4;
}

.item-g {
    grid-column-start: 1;
    grid-column-end: span 4;
}

.item-h {
    grid-column-start: 1;
    grid-column-end: span 4;
}

.item-i {
    grid-column-start: 1;
    grid-column-end: span 4;
}

.item-j {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 3;
}

.griditem:hover {
    transition: all 0.25s ease;
}

.griditem {
    transition: all 0.25s ease;
}

@media (min-width: 1011px) {
    .Maingrid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 143px 143px 143px 143px 143px 143px 143px 143px 143px 143px 143px 143px;
    }

    .item-0 {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 5;
    }

    .item-1 {
        grid-column-start: 3;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 3;
    }

    .item-2 {
        grid-column-start: 5;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 3;
    }

    .item-3 {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 5;
        grid-row-end: 7;
    }

    .item-4 {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 5;
        grid-row-end: 7;
    }

    .item-5 {
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 3;
        grid-row-end: 7;
    }

    .item-6 {
        grid-column-start: 4;
        grid-column-end: 5;
        grid-row-start: 3;
        grid-row-end: 5;
    }

    .item-7 {
        grid-column-start: 4;
        grid-column-end: 5;
        grid-row-start: 5;
        grid-row-end: 7;
    }

    .item-8 {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 7;
        grid-row-end: 9;
    }

    .item-9 {
        grid-column-start: 2;
        grid-column-end: 2;
        grid-row-start: 7;
        grid-row-end: 9;
    }

    .item-10 {
        grid-column-start: 3;
        grid-column-end: 5;
        grid-row-start: 7;
        grid-row-end: 11;
    }

    .item-11 {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 9;
        grid-row-end: 11;
    }

    .item-12 {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 11;
        grid-row-end: 13;
    }

    .item-13 {
        grid-column-start: 2;
        grid-column-end: 2;
        grid-row-start: 9;
        grid-row-end: 13;
    }

    .item-14 {
        grid-column-start: 3;
        grid-column-end: 3;
        grid-row-start: 11;
        grid-row-end: 13;
    }

    .item-15 {
        grid-column-start: 4;
        grid-column-end: 4;
        grid-row-start: 11;
        grid-row-end: 13;
    }
}

.viewport {
    margin: 0 0 0 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.our-partners-container {
    margin-top: -130px;
}

.bg-pattern-1 {
    padding: 50px 25px 110px;
    position: inherit;
    overflow: visible;
}


/*
.bg-pattern-1:after {
  content: '';
 background: url(../images/pattern/01-paper-texture.png)  left center / contain no-repeat;
 width: 75%;
 height: 100%;
 position: absolute;
 top: 0;
 left: 0;
}*/

.bg-pattern-1.left:after {
    left: 30%
}


/*
.overlaycrossImage:after {
  content: '';
  background: url(../images/pattern/14-paper-graphic.png)  center / cover no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 75%;
}*/

.destinationLayout h2 {
    position: absolute;
    bottom: -86px;
    left: 300px;
    z-index: 100000;
    width: 115%;
}

.destinationLayout h3 {
    color: rgb(198, 181, 167);
    font-size: 200px;
    position: absolute;
    top: -30px;
    left: -140px;
}

.verticalline {
    height: 45px;
    background: #c6b5a7;
    width: 3px;
    margin: 740px auto;
}

.blogslider:after {
    content: '';
    width: 78%;
    height: 85%;
    background: #f5f3ee;
    position: absolute;
    top: 13%;
    z-index: -1;
}

.oneslider:after {
    content: '';
    width: 78%;
    height: 85%;
    background: #f5f3ee;
    position: absolute;
    top: 5%;
    z-index: -1;
}

.twoslider:after {
    content: '';
    width: 110%;
    height: 110%;
    background: #f5f3ee;
    position: absolute;
    top: -10%;
    z-index: -1;
    left: -10%;
}

.owl-theme .owl-dots,
.owl-theme .owl-nav {
    text-align: center;
    -webkit-tap-highlight-color: transparent
}

.owl-theme .owl-nav {
    margin-top: 0px
}

.owl-theme .owl-nav [class*=owl-]:hover {
    background: #869791;
    color: #FFF;
    text-decoration: none
}

.owl-theme .owl-nav .disabled {
    opacity: .5;
    cursor: default
}

.owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 10px
}

.owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1
}

.owl-theme .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 5px 7px;
    background: #2b201a42;
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity .2s ease;
    border-radius: 30px
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background: #2B201A
}

#hotelslider a.left.carousel-control.main {
    left: 0;
}

#hotelslider a.right.carousel-control.main {
    right: 10px;
}

#hotelslider a.carousel-control.main svg {
    width: 50px;
}

.grayscale {
    filter: grayscale(1);
    -webkit-filter: grayscale(1);
    filter: grayscale(100%);
}


/*********Common*********/

.fnt-8 {
    font-size: 8px;
}

.fnt-9 {
    font-size: 9px;
}

.fnt-10 {
    font-size: 10px;
}

.fnt-10 {
    font-size: 10px;
}

.fnt-10i {
    font-size: 10px !important;
}

.fnt-12 {
    font-size: 12px;
}

.fnt-12i {
    font-size: 12px !important;
}

.fnt-13 {
    font-size: 13px;
}

.fnt-14 {
    font-size: 14px;
    font-family: 'Bembo Regular' !important;
}

.fnt-15 {
    font-size: 15px;
}

.fnt-16 {
    font-size: 16px;
}

.fnt-18 {
    font-size: 18px;
}

.fnt-20 {
    font-size: 20px;
}

.fnt-22 {
    font-size: 22px;
}

.fnt-22i {
    font-size: 22px !important;
}

.line-hght-16 {
    line-height: 16px
}

.line-hght-18 {
    line-height: 18px
}

.line-hght-20 {
    line-height: 20px
}

.line-hght-22 {
    line-height: 22px
}

.fnt-22 {
    font-size: 22px;
}

.fnt-24 {
    font-size: 24px;
}

.fnt-26 {
    font-size: 26px;
}

.fnt-30 {
    font-size: 30px;
}

.fnt-36 {
    font-size: 36px;
}

.fnt-40 {
    font-size: 40px;
}

.fnt-44 {
    font-size: 44px;
}

.fnt-50 {
    font-size: 50px;
}

.fnt-60 {
    font-size: 60px;
}

.fnt-70 {
    font-size: 70px;
}

.fnt-80 {
    font-size: 80px;
}

.fnt-100 {
    font-size: 120px;
}

.fnt-150 {
    font-size: 150px;
}

.let-space-p5 {
    letter-spacing: 0.5;
}

.fnt-h1vw {
    font-size: 3.5vw;
}

.fnt-h2vw {
    font-size: 3.1vw;
}

.fnt-h3vw {
    font-size: 2.6vw;
}

.fnt-h4vw {
    font-size: 2vw;
}

.fnt-h5vw {
    font-size: 1.2vw;
}

.fnt-h6vw {
    font-size: 0.9vw;
}

.lne-Thgt-cls {
    line-height: 0.8
}

.lne-Thgt-clsI {
    line-height: 0.8 !important
}

.lne-hgt-cls {
    line-height: 1
}

.fnt-wgt-lgt {
    font-family: "font-Baskerville", "Times New Roman", Times, Georgia, serif;
}

.fnt-wgt-thn {
    font-family: "Gotham Thin", Helvetica, Arial, sans-serif;
}

.fnt-wgt-nml {
    font-family: "Gotham Normal", Helvetica, Arial, sans-serif;
    color: #262626;
}

.fnt-wgt-bld {
    font-family: "Gotham Bold", Helvetica, Arial, sans-serif;
    color: #262626;
}

.fnt-wgt-300 {
    font-weight: 300;
}

.fnt-wgt-400 {
    font-weight: 400;
}

.fnt-wgt-700 {
    font-weight: 700;
}

.fnt-new-parathalic {
    font-family: 'Cormorant Garamond', serif;
}

.fnt-new-title {
    font-family: 'Laila', serif;
}

.fnt-new-handwritten {
    font-family: 'Bembo Regular' !important;
    font-size: 70px;
    letter-spacing: 0px;
    font-weight: 300;
}

.fnt-new-subtitle {
    font-family: 'Roboto Condensed', sans-serif;
}

.fnt-new-arial {
    font-family: 'Bembo Regular' !important;
    font-weight: 400;
}

.fnt-new-times {
    font-family: Times New Roman, Times, serif !important;
}

.fnt-wgt-bld-new {
    font-weight: bold
}

.no-marg {
    margin: 0;
}

.no-marg-i {
    margin: 0 !important;
}

.marg-tp-5 {
    margin-top: 5px
}

.marg-tp-10 {
    margin-top: 10px
}

.marg-tp-15 {
    margin-top: 15px
}

.marg-tp-20 {
    margin-top: 20px
}

.marg-tp-25 {
    margin-top: 25px
}

.marg-tp-30 {
    margin-top: 30px
}

.marg-tp-35 {
    margin-top: 35px
}

.marg-tp-5p {
    margin-top: 5%
}

.marg-tp-10p {
    margin-top: 10%
}

.marg-tp-15p {
    margin-top: 15%
}

.marg-tp-20p {
    margin-top: 20%
}

.marg-tp-40p {
    margin-top: 40%
}

.marg-btm-5 {
    margin-bottom: 5px
}

.marg-btm-10 {
    margin-bottom: 10px
}

.marg-btm-15 {
    margin-bottom: 15px
}

.marg-btm-20 {
    margin-bottom: 20px
}

.marg-btm-25 {
    margin-bottom: 25px
}

.marg-btm-30 {
    margin-bottom: 30px
}

.marg-btm-n-30 {
    margin-bottom: -30px
}

.marg-btm-35 {
    margin-bottom: 35px
}

.marg-n-left-100 {
    margin-left: -100px;
}

.marg-n-left-25 {
    margin-left: -25px;
}

.marg-n-top-100 {
    margin-top: -100px;
}

.padd-tp-5 {
    padding-top: 5px
}

.padd-tp-10 {
    padding-top: 10px
}

.padd-tp-15 {
    padding-top: 15px
}

.padd-tp-20 {
    padding-top: 20px
}

.padd-tp-25 {
    padding-top: 25px
}

.padd-tp-30 {
    padding-top: 30px
}

.padd-tp-35 {
    padding-top: 35px
}

.padd-tp-50 {
    padding-top: 50px
}

.padd-tp-60 {
    padding-top: 60px
}

.padd-btm-5 {
    padding-bottom: 5px
}

.padd-btm-15 {
    padding-bottom: 30px
}

.padd-btm-30 {
    padding-bottom: 30px
}

.padd-btm-50 {
    padding-bottom: 50px
}

.padd-btm-100 {
    padding-bottom: 100px
}

.marg-v-5 {
    margin-top: 5px;
    margin-bottom: 5px
}

.marg-v-10 {
    margin-top: 10px;
    margin-bottom: 10px
}

.marg-v-15 {
    margin-top: 15px;
    margin-bottom: 15px
}

.marg-v-20 {
    margin-top: 20px;
    margin-bottom: 20px
}

.marg-v-25 {
    margin-top: 25px;
    margin-bottom: 25px
}

.marg-v-30 {
    margin-top: 30px;
    margin-bottom: 30px
}

.marg-v-40 {
    margin-top: 40px;
    margin-bottom: 40px
}

.marg-v-50 {
    margin-top: 50px;
    margin-bottom: 50px
}

.marg-v-60 {
    margin-top: 60px;
    margin-bottom: 60px
}

.marg-h-5 {
    margin-left: 5px;
    margin-right: 5px
}

.marg-h-10 {
    margin-left: 10px;
    margin-right: 10px
}

.marg-h-15 {
    margin-left: 15px;
    margin-right: 15px
}

.marg-h-35 {
    margin-left: 35px;
    margin-right: 35px
}

.no-padd {
    padding: 0;
}

.no-padd-h {
    padding-right: 0;
    padding-left: 0;
}

.padd-5 {
    padding: 5px
}

.padd-10 {
    padding: 10px
}

.padd-15 {
    padding: 15px
}

.padd-20 {
    padding: 20px
}

.padd-25 {
    padding: 25px
}

.padd-30 {
    padding: 30px
}

.padd-35 {
    padding: 35px
}

.padd-40 {
    padding: 40px
}

.padd-60 {
    padding: 60px
}

.padd-100 {
    padding: 100px
}

.padd-v-5 {
    padding-top: 5px;
    padding-bottom: 5px
}

.padd-v-10 {
    padding-top: 10px;
    padding-bottom: 10px
}

.padd-v-15 {
    padding-top: 15px;
    padding-bottom: 15px
}

.padd-v-20 {
    padding-top: 20px;
    padding-bottom: 20px
}

.padd-v-25 {
    padding-top: 25px;
    padding-bottom: 25px
}

.padd-v-30 {
    padding-top: 30px;
    padding-bottom: 30px
}

.padd-v-40 {
    padding-top: 40px;
    padding-bottom: 40px
}

.padd-v-60 {
    padding-top: 60px;
    padding-bottom: 60px
}

.padd-h-5 {
    padding-left: 5px;
    padding-right: 5px
}

.padd-h-10 {
    padding-left: 10px;
    padding-right: 10px
}

.padd-h-15 {
    padding-left: 15px;
    padding-right: 15px
}

.padd-h-20 {
    padding-left: 20px;
    padding-right: 20px
}

.padd-h-25 {
    padding-left: 25px;
    padding-right: 25px
}

.padd-h-30 {
    padding-left: 30px;
    padding-right: 30px
}

.padd-h-40 {
    padding-left: 40px;
    padding-right: 40px
}

.padd-h-50 {
    padding-left: 50px;
    padding-right: 50px
}

.padd-h-60 {
    padding-left: 60px;
    padding-right: 60px
}

.padd-btm-60 {
    padding-bottom: 60px;
}

.padd-btm-80 {
    padding-bottom: 80px;
}

.top_0 {
    top: 0;
}

.top_50 {
    top: 50%;
    margin-top: -54px;
}

.bottom_0 {
    bottom: 0;
}

.left_0 {
    left: 0;
}

.right_0 {
    right: 0;
}

.w-40 {
    width: 40% !important;
}

.full-height {
    height: 100%
}

.full-height-i {
    height: 100% !important;
}

.full-width {
    width: 100%;
}

.full-width-i {
    width: 100% !important;
}

.auto-width {
    width: auto;
}

.dis-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.hide {
    display: none !important;
}

.dis-tbl {
    display: table
}

.dis-tbl-row {
    display: table-row
}

.dis-tbl-cell {
    display: table-cell
}

.dis-tbl-hdr-grp {
    display: table-header-group
}

.dis-tbl-ftr-grp {
    display: table-footer-group
}

.tbl-con-algn-top {
    vertical-align: top
}

.tbl-con-algn-center {
    vertical-align: middle
}

.tbl-con-algn-btm {
    vertical-align: bottom
}

.dis-in-blk {
    display: inline-block;
}

.dis-in-blkI {
    display: inline-block !important;
}

.dis-in {
    display: inline;
}

.bg-gold {
    background: #eccc68;
}

#secondary_bar {
    background-color: #fff !important;
    width: 100% !important
}

.text-gold {
    color: #acacac;
}

.text-gold-new {
    color: #daa910;
}

.text-maroon {
    color: #741539;
}

.text-white,
.text-white:hover,
.text-white:focus {
    color: #fff !important;
}

.bg-maroon {
    background-color: #741539;
}

.bg-VLgrey {
    background-color: #fafafa;
}

.bg-Lgrey {
    background-color: #f5f3ee;
}

.text-Lgrey {
    color: #f5f3ee
}

.bg-grey {
    background-color: #EFEEEE !important;
    color: #7F7773;
}

.bg-Dgrey {
    background-color: #2B201A !important;
}

.text-grey {
    color: #2B201A
}

.text-greyI {
    color: #c0c0c0 !important
}

.text-grey-new {
    color: #7F7773 !important
}

.bg-dark-blue {
    background: #171C35;
}

.bg-trans {
    background: transparent
}

.list-sty-none {
    list-style: none;
}

.over-hdden {
    overflow: hidden;
}

.pos-rela {
    position: relative;
}

.pos-relaI {
    position: relative !important;
}

.pos-stat {
    position: static;
}

.pos-abs {
    position: absolute;
}

.take-top1k {
    z-index: 9;
}

.take-topauto {
    z-index: auto;
}

.noborder {
    border: 0;
}

.bdr-radus-5 {
    border-radius: 3px;
}

.noborderRds {
    border-radius: 0 !important;
}

.bdr-1-grey {
    border: 1px solid #c7c7c7;
}

.bdr-rgt-1-grey {
    border-right: 1px solid #c7c7c7;
}

.bdr-lft-1-grey {
    border-left: 1px solid #c7c7c7;
}

.bdr-tp-1-grey {
    border-top: 1px solid #c7c7c7;
}

.bdr-tp-1-Lgrey {
    border-top: 1px solid #f5f5f5;
}

.bdr-btm-1-grey {
    border-bottom: 1px solid #c7c7c7;
}

.bdr-btm-1-white {
    border-bottom: 1px solid #fff;
}

.bdr-btm-1-Lgrey {
    border-bottom: 1px solid #efefef;
}

.offer-bdr {
    border-bottom: 2px dashed #daa910;
}

.float-n {
    float: none;
}

.cursor-point {
    cursor: pointer;
}

.cursor-unset {
    cursor: unset;
}

.ovr-hdn {
    overflow: hidden;
}

@media (max-device-width: 1200px) {
    .mega-menus .mega-menu-column {
        width: 100%;
    }
}

@media (max-width: 1024px) {
    #limited_title {
        font-size: 14px;
    }

    .ui-datepicker {
        width: 85%;
    }

    .ov-top.js-ov-whatsapp {
        display: block;
        visibility: visible;
    }

    .padd-v-30 {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .scrollbar,
    .force-overflow {
        height: auto;
        min-height: auto;
    }

    a.left.carousel-control.main,
    a.right.carousel-control.main {
        top: 43%;
    }

    .page-myform {
        padding: 0;
    }

    .page-myform>.container {
        padding: 70px;
    }

    .text-md-center {
        text-align: center !important;
    }

    #inquirypopupNew .centain,
    #inquirypopupNewfrance .centain,
    #inquirypopupNewarabic .centain,
    #bookwhenever .centain {
        width: 100%;
    }

    .ov-header {
        position: fixed;
    }

    .body {
        padding-top: 84px;
    }

    .navbar-default {
        padding: 0;
    }

    .nav>li>a {
        padding: 10px 10px;
    }

    .navbar-nav li.call-us .call-text span,
    .navbar-nav li.call-us .contact-num span {
        display: none;
    }

    .navbar-nav li.call-us .contact-num i,
    .navbar-nav li.call-us .call-text i {
        display: block !important;
        font-size: 16px;
    }

    .navbar-nav li.call-us>div>div:first-child,
    .navbar-nav li.call-us>div>div:nth-child(2) {
        display: inline-block;
    }

    a.contact-num {
        border: none;
    }

    img#logo {
        margin-top: 5px !important;
        padding: 0 15px
    }

    .col-sm-3 {
        width: 20%
    }

    .col-sm-9 {
        width: 80%
    }

    .pad-tb-70 {
        padding-top: 40px !important;
        padding-right: 0px !important;
        padding-bottom: 40px !important;
        padding-left: 0px !important;
    }

    img#logo {
        margin-left: 0px;
    }

    a.cool-link {
        width: 100px !important;
    }

    .hero-text.homepage {
        transform: translate(-51%, -110%) !important;
    }

    #home-banner .hero-text {
        font-size: 48px !important;
        line-height: 48px !important;
    }

    .new-ov-main-title {
        font-size: 40px !important;
        line-height: 40px !important;
    }

    .new-ov-sub-title {
        font-size: 40px !important;
        line-height: 40px !important;
    }

    .tf-cta-desc h3 {
        font-size: 14px;
        margin-left: 20px;
        margin-right: 20px;
        height: auto;
    }

    .whatwedo-home-slider-image {
        height: 450px;
    }

    #book-and-pay .col-md-12 {
        padding-top: 100px !important;
        padding-bottom: 100px !important;
        width: 55%;
        float: right;
    }

    .card-media {
        height: 250px;
    }

    img#contact-awards {
        height: 56px;
    }

    #home-offer img {
        height: 300px !important;
    }

    .effect-bubba .resort-title {
        top: 30px;
        padding-right: 60px;
        padding-left: 0px;
    }

    .effect-bubba p {
        top: 65px;
    }

    #news img {
        width: 100%;
    }

    /* #services .col{
         height: 400px;
     }*/
    #offer-body-sec-0 .cd-fixed-bg {
        min-height: 330px !important;
    }

    .show-dining-menu {
        top: 20px;
    }

    .bx-controls-direction.right {
        left: 0;
    }

    .bx-controls .bx-pager.bx-default-pager {
        min-width: 543px;
    }

    .show-dining-menu {
        left: 68.5%;
    }

    .bx-wrapper .bx-controls-direction a,
    .bx-wrapper .bx-controls-direction a,
    .bx-wrapper .bx-pager,
    .bx-wrapper .bx-controls-auto {
        width: 3%;
    }

    .bx-controls-direction {
        width: 100%;
    }

    .why-we-love-it .row,
    .why-we-love-it .col-md-8,
    .why-we-love-it .col-md-4 {
        display: block !important;
        width: auto !important;
    }

    .resort-page-overview {
        padding: 50px !important;
    }

    .bx-controls-direction.right {
        width: 100%;
    }

    .resort-main-title {
        font-size: 4em !important;
    }

    .holiday-action-buttons {
        margin: 30px;
    }

    .OUR-PARTNERS-container {
        width: 750px;
    }

    .effect-apollo img,
    .effect-apollo .bg-image {
        height: auto;
    }

    #whychoseov .col-md-4 {
        display: block;
    }

    .dis-tbl:not(.notblk),
    .dis-tbl-cell:not(.notblk) {
        display: block;
    }
}

@media (max-width: 768px) {

    #equalsize.pad-t-70,
    #intotextforresort.pad-tb-70 {
        padding-top: 40px !important;
    }

    #equalsize .pad-b-50,
    #intotextforresort .pad-b-50 {
        padding-bottom: 40px !important;
    }

    a.left.carousel-control.main,
    a.right.carousel-control.main {
        top: 30%;
    }

    .grid {
        width: 100% !important;
    }

    .pos-sm-rela {
        position: relative;
    }

    .padd-md-50 {
        padding: 50px 15px
    }

    .col-sm-3 {
        width: 15%
    }

    .col-sm-9 {
        width: 85%
    }

    .pad-tb-70 {
        padding-top: 50px !important;
        padding-right: 0px !important;
        padding-bottom: 50px !important;
        padding-left: 0px !important;
    }

    .pad-70 {
        padding: 30px !important;
    }

    .pad-tb-100 {
        padding-top: 70px !important;
        padding-right: 0px !important;
        padding-bottom: 70px !important;
        padding-left: 0px !important;
    }

    .pad-t-70 {
        padding-top: 30px !important;
    }

    .pad-b-70 {
        padding-bottom: 50 !important;
    }

    .navbar-collapse {
        padding-right: 0px;
        padding-left: 0px;
    }

    img#logo {
        margin-left: 0px;
        margin-top: 10px;
    }

    a.cool-link {
        width: 100% !important;
    }

    .hero-text.homepage {
        transform: translate(-51%, -109%) !important;
    }

    #home-banner .hero-text {
        font-size: 30px !important;
        line-height: 30px !important;
    }

    .new-ov-main-title {
        font-size: 30px !important;
        line-height: 30px !important;
    }

    .new-ov-sub-title {
        font-size: 30px !important;
        line-height: 30px !important;
    }

    .whatwedo-home-slider-image {
        height: 350px;
    }

    #book-and-pay .col-md-12 {
        padding-top: 70px !important;
        padding-bottom: 70px !important;
        width: 60%;
        float: right;
    }

    .card-media {
        height: 250px;
    }

    img#contact-awards {
        height: auto;
    }

    #home-offer img {
        height: 250px !important;
    }

    .effect-bubba .resort-title {
        top: 30px;
        padding-right: 60px;
        padding-left: 0px;
    }

    .effect-bubba p {
        top: 65px;
    }

    li.call-us {
        padding-left: 0px;
        padding-right: 0px;
    }

    #nav .col-md-8 {
        padding-right: 0px;
    }

    #news img {
        width: auto !important;
    }

    .package-listing .effect-apollo {
        height: 550px;
    }

    .show-dining-menu {
        top: 450px;
    }

    .dining-navi-menu {
        top: 450px;
    }

    .my-room-navi-items {
        padding: 26px 15px;
        width: 250px;
    }

    .btn-ov-cta {
        width: 190px;
    }

    .cta-action-box {
        margin: 20px;
    }

    #rooms-slider .item {
        display: block !important;
    }

    #rooms-slider .item .col-md-8,
    #rooms-slider .item .col-md-4 {
        display: block !important;
    }

    #dining-slider .item {
        display: block !important;
    }

    #dining-slider .item .col-md-8,
    #rooms-slider .item .col-md-4 {
        display: block !important;
        width: auto !important;
    }

    li.link-footer {
        margin: 15px;
    }

    .resort-page-overview {
        padding: 50px !important;
    }

    .bx-controls-direction {
        width: 100%;
    }

    #resort-page-strip-01 .col-md-8,
    #resort-page-strip-01 .col-md-4 {
        display: block !important;
    }

    .holiday-action-buttons {
        margin: 30px;
    }

    .resort-logo .item img {
        padding: 0px;
    }

    .OUR-PARTNERS-container {
        width: 650px;
    }

    .effect-apollo img,
    .effect-apollo .bg-image {
        height: auto;
    }

    .package-list-view-main-div,
    .package-list-view-main-div .col-md-4,
    .package-list-view-main-div .col-md-8 {
        display: block !important;
    }

    #whychoseov .col-md-4 {
        display: block;
    }

    .styled-select select {
        width: 100%;
    }

    .styled-select:after {
        top: -21px;
        left: 152px;
    }

    .body {
        padding-top: 100px;
    }

    .cookie-policy {
        padding-bottom: 10px;
    }

    #home-blog .title {
        top: 0px !important;
        left: 2px !important;
        color: #3f3f3f !important;
        padding: 40px 20px;
    }

    .styled-select:after {
        top: -25px;
        left: 120px;
    }

    #intotextforresort .col-md-4 {
        padding-bottom: 10px !important;
    }

    #intotextforresort .col-md-3 {
        padding-bottom: 10px;
    }

    #intotextforresort .col-md-3:last-child {
        padding-bottom: 0px;
    }

    #one-big-cta-footer .new-ov-main-title {
        font-size: 30px !important;
        line-height: 30px !important;
    }

    #one-big-cta-footer .new-ov-sub-title {
        font-size: 30px !important;
        line-height: 30px !important;
    }

    #awards img {
        width: 300px !important;
        max-width: 100% !important;
    }

    .awards-img {
        width: 300px !important;
        max-width: 100% !important;
    }

    .offer-btn {
        letter-spacing: 2px;
    }

    #offer-listing-section {
        text-align: center;
    }

    .offer-listing-desc-para {
        width: 100%;
        position: absolute;
        padding: 0px 40px;
        left: 0px;
    }

    .offer-listing-desc {
        text-align: center;
    }

    .view-resort {
        left: 40px;
    }

    .fancybox-slide--html .fancybox-content {
        margin-bottom: 0px;
        margin-top: 99px;
    }

    #calluspopup .centain {
        width: 100%;
    }

    #inquirypopup .centain {
        width: 100%;
    }

    .home-big-titles {
        font-size: 34px;
    }

    #offer-body-sec-0 .home-big-titles {
        font-size: 24px;
    }

    .page-myform {
        padding: 50px 10px;
    }

    .float-icon {
        display: none;
        bottom: 0;
        right: 10px;
        width: 100%;
        right: 0;
    }

    .door {
        margin: auto;
        top: 38%
    }

    .new-ov-main-title-home {
        font-size: 24px;
        line-height: 24px;
    }

    .new-ov-sub-title-home {
        font-size: 24px;
        line-height: 24px;
    }

    .col-xs-2 {
        width: 100%;
    }

    .pad-100 {
        padding-top: 72px !important;
        padding-right: 40px !important;
        padding-bottom: 40px !important;
        padding-left: 40px !important;
    }

    .effect-apollo .ovrly23 {
        margin: 0px !important;
    }

    #step-row h2,
    #step-row .new-ovss-sub-title,
    #step-row .new-ov-main-para,
    #step-row .col-md-4,
    #step-row #new-ovss-sub-title {
        text-align: center !important;
    }

    li.link-footer {
        margin: 5px !important;
    }

    footer ul {
        display: grid;
    }

    a.left.carousel-control.main {
        font-size: 32px;
    }

    a.right.carousel-control.main {
        font-size: 32px;
    }

    .footer-img-desktop {
        display: none;
    }

    .footer-img-mobile {
        display: block;
    }

    #intotextforresort .new-ovss-sub-title {
        text-align: center;
    }

    .effect-apollo:hover img,
    .effect-apollo:hover .bg-image,
    .effect-apollo:focus figcaption::before,
    .effect-apollo:active img,
    .effect-apollo:active .bg-image {
        opacity: 1;
    }

    #step-row div p {
        text-align: center;
    }

    .read-more-wrap-new {
        text-align: center;
    }

    #intro-ov p {
        text-align: center;
    }

    .dynamic-grid {
        -moz-column-count: 1;
        -moz-column-gap: 1%;
        -moz-column-width: 100%;
        -webkit-column-count: 3;
        -webkit-column-gap: 1%;
        -webkit-column-width: 100%;
        column-count: 1;
        column-gap: 1%;
        column-width: 100%;
    }

    .ov-lined-listx li {
        margin-right: 0px;
    }

    .resort-usp-wrapper {
        padding: 10px;
    }

    .resort-facilities-list-item {
        width: 24%;
    }

    #dining-container .col-md-12,
    #dining-container .col-md-8,
    #dining-container .col-md-4 {
        display: block !important;
    }

    #room-container .col-md-12,
    #room-container .col-md-8,
    #room-container .col-md-4 {
        display: block !important;
    }

    .bx-controls .bx-pager.bx-default-pager {
        min-width: 354px;
    }

    .my_room_name {
        margin-top: 20%;
        font-size: 16px;
    }

    .myoffer-details-ul {
        margin: 0px;
        padding: 0px;
    }

    .myoffer-details-ul li {
        display: block;
        padding-left: 15px;
        padding-right: 15px;
        color: #fff;
        list-style: none;
        text-align: left;
        line-height: 30px;
        margin: 0px;
        padding: 0px;
    }

    .bx-wrapper .bx-prev {
        left: 0;
    }

    .bx-wrapper .bx-controls-direction .bx-pager {
        left: 0;
        right: 0;
    }

    .specialofr_padd {
        padding-left: 15px;
        padding-right: 15px;
    }

    .width-70-new {
        padding: 60px 0px !important;
    }

    .mobile-hide {
        display: none !important;
    }

    .slide-pane.slide-pane_from_right {
        width: 95% !important;
    }

    .slide-pane .slide-pane__content {
        padding: 0px;
    }

    .slide-pane #inquire {
        padding: 0px;
    }
}

@media (max-width: 580px) {
    .oluhuveliRes {
        padding: 5px 14px;
        font-size: 14px;
        line-height: 13px;
    }

    .oluhuveliRes span {
        font-size: 9px;
        letter-spacing: 7px;
    }

    .cookie-policy {
        margin-top: 15px;
    }

    .cookie-policy__text,
    .cookie-policy__actions {
        float: none;
        max-width: initial;
        width: 100%;
    }

    .cookie-policy__text p:first-child {
        margin-top: 0;
    }

    .cookie-policy__actions {
        margin: auto;
        margin-top: 15px;
    }

    .cookie-policy__actions .cp-btn__link,
    .cookie-policy__actions .cp-btn__continue {
        display: inline-block;
        margin: 0 5px;
    }

    .cookie-policy__actions .cp-btn__link {
        padding-bottom: 0;
    }

    .cookie-policy__actions .cp-btn__link:after {
        bottom: -5px;
    }

    .pad-t-100 {
        padding-top: 40px !important;
    }

    .page-myform>.container {
        padding: 40px 15px;
    }

    #intro-ov,
    #intotextforresort {
        background-position: 150% !important;
        background-size: 150% !important;
        background-repeat: no-repeat !important;
        background-attachment: initial !important;
    }

    .moving-background-image:before {
        background-attachment: initial;
    }

    .fnt-h1vw {
        font-size: 30px;
    }

    p {
        padding: 0;
    }

    .home-banner .door {
        position: relative;
        top: 0;
        padding: 20px 15px 10px;
        color: #000;
    }

    a.right.carousel-control.main,
    a.left.carousel-control.main {
        top: 45%
    }

    .home-banner a.right.carousel-control.main,
    .home-banner a.left.carousel-control.main {
        top: 25%
    }

    .pad-tb-70,
    .pad-tb-100 {
        padding: 70px 15px !important;
    }

    .cta-action-box {
        margin: 20px;
    }

    .ov-texture-bg-new .home-big-titles {
        font-size: 34px;
        padding: 30px 15px 30px !important;
        line-height: 28px;
    }

    .footercapt .home-big-titles {
        font-size: 18px !important;
        line-height: 26px !important;
    }

    .ov-lined-listx li {
        margin-right: 0;
    }

    .resort-usp-wrapper {
        padding: 15px 0;
    }

    .fnt-40,
    .fnt-44 {
        font-size: 30px;
    }

    .width-70-new {
        width: 100% !important;
    }

    .width-70-new .home-para {
        font-size: 24px;
        padding: 16px;
        line-height: 1.2em;
    }

    .footer-mobile h2 {
        text-align: center;
    }

    .footer-mobile .col-md-12 {
        padding-bottom: 40px;
    }

    .new-font-style {

        font-size: 22px !important;
        text-align: center !important;
        line-height: 28px !important;
    }

    .new-font-style.heading-new.text-center.rich-pading-b.splt {
        font-size: 26px !important;
        padding: 0px 20px;
    }


}

@media (max-width: 420px) {
    #intotextforresort .new-ovss-sub-title {
        text-align: center;
    }

    .call-us-popup {
        width: 100%;
    }

    #offer-list .list-offer-item,
    #offer-list .list-offer-item .full-height {
        height: auto !important;
    }

    #hotelMobslider .owl-nav {
        position: absolute;
        top: 42%;
        width: 100%;
    }

    .owl-prev {
        background-color: #fff !important;
        height: 40px;
        padding-top: 6px;
    }

    .owl-prev,
    .owl-next {
        width: 10% !important;
    }

    #hotelMobslider .sr-only {
        display: none !important;

    }

    .owl-next {
        float: right !important;
        background-color: #fff !important;
        height: 40px;
        padding-top: 6px;
    }

    .pos-rela.take-top1k .react-multi-carousel-track {
        margin-top: 30px !important;
    }

    .width-70 {
        padding: 30% 0px !important;
    }
}


/*formmobile*/

@media (max-width: 375px) {
    .pad-70 {
        padding: 30px !important;
    }

    .pad-t-70 {
        padding-top: 30px !important;
    }

    #home-banner .hero-text {
        font-size: 30px !important;
        line-height: 30px !important;
    }

    .new-ov-sub-title {
        font-size: 30px !important;
        line-height: 30px !important;
    }

    .hero-text.homepage {
        transform: translate(-51%, -105%) !important;
    }

    .new-ov-main-para {
        font-size: 30px !important;
        line-height: 30px !important;
    }

    .new-ov-sub-para {
        font-size: 30px !important;
        line-height: 30px !important;
    }

    .new-ov-main-title {
        font-size: 30px !important;
        line-height: 30px !important;
    }

    .new-ov-sub-title {
        font-size: 30px !important;
        line-height: 30px !important;
    }

    .carousel-inner>.item {
        height: auto !important;
    }

    #awards img {
        width: 300px !important;
        max-width: 100% !important;
    }

    .awards-img {
        width: 300px !important;
        max-width: 100% !important;
    }

    #awards .pad-70 {
        padding: 0px !important;
    }

    .offer-btn {
        letter-spacing: 2px !important;
        padding-left: 5px !important;
        padding-right: 5px !important;
    }

    .body {
        padding-top: 100px;
    }

    form#frmContract {
        padding: 0px !important;
    }

    .bx-controls .bx-pager.bx-default-pager {
        min-width: 100% !important;
    }

    .OUR-PARTNERS-container {
        width: 50%;
    }

    #news img {
        width: 100% !important;
        height: auto;
    }

    .playBut {
        right: 36.5% !important;
    }

    #home-blog .title {
        top: 0px !important;
        left: 2px !important;
        color: #3f3f3f !important;
        padding: 40px 20px;
    }

    .new-ov-main-title-home {
        font-size: 24px;
        line-height: 24px;
    }

    .new-ov-sub-title-home {
        font-size: 24px;
        line-height: 24px;
    }

    .styled-select:after {
        top: -25px;
        left: 120px;
    }

    #intotextforresort .col-md-4 {
        padding-bottom: 50px;
    }

    #one-big-cta-footer .new-ov-main-title {
        font-size: 30px !important;
        line-height: 30px !important;
    }

    #one-big-cta-footer .new-ov-sub-title {
        font-size: 30px !important;
        line-height: 30px !important;
    }

    .offer-listing-desc-para {
        width: 100%;
        position: absolute;
        padding: 0px 10px;
    }

    .offer-listing-desc {
        text-align: center;
    }

    #calluspopup .centain {
        width: 100%;
    }

    #inquirypopup .centain {
        width: 100%;
    }

    .page-myform {
        padding: 50px 10px;
    }

    .col-xs-2 {
        width: 100%;
    }

    .pad-100 {
        padding-top: 40px !important;
        padding-right: 40px !important;
        padding-bottom: 40px !important;
        padding-left: 40px !important;
    }

    .footer-img-desktop {
        display: none;
    }

    .footer-img-mobile {
        display: block;
    }

    .effect-apollo:hover img,
    .effect-apollo:hover .bg-image,
    .effect-apollo:focus figcaption::before,
    .effect-apollo:active img,
    .effect-apollo:active .bg-image {
        opacity: 1;
        -webkit-transform: scale3d(0, 0, 0);
        transform: scale3d(1.2, 1.2, 1.2);
    }

    .call-us-popup {
        width: 100%;
    }
}

#offer-list .list-offer-item {
    height: 620px;
}

.button-b7zqi {
    -moz-user-select: none;
    -ms-flex-align: center;
    -ms-flex-line-pack: center;
    -ms-flex-pack: center;
    -ms-touch-action: manipulation;
    -ms-user-select: none;
    -webkit-align-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -webkit-user-select: none;
    align-content: center;
    align-items: center;
    background-image: none;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 0;
    cursor: pointer;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: inherit;
    font-weight: bold;
    justify-content: center;
    line-height: 1;
    margin-bottom: 5px;
    padding: 10px 0px;
    text-align: center;
    touch-action: manipulation;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    color: white;
}

.third-Shfgs.with-gradient-191wD {
    background-image: -webkit-gradient(linear, right top, left top, color-stop(3%, #66c8ce), to(#00a3ad));
    background-image: -o-linear-gradient(right, #66c8ce 3%, #00a3ad 100%);
    background-image: linear-gradient(-90deg, #66c8ce 3%, #00a3ad);
}

.iWFSXd {
    margin-top: 0.625rem;
    padding: 0.9375rem 0.9375rem 0px 1.25rem;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(214, 217, 218);
    border-image: initial;
}

.jTDrSH {
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 10px;
}

.iWFSXd li {
    margin-top: 0.625rem;
}

.gopSIr li {
    text-indent: -0.25rem;
}

.sc-dznXNo.iBiSoY {
    display: inline-grid;
    justify-content: space-between;
    align-items: center;
    color: #333;
    width: 100%;
    cursor: pointer;
}

ul.iUhYpB {
    margin-left: 15px;
    padding: 10px;
}

.flash-package .card {
    margin: 0 auto;
    display: inline-block;
    margin-right: 0px;
    /*   -webkit-transform: scale(0);
    transform: scale(0); */
    text-align: center;
    position: relative;
    transition: all .2s;
    cursor: pointer;
    border-radius: 14px;
}

.flash-package .card_inner {
    margin: 0;
    box-shadow: 0px 17px 46px -10px #777777;
    border-radius: 14px;
    background: white;
}

.flash-package .card {
    background-color: transparent !important;
}

.ht-header-details {
    position: absolute;
    display: block;
    top: 22px;
    margin: 10px;
    text-align: left;
}

.ht-nights {
    color: white;
    font-size: 12px;
    font-weight: bold;
}

.ht-title {
    color: white;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
}

.ht-resort {
    color: white;
    font-size: 12px;
    font-weight: bold;
}

.ht-price {
    color: white;
    font-size: 26px;
    font-weight: bold;
    margin-top: 10px;
}

.ht-price-alt {
    color: white !important;
    font-size: 12px;
    font-weight: bold;
}

#package_item p {
    line-height: 16px;
}

.ht-bg-img {
    position: relative;
    width: 100%;
    height: 100%;
    background-size: cover;
}

.ht-bg-img:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to bottom right, #2B201A, #171930);
    opacity: .4;
}

.owl-carousel-deal-package-list .owl-prev:hover,
.owl-carousel-deal-package-list .owl-prev:active,
.owl-carousel-deal-package-list .owl-prev:focus,
.owl-carousel-deal-package-list .owl-next:hover,
.owl-carousel-deal-package-list .owl-next:active,
.owl-carousel-deal-package-list .owl-next:focus {
    background: transparent;
}

.owl-carousel-deal-package-list .owl-next,
.owl-carousel-deal-package-list .owl-prev {
    position: absolute;
    top: 50%;
    z-index: 5;
    display: inline-block;
    margin-top: -10px;
    font-size: 50px;
    border: none;
}

.owl-carousel-deal-package-list .owl-next {
    right: 0
}

.owl-carousel-deal-package-list .owl-prev {
    left: 0
}

.flash-package .card:hover .card_inner__header img {
    left: -50px;
    transition: all 3.4s linear;
}

.flash-package .card:hover .card_inner__cta button {
    -webkit-transform: scale(1);
    transform: scale(1);
}

.flash-package .card:nth-of-type(1):hover .card_inner__circle img {
    -webkit-animation: launch 1s forwards;
    animation: launch 1s forwards;
}

.flash-package .card:nth-of-type(1) .card_inner__circle img {
    top: 22px;
    left: 1px;
}

.flash-package .card:nth-of-type(2):hover .card_inner__circle img {
    -webkit-animation: spin 1s forwards;
    animation: spin 1s forwards;
}

.flash-package .card:nth-of-type(2) .card_inner__circle img {
    top: 22px;
}

.flash-package .card:nth-of-type(3):hover .card_inner__circle img {
    -webkit-animation: fly 1s forwards;
    animation: fly 1s forwards;
}

.flash-package .card:nth-of-type(3) .card_inner__circle img {
    top: 22px;
    left: 1px;
}

.flash-package .card:hover {
    opacity: 1;
}

.flash-package .card_inner__circle {
    overflow: hidden;
    width: 70px;
    position: absolute;
    background: #F1F0ED;
    z-index: 10;
    height: 70px;
    border-radius: 100px;
    left: 0;
    box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.28);
    right: 0;
    margin: auto;
    border: 4px solid white;
    top: 82px;
}

.flash-package .card_inner__circle img {
    height: 26px;
    position: relative;
    top: 17px;
    transition: all .2s;
}

.flash-package .card_inner__header {
    height: 200px;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    overflow: hidden;
}

.flash-package .card_inner__header img {
    width: 120%;
    position: relative;
    top: -30px;
    left: 0;
    transition: all 0.1s linear;
}

.flash-package .card_inner__content {
    padding: 20px;
}

.flash-package .card_inner__content .price {
    /* color: white; */
    font-weight: 800;
    font-size: 30px;
    /* text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.42); */
}

.flash-package .card_inner__content .text {
    /* color: rgba(255, 255, 255, 0.6); */
    font-weight: 100;
    margin-top: 20px;
    font-size: 13px;
    line-height: 16px;
}

.flash-package .card_inner__content .title {
    font-weight: bold;
    text-transform: uppercase;
    /* color: rgba(255, 255, 255, 0.64); */
    margin-top: 10px;
    font-size: 16px;
    text-align: left;
    letter-spacing: 1px;
}

.flash-package .card_inner__cta {
    position: absolute;
    bottom: -24px;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
}

@-webkit-keyframes launch {
    0% {
        left: 1px;
    }

    25% {
        top: -50px;
        left: 1px;
    }

    50% {
        left: -100px;
    }

    75% {
        top: 100px;
        -webkit-transform: rotate(40deg);
        transform: rotate(40deg);
    }

    100% {
        left: 1px;
    }
}

@keyframes launch {
    0% {
        left: 1px;
    }

    25% {
        top: -50px;
        left: 1px;
    }

    50% {
        left: -100px;
    }

    75% {
        top: 100px;
        -webkit-transform: rotate(40deg);
        transform: rotate(40deg);
    }

    100% {
        left: 1px;
    }
}

@-webkit-keyframes fly {
    0% {
        left: 0px;
    }

    25% {
        top: -50px;
        left: 50px;
    }

    50% {
        left: -130px;
    }

    75% {
        top: 60px;
    }

    100% {
        left: 0px;
    }
}

@keyframes fly {
    0% {
        left: 0px;
    }

    25% {
        top: -50px;
        left: 50px;
    }

    50% {
        left: -130px;
    }

    75% {
        top: 60px;
    }

    100% {
        left: 0px;
    }
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(720deg);
        transform: rotate(720deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(720deg);
        transform: rotate(720deg);
    }
}

@-webkit-keyframes intro {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    25% {
        -webkit-transform: scale(1.06);
        transform: scale(1.06);
    }

    50% {
        -webkit-transform: scale(0.965);
        transform: scale(0.965);
    }

    75% {
        -webkit-transform: scale(1.02);
        transform: scale(1.02);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes intro {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    25% {
        -webkit-transform: scale(1.06);
        transform: scale(1.06);
    }

    50% {
        -webkit-transform: scale(0.965);
        transform: scale(0.965);
    }

    75% {
        -webkit-transform: scale(1.02);
        transform: scale(1.02);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.animate,
.as-accordion-close {
    transition: -webkit-transform .2s ease-out;
    transition: transform .2s ease-out;
    transition: transform .2s ease-out, -webkit-transform .2s ease-out;
}

.accordion {}

.accordion:nth-last-child(1) {
    border-bottom: 1px solid #cdcdcd;
}

.as-accordion-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333;
    width: 100%;
    padding: 30px 0;
    cursor: pointer;
}

.as-accordion-head:hover {
    color: #c78939;
}

.as-accordion-title {
    font-size: 20px;
    line-height: 0.5;
    letter-spacing: 0;
    font-weight: bold;
}

.as-accordion-close {
    font-size: 50px;
    font-weight: 100;
    -webkit-transform: translateY(0px) rotate(45deg) scale(0.8);
    transform: translateY(0px) rotate(45deg) scale(0.8);
    line-height: 0;
}

.as-accordion-close.open {
    -webkit-transform: translateX(-3px) translateY(-2px) rotate(0deg) scale(1);
    transform: translateX(-3px) translateY(-2px) rotate(0deg) scale(1);
}

.as-accordion-collapse {
    display: none;
    padding-bottom: 40px;
}

.formwrap {
    border-bottom: 1px solid #50849b;
    -moz-transition: -moz-transform 1s ease-out;
    -o-transition: -o-transform 1s ease-out;
    -webkit-transition: -webkit-transform 1s ease-out;
    transition: transform 1s ease-out;
}

.formwrap .field {
    position: relative;
    padding-bottom: 25px;
}

#deal-package-page main {
    padding-top: 40px;
}

#deal-package-page .owl-carousel {
    position: relative;
}

#deal-package-page .owl-prev,
#deal-package-page .owl-next {
    position: absolute;
    top: calc(50% - 20px);
    background: transparent;
    color: yellow;
    border: 1px solid yellow;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    transition: all 400ms ease-in-out;
}

#deal-package-page .owl-prev:hover,
#deal-package-page .owl-prev:active,
#deal-package-page .owl-prev:focus,
#deal-package-page .owl-next:hover,
#deal-package-page .owl-next:active,
#deal-package-page .owl-next:focus {
    background: rgba(255, 255, 0, 0.95);
    color: #000;
    outline: none;
}

#deal-package-page.owl-prev .fa,
#deal-package-page .owl-next .fa {
    font-size: 50px;
    position: relative;
}

#deal-package-page .owl-prev {
    left: 30px;
}

#deal-package-page .owl-prev .fa {
    right: 2px;
}

#deal-package-page .owl-next {
    right: 30px;
}

#deal-package-page .owl-next .fa {
    left: 2px;
}

.card .card_inner__content_list p {
    font-size: 12px;
}

.card_inner__content_list p {
    font-weight: bold;
}

#share-buttons img {
    width: 35px;
    padding: 5px;
    border: 0;
    box-shadow: 0;
    display: inline;
}

#blog-archive-destination a.right.carousel-control.main {
    top: 40%;
    margin-right: -50px;
}

#blog-archive-destination a.left.carousel-control.main {
    top: 40%;
    margin-left: -50px;
}

.offerpackages {
    line-height: 20px;
    font-family: 'Gotham Bold', sans-serif, sans;
}

.offerpackages span {
    font-family: 'Gotham Bold', sans-serif, sans;
    letter-spacing: 3px;
}

#sidebar.fixed {
    position: fixed;
    top: 0;
}

.pbContent {
    padding-bottom: 30px;
}

.padd-lft-60 {
    padding-left: 60px;
}

.padd-rgt-60 {
    padding-right: 60px;
}

.home-big-titles,
#one-big-cta-footer .new-ov-main-title,
#one-big-cta-footer .new-ov-sub-title {
    font-family: "font-Baskerville", "Times New Roman", Times, Georgia, serif !important;
    text-transform: uppercase;
    margin-top: 18px;
    color: #262626;
    line-height: 1.3em;
    letter-spacing: 0.1em;
    font-size: 36px !important;
    text-rendering: optimizeLegibility;
    line-height: 40px !important;
    font-style: normal;
    font-weight: normal;
}

.fnt-30i {
    font-size: 30px !important;
}

#desktop-nav {
    top: 35%;
    position: fixed;
    right: 0;
    text-align: right;
    z-index: 10000;
}

#desktop-nav ul {
    list-style: none;
}

#desktop-nav ul li {
    padding: 0 20px;
    font-size: 10px;
    background: white;
    margin: 3px 0;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    text-transform: uppercase;
    animation: slidein 0.3s ease 1 both;
    background: #2B201A;
}

#desktop-nav ul li:hover {
    background: #171930;
}

#desktop-nav ul li a {
    color: #fff
}

#desktop-nav ul li:nth-child(2) {
    animation-delay: 0.15s
}

#desktop-nav ul li:nth-child(3) {
    animation-delay: 0.30s
}

#desktop-nav ul li:nth-child(4) {
    animation-delay: 0.45s
}

#desktop-nav ul li:nth-child(5) {
    animation-delay: 0.60s
}

#desktop-nav ul li:nth-child(6) {
    animation-delay: 0.75s
}

#desktop-nav ul li:nth-child(7) {
    animation-delay: 0.85s
}

#desktop-nav ul li:nth-child(8) {
    animation-delay: 0.95s
}

#desktop-nav ul li:nth-child(9) {
    animation-delay: 1s
}

#desktop-nav ul li:nth-child(10) {
    animation-delay: 1.10s
}

@keyframes slidein {
    from {
        transform: translateX(-5rem);
        opacity: 0;
    }

    to {
        transform: translate(0);
        opacity: 1;
    }
}

#desktop-nav ul li:after {
    content: '';
    width: 5px;
    height: 5px;
    background: #fff;
    position: relative;
    display: inline-block;
    top: -1px;
    right: -10px;
    border-radius: 50%;
}

.page-scroll {
    color: #2B201A;
}

.page-scroll:hover {
    color: #171930;
}

#desktop-nav ul li.active:after {
    background: #171930;
}

#desktop-nav ul li.active a {
    font-family: 'Gotham Bold', sans-serif, sans;
    font-size: 11px;
}

.footercapt {
    position: absolute;
    height: 100%
}

.footercapt h3 {
    color: #fff;
    background: rgba(43, 32, 26, 0.3);
}

#one-big-cta-footer .new-ov-main-title:after {
    content: '';
    width: 510px;
    height: 1px;
    background: radial-gradient(#b3b3b3, transparent);
    display: block;
    margin: 50px auto;
}

.btn-ov i {
    padding-right: 10px;
}

.default-spacing {
    line-height: 1.3em;
    letter-spacing: 0.1em;
}

.gray-bg-sections,
.border-box,
.bg-grey {
    background-color: #faf5f1 !important;
}

.page-myform {
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.05);
    background-color: #faf5f1 !important;
}

blockquote {
    font-family: 'La Belle Aurore', cursive;
    text-transform: initial;
    font-size: 22px;
    letter-spacing: 2.5px;
    padding: 0 40px;
    color: #000;
    padding-bottom: 30px;
    margin-top: 10px;
}

.line-hght-26 {
    line-height: 26px;
}

.line-hght-34 {
    line-height: 34px;
}

.blog-section #one-big-cta-footer .container {
    width: 100%;
}

.blog-section #one-big-cta-footer .container .cta-action-box {
    margin: 10px 20px;
}

.blog-section #one-big-cta-footer .new-ov-main-title,
.blog-section #one-big-cta-footer .new-ov-main-title .new-ov-sub-title {
    font-size: 20px !important;
    line-height: 30px !important;
    letter-spacing: 0;
    text-transform: initial;
    line-height: 24px !important;
}

.blog-section #one-big-cta-footer .new-ov-main-title .new-ov-sub-title {
    text-transform: uppercase;
}

.blog-section #one-big-cta-footer {
    margin: 0;
}

#sync2 .item {
    margin: 5px;
    cursor: pointer;
}

#sync2 .item h1 {
    font-size: 18px;
}

.owl-theme .owl-nav [class*='owl-'] {
    transition: all .3s ease;
}

.owl-theme .owl-nav [class*='owl-'].disabled:hover {
    background-color: #D6D6D6;
}

#sync1.owl-theme {
    position: relative;
}

#sync1.owl-theme .owl-item.active .item {
    opacity: 1;
}

#sync1.owl-theme .owl-next,
#sync1.owl-theme .owl-prev {
    width: 22px;
    height: 22px;
    position: absolute;
    top: 50%;
    transform: translateY(-125%)
}

#sync2 a.right.carousel-control.main {
    right: 0px;
}

#sync2 a.left.carousel-control.main {
    left: 0px;
}

#sync2 a.left.carousel-control.main .fa-chevron-left:before,
#sync2 a.right.carousel-control.main .fa-chevron-right:before {
    color: #000 !important;
}

#sync1.owl-theme .owl-prev {
    left: -60px;
}

#sync1.owl-theme .owl-next {
    right: -60px;
}

#sync1 a.left.carousel-control.main .fa-chevron-left:before,
#sync1 a.right.carousel-control.main .fa-chevron-right:before,
#deal-package-page .owl-prev i .fa-chevron-left:before,
a.left.carousel-control.main .fa-chevron-left:before {
    color: #fff !important;
}

#sync1.owl-theme .owl-nav [class*=owl-]:hover {
    background: transparent;
    outline: none;
}

#sync2 .owl-item.current .item .card {
    background: #f0f0f0;
}

.special-padd {
    padding-bottom: 23px;
}

#sync2 .owl-item.current .ovrly26 .ovrly {
    -webkit-transform: scale(3);
    -moz-transform: scale(3);
    -ms-transform: scale(3);
    -o-transform: scale(3);
    transform: scale(3);
}

.withafter:after {
    content: '';
    background: #dadada;
    position: absolute;
    width: 500%;
    height: 100%;
    top: 0;
    right: 0;
}

#owl-carousel-deal-package a.left.carousel-control.main,
.owl-carousel-deal-package-list a.left.carousel-control.main,
#mainslider a.left.carousel-control.main,
.common-slider a.left.carousel-control.main,
.owl-carousel-staff a.left.carousel-control.main,
#wineanddinesec_new a.left.carousel-control.main,
#sync1 a.left.carousel-control.main {
    left: -70px;
    line-height: 18px;
    padding: 0px;
    border: 1px solid #2b201a !important;
}



#owl-carousel-deal-package a.left.carousel-control.main:hover,
#owl-carousel-deal-package a.right.carousel-control.main:hover,
.owl-carousel-deal-package-list a.left.carousel-control.main:hover,
.owl-carousel-deal-package-list a.right.carousel-control.main:hover,
#mainslider a.left.carousel-control.main:hover,
.common-slider a.left.carousel-control.main:hover,
.owl-carousel-staff a.left.carousel-control.main:hover,
#wineanddinesec_new a.left.carousel-control.main:hover,
#sync1 a.left.carousel-control.main:hover,
.common-slider a.right.carousel-control.main:hover,
.owl-carousel-staff a.right.carousel-control.main:hover,
#wineanddinesec_new a.right.carousel-control.main:hover,
#mainslider a.right.carousel-control.main:hover,
#sync1 a.right.carousel-control.main:hover {}

#owl-carousel-deal-package a.left.carousel-control.main:hover svg,
#owl-carousel-deal-package a.right.carousel-control.main:hover svg,
.owl-carousel-deal-package-list a.left.carousel-control.main:hover svg,
.owl-carousel-deal-package-list a.right.carousel-control.main:hover svg,
#mainslider a.left.carousel-control.main:hover svg,
.common-slider a.left.carousel-control.main:hover svg,
.owl-carousel-staff a.left.carousel-control.main:hover svg,
#wineanddinesec_new a.left.carousel-control.main:hover svg,
#sync1 a.left.carousel-control.main:hover svg,
.common-slider a.right.carousel-control.main:hover svg,
.owl-carousel-staff a.right.carousel-control.main:hover svg,
#wineanddinesec_new a.right.carousel-control.main:hover svg,
#mainslider a.right.carousel-control.main:hover svg,
#sync1 a.right.carousel-control.main:hover svg {
    fill: #fff
}

#owl-carousel-deal-package a.right.carousel-control.main,
.owl-carousel-deal-package-list a.right.carousel-control.main,
#mainslider a.right.carousel-control.main,
.owl-carousel-staff a.right.carousel-control.main,
.owl-carousel-staff a.right.carousel-control.main,
.common-slider a.right.carousel-control.main,
#wineanddinesec_new a.right.carousel-control.main,
#sync1 a.right.carousel-control.main {
    right: -70px;
    border: 1px solid #2B201A !important;
    line-height: 18px;
    padding: 0px;
}

.common-slider .owl-dots,
.owl-carousel-staff .owl-dots,
#mainslider .owl-dots {
    position: absolute;
    bottom: 0;
    width: 100%
}

.common-slider .owl-nav,
.common-slider .owl-nav button,
.owl-carousel-staff .owl-nav,
.owl-carousel-staff .owl-nav button,
#mainslider .owl-nav,
#mainslider .owl-nav button,
#owl-carousel-deal-package .owl-nav,
#owl-carousel-deal-package .owl-nav button,
.owl-carousel-deal-package-list .owl-nav,
.owl-carousel-deal-package-list .owl-nav button {
    margin: 0 !important;
}

#sync1.owl-theme .owl-item.active:before {
    background: transparent;
    transition: all .3s ease;
}

#mainslider.oneslider .owl-dots {
    bottom: 80px;
}

#mainslider.fullscreen a.right.carousel-control.main {
    right: 30px;
    border: 1px solid #ffffff !important;
}

#mainslider.fullscreen a.left.carousel-control.main {
    left: 30px;
    border: 1px solid #ffffff !important;
}

#mainslider.fullscreen a.carousel-control.main svg {
    fill: #fff;
}

.remodified .tf-cta-text {
    height: 0;
    border: none;
    font-size: 13px;
    line-height: 18px;
}

.remodified .tf-cta-text:after {
    background: transparent;
}

.remodified .ovrly23:hover .tf-cta-desc .tf-cta-text {
    height: auto;
}

.border-box {
    background-color: #EFEEEE;
    padding: 20px 20px 0px 20px;
    margin-bottom: 20px;
    text-align: left;
}

.speci-font .custom-option {
    font-size: 12px;
}

.panelcustm {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.1s ease;
}

.accordion.active+.panelcustm {
    max-height: 100%;
}

#wineanddinesec_new .owl-nav button {
    position: absolute;
    top: 0;
    height: 100%;
}

#wineanddinesec_new .owl-nav button a {
    position: relative;
}

#wineanddinesec_new .owl-nav button.owl-prev,
.owl-carousel-deal-package-list .owl-nav button.owl-prev,
#sync1.owl-theme .owl-prev {
    left: -30px;
}

#wineanddinesec_new .owl-nav button.owl-next,
.owl-carousel-deal-package-list .owl-nav button.owl-next,
#sync1.owl-theme .owl-next {
    right: -30px;
}

.common-slider.outernav .owl-prev a {
    left: -30px !important;
}

.common-slider.outernav .owl-next a {
    right: -30px !important;
}

#wineanddinesec_new .owl-nav button.owl-next:hover,
#wineanddinesec_new .owl-nav button.owl-prev:hover {
    background: transparent;
    outline: none !important;
}

.tf-cta-desc.newLayout h3 {
    margin-left: 0;
    margin-right: 0;
    height: auto;
}

.ovrly23:hover .tf-cta-desc.newLayout {
    bottom: 10px;
}

/* .MasonrySec .Masonry-item:nth-child(2){
    margin-top: 30px;
} */

.modal-dialog {
    max-width: 900px;
}

@media (max-width: 1600px) {
    #botrowing .pad-t-100 {
        padding-top: 50px !important;
    }

    .titleTag.large {
        font-size: 3rem;
        line-height: 3rem;
    }


}

@media (min-width: 1200px) {
    .container-special {
        width: 1200px;
    }
}

@media (max-width: 1200px) {
    #botrowing .pad-t-100 {
        padding-top: 10px !important;
    }
}

@media (max-width: 1300px) and (min-width: 991px) {
    .col-lg-11 {
        -ms-flex: 0 0 91.666667% !important;
        flex: 0 0 91.666667% !important;
        max-width: 91.666667% !important;
    }

    .col-lg-8 .col-lg-6 {
        -ms-flex: 0 0 70%;
        flex: 0 0 70%;
        max-width: 70%;
    }

    #iconicstay {
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
        margin: auto;
    }

}

@media (max-width: 1300px) and (min-width: 1200px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1060px;
    }
    .ov-background-black-height, .ov-background-black {
        height: 75vh;
    }
    .w-75 {
        width: 80%!important;
    }
}

@media (max-width: 1024px) {

    #owl-carousel-deal-package a.left.carousel-control.main,
    .owl-carousel-deal-package-list a.left.carousel-control.main,
    #mainslider a.left.carousel-control.main,
    .common-slider a.left.carousel-control.main,
    .owl-carousel-staff a.left.carousel-control.main,
    #wineanddinesec_new a.left.carousel-control.main,
    #sync1 a.left.carousel-control.main {
        left: -30px;
    }

    #owl-carousel-deal-package a.right.carousel-control.main,
    .owl-carousel-deal-package-list a.right.carousel-control.main,
    #mainslider a.right.carousel-control.main,
    .owl-carousel-staff a.right.carousel-control.main,
    .owl-carousel-staff a.right.carousel-control.main,
    .common-slider a.right.carousel-control.main,
    #wineanddinesec_new a.right.carousel-control.main,
    #sync1 a.right.carousel-control.main {
        right: -30px;
    }

    .padd-rgt-60 {
        padding-right: 15px;
    }

    #room-pic-main .item .padd-v-30 {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .page-myform>.container {
        padding: 0;
    }

    .footercapt h3 {
        background: transparent;
        padding-top: 50px !important;
        padding-bottom: 40px !important;
        height: 100%
    }

    .padd-v-30 {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .w-75 {
        width: 81%!important;
    }
}

@media (max-width:991px) {
    .marg-n-top-100 {
        margin-top: -50px;
    }

    #iconicstay h2 {
        top: 30px !important;
    }

    .oneslider:after {
        width: 100%;
    }

    #touritenaery .fnt-100 {
        font-size: 60px;
        line-height: 80px;
        position: relative;
        left: 0 !important;
        text-align: center;
    }


    .thevideo {
        height: 50vh !important;
    }

    .footercapt {
        position: relative;
        height: auto;
        padding-bottom: 50px;
    }

    .footercapt h3 {
        background: transparent;
        color: #333;
        height: auto;
        padding-bottom: 90px !important;
    }

    .border-box .padd-h-10 {
        padding: 0;
    }

    .border-box {
        text-align: center;
    }

    #sync1 .owl-item .new-ov-sub-title>span {
        font-size: 24px !important;
    }

    .bdr-lft-1-grey {
        border: none;
    }

    #sync2 .owl-item .card .padd-v-30 {
        padding: 10px !important;
    }

    #sync1 .slide-overlay .new-ov-main-title-home,
    #sync1 .slide-overlay .new-ov-sub-title-home {
        font-size: 16px;
        line-height: 24px;
    }

    #sync1.owl-theme .owl-next,
    #sync2 a.right.carousel-control.main,
    #wineanddinesec_new .owl-nav button.owl-next,
    .owl-carousel-deal-package-list .owl-nav button.owl-next,
    #sync1.owl-theme .owl-next {
        right: 0;
    }

    #sync1.owl-theme .owl-prev,
    #sync2 a.left.carousel-control.main,
    #wineanddinesec_new .owl-nav button.owl-prev,
    .owl-carousel-deal-package-list .owl-nav button.owl-prev,
    #sync1.owl-theme .owl-prev {
        left: 0;
    }

    .common-slider.outernav .owl-prev a {
        left: -15px !important;
    }

    .common-slider.outernav .owl-next a {
        right: -15px !important;
    }

    #brown-border-sec {
        background: transparent !important;
        /* color: #333; */
        padding: 15px;
        text-align: left;
    }

    #flashdeal>.col-md-12 {
        padding-bottom: 20px;
    }

    #singleofferprice {
        text-align: center !important;
    }

    #brown-border-sec>.dis-tbl {
        text-align: left !important;
    }

    #brown-border-sec>.dis-tbl h3 {
        color: #2B201A;
    }

    .dis-tbl.sm-block,
    .dis-tbl-cell.sm-block {
        display: block;
    }

    .offer-list-offer-item .price-title {
        flaot: left;
    }

    .blogslider:after {
        width: 100%;
        height: 85%;
        top: 13%;
        z-index: -1;
        left: 0;
    }

    .titleTag.exlarge {
        font-size: 8vw;
        line-height: 8vw;
        left: 0 !important;
        top: 0 !important;
    }

    #botrowing .pad-t-100 {
        background-position: top !important;
        padding-top: 300px !important;
    }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) {

    .offer-list-offer-item,
    .resort-list-item {
        height: auto !important;
    }

    #SnglOfrDesc {
        float: left !important;
    }

    .view-resort-button,
    .view-offer-button {
        position: relative;
        bottom: 0;
        left: 0;
    }

    .mobile-section-wrapper .main-background {
        background-position: -80px 155px !important;
    }
}



@media (max-width:768px) {

    .modal-dialog {
        max-width: 100%;
    }

    .display-none {
        display: none;
    }

    .islanddesc {
        position: relative;
    }

    .company-title {
        text-align: center !important;
    }

    #intotextforresort .container,
    #intotextforresort .container>div {
        padding-left: 0;
        padding-right: 0;
    }

    #one-big-cta-footer .new-ov-main-title:after {
        width: 100%;
    }

    #sync1 .owl-item .new-ov-sub-title>span {
        font-size: 24px !important;
    }

    #sync1 .slider-desc .bdr-lft-1-grey {
        border: none;
    }

    #sync2 .owl-item .card .padd-v-30 {
        padding: 10px !important;
    }

    #sync1 .slide-overlay .new-ov-main-title-home,
    #sync1 .slide-overlay .new-ov-sub-title-home {
        font-size: 16px;
        line-height: 24px;
    }

    #sync1.owl-theme .owl-next,
    #sync2 a.right.carousel-control.main {
        right: 0;
    }

    #sync1.owl-theme .owl-prev,
    #sync2 a.left.carousel-control.main {
        left: 0;
    }

    #wineanddinesec .item .padd-v-30 {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .withafter:after {
        background: transparent;
    }

    .page-myform>.container>.row {
        margin: 0;
    }

    .special-padd {
        padding: 10px 0;
    }

    .tf-cta-desc .fnt-50 {
        font-size: 30px;
    }

    .fixedsection {
        display: none;
    }
}

@media (max-width:767px) {
    .cta-footer-action {
        display: flex;
        padding: 0px 0;
        justify-content: center;
    }

    #SnglOfrDescmbo {
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.55) 100%, rgba(0, 0, 0, 0.65) 100%);
    }

    .cta-footer-action .cta-action-box {
        margin: 8px;
    }

    .cta-footer-action .cta-action-box .newbutton {
        font-size: 8px;
        min-width: 100px;
        border: none;
    }

    #touritenaery .padd-rgt-60,
    .ov-lined-listx {
        padding: 0 !important
    }

    .panel__label {
        padding: 25px 50px 25px 5px;
    }

    #owl-carousel-deal-package a.left.carousel-control.main,
    .owl-carousel-deal-package-list a.left.carousel-control.main,
    #mainslider a.left.carousel-control.main,
    .common-slider a.left.carousel-control.main,
    .owl-carousel-staff a.left.carousel-control.main,
    #wineanddinesec_new a.left.carousel-control.main,
    #sync1 a.left.carousel-control.main {
        left: -16px;
    }

    #owl-carousel-deal-package a.right.carousel-control.main,
    .owl-carousel-deal-package-list a.right.carousel-control.main,
    #mainslider a.right.carousel-control.main,
    .owl-carousel-staff a.right.carousel-control.main,
    .owl-carousel-staff a.right.carousel-control.main,
    .common-slider a.right.carousel-control.main,
    #wineanddinesec_new a.right.carousel-control.main,
    #sync1 a.right.carousel-control.main {
        right: -16px;
    }

    .fnt-15 {
        font-size: 13px;
    }

    #pkginnerdesc {
        padding-top: 0px !important;
    }

    .pad-b-70 {
        padding-bottom: 20px !important;
    }

    .moving-background-image:before,
    .moving-background-container {
        position: relative;
        height: 150px;
        min-height: 150px;
    }

    #blogslider .pad-tb-100,
    #pkghotelslider .padd-btm-100,
    .padd-100 {
        padding: 10px !important;
    }

    #blogslider .titleTag {
        color: #2B201A !important;
        text-shadow: none !important;
        text-align: left !important;
        font-size: 14px;
        padding-bottom: 0 !important;
        font-family: Arial, Helvetica, sans-serif !important;
        margin-bottom: 0 !important;
        line-height: 16px !important;
        padding-top: 5px;

    }

    #mainslider.home .owl-item .item {
        height: 280px !important;
    }

    .expericenimg {
        height: 220px !important;
    }

    #mobileslider .titlefont {
        font-size: 10px;
    }

    footer .container.pad-t-20 {
        padding-top: 10px !important;
    }

    .footer-link-wrapper {
        display: none;
    }

    .bosspic {
        width: 150px !important;
        height: 150px !important;
        margin: auto;
        border-radius: 50%;
        margin-bottom: -20px;
    }

    .fnt-new-handwritten.fnt-44 {
        line-height: 36px !important;
        font-size: 34px !important;
    }

    .jobpost {
        font-size: .7375rem;
    }

    #blogslider {
        padding: 0 10px;
    }

    #blogslider .owl-slide-title {
        color: #2B201A !important;
        text-shadow: none !important;
        font-size: 14px;
        text-align: left !important;
    }

    #mainslider .fnt-100,
    #pkghotelslider .fnt-100 {
        font-size: 40px;
    }

    #mainslider.home .fnt-18 {
        font-size: 16px;
    }

    #mainslider.home .slide-overlay {
        top: auto;
        bottom: 0;
        padding: 0px;
    }

    #mainslider.home .slide-overlay .padd-btm-100 {
        padding-bottom: 10px
    }

    #bringuthetitle {
        font-size: 20px;
        line-height: 26px;
        top: 0 !important;
    }

    #bringuthedesc.padd-h-60 {
        padding-left: 0;
        padding-right: 0;
    }

    #bringyouMainSec {
        padding-bottom: 10px !important;
    }

    #bringuthedesc .col-md-8 {
        padding: 0
    }

    .blogslider:after {
        top: 6%;
    }

    .menu li a,
    .menu li label {
        font-size: 14px;
        color: #7e6e64;
        font-family: "Copperplate";
        padding: 15px 0;
        letter-spacing: 1px !important;
    }

    .sub-menu-new li a {
        font-size: 13px !important;
        padding-left: 10px !important;
        padding: 10px 0;

    }

    #hotelMobslider.owl-carousel .owl-stage-outer {
        padding-top: 60px;
        padding-bottom: 10px;
    }

    .menu li .menu-item-has-children:before {
        position: absolute;
        content: '';
        font-size: 17px;
        line-height: 28px;
        padding: 8px;
        top: 14px;
        right: 5px;
        z-index: 9999;
        color: #DED6D0;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='30' height='30' fill='%23787474'%3E%3Cpath d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'%3E%3C/path%3E%3C/svg%3E");
        transition: all .2s ease-in-out;
        filter: invert(99%) sepia(1%) saturate(628%) hue-rotate(68deg) brightness(225%) contrast(100%);
    }


    #hotelMobslider a.right.carousel-control.main {
        position: relative;
        top: auto;
        bottom: 4px;
        right: 60px;
        outline: none;
    }

    #hotelMobslider a.left.carousel-control.main {
        position: relative;
        top: auto;
        bottom: 4px;
        left: 60px;
        outline: none;
    }

    #hotelMobslider .owl-nav>div {
        width: 50%;
        float: left;
        background: transparent;
    }

    #hotelMobslider .owl-item {
        top: -10px;
    }

    #hotelMobslider .owl-item .textsection {
        opacity: 0
    }

    #hotelMobslider .owl-item.active .textsection {
        opacity: 1
    }

    #hotelMobslider .owl-item.active {
        transform: scale(1.2);
        z-index: 100;
        box-shadow: 0 0 1px 0px #0000005e;
        opacity: 1;
        top: 0;
        transition: .5s ease;
    }

    #hotelMobslider .owl-item.active .titlefont {
        font-size: 12px;
    }

    #sigleDestpkg {
        padding: 30px;
    }

    #sigleDestpkg .bg-Dgrey {
        background: transparent !important
    }

    #sigleDestpkg .bg-Dgrey .text-white {
        color: #2B201A !important;
    }

    #sigleDestpkg .bg-Dgrey h3,
    #touritenaery .col-md-7 span {
        font-size: 14px;
        text-transform: initial !important;
        font-style: italic;
        font-weight: 500;
        text-align: center;
        margin-top: 15px;
    }

    #sigleDestpkg .bg-Dgrey h3.titleTag {
        font-style: initial;
        font-size: 18px;
        text-align: center !important;
        margin-bottom: 0px;
        margin-top: 25px;
        text-transform: uppercase !important;
    }

    #SnglOfrDesccovratEase h3 {
        font-style: initial;
        font-size: 18px;
        text-align: center !important;
        margin-bottom: 20px;
        margin-top: 45px;
        text-transform: uppercase !important;
        font-family: Times New Roman, Times, serif !important;
    }

    #SnglOfrDescHotels h5 {
        font-style: initial;
        font-size: 18px;
        margin-bottom: 20px;
        margin-top: 10px;
        text-transform: uppercase !important;
        font-family: Times New Roman, Times, serif !important;
    }

    #SnglOfrDescEpic h5 {
        font-style: initial;
        font-size: 18px;
        margin-bottom: 20px;
        margin-top: 10px;
        text-transform: uppercase !important;
        font-family: Times New Roman, Times, serif !important;
    }

    #touritenaery .col-md-5 .titlefont {
        font-style: initial;
        font-size: 18px;
        text-align: center !important;
        margin-bottom: 25px;
        margin-top: -10px;
        text-transform: uppercase !important;
        font-family: Times New Roman, Times, serif !important;
    }

    #inquire .padd-h-30 {
        padding-left: 15px;
        padding-right: 15px;
    }

    #inquire {
        padding: 15px;
        border: 1px solid #7373734f;
        margin: 10px;
    }

    #inquire>div {
        border: 1px solid #7373734f;
        margin: 0;
        box-shadow: 0 0 4px #7373734f;
    }

    #inquire p.new,
    #inquire .titlefont {
        font-size: 12px;
    }

    #inquire label {
        margin: 0;
        font-size: 12px;
    }

    .container.border-dark {
        padding: 25px 10px;
    }

    #hotelMobslider .owl-item.active p.new {
        font-size: 12px;

    }

    .MasonrySec .Masonry-item:nth-child(2) {
        margin-top: 0
    }

    .titleTag,
    .titleTag.exlarge {
        font-size: 1.5rem;
        letter-spacing: .1125rem;
        margin-bottom: 1.125rem;
        line-height: 1.25;
        text-align: center;
    }

    .titleTag.mobile {
        font-size: 5vw;
        letter-spacing: .1125rem;
    }

    #mainslider.home .owl-dots {
        position: relative;

    }

    #mainslider.home {
        padding: 0 0px;
    }

    .gridrow {
        margin-right: 5px;
        margin-left: 5px;
    }

    #mainslider video {
        height: 100% !important;
    }

    .titleTag.large {
        font-size: 2.5rem;
        line-height: 2.5rem;
        left: 0 !important;
    }

    #intotextforresort .col-md-4.padd-tp-15 {
        padding-top: 0;
    }

    #radiobuttono {
        display: block;
    }

    #radiobuttono>div {
        display: block;
        float: left
    }

    #accordionBlog .padd-v-40,
    #blog .padd-v-60 {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    #offers .container.padd-v-15 {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .border-box {
        padding: 0;
        margin-bottom: 5px;
        text-align: left;
    }

    .bdr-lft-1-grey {
        border-left: none;
    }

    #offerdaycount {
        padding: 0 !important;
        order: 1;
        background: transparent;
    }

    #itemsection {
        padding: 10px;
    }

    #itemsection>div {
        border: 5px solid white;
        display: flex;
        flex-direction: column;
    }

    #offerdetailssec {
        padding: 0;
        order: 2;
    }

    .border-box>span,
    .border-box>h3 {
        display: inline-block !important;
        text-transform: uppercase;
        font-size: 16px;
    }

    .singlefaci {
        min-height: 45px !important;
    }

    .singlefaci>div svg {
        width: 20px;
        height: 30px;
    }

    .singlefaci>div {
        display: inline-block;
        text-align: left;
        width: 20px !important;
        float: left;
    }

    #resort-facilities .facname {
        padding-top: 5px;
        text-transform: uppercase;
        color: #555555;
        display: inline-block;
        width: calc(100% - 20px);
        float: left;
        text-align: left;
        padding-left: 10px;
    }

    #dining-container {
        padding: 15px !important;
    }

    #wineanddinesec_new .owl-nav button.owl-prev {
        left: -15px;
        margin: 0;
    }

    #wineanddinesec_new .owl-nav button.owl-next {
        right: -15px;
        margin: 0;
    }

    #resort-facilities {
        padding-bottom: 50px;
    }

    .sec_resort .img-container .col-md-5 {
        padding: 0;
        margin-bottom: 15px;
    }

    #bookwhenever .margin-n-left-100 {
        margin: 0 !important;
    }

    .footer-mobile {
        margin-top: 35px;
    }
}

@media (max-width: 580px) {
    .modal-dialog {
        max-width: 100%;
    }

    .new-ov-main-title-home {
        font-size: 16px;
        line-height: 24px;
    }

    .new-ov-sub-title-home {
        font-size: 20px;
        line-height: 24px;
    }

    .door {
        top: 20%;
        padding: 0 40px;
    }

    .shareandvideo a {
        width: 100%;
    }

    header {
        padding: 1px 10px !important;
        box-shadow: 0px 2px 8px #0000000f;
    }

    header .site-title img {
        width: 100px;
    }

    .responsive-chat-icon,
    .responsive-menu-icon {
        top: 15px !important;
    }

    .body {
        padding-top: 62px;
    }

    .footercapt {
        padding-bottom: 10px;
    }

    #one-big-cta-footer {
        margin: 10px 0;
    }

    .page-myform .padd-v-30,
    .offer-list-offer-item .card .img-container .padd-v-30 {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .home-big-titles,
    #one-big-cta-footer .new-ov-main-title,
    #one-big-cta-footer .new-ov-sub-title {
        letter-spacing: 0.05em;
        font-size: 20px !important;
        line-height: 26px !important;
    }

    .home-big-titles+h3.amber {
        font-size: 18px;
    }

    .bgImageHeight {
        height: 250px !important
    }

    #resort-page-strip-01:not(.ov-texture-bg-new),
    #radiobuttono {
        padding-bottom: 5px !important;
        padding-top: 25px !important;
    }

    .page-myform {
        padding: 10px 10px;
    }

    #breadcrumb .pad-t-70 {
        padding-top: 50px !important;
    }

    #desktop-nav {
        display: none !important;
    }

    a.right.carousel-control.main,
    a.left.carousel-control.main {
        top: 15%;
    }

    form.global-form>div input+label {
        min-height: 110px !important;
    }

    .owl-carousel-staff a.right.carousel-control.main,
    .owl-carousel-staff a.left.carousel-control.main,
    .common-slider a.right.carousel-control.main,
    .common-slider a.left.carousel-control.main,
    #mainslider a.right.carousel-control.main,
    #mainslider a.left.carousel-control.main {
        top: 50%;
    }

    .resort-logo .owl-next,
    .resort-logo .owl-prev {
        width: 40px !important;
        height: 40px !important;
        line-height: 33px !important;
    }

    #owl-carousel-deal-package a.right.carousel-control.main,
    #owl-carousel-deal-package a.left.carousel-control.main {
        top: 0;
    }

    #secondary_resorts {
        padding-bottom: 20px;
        padding-top: 0px !important;
    }

    #sync1 .item .padd-v-25.padd-h-25 {
        padding: 15px;
    }

    #sync1.owl-theme .owl-next,
    .owl-carousel-deal-package-list a.right.carousel-control.main {
        right: -20px;
    }

    #sync1.owl-theme .owl-prev,
    .owl-carousel-deal-package-list a.left.carousel-control.main {
        left: -20px;
    }

    #sync1 a.right.carousel-control.main,
    #sync1 a.left.carousel-control.main,
    #wineanddinesec_new a.right.carousel-control.main,
    #wineanddinesec_new a.left.carousel-control.main,
    .common-slider a.right.carousel-control.main,
    .common-slider a.left.carousel-control.main,
    .owl-carousel-staff a.right.carousel-control.main,
    .owl-carousel-staff a.left.carousel-control.main,
    #mainslider a.right.carousel-control.main,
    #mainslider a.left.carousel-control.main,
    #owl-carousel-deal-package a.right.carousel-control.main,
    #owl-carousel-deal-package a.left.carousel-control.main,
    .owl-carousel-deal-package-list a.right.carousel-control.main,
    .owl-carousel-deal-package-list a.left.carousel-control.main {
        width: 40px;
        height: 40px;
        font-size: 22px;
        padding: 5px;
    }

    a.left.carousel-control svg,
    a.right.carousel-control svg {
        width: 20px
    }

    .cd-fixed-bg-overlay {
        min-height: 350px;
        padding-top: 58px;
    }

    #offer-body-sec-0 .home-big-titles {
        font-size: 16px;
        line-height: 22px;
        padding: 20px 5px 20px !important;
    }

    .page-myform .padd-h-30,
    .offer-list-offer-item {
        padding-left: 15px;
        padding-right: 15px;
    }

    #intotextforresort {
        padding-bottom: 10px !important;
    }

    .pad-b-100 {
        padding-bottom: 30px !important;
    }

    .blogslider:after {
        background: #ffffff;
    }

    #bringyouMainSec .pad-t-100 {
        padding-top: 15px !important;
    }

    #bringyouMainSec .pad-b-50 {
        padding-bottom: 0 !important;
    }

    #owl-carousel-deal-package a.right.carousel-control.main,
    .owl-carousel-deal-package-list a.right.carousel-control.main,
    #mainslider a.right.carousel-control.main,
    .owl-carousel-staff a.right.carousel-control.main,
    .owl-carousel-staff a.right.carousel-control.main,
    .common-slider a.right.carousel-control.main,
    #wineanddinesec_new a.right.carousel-control.main,
    #sync1 a.right.carousel-control.main {

        line-height: 22px;

    }

    #owl-carousel-deal-package a.left.carousel-control.main,
    .owl-carousel-deal-package-list a.left.carousel-control.main,
    #mainslider a.left.carousel-control.main,
    .common-slider a.left.carousel-control.main,
    .owl-carousel-staff a.left.carousel-control.main,
    #wineanddinesec_new a.left.carousel-control.main,
    #sync1 a.left.carousel-control.main {
        line-height: 22px;
    }

    .rich-container-pdn {
        padding: 30px 0px !important;
    }

    .rich-container-pdn .rich-pading-b {
        margin-bottom: 20px !important;
    }

    section.rich-container-pdn-bottom.mob-botm-p {
        padding-top: 120px;
    }

    .rich-container-pdn-bottom {
        padding-bottom: 0px;
    }

    .ov-form-input-holder {
        margin: 13px 0;
    }

    #msform .action-button {
        width: 100px;
        border: 1px solid #2B201A;
    }

    .slide-in-home .slide-in-pain {
        position: fixed;
        right: -65px;
        transform: rotate(-90deg);
        z-index: 88888;
        top: 55%;
        border: none;
        background-color: #FFE40F;
        padding: 5px 9px;
        font-size: 11px;
        font-weight: 300;
        text-transform: uppercase;
        color: #282828;
        font-family: 'Bembo Regular' !important;
        letter-spacing: 1px;
        animation: fadeIn ease 5s;
        -webkit-animation: fadeIn ease 5s;
    }

    .rich-container-pdn-img {
        padding: 10px 0px !important;
    }
}

.room-pic-slider>div {
    width: 100% !important;
}

.ov-texture-bg-new:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: auto;
    opacity: 0.4;
}

.ov-texture-bg-new-layout:after {
    content: '';
    background-image: url(../images/ov-texture-bg.png);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: auto;
    opacity: 0.4;
}

.both-side-shape:after {
    content: '';
    background-image: url(../images/ov-texture-bg.png);
    width: 10%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: auto;
    opacity: 0.4;
}

.both-side-shape:before {
    content: '';
    background-image: url(../images/ov-texture-bg.png);
    width: 10%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-size: auto;
    opacity: 0.4;
}

#mainslider .item.ov-texture-bg-new-layout:after {
    opacity: 0.3;
}

.gradientBottom {
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.91) 52%, rgba(255, 255, 255, 1) 57%, rgba(255, 255, 255, 1) 100%);
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.91) 52%, rgba(255, 255, 255, 1) 57%, rgba(255, 255, 255, 1) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.91) 52%, rgba(255, 255, 255, 1) 57%, rgba(255, 255, 255, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#ffffff', GradientType=0);
}

.gradientBottom.no-bg {
    background: none;
}

.minified {
    max-height: 500px;
    overflow: hidden;
    transition: max-height 0.9s linear;
    padding-bottom: 60px;
}

#room-pic-main .owl-item .bx-controls-direction {
    display: none;
}

#deal-package-page .owl-next,
#deal-package-page .owl-prev {
    position: absolute;
    top: 50%;
    z-index: 5;
    display: inline-block;
    margin-top: -10px;
    font-size: 50px;
    border: none;
}

#deal-package-page .owl-next {
    right: 0
}

#deal-package-page .owl-prev {
    left: 0
}

i.black.fa-chevron-right:before,
i.black.fa-chevron-left:before {
    color: #2B201A;
}

.badge.badge-light {
    margin: 0;
    background: transparent;
    font-size: 14px;
    padding: 5px 0;
    margin-bottom: 5px;
    font-family: "font-Baskerville", "Times New Roman", Times, Georgia, serif;
    letter-spacing: 0.2em;
}


.margin-around {
    margin: 5px;
    padding: 0px;
}

.display-style {
    display: block;
}

.height-for-web {
    height: 537px;

}

.height-full {
    height: 100%;
    width: auto !important;
}


#hotelslider .owl-nav {
    position: absolute;
    top: 40%;
    right: 0;
    width: 100%;
}

#hotelslider .owl-nav .owl-prev {
    float: left;
    padding: 12px;
    box-shadow: 1px 1px 6px #cacaca;
}

#hotelslider .owl-nav .owl-next {
    float: right;
    padding: 12px;
    box-shadow: 1px 1px 6px #cacaca;
}



#resortslider .owl-nav {
    position: absolute;
    top: 40%;
    right: 0;
    width: 100%;
}

#resortslider .owl-nav .owl-prev {
    float: left;
    padding: 6px 2px;
    box-shadow: 1px 1px 6px #cacaca;
}

#resortslider .owl-nav .owl-next {
    float: right;
    padding: 6px 2px;
    box-shadow: 1px 1px 6px #cacaca;
}

#blogslider .owl-nav {
    display: none;
}

.display-none {
    display: none;
}

.load-more-btn svg {
    cursor: pointer;
    fill: rgb(43 32 26 / 85%);
    display: block;
    margin: 0 auto;
    margin-bottom: 12px;
    transition: 0.9s;
}

.see-more-btn {
    font-family: Arial, Helvetica, sans-serif !important;
    font-size: .625rem;
    letter-spacing: .3em;
    text-transform: uppercase;
}

.load-more-btn svg:hover {
    transition: 0.9s;
    transform: rotateY(180deg);
}

.new-carousol img {
    width: 100%;
}

.margn-top-new {
    margin-top: 100px;
}

.mrgin-left {
    margin-left: 20%;
}


.react-multiple-carousel__arrow {
    border-radius: 0px !important;
}

.react-multiple-carousel__arrow--right {
    right: 10px !important;
    top: 42%
}

.react-multiple-carousel__arrow--left {
    left: 0px !important;
    top: 42%
}

.react-multi-carousel-track {

    margin-top: 30px !important;
}

.margin-top-new {
    margin-top: 85px;
}

.text-caro {
    margin-top: 24px;
}

.text-caro .titleTag.fnt-new-times {
    margin-bottom: 2px;
}

.new-line-after .tf-cta-text:after {
    background: #847770;
}


/*================ Destination Page =============== */

.heading-new {
    font-family: 'Bembo Regular' !important;
}

.heading2-style {
    font-family: 'Bembo Regular' !important;
    color: #4C4442 !important;
    font-size: 45px;
}

.body-new {
    font-family: 'Bembo Regular' !important;
    color: #1c222f !important;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0px;
}

.new-font-style {
    color: #4C4442 !important;
    font-size: 58px;
    line-height: 100%;
}

.width-70 {
    width: 70%;
    margin: 0 auto;
    padding: 20% 0px;
}

.white-font-style {
    color: #0d1720 !important;
    font-size: 18px;
    line-height: 28px;
}

.padin-b-50 {
    padding-bottom: 120px;
}

.rich-width-60 {
    width: 50%;
    margin: 0 auto;
}

.rich-pading-b {
    margin-bottom: 40px !important;
}

.packages {
    padding-bottom: 100px;
}

.packages:last-child {
    padding-bottom: 0px;
}

.pcg-detils {
    font-family: 'Bembo Regular' !important;
    color: #7E6E64 !important;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 30px;
}

.width-60-left {
    padding: 95px;
}

.width-60-right {
    width: 77%;
    margin-left: 0px;
}

.cta-underline-btn {
    padding: 13px 12px;
    display: inline-block;
    font-family: "Copperplate" !important;
    text-transform: uppercase;
    color: #7e6e64;
    overflow: hidden;
    letter-spacing: 0px;
    cursor: pointer;
    border: 1px solid #ffe40f;
    border-radius: 41px;
    line-height: 9px;
}

.cta-underline-btn a {
    font-family: "Copperplate";
    padding: 20px 0px;
}

.rich-inner-title {
    font-size: 22px;
    margin-bottom: 30px;
    color: #000 !important;
    line-height: 1.3em;
}

.gridrow .col-lg-4 {
    margin: 20px 0px 0px 0px;
}

body #mainslider a.left.carousel-control.main {
    width: 100px;
    height: 75px;
    -ms-transform: rotate(180deg);
    /* IE 9 */
    transform: rotate(180deg);

    border: none !important;

    left: 0px;
}

body #mainslider a.right.carousel-control.main {
    width: 100px;
    height: 75px;


    border: none !important;

    right: 0px
}

.text-uppercase {
    text-transform: uppercase;
}

.width-mrgn {
    width: 70%;
    margin: 0 auto;
}

.pb-30 {
    padding-bottom: 30px;
}

.text-wight {
    color: #fff !important;
}

.blog-title {
    font-size: 20px !important;
    margin-bottom: 20px !important;
    text-transform: capitalize !important;
}

.footer-space {
    margin-bottom: 75px;
}

.font-size-new {
    font-size: 36px !important;
    margin-bottom: 15px;
}

.home-para {
    font-size: 50px;
    line-height: 110%;
    text-align: center;
    color: #1c222f  !important;
    padding: 0px;
    margin: 0px;

}

.gray {
    color: #B2ACAA;
    font-style: normal;
}

.width-70-new {

    padding: 130px 0px;
}

.px-img img {
    width: 232px !important;
    left: 80% !important;
    top: 52% !important;
}

.bg-gray {
    background-color: #f5f3ee;
    padding: 70px 0px;
}

.row-bg-white {
    background-color: #fff;
    margin: 0px auto;
}

.row-bg-white-1 {
    margin: 0px auto;
}

.padding-0 {
    padding: 0px;
}

.flex-col {
    display: flex;
    align-items: center;
    padding: 10px;
}

.pdn-left {
    padding-left: 60px;
}

.width-full img {
    width: 100%;
}

.padin-new {
    padding: 87px 124px;
}

.text-align {
    padding: 0px 40px;
    padding-bottom: 100px;
}

.para-padding {
    padding-top: 8%;
    padding-bottom: 15%;
}


.form-pdn {
    padding: 0px 0px;
}

.form-title {
    font-size: 20px !important;
}

.checkbox-material {
    top: 2px !important;
}

.mrgin-b-0 {
    margin-bottom: 20px !important;
}

.mrgin-t-0 {
    margin-top: 0px !important;
}

.new-svg svg {
    width: 50px;
    height: 50px;
}

.resort-white-strip .row.padd-v-10 {
    align-items: center;
}

.resort-white-strip .container .pad-t-70.row {
    margin-left: 78px !important;
}

.form-width p {
    text-align: left;
    width: 80%;
    margin: 30px 25px;
}

.form-width h6 {
    width: 80%;
    margin: 30px 25px;
}

.destination-new-fld {
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.bg-transprt {
    background: transparent !important;
}

.bg-white {
    background: #fff !important;
}

.rich-form-pdn {
    padding: 45px 35px;
}

.fnt-15 {
    font-family: 'Bembo Regular' !important;

    line-height: 1.5em !important;
    color: #1c222f  !important;
}

.pdn-around {
    padding-right: 45px;
    padding-top: 50px;
}

.rich-padin {
    padding: 130px !important;
}

.font-white {
    color: #fff !important;
}

.map-img {
    width: 100%;
}

.rich-pdin svg {
    margin-bottom: 10px !important;
}

.text-step {
    font-family: 'Bembo Regular' !important;
    color: #c0bfbe;
    font-size: 15px;
    letter-spacing: 1px;
    margin-bottom: 0px;
}

.step-number {
    font-family: 'Bembo Regular' !important;
    font-weight: 700;
    font-size: 16px;
    font-size: 40px;
    margin-bottom: 20px;
}


/*======================= New Spacing CSS Start =========================*/
.rich-container-pdn {
    padding: 130px 0px;
}

.maldives-space {
    justify-content: center;
    margin: 0px;
    padding: 0px;
}

.rich-container-pdn-img {
    padding: 100px 0px;
}

.rich-leftandright {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.rich-container-title {
    padding-top: 160px;
    padding-bottom: 120px;
}

.rich-container-title-inner {
    padding-bottom: 130px;
}

.rich-container-title-inner-1 {
    padding-bottom: 80px;
}

.rich-container-title-top {
    padding-top: 150px;
}

.rich-container-pdn-new {
    padding: 65px 0px;
}

.rich-container-pdn-t {
    padding-top: 130px;
    padding-bottom: 85px;
}

.rich-mrgn-0 {
    margin: 0px !important;
}

.modal-body {
    padding: 4rem;
}

.quet-icon {
    margin-bottom: 50px;
}

.quet-icon svg {
    fill: #d7d6d6;
}

.quet-icon-2 {
    margin-top: 50px;
    text-align: right;
}

.quet-icon-2 svg {
    fill: #d7d6d6;
}

.rich-margin-l-0 {
    margin-left: 0px !important;
}

.rich-mrgn-botm {
    margin-bottom: 15px !important;
}

.rich-mrgn-top {
    margin-top: 56px !important;
}

.rich-bg-gray {
    background-color: #f5f3ee !important;
}

.rich-pdin {
    padding: 50px 37px;
}

.rich-pdn-bottom-0 {
    padding-bottom: 0px !important;
}

.rich-mrgn-bottom-0 {
    margin-bottom: 0px !important;
}

.rich-form-style label {
    font-family: 'Bembo Regular' !important;
    font-size: 16px;
    color: #7E6E64 !important;
    margin-top: 17px;
}

.rich-form-style input {
    font-family: 'Bembo Regular' !important;
}

.rich-form-style .custom-selector-trigger {
    font-family: 'Bembo Regular' !important;
}

.rich-form-style .btn-ov.btn-ov-cta {
    font-family: 'Bembo Regular' !important;
}

.remove-btn-style {
    border: none;
    background-color: transparent;

}

.ov-form-input-holder label {
    font-family: 'Bembo Regular' !important;
    margin-top: 17px;
}

.form-control {
    height: 50px;
}

.custom-selector-trigger {
    line-height: 2.5;
}

.padin-left-right {
    padding: 0px 55px;
}

.width-60 {
    padding: 90px;
}

.rich-pdn-o {
    padding: 0px !important;
}

.width-90 {
    width: 92%;
}

.rich-padn-0 {
    padding: 0px !important;
}

.form-row-pdn {
    padding: 20px 0px;
}

.row.items.pkg-items {
    padding-bottom: 80px;
}

.rich-new-pdn {
    padding: 30px 0px;
}

.new-flex-item {

    display: flex;
    align-items: flex-end;
}

.rich-container-pdn-new1 {
    padding: 310px 0px;
}

.rich-margin-minas {
    margin-top: -472px;
}

.specilist-item .react-multi-carousel-track li:first-child {
    margin-left: 80px;
}

.bg-gray-boxs {
    background-color: #F5F3EE;
    padding: 34px 9px;
}

.bg-yellow-boxs {
    background-color: #fffbdf;
    padding: 112px 10px;
}

.specilist-section .react-multi-carousel-track {
    margin-top: 0px !important;
}

.specilist-item .react-multi-carousel-track {
    margin-top: 0px !important;
}

.width-70-new.only-thing {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.specilist-section2 .react-multiple-carousel__arrow {
    margin-top: 50px !important;
    box-shadow: 1px 1px 6px #cacaca;
}

.new-flex-item p {
    margin-bottom: 50px;
}

.diff-in-pers img {
    margin-bottom: 15px;
}

.gray-bg-before:before {
    content: '';
    display: block;
    width: 96%;
    height: 96%;
    /* height: 700px; */
    background-color: #f6f6f6;
    position: absolute;
    top: -71px;
    left: 80px;
}

.contect-safi {
    padding-left: 73px;
}

.contect-safi .jobpost {
    text-align: right;
}

.contect-safi .heading-new {
    text-align: right;
}

.padn-top-new {
    padding-top: 200px;
}

.rich-mrgn-btm {
    margin-bottom: 70px !important;
}

.pr-spacing {
    padding: 20px 0px;
}

.display_block {
    display: block;
}

.pdn-right {
    padding-right: 50px;
}

.mrgn-botm-50 {
    margin-bottom: 93px;
}

.mrgn-top-50 {
    margin-top: 70px;
}

.rich-pdn-130 {
    padding-top: 130px;
}

.react-multi-carousel-list.specilist-item {
    justify-content: center;
}

.policytb tr td {
    font-size: 14px;
    line-height: 20px;
    border-bottom: 1px solid #CCC;
    border-right: 1px solid #CCC;
    padding: 15px;
    text-align: center;
}

.policytb tr th {
    background-color: #E7E7E7;
    font-size: 18px;
    line-height: 20px;
    border-bottom: 1px solid #CCC;
    border-right: 1px solid #CCC;
    text-align: center;
    padding: 14px;
    font-weight: 400;
    color: #363636;
}

.policytb {
    border-top: 1px solid #CCC;
    border-left: 1px solid #CCC;
    width: 100%;
    margin-top: 50px;
}

.align-center-item {
    margin: 0 auto;
}

.bg-wight {
    padding: 120px 60px;
    background-color: #fff;
}

/*======================= New Spacing CSS End =========================*/

.slider-sub-text {
    text-transform: none;
    font-family: 'Bembo Regular' !important;
    letter-spacing: 0px;
    font-size: 40px;
    color: #C9C9C9;
}

.font-size-sml {
    font-size: 21px !important;
    line-height: 40px !important;
}

.slide__container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

.map-img img {
    width: 100%;
}

.map-img {
    padding: 0px;
}


#resortslider a.left.carousel-control.main {
    position: relative;
    margin-top: 0px;
}

#resortslider a.right.carousel-control.main {

    position: relative;
    margin-top: 0px;
}

.react-multiple-carousel__arrow:hover {
    background: rgb(163 163 163) !important;
}

.react-multiple-carousel__arrow {
    background: #fff !important;
}

.react-multiple-carousel__arrow:focus {
    border: none !important;
}

.react-multiple-carousel__arrow::before {
    color: #454545 !important;
}

button:focus {
    outline: 0px auto -webkit-focus-ring-color !important;
}

.react-multiple-carousel__arrow {
    min-width: 38px;
    min-height: 46px;
}

.width-60-left-without-m {
    padding: 95px;
}

.rich-container-pdn-new {
    padding-top: 50px;
    padding-bottom: 60px;
}

.packages.container .img-wrapper {
    margin-top: 0px;
}

.rich-pdn-bot-0 {
    padding-bottom: 0px !important;
}

.slide-pane__overlay {
    z-index: 99999;
}

.slide-pane__header {
    background: #ffffff !important;
    border-bottom: 0px !important;
}

.slide-pane .form-width p {
    margin-top: 0px;
}

.diff-in-pers img {
    width: 100%;
}

.diff-soc-icons {
    position: absolute;
    right: -180px;
    display: flex;
    top: 60%;
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.diff-soc-icons p {
    padding: 22px 10px;
    font-size: 13px;
    text-transform: uppercase;
    font-family: 'Bembo Regular' !important;
    font-weight: 400;
}

/**********Slide in panal css **********/
.slide-in-pain {
    position: fixed;
    right: -81px;
    transform: rotate(-90deg);
    z-index: 88888;
    top: 55%;
    border: none;
    background-color: #FFE40F;
    padding: 9px 15px;
    font-size: 13px;
    font-weight: 300;
    text-transform: uppercase;
    color: #282828;
    font-family: 'Bembo Regular' !important;
    letter-spacing: 2px;
    animation: fadeIn ease 5s;
    -webkit-animation: fadeIn ease 5s;

}

.fade-in {
    animation: fadeIn ease 20s;
    -webkit-animation: fadeIn ease 20s;
    -moz-animation: fadeIn ease 20s;
    -o-animation: fadeIn ease 20s;
    -ms-animation: fadeIn ease 20s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.slide-pane {
    width: 60% !important;
    box-shadow: none !important
}

.header .is-visible .call-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.header .is-visible .fab.fa-whatsapp {
    margin-right: 15px;

}

.full-width-new {
    width: 100%;
}

.full-width-new img {
    width: 100%;
}

.img-around-pdn {
    padding: 100px 15px;
}

/**************************  New Header css Start***********************************/
.header-btn {
    padding: 10px 25px;
    background-color: #ffe40f;
    color: #2B201A;

    font-family: 'Bembo Regular' !important;
    font-size: .6875rem;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.flex-col-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.flex-col {
    display: flex;
    align-items: center;
}

.header-display-none {
    display: none;
}

.flex-col .icons {
    margin-left: 22px;

}

.margin-0 {
    margin: 0px;
}


.pdn-20 {
    padding: 10px;
}

.hovermenu.tf-secondary-nav:hover {
    background-color: #fff;
}

.width-70-new1 {
    width: 1344px;
    margin: 0 auto;
    padding: 60px 0;
}

.home-header .nav.navbar-nav {

    width: 30%;

}

/**************************  New Header css End***********************************/


.width-70-resort {

    margin: 0 auto;
    padding: 30% 0px;
}

.width-70-resort-new {

    height: 85vh;
}

.width-70-moon-new {

    height: 65vh;
}
.width-70-moon-new-contact{
    height: 80vh;
}

.margn-top-min {
    margin-top: -100px;
}

.margn-top-min-new {
    margin-top: -175px;
}

.rich-pading-bot {
    margin-bottom: 80px !important;
}

.width-60-left-new {
    padding-right: 0px;
    padding-left: 128px;
}

.blog-am-text {
    font-family: 'Bembo Regular' !important;
    /* text-decoration: underline; */
    font-size: 18px;
    line-height: 28px;
    font-style: italic;
    color: #383838;
    font-weight: 400;
    margin-top: 20px;
    padding: 20px;

}

.width-60-right-new {
    padding-right: 128px;
    padding-left: 0px;
}

.MuiPopover-root {
    z-index: 999999999999999999 !important;
}

.qodef-m-subtitle {
    position: absolute;
    bottom: 61%;
    left: 243px;
    margin: 0;
    transform: rotate(-90deg) translateX(-100%);
    transform-origin: 0 100%;
    font-family: 'Bembo Regular' !important;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 20px;
}

.color-white {
    color: #fff !important;
}

.slider-wrapper {
    position: relative;
    height: 70vh;
    overflow: hidden;
}

.slide {
    height: 100%;
    background-size: cover !important;
}

.slide::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;

    bottom: 0;
    left: 0;
}

.previousButton,
.nextButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    background: url('http://svgshare.com/i/41_.svg') no-repeat center center / 16px;
    width: 32px;
    height: 32px;
    text-indent: -9999px;
    cursor: pointer;
}

.previousButton:hover,
.nextButton:hover {
    background: url('http://svgshare.com/i/41_.svg') no-repeat center center / 16px;
}

.previousButton {
    left: 0;
    -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
    transform: rotate(180deg) translateY(calc(50% + 0px));
}

.previousButton:hover {
    left: -10px;
}

.nextButton {
    right: 0;
}

.nextButton:hover {
    right: -10px;
}

.slider-content {
    text-align: center;
}

.slider-content .inner {
    padding: 0 70px;
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.slider-content .inner button {
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

.slider-content .inner h1 {
    font-weight: 900;
    margin: 0 auto;
    max-width: 840px;
    color: #FFFFFF;
    font-size: 64px;
    line-height: 1;
}

.slider-content .inner p {
    color: #FFFFFF;
    font-size: 14px;
    line-height: 1.5;
    margin: 20px auto 30px;
    max-width: 640px;
}

.slider-content section {
    position: absolute;
    bottom: 20px;
    left: 20px;
}

.slider-content section span {
    color: #FFFFFF;
}

.slider-content section span {
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    display: inline-block;
    text-align: left;
    line-height: 1.4;
    vertical-align: middle;
    margin-left: 10px;
}

.slider-content section img {
    width: 40px;
    height: 40px;
    border: solid 2px rgba(255, 255, 255, 0.5);
    border-radius: 100%;
    vertical-align: middle;
}

.slider-content section span strong {
    color: #FFFFFF;
    font-size: 14px;
    display: block;
}

@media (max-height: 500px) {

    .slider-wrapper,
    .slide {
        height: calc(100vh - 75px);
    }
}

@media (max-width: 640px) {

    .slider-wrapper,
    .slide {
        height: 100%;
    }
}

@media (max-height: 600px) {
    .slider-content .inner h1 {
        font-size: 32px;
    }
}

@media (max-width: 640px) {
    .slider-content .inner h1 {
        font-size: 32px;
    }
}

.offer-boxs {
    border: 1px solid #C5E4EF;
    padding: 50px 20px;
}

.offer-boxs.bg-color-gray {
    padding: 70px 20px;
    background-color: #fffef4;
    border: none;
}

.qodef-m-price {
    text-align: center;
}

.qodef-m-price-value {
    display: flex;
    align-items: center;
    justify-content: center;
}

.offer-details {
    list-style: none;
    text-align: center;
    line-height: 36px;
    font-size: 18px;
    font-family: 'Bembo Regular' !important;
    margin-top: 5px;
    color: #535353;
    margin-bottom: 0px;
}

.offer-price {
    font-family: cormorant garamond, serif;
    letter-spacing: -.01em;
    font-size: 99px;
    margin-bottom: 37px;
    padding: 0px 8px;
    margin-top: 10px;
    color: #ffe40f;
}

.qodef-m-price-period,
.qodef-m-price-currency {
    font-size: 20px;
    color: #939593;
    font-family: 'Bembo Regular' !important;
}

.rich-pading-botm-inner {
    margin-bottom: 80px;
}

.align-items-flex-end {
    align-items: flex-end;
}

.pdn-right-col {
    padding-right: 100px;
}

.love-this {
    position: relative;
}

.love-this-inner {
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 9;
}

.love-this-inner img {
    width: 250px;
    z-index: 9;
    margin-left: -25px;

}

.blog-flex {

    display: flex;
    align-items: flex-end;
    padding-right: 100px;

}

.img-inner-slider {
    width: 100%;
}

.pdn-sec {
    padding: 15% 0px;
}

.mrgn-top-min {
    margin-top: -42%;
    margin-bottom: 150px;
}

.white-box-slider {
    background-color: #fff;
    padding: 20px;
    width: 670px;
    margin-top: -513px;
    height: 190px;
}

.pdn-remove {
    padding-top: 150px;
}

.height-150 {
    padding-bottom: 150px;
}

.lower-price {
    font-family: 'Bembo Regular' !important;

}

.lower-price-new {
    font-family: cormorant garamond, serif;
    letter-spacing: -.01em;
    font-size: 28px;

    color: #ffe40f;
}

.rich-pading-b-nw {
    margin-bottom: 130px;
}

.position-rel {
    position: relative;
}

.position-rel-new {
    position: relative;
}

.position-ab {
    position: absolute;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    transition: 0.7s;
}

.inner-waper-in {
    padding: 24px;
    background-color: #fff;
    margin: 50px;
    padding: 20px;
    text-align: center;
    width: 100%;
    transition: 0.7s;
    cursor: pointer;

}

.inner-waper-in h3 {
    font-family: 'Bembo Regular' !important;
    font-size: 26px;
    color: #4c4442 !important;
    line-height: 1.3em;
    margin-bottom: 30px;
}

.inner-waper-in p {
    font-family: 'Bembo Regular' !important;
    line-height: 1.5em;
    color: #7E6E64 !important;
}

.position-ab:hover .inner-waper-in {

    transition: 0.7s;
    padding: 168px 40px;
}

.position-ab:hover .inner-text {

    transition: 0.8s;
    opacity: 1;
    margin-bottom: 0px !important;
}

.inner-waper-in .inner-text {
    opacity: 0;
    transition: 0.8s;
    margin-bottom: -110px;
}

.display-none-see-more #loadMore {
    display: none;
}

.inner-waper-in-moon {
    margin: 30px;
    padding: 14px;
    opacity: 0;

}

.position-ab:hover .inner-waper-in-moon {

    transition: 0.7s;
    padding: 46px 15px;
    opacity: 1;
}

.m-b-0 {
    margin-bottom: 0;
}

.color-yellow {

    font-size: 18px;
    color: #181818;
}

.color-yellow1 {

    font-size: 18px;
    color: #fff;
}

.underline-new {
    text-decoration: line-through;
}

.inner-waper-in-moon .inner-text {
    margin-bottom: -165px;

}

.m-t-75 {
    margin-top: -35px;
}

.mt-75-new {
    margin-top: 130px;
}

.mb-100-new {
    margin-bottom: 120px;
}

.margin-top-new-1 {
    margin-top: 300px;
}

.rich-pdn-resort {
    padding-top: 100px;
    padding-bottom: 100px;
}

.margin-mins-top {
    margin-top: -35%;
}

.mrgn-botm-0 {
    margin-bottom: 0px;
}

/* .gridrow > [class*="col-"] {
    padding: 22px;
} */


.pdn-22 {
    padding: 22px;
}

.magn-t20 {
    margin-top: 22px;
}

.magn-40 {
    margin-top: 40px;
}

.pdn-lr {
    padding: 0px 50px;
}

.margin-top-botm {
    margin: 130px 0px;
}

.magin-left-right-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.hover-btn:hover .inner-waper-in-moon {

    transition: 0.7s;
    padding: 46px 15px;
    opacity: 1;
}

.dropdown-toggle::after {
    margin-bottom: -0.19em;
    margin-left: .465em;
}

.margin-top-new-inner {
    margin-top: 115px;
    margin-bottom: 70px;
    padding-left: 0px;
    padding-right: 0px;
}

.around-mrgn {
    margin-top: 50px;
    margin-bottom: 30px;
}

.height-100 {
    height: 100%;
}

.width-70-moon-new-20 {
    padding: 20% 0px;
}

.testi-sction .bosspic {
    width: 170px;
    height: 170px !important;
    border-radius: 50%;
    margin: 0 auto;
    margin-top: 20px;
}

.img-widht-100 {
    width: 100%;

}

.the-expirience {
    margin-top: 130px;
    margin-bottom: 100px;
}

.new-line-text {
    padding-bottom: 0px;
    color: #595656 !important;
    font-style: italic;
    margin-bottom: 12px;
}

.mt-0 {
    margin-top: 0px;
}

.color-white a {
    color: #fff !important;
}



.bgcolor-change {

    animation: colorchange 20s linear 20s infinite;
    /* animation-name followed by duration in seconds
       /* you could also use milliseconds (ms) or something like 2.5s */
    -webkit-animation: colorchange 20s linear 0s infinite alternate;
    /* Chrome and Safari */
}

@keyframes colorchange {
    0% {
        background: #ffb3a6;
    }

    33% {
        background: #bdba70;
    }

    66% {
        background: #bda570;
    }

    100% {
        background: #a7bd70;
    }
}

@-webkit-keyframes colorchange

/* Safari and Chrome - necessary duplicate */
    {
    0% {
        background: #ffb3a6;
    }

    33% {
        background: #bdba70;
    }

    66% {
        background: #bda570;
    }

    100% {
        background: #a7bd70;
    }
}

.font-color-w {
    color: #fff !important;
}

.letter-spacing-50 {
    letter-spacing: 25px;
}

.new-font-size {
    font-size: 90px !important;
}

.font-size-dif {
    font-size: 24px;
}

.wedding-testimonial .bosspic {
    height: 500px;
    width: 500px;
    border-radius: 50% !important;
    margin-left: 100px;
}

.wedding-testimonial .bosspic1 {
    height: 550px;
    width: 500px;
    margin-left: -120px;

}


.testi-sctiontext {
    padding-top: 135px;
    padding-left: 170px;
}

.testi-sctiontext1 {
    padding-top: 100px;
    padding-left: 93px;
    z-index: 9;
}

.font-70 {
    font-size: 70px !important
}


.display-flex-section {
    display: flex;
    align-items: center;
}

.section-wedding-pdn {
    padding-top: 80px;
    padding-bottom: 80px;
    padding-right: 50px;
    border-top: 30px solid #fd538829;
    border-bottom: 30px solid #fd538829;
    border-right: 30px solid #fd538829;
    padding-left: 50px;
}

.left-mrg .left-2 {
    left: 2% !important;
}

.left-mrg .left-4 {
    left: 4% !important;
}

.left-mrg .right-2 {
    right: 2% !important;
}

.margn-top-2 {
    margin-top: 2% !important;
}

.left-mrg .row {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.new-col-6 {
    max-width: 46%;
}

.pading-0-new {
    padding: 0px !important;
}

.bg-white {
    background-color: #fff;
}

.pading-50 {
    padding: 50px;
}

.width-form {
    padding: 7% 0px;
}

.display-flex-end {
    display: flex;
    align-items: flex-end;
}

.wedding-form label {
    color: #7E6E64 !important;
    font-size: 17px;
    font-family: 'Bembo Regular' !important;
}

.wedding-form-btn {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: auto;
    outline: 0;
    font-size: 18px;
    line-height: 2em;
    font-weight: 400;

    padding: 9px 61px;
    color: #160808;
    background-color: #f0ede8;
    border: 1px solid transparent;
    border-radius: 0;
    cursor: pointer;
    font-family: 'Bembo Regular' !important;
    color: #7E6E64 !important;
}

.width-70-resort-new-is {
    padding-top: 10%;
    padding-bottom: 40%;
}

.offer-boxs .right-0 {
    right: 0px !important;
}

.margn-top-0 {
    margin-top: 0px !important;
}

.mrg-top-min {
    margin-top: -75px;
}

.dispay-center {
    display: flex;
    align-items: center;
}

.pdn-top-wedding-2 {
    padding-top: 100px;
}

.h1before img {
    width: 100%;
    opacity: 0.1;
}

.h1before {
    text-align: center;
    margin-bottom: -80px;
}

.slider-sub-text {

    margin: 0px auto;
}

.new-paralex .react-parallax-bgimage {
    transition: all 2s ease-out 0s;
}

.width-400 {
    width: 400px;

}

.float-r {
    float: right;
}

.pdn-right {
    padding-right: 120px;
}

.pdn-left {
    padding-left: 120px;
}

.marg-btm-30 {
    margin-bottom: 30px;
}

.mrg-bort-0 {
    margin-bottom: 5px !important;
}

.pdn-tb-homenew {

    padding: 10px 0px;

}

.first-box-homenew {
    margin-top: 40px;
}

.home_align_item_center {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.pl-new {
    padding-left: 140px;
}

.home_section_space {
    margin-top: 130px;
}

.about_sec_space {
    margin-top: 100px;
}

.padding-servis {
    padding: 10% 0px;
}

.padding-servis-top {
    padding: 15% 0px;
}

.home_section_space2 {
    margin-top: 170px;
}

.padn_map_new {
    padding: 10% 0px;
}

/*======================================= New Home page css start ==========================================*/
.tawk-custom-color.tawk-custom-border-color.tawk-button {
    border-radius: 0px 0px 0px 0px !important;
}

.home_sec_m_only_top {
    margin-top: 130px;
}

.home_display_none {
    display: none;
}

.servises-none {
    display: none;
}

.position-ab:hover .cta-underline-btn {
    opacity: 1;
    transition-delay: 2s;
}

.home_mt1 {
    margin-top: -75px;
}

.home_mt2 {
    margin-top: -45px;
}

.home_mt3 {
    margin-top: -77px;
}

.home_mt4 {
    margin-top: -42px;
}

.home_mt5 {
    margin-top: -73px;
}

.rich-container-pdn-only-t {
    padding-top: 130px;
}

.offer-box-top {
    margin-bottom: 30px;
    background-color: #f6f6f6;
    border-color: #fff;

}

.col-3.right-0-aboutus {
    right: 0% !important;
}

.about-us-step .multiple-carousel__arrow {
    top: 50% !important;
    ;
}

.about-newsteps {
    padding-top: 9%;
    padding-bottom: 10%;
}

.width-500 {
    width: 500px;
}

.row_about {
    justify-content: center;
    margin-bottom: 80px;
}

.md-img {
    width: 200px;
    margin-bottom: 30px;
}

.review-carsol .react-multi-carousel-item {
    width: 1000px !important;
    margin-bottom: 70px;
}

.review-carsol .react-multi-carousel-track li:first-child {
    margin-left: 0px !important;
}

.partner-carsol .react-multi-carousel-item {
    width: 150px !important;
    margin-right: 100px;
}

.partner-carsol .react-multi-carousel-list.specilist-item {
    justify-content: left;
}

.center-new-wedding {

    justify-content: center;
}

.offer-slider .slider-sub-text {
    margin: 30px auto;
    text-align: center;
    line-height: 110%;
    color: #ffffff;
}

.offer-slider .slider-largetext {
    margin: 0px auto;
    text-align: center;
    color: #ffffff;
}

.hint-popup {
    border: solid 1px #e9e9e9;
    background: #fff;
    width: max-content;
    position: absolute;
    top: 10px;
    z-index: 9999998;
    left: 10px;
    padding: 20px 28px 10px 28px;
    width: 25%;


}

.hint-popup .rich-inner-title {
    margin-bottom: 0;
}

.hint-popup .hint-text p {
    margin-top: 0 !important;
    font-size: 16px;
    text-align: center;
    font-family: 'Bembo Regular' !important;
    line-height: 1.6;
    font-style: italic;
}

.hint-popup .login-close-icon {
    float: none;
    font-size: 20px;

    cursor: pointer;
    background-color: transparent;
    padding: 0;
    border: 1px solid #7e6e64;
    border-radius: 66px;
    height: 30px;
    width: 30px;
}

.hint-popup a {
    font-size: 13px;
}

.img-hint {
    width: 60px;

}

.hint-popup .resortTag {
    margin-right: 0 !important;
    border: none;
}

.hint-popup .r-login-media-icons {

    padding: 10px 0;

}

/*======================================= New Home page css end ==========================================*/




@media screen and (min-device-width: 1200px) and (max-device-width: 1500px) and (-webkit-min-device-pixel-ratio: 1) {

    .width-70-new {
        width: 80%;
    }


    .mrn-top-new {
        margin-top: 36px !important;
    }



    .react-multi-carousel-item .bg-gray-boxs {
        background-color: #F5F3EE;
        padding: 12px 9px;
        height: 200px;
    }

    .react-multi-carousel-item .white-font-style {
        font-size: 16px;
        line-height: 22px;
    }

    .ov-header.is-fixed {
        position: fixed;
        top: -140px;
        transition: transform .3s;
    }

    .position-rel div:last-child {
        height: 244px !important;
    }

    .img-wrapper.position-rel .inner-waper-in-moon {
        height: auto !important;
    }

    .img-wrapper.position-rel .inner-waper-in-moon .inner-text {
        height: auto !important;
    }

    .inner-waper-in-moon {
        margin: 0px !important;
    }

    .position-ab:hover .inner-waper-in-moon {
        padding: 30px 15px;
    }

    .inner-waper-in h3 {
        margin-bottom: 12px;
    }

    .item.pos-rela.expericenimg {
        height: 220px !important;
    }

    .villas-img .img-wrapper div {
        height: 300px !important;
    }

    .offer-slider .slider-largetext {
        margin: 10px auto !important;
    }

    .testi-sctiontext {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 0px;
        padding-left: 100px;
    }

    .wedding-testimonial .bosspic {
        height: 400px !important;
        width: 400px !important;
    }

    .navbar-nav>li {
        padding: 0px 5px !important;
    }

    .testi-sctiontext1 {
        padding-top: 100px;
        padding-left: 40px;
        z-index: 9;
    }
}

@media screen and (min-device-width: 1390px) and (max-device-width: 1500px) and (-webkit-min-device-pixel-ratio: 1) {
    .ov-header.is-fixed {
        position: fixed;
        top: -140px;
        transition: transform .3s;
    }

}

.mrn-top-new {
    margin-top: 100px;
    padding: 0px;
}

.full-width-img {
    width: 100%;
    max-width: 100%;
    padding: 0px 20px;
}

.rich-container-pdn-t-n {
    padding-top: 130px;
    padding-bottom: 0px;
}

.owl-slide-text {
    padding: 0px;
}


.btn-height .cta-underline-btn.mt-0 {
    height: auto !important;
}

.btn-height .inner-text p {
    padding-bottom: 0px;
}

.btn-height .position-ab:hover .inner-waper-in-moon {
    padding: 25px 15px;
}

.bottomMenu {
    display: none;
    position: fixed;
    bottom: 0;
    width: 100%;

    background: transparent;
    z-index: 99999;

}

.bottomMenu2 {
    display: none;
    position: fixed;
    bottom: 0;
    width: 100%;

    background: transparent;
    z-index: 99999;

}

.form-offer-filter {
    display: flex;
    align-items: center;
    justify-content: center;

}

.form-offer-filter .form-inner {
    background-color: #fff;
    padding: 20px 20px;
    -webkit-box-shadow: 0px 0px 0px 1px #eaeaea;
    box-shadow: 0px 0px 0px 1px #eaeaea;
    display: flex;
}

.form-group {
    margin-bottom: 0px;
    margin-right: 10px;
}

.form-offer-filter .form-control {
    height: 35px;
    font-family: 'Bembo Regular' !important;
    font-size: 14px;
    color: #686262;
    border-radius: 0px;
    width: 200px;
}

.form-offer-filter .btn-primary {
    width: 102px;
    border-radius: 0px;
    color: #fff;
    background-color: #493737;
    border: 0px;
    font-family: 'Bembo Regular' !important;
}

.input-resort {
    width: 400px !important;
}

.home_section_space2-bot {
    margin-bottom: 130px;
}

.about-us-style-slider .slider-sub-text {
    color: #4c4442 !important;
    font-size: 26px;
    line-height: 1.4em;
    width: 70%;
    margin: 22px auto;
}

.margn-botm-review {
    margin-bottom: 130px;
}



.makeStyles-root-1 .MuiTextField-root {
    width: 93% !important;
    margin: 10px !important;
}

.MuiFormControl-root {
    width: 93% !important;
    margin: 10px !important;
}

.MuiInputLabel-formControl {
    color: #4C4442 !important;
    font-family: 'Bembo Regular' !important;
    font-size: 16px;
    font-weight: 400;
}

.MuiTypography-body1 {
    color: #4C4442 !important;
    font-family: 'Bembo Regular' !important;
    font-size: 16px;
    font-weight: 400;
}

.MuiFormLabel-root {
    color: #4C4442 !important;
    font-family: 'Bembo Regular' !important;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 13px;
}

.mrg-bort-0new {
    margin-bottom: 20px;
}

.MuiSelect-selectMenu {
    min-height: 2em !important;
}

.mrgn-mins {
    margin-bottom: -20px;
}

.mrg-bottom {
    margin-bottom: -15px;
}

.slide-pane__content form {
    padding-bottom: 100px;
}

.MuiFormControl-root.MuiTextField-root {
    width: 100% !important;
    margin: 10px 0px 20px 0 !important;

}

.submit-btn {
    text-align: center;
}

.submit-btn Button {
    padding: 6px 35px;
    border-radius: 0px;
    color: #fff;
    background-color: #493737;
    border: 0px;
    font-family: "Copperplate";
    box-shadow: none;
    border-radius: 30px;
}

.submit-btn Button:hover {
    background-color: #493737;
}

.MuiInputBase-input.MuiInput-input {
    font-family: 'Bembo Regular' !important;
}


/*********************************Exit Popup****************************************/

.stick_block_layer {
    background: rgba(0, 0, 0, 0.6);
    opacity: 0.7;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
    filter: alpha(opacity=70);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
}

/* 
CSS3 animation keyframes.
*/
@-webkit-keyframes zoomin {

    0% {
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }

    50% {
        -webkit-transform: scale(1.04);
        -ms-transform: scale(1.04);
        transform: scale(1.04);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

@-ms-keyframes zoomin {

    0% {
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }

    50% {
        -webkit-transform: scale(1.04);
        -ms-transform: scale(1.04);
        transform: scale(1.04);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }

}

@keyframes zoomin {

    0% {
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }

    50% {
        -webkit-transform: scale(1.04);
        -ms-transform: scale(1.04);
        transform: scale(1.04);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

/*********************************Exit Popup****************************************/


.popup-form input {
    font-family: 'Bembo Regular' !important;
    font-size: 16px;
    width: 100%;
    height: 36px;
    background-color: #f2f2f2;
    border: 1px solid #979797;
    margin-bottom: 7px;
    color: #7E6E64 !important;
}

.popup-form select {
    width: 100%;
    height: 36px;
    background-color: #f2f2f2;
    border: 1px solid #979797;
    font-family: 'Bembo Regular' !important;
    color: #7E6E64 !important;
}

.popup-form label {
    font-family: 'Bembo Regular' !important;
    color: #7E6E64 !important;
    font-size: 16px;
}

.submit-btn1 {
    width: 200px;
    border-radius: 0px;
    color: #fff;
    background-color: #493737;
    border: 0px;
    font-family: 'Bembo Regular' !important;
    box-shadow: none;
    height: 36px;
    margin-top: 5px;
}

.col-pdng-top {
    padding-top: 25px;
}

.body-new1 {
    font-family: 'Bembo Regular' !important;
    line-height: 1.5em;
    color: #393533 !important;
    font-size: 16px;
    padding-bottom: 5px;
}

.color-yellow {
    color: #181818 !important;
    font-size: 18px !important;
}

.bg-w {
    background-color: #fff;
}

.pdn-footer-new {
    padding: 50px;
}

.mrgn-bot-min {
    margin-bottom: -20px !important;
}

.MuiFormGroup-root.MuiFormGroup-row {
    justify-content: left;
}

.width-100-form .MuiFormControl-root {
    width: 100% !important;
    margin: 10px 0px !important;
}

.rich-container-pdn-only-tm {
    margin-top: 130px;
}

.p-0 {
    padding: 0px;
}

.bot-0 {
    margin-bottom: 0px;
}

.font-20 {
    font-size: 20px !important;
}

.benifit-p {
    margin-bottom: 0px;
    padding-bottom: 7px;
    font-size: 14px;
    font-family: 'Bembo Regular' !important;
    color: #999999 !important;
}

.benifit-p span {
    margin-right: 20px;
}

.new-pdn-benifits {
    padding: 20px 21px !important;
}

.new-ab {
    z-index: 9;
}

.font-size-impor {
    font-size: 40px !important;
    line-height: 115%;
}

.new-lefr {
    text-align: left !important;
    color: #fff !important;
}

.mrgn-left-offe {
    margin-left: 11% !important;
}

.position-ab .new-offerflex {
    margin: 0px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00000054;
    color: #fff;
}

.position-ab .new-offerflex .col-w {
    color: #ffffff !important;
    font-size: 24px !important;
}

.cta-underline-btn-offer:after {
    content: '';
    height: 0.9px;
    width: 126%;
    background-color: #ffe40f;
    display: block;
    margin-left: -11px;
}


.cta-underline-btn-offer {
    padding: 15px 0px;
    display: inline-block;
    position: relative;
    font-size: 12px;
    font-family: "Gotham Bold", sans-serif, sans;
    text-transform: uppercase;
    color: #7e6e64 !important;
    overflow: hidden;
    margin-top: 25px;
    letter-spacing: 0.5px;
    cursor: pointer;
}

.cta-underline-btn-offer a {
    padding: 20px;
}

.width-70-resort-new-water {
    height: 85vh;
    display: flex;
    align-items: center;
}

.header-p-0 {
    padding: 5px;
}

.header-top-row {
    margin: 0px 25px;

}

.header-top-row2 {
    margin-left: 25px;


}

.header-number {
    margin-right: 42px;
}

.number-h {
    font-family: "Gotham Bold", sans-serif, sans;
    font-size: 14px;
    letter-spacing: 1px;
    color: #fff !important;
    padding: 7px 0px;
    border-top: 1px solid#898989;
    border-bottom: 1px solid#898989;
    margin: 7px 0px;
}

.mali-header .pdn-20 {
    padding: 10px 0px;
}

.header-search-form {
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    height: 40px;
    border: none;
    width: 400px !important;
    font-family: 'Bembo Regular' !important;
    /* display: block; */
    color: #4c4442 !important;
    font-size: 14px;
    padding-left: 32px;
    margin-top: 5px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.header-s-icon {
    margin-left: 0px;
    margin-right: -19px;
    z-index: 9;
}

.padn-top-130 {
    padding-top: 150px;
    padding-bottom: 150px;
}

.width-57 {
    width: 55%;
}

.bgptn {
    position: absolute;
    left: 77%;
    z-index: -1;
    top: 40%;
}

.pos-rel {
    position: relative;
}

.img-logo img {
    height: 50px;

}

.rich-container-pdn3 {
    padding: 100px 0px;
}

.width-new1 img {
    width: 100% !important;
    margin-top: 100px;

}

.map-top-section {
    width: 70%;
    margin: 0 auto;
}

.pdn-btm {
    padding-bottom: 200px;
}

.h1beforeq {
    text-align: center;
    margin-bottom: 30px;
}

.h1beforeq img {
    text-align: center;
    width: 50px !important;
}

.margin-top-form {
    margin-top: 10px;
}

.margin-top-form2 {
    margin-bottom: 3px;
}

.name-resort {}

.margin-around-form {
    margin: 15px 0px !important;
}

.header-card-form {
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-card-form .MuiFormLabel-root {
    text-align: left;
    line-height: 1.5em;
}

.border-none-form {
    border-bottom: 0px !important;
}

.border-none-form .card-header {
    background-color: transparent;
    padding: 0px;
}

.border-none-form .card-body {
    text-align: left;
    padding: 30px 0px;
}

.margin-around-form3 {
    margin: 35px 0px !important;
    border-bottom: 1px solid #e1e1e1;
}

.margin-around-form3 legend {
    font-size: 18px;
    color: #1c222f !important;
    margin-bottom: 21px;
    font-weight: 400;
}


.offer-title-new {
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
}

.offer-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;

}

.card-header {
    border-bottom: 0 !important;
}

.about-us-step .react-multi-carousel-item {

    width: 151px !important;
    margin-top: 57px;
    margin-right: 64px;

}

#nav {
    position: fixed;
}

em {
    font-style: italic;
    color: #B2ACAA;
}

.width-70-resortb {
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.new-m-header {
    margin-bottom: 20px;
}

.rich-pdn-resort .container {
    padding: 0px;
}

/* .resort-offer-m{
    margin-top: 30px;
} */
.resort-room-m {
    margin-top: 285px;
}

.bg-img-bot {
    background-image: url(../images/orm-bg-bottm-01.png), url(../images/bot-01.png);
    background-repeat: no-repeat, no-repeat;
    background-position: bottom 0px right 0px, top 0px left 0px;

}

.bg-new-color-1 {
    background-color: #aeac55;
}

.bg-new-color-2 {
    background-color: #bda570;
}


.rich-padn-ex {
    padding-top: 0px;
    padding-bottom: 0px;
}


.benifit .accordion>.card>.card-header {
    padding: 0px;
}

.accordion>.card>.card-header {
    margin-bottom: 0px;
}

.benifit .card-body {
    padding: 20px 0px;
}

.btn.btn-link:focus {
    box-shadow: none
}

.benifit .card {
    justify-content: center;
    border-bottom: 1px solid #bcbcbc !important;
    border-radius: 0px !important;
    margin-bottom: 15px;
}

.benifit .card p {
    margin-bottom: 0px !important;
}

.benifit .card .btn {
    margin-bottom: 0px !important;
}

.benifit .accordion {
    margin-bottom: 35px;
}

.new-bot-resort {
    padding: 0px;
}

.margn-botm-of {
    margin-bottom: 50px;
}

.MuiPickersToolbar-toolbar {
    background-color: #7e6e64 !important;
}

.MuiPickersDay-daySelected {
    background-color: #969390 !important;
}

.MuiPickersToolbarText-toolbarTxt {
    color: #8b8b8b;
}

.width-70-resorti-new {
    height: 85vh;
    display: flex;
    align-items: center;
}

.rich-pading-bn {
    margin-bottom: 40px;
}

.wedding-description {
    color: #fff !important;
}

.pdn-top-left {
    padding-top: 80px;
    padding-left: 80px;
}

.padn-around {
    padding: 0px 40px;
}

.pdn-top-right {
    padding-top: 80px;
    padding-right: 80px;
}

.padn-around-new {
    padding: 0px 40px;
    padding-top: 120px;
}

.pdn-top-right-new {
    margin-top: -60px;
    padding-right: 80px;
}

.pdn-top-left-new {
    padding-top: 120px;
    padding-left: 80px;
}

.position-ab .new-offerflex-wedding {
    margin: 0px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(255 255 255 / 90%);
    /* color: #fff; */
    color: #4c4442;
}

.position-ab .new-offerflex-wedding .col-w {
    color: #4c4442 !important;
    font-size: 24px !important;
}

.logo-new-sec div {

    text-align: left !important;
    height: 116px;
    display: flex;
    align-items: center;

}

.menu-sec {
    /* height: 117px !important; */
    /* display: flex; */
}

.menu-sec .nav {
    height: 100% !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.end-menu {
    background-color: #7E6E64;
    display: flex !important;
    flex-direction: column;
    text-align: center;
    height: 100%;
    justify-content: center;
    width: 180px;
}

.header-enq {
    color: #fff !important;
    font-size: 12px;
    font-family: "Copperplate" !important;
    cursor: pointer;
}

.header-enq:hover {
    color: #8b8976 !important;
}

.carousel.slide.carousel-fade {
    position: relative;
}

.wedding-top-carousel .carousel-control-prev,
.wedding-top-carousel .carousel-control-next,
.wedding-top-carousel .carousel-indicators {

    display: none;

}

.wedding-top-carousel .carousel-item {
    min-height: 120px;
}

.pos-stiky {
    position: sticky;
    top: 0px;
    width: 100%;
    height: 700px;
    top: calc(50vh - 340px) !important;
}

.wedding-mt {
    margin-top: 200px;
}

.weddng-mt-m {
    margin-top: -95px;
}

.wedding-pb {
    padding-bottom: 130px;
}

.wedding-pdn-t-0 {
    padding-top: 0px !important;
}

.qodef-e-quote-svg-holder svg {
    width: 88px;
    fill: #bdb9b3;
}

.mb-wm {
    margin-bottom: -30px;
}

.left-right-mw {
    padding: 0px 45px;
}

.wedding-carosel-bit.slide p {
    opacity: 1 !important;
    text-align: right;
    margin-top: 40px;
    margin-right: 15%;
}

.wedding-carosel-bit.slide p:before {
    height: 0.8px;
    content: '';
    width: 80px;
    display: inline-flex;
    background-color: #707070;
    margin-right: 14px;
}

.pdn-22 {
    padding: 22px;
}

.wedding-pbn {
    padding-bottom: 50px;
}

.img-round-row {
    text-align: center;
    padding: 33px;
}


.mrgn-wc {
    margin-top: -21px;
}

.rich-pading-bn-new {
    border: 1px dashed #d5cdcd;
    border-radius: 50%;
    width: 280px;
    height: 280px;
    padding: -3px;
    margin: 0 auto;
    display: flex;
    margin-bottom: 40px;
    align-items: center;
    background-color: #fff;
}

.mrgn-wc .rich-pading-bn-new {
    width: 330px;
    height: 330px;
}

.specilist-section2 ul.react-multi-carousel-track:after {
    content: '';
    width: 100%;
    position: absolute;
    height: 1px;
    background-color: #fff;
    border-bottom: 1px dashed #d5cdcd;
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    top: 33%;
    z-index: -111;
}

.rich-pading-bnew {
    margin-bottom: 70px !important;
}

.testi-sctiontext12 {
    padding-top: 100px;
    padding-left: 128px;
    z-index: 9;
}

.pading-bot-new {
    padding-bottom: 100px;
}

.min-height-new {
    height: 260px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 20px;
    margin-bottom: 100px;
}

.min-height-new-new {
    align-items: flex-start;
}

.specilist-section2new ul.react-multi-carousel-track:after {
    content: '';
    width: 100%;
    position: absolute;
    height: 1px;
    background-color: #fff;
    border-bottom: 1px solid #d5cdcd;
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    top: 44%;
    z-index: -111;
}

.cursornew {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #000;
    opacity: 0 !important;
    transition: 0.1s;
}


#dragover:hover .cursornew {
    opacity: 1 !important;
}

.min-height-new h6 {
    user-select: none;
}

.width-70-resort-newabout {
    padding-top: 24%;
    padding-bottom: 25%;
}

.review-date {
    display: block;
    margin-top: 21px;
    color: #3a3532;
    font-family: "Gotham Bold", sans-serif, sans;
    font-size: 13px;

}

.min-height-new:after {
    content: '';
    width: 13px;
    height: 13px;
    background-color: #dcdcdc;
    display: block;
    position: absolute;
    border-radius: 50%;
    /* bottom: 13px; */
    top: 43%;
}

.searchbar {
    position: fixed;
}

.pdn-tb {
    padding: 50px 0px;
}

.MasonrySecroom li {
    width: 100%;
}

.margin-top-rooms {
    margin-top: 300px;
}

.blog-grod-text {

    padding: 0px 65px;

}

.blogs-grid .MasonrySec li:nth-child(even) .width-400 {
    float: right;
}

.blogs-grid .MasonrySec li:nth-child(even) .left-img {
    left: 44% !important;
}


.blogs-grid .react-parallax-bgimage {
    transition: all 2s ease-out 0s;
}

.MasonrySecex li:nth-child(1) .item {
    height: 197px !important;
}

.MasonrySecex li:nth-child(2) .item {
    height: 266px !important;
}

.MasonrySecex li:nth-child(3) .item {
    height: 196px !important;
}

.MasonrySecex li:nth-child(4) .item {
    height: 266px !important;
}

.MasonrySecex li:nth-child(5) .item {
    height: 196px !important;
}

.MasonrySecex li:nth-child(6) .item {
    height: 266px !important;
}

.MasonrySecex li:nth-child(7) .item {
    height: 266px !important;
}

.MasonrySecex li:nth-child(8) .item {
    height: 196px !important;
}

.MasonrySecex li:nth-child(9) .item {
    height: 196px !important;
}

.MasonrySecex li:nth-child(10) .item {
    height: 266px !important;
}

.MasonrySecex li:nth-child(11) .item {
    height: 266px !important;
}

.MasonrySecex li:nth-child(12) .item {
    height: 196px !important;
}

.MasonrySecex li:nth-child(13) .item {
    height: 266px !important;
}

.MasonrySecex li:nth-child(14) .item {
    height: 196px !important;
}

.MasonrySecex li:nth-child(15) .item {
    height: 196px !important;
}

.MasonrySecex li:nth-child(16) .item {
    height: 266px !important;
}

.MasonrySecex li:nth-child(17) .item {
    height: 196px !important;
}

.MasonrySecex li:nth-child(18) .item {
    height: 266px !important;
}

.MasonrySecex li:nth-child(19) .item {
    height: 266px !important;
}

.benefits-resort span {
    color: #7e6e64 !important;
    font-family: 'Bembo Regular' !important;
}

.benefits-resort div ul {
    margin-bottom: 20px;
    color: #000 !important;
    font-family: 'Bembo Regular' !important;
    line-height: 1.5em !important;
}

.benefits-resort div p {
    font-size: 18px;
    font-family: 'Bembo Regular' !important;
    color: #7e6e64 !important;
    line-height: 28px;
    letter-spacing: 0px;
    margin-bottom: 5px;
    margin-left: 10px;

}

.benefits-resort.underline-offer div p {
    text-decoration: underline;
    -webkit-text-decoration-color: #ffe40f;
    text-decoration-color: #ffe40f;
}

.benefits-resort div {
    margin-bottom: 40px;
}

button.rich-inner-title {
    margin-bottom: 0px !important;
}

.MasonrySecoffergrid li {
    width: 100%;
}

.budget-rate {
    font-family: "Gotham Bold", sans-serif, sans;
    color: #353232;
    font-size: 13px;
    margin-left: 0px;
}

.modal {
    z-index: 999999;
}

.modal-backdrop {
    z-index: 88888;
}


.img1-change {
    transition: all 3s ease 0s;
}

.link-img .imgn {
    opacity: 0;
    transition: opacity .3s ease-out, transform .6s cubic-bezier(.35, .18, .07, 1.04);
    transform: translateY(-10%) scaleY(1.1);
}

.link-img .imgn.active {
    opacity: 1;
    transform: translateY(0) scaleY(1);
}

.link-img .imgnr {
    opacity: 0;
    transition: opacity .3s ease-out, transform .6s cubic-bezier(.35, .18, .07, 1.04);
    transform: translateY(-10%) scaleY(1.1);
}

.link-img .imgnr.active {
    opacity: 1;
    transform: translateY(0) scaleY(1);
}

.link-img .imgne {
    opacity: 0;
    transition: opacity .3s ease-out, transform .6s cubic-bezier(.35, .18, .07, 1.04);
    transform: translateY(-10%) scaleY(1.1);
}

.link-img .imgne.active {
    opacity: 1;
    transform: translateY(0) scaleY(1);
}

.link-img .imgnv {
    opacity: 0;
    transition: opacity .3s ease-out, transform .6s cubic-bezier(.35, .18, .07, 1.04);
    transform: translateY(-10%) scaleY(1.1);
}

.link-img .imgnv.active {
    opacity: 1;
    transform: translateY(0) scaleY(1);
}

.link-img .imgnvl {
    opacity: 0;
    transition: opacity .3s ease-out, transform .6s cubic-bezier(.35, .18, .07, 1.04);
    transform: translateY(-10%) scaleY(1.1);
}

.link-img .imgnvl.active {
    opacity: 1;
    transform: translateY(0) scaleY(1);
}

.pdn-0-form {
    padding: 0px !important;
}

.stick_block_layer {
    z-index: 88888 !important;
}

#stickLayer {
    z-index: 99999 !important;
    width: 1000px !important;
}

.newsletter-form {
    padding: 0px !important;
    height: 600px;
    margin-top: 35px;
    background-color: #fff;
    margin-left: -75px;
}

.pdn-form {
    padding: 25px;
}

.form-fild-newsletter .MuiFormControl-root.MuiTextField-root {
    margin: 4px 0px !important;
}

.newsletterntn {
    margin-top: 25px !important;
    text-align: left;
}

.closeicon svg {
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
}

.closeicon {
    text-align: right;
    margin-bottom: -45px;
    position: absolute;
    right: -45px
}

.bg-new-honeymoon {
    background-color: #bda570;
}

.row-m-0 {
    margin: 0px;
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
    .rich-container-pdn {
        padding: 75px 0px;
    }

    .rich-pading-b {
        margin-bottom: 25px !important;
    }

    .new-font-style {
        font-size: 48px;
    }

    .new-header-description {
        font-size: 30px;
    }

    .width-70-moon-new {
        padding: 25% 0px;
    }

    .img-wrapper.position-rel div:last-child {
        height: 20vh !important;
    }

    .item.pos-rela.expericenimg {
        height: 200px !important;
    }

    .rich-inner-title {
        font-size: 22px;
        margin-bottom: 25px;
    }

    .body-new {
        font-size: 16px;
    }

    .padd-btm-15 {
        padding-bottom: 10px;
    }

    .cta-underline-btn {
        margin-top: 20px;
        font-size: 14px;
    }

    .margin-top-new {
        margin-top: 60px;
    }

    .pl-4,
    .px-4 {
        padding-left: 1rem !important;
    }

    .pb-4,
    .py-4 {
        padding-bottom: 1rem !important;
    }

    .pr-4,
    .px-4 {
        padding-right: 1rem !important;
    }

    .font-size-impor {
        font-size: 28px !important;
    }

    footer ul {
        display: flex;
        justify-content: space-around;
    }

    .rich-container-pdn-t-n {
        padding-top: 80px;
    }

    .font-size-dif {
        font-size: 22px;
    }

    .letter-spacing-50 {
        letter-spacing: 21px;
    }

    .rich-container-pdn-only-t {
        padding-top: 80px;
    }

    .bgptn {
        width: 100% !important;
    }
}

@media only screen and (min-width: 1157px) and (max-width: 1300px) {
    .header-top-row2 .col-md-5 {
        flex: 0 0 26.666667%;
        max-width: 26.666667%;
    }

    .header-top-row2 .col-md-7 {
        flex: 0 0 73.333333%;
        max-width: 73.333333%;
    }
}

.m-t-75-offer {
    margin-top: -210px;
}

.z-index-9 {
    z-index: 999;
}

.display-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
}

.display-flex-center .container {
    z-index: -1;
}

.wedding-mtn {
    margin-top: 24px;
}

.wedding-grid1 {
    margin-top: -50px;
}

.wedding-grid3 {
    margin-top: -80px;
}

.wedding-grid2 {
    margin-top: 50px;
}

.sliderimg {
    width: 100%;
    height: 500px;
    object-fit: cover
}

.width-100 {
    width: 100% !important;
}

.BrainhubCarouselItem img {
    border: 30px solid #fff;
    width: 100% !important;
}

.BrainhubCarousel {
    position: relative !important;
}

.BrainhubCarousel__arrows {
    position: absolute !important;
    padding: 85px !important;
    background-color: #ffffff !important;
}

.BrainhubCarousel__arrows:hover {
    background-color: #ffffff !important;
}

.BrainhubCarousel__arrows.BrainhubCarousel__arrowRight {
    height: 100%;
    right: 0px;
}

.BrainhubCarousel__arrows.BrainhubCarousel__arrowLeft {
    height: 100%;
    left: 0px;
    z-index: 9;
}

.BrainhubCarousel__arrows span {
    border-color: #949393 !important;
    border-width: 2px 2px 0 0 !important;
    padding: 22px !important;
}

.wedding-grid2new {
    margin-top: 20px;
    padding: 0px 177px;
}

@media only screen and (min-width: 200px) and (max-width: 760px) {

    .hint-popup {
        display: none;
    }

    .hero-img {
        display: none;
    }

    .mob-90-left {
        flex: 0 0 94%;
        max-width: 94%;
        margin-left: 6%;
        padding-left: 0px;
        padding-right: 0px;
    }

    .mob-90-right {
        flex: 0 0 94%;
        max-width: 94%;
        margin-right: 6%;
        padding-left: 0px;
        padding-right: 0px;
    }

    .mob-90-right .img-wrapper div {
        height: 220px !important;
    }

    .mob-90-left .img-wrapper div {
        height: 220px !important;
    }

    .mobile-mt-5.mt-5 {
        margin-top: 0px !important;
    }

    .home-page-ex .alice-carousel .alice-carousel__prev-btn {
        position: absolute;
        top: 44%;
        width: 17px !important;
        left: 20px;
        height: 17px !important;
        z-index: 9;
        padding: 0px !important;
        transform: translate(-50%, -50%) rotate(-135deg);
        border: solid;
        border-color: #949393 !important;
        border-width: 2px 2px 0 0 !important;
    }

    .home-page-ex .alice-carousel .alice-carousel__next-btn {
        position: absolute;
        top: 44%;
        width: 17px !important;
        right: 3px !important;
        height: 17px !important;
        z-index: 9;
        /* border-color: #484545!important; */
        /* border-width: 1px 1px 0px 0px!important; */
        padding: 0px !important;
        transform: translate(-50%, -50%) rotate(-316deg);
        border: solid;
        border-color: #949393 !important;
        border-width: 2px 2px 0 0 !important;
    }

    /* .new-ex-carasol{
        padding: 0px 20px!important;
    } */

    .alice-carousel .alice-carousel__prev-btn-item,
    .alice-carousel .alice-carousel__next-btn-item {
        padding: 12px !important;

    }

    .home-page-ex .alice-carousel .alice-carousel__prev-btn-item,
    .alice-carousel .alice-carousel__next-btn-item {
        padding: 8px !important;

    }

    .alice-carousel .alice-carousel__prev-btn {
        position: absolute;
        top: 44%;
        width: 17px !important;
        left: 7px;
        height: 17px !important;
        z-index: 9;
        padding: 0px !important;
        transform: translate(-50%, -50%) rotate(-135deg);
        border: solid;
        border-color: #949393 !important;
        border-width: 2px 2px 0 0 !important;
    }

    .alice-carousel .alice-carousel__next-btn {
        position: absolute;
        top: 44%;
        width: 17px !important;
        right: -10px !important;
        height: 17px !important;
        z-index: 9;
        /* border-color: #484545!important; */
        /* border-width: 1px 1px 0px 0px!important; */
        padding: 0px !important;
        transform: translate(-50%, -50%) rotate(-316deg);
        border: solid;
        border-color: #949393 !important;
        border-width: 2px 2px 0 0 !important;
    }

    .new-ex-carasol .alice-carousel__next-btn {
        display: block !important;
    }

    .new-ex-carasol .alice-carousel__prev-btn {
        display: block !important;
    }

    .resort-page-inner .cta-underline-btn {
        line-height: 20px !important;
    }

    .mobile-top-space {
        margin-top: 60px;
    }

    .new-mobile-margin .MuiSelect-root.MuiSelect-select {
        margin-top: 14px;
        padding-bottom: 13px;
    }

    .width-40-hard {
        width: 100% !important;
        padding-left: 20px;
        padding-right: 20px;
    }

    .new-pdn-hard-rock {
        padding-top: 10% !important;
        padding-bottom: 10% !important;
    }

    .mobile-font-sizehnew {
        font-size: 20px !important;
        padding: 0px 20px !important;
    }

    .mobilesvg svg {
        width: 35px;
    }

    .MuiMenuItem-root {
        white-space: pre-wrap !important;
    }

    .search-box {
        Display: none !important;
    }

    .search-text {
        display: none !important;
    }

    .search-box.showSearch {
        width: 75% !important;
        padding: 12px 18px !important;
    }

    .search-btn {
        height: 30px !important;
        font-size: 12px !important;
    }

    .about-r ul li:first-child {
        margin-left: 0px !important;
    }

    .img-about-team.bosspic1 {
        margin-left: 0px !important;
        height: 344px !important;
        width: 327px !important;
    }

    .pdn-btm {
        padding-bottom: 85px;
    }

    .h1beforeq img {
        width: 37px !important;
    }

    .md-img {
        width: 120px;
        margin-bottom: 60px;
    }

    .width-70-new.only-thing {
        padding: 30px !important;
    }

    .min-height-new {
        height: 100px;
    }

    .specilist-section2new {
        margin: 50px 0px;
    }

    .mobile-partner-m .new-carousol img {
        width: 65%;
    }

    .mobile-partner-m .new-carousol {
        text-align: center;
    }

    .mobile-flip .oder1 {
        order: 2;
    }

    .about_sec_space {
        margin-top: 0px;
    }

    .mobile-h {
        display: none;
    }

    .mob-100 {
        flex: 0 0 100%;
        max-width: 100%;
        padding-left: 0px;
        padding-right: 0px;
    }

    .mob-100 .img-wrapper div {
        height: 220px !important;
    }

    .margn-top-min {
        margin-top: 30px;

    }

    .cta-underline-btn {
        font-size: 14px !important;
        line-height: 1.4em;
    }

    .inner-waper-in.inner-waper-in-moon {
        display: none;
    }

    .owl-nav {
        display: none;
    }

    .width-70-new .home-para {
        font-size: 20px;
        padding: 0px;

    }

    .col-12 {
        padding-right: 30px !important;
        padding-left: 30px !important;
    }

    .body-new {
        font-size: 16px !important;
        line-height: 24px !important;
    }

    .resort-title-tag-main-wrapper .rich-offer-title {
        font-size: 26px !important;
    }

    .resort-name-tag .resortTag.deal-tag {
        top: 0px !important;
    }

    .h2,
    h2 {
        font-size: 24px;
    }

    .rich-inner-title {
        font-size: 20px !important;
    }

    .mobile-sub-h {
        padding-bottom: 4px;
        padding-top: 30px;
    }

    .home-para {
        font-size: 34px;
    }

    .marg-btm-30 {
        margin-bottom: 15px;
    }

    .first-box-homenew {
        margin-top: 40px;
    }

    .padd-btm-15 {
        padding-bottom: 20px;
    }

    #mainslider .fnt-100,
    #pkghotelslider .fnt-100 {
        font-size: 22px;
    }

    .slider-sub-text {
        font-size: 16px !important;
    }

    .mobile-top-50 {
        margin-top: 60px;
    }

    .the-expirience {
        margin-top: 30px;
        margin-bottom: 55px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .new-header-description {
        font-size: 16px;
        line-height: 26px;
        padding: 0px 30px;
    }

    .margin-top-new-inner {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .magin-left-right-0 {
        margin-left: 20px !important;
        margin-right: 20px !important;
    }

    .pdn-22 {
        padding: 10px;
    }

    .mob-12 {
        height: 164px !important;
        margin-top: 50px !important;
    }

    .mob-1 {
        height: 130px !important;

    }

    .mob-2 {
        height: 164px !important;
    }

    .mob-3 {
        height: 142px !important;
    }

    .mob-4 {
        height: 195px !important;
    }

    .mob-5 {
        height: 138px !important;
    }

    .mob-6 {
        height: 160px !important;
    }

    .mob-7 {
        height: 150px !important;
    }

    .mob-8 {
        height: 165px !important;
    }

    .mob-5 {
        height: 164px !important;
    }

    .mob-9 {
        height: 164px !important;
    }

    .mob-10 {
        height: 138px !important;
    }

    .mob-11 {
        height: 157px !important;
    }

    .width-70-moon-new {
        height: 30vh;
    }
    .width-70-moon-new-contact{
        margin-top: 220px;
    }

    .font-size-dif {
        font-size: 16px;
    }

    .letter-spacing-50 {
        letter-spacing: 7px;
    }

    .mob-heading2 {
        color: #7E6E64;
        font-size: 16px;
        line-height: 26px;
    }

    .mob-botm-p {
        padding-bottom: 50px !important;
        padding-top: 50px !important;
    }

    .width-70-resortb {
        height: 35vh;
    }

    .font-size-impor {
        font-size: 16px !important;
        line-height: 128%;
        margin-bottom: 25px !important;
    }

    .rich-pading-b {
        margin-bottom: 25px !important;
    }

    .rich-pdn-resort {
        padding-top: 30px;
        padding-bottom: 60px;
    }

    .margin-top-new {
        margin-top: 60px;
    }

    .gridrow>[class*="col-"] {
        padding: 0px !important;
    }

    .cta-underline-btn.color-white {
        margin: 0px;
    }

    .new-line-after.load-more-btn .cta-underline-btn {
        display: none;
    }

    .m-t-75-offer {
        margin-top: 0px;
    }

    .width-70-resortb .text-resort {
        padding: 0px 30px;
    }



    .white-font-style {

        font-size: 16px;
        line-height: 24px;
    }

    .m-t-75 {
        margin-top: 0px;
    }

    .footer-mobile .new-font-style {
        font-size: 22px !important;

    }

    .offer-slider .col-lg-12.owl-slide-text {
        margin-top: 0px;
        padding: 0px 0px;
    }

    .offer-slider .slider-sub-text {
        margin: 23px auto;
    }

    .offer-slider #mainslider.home .slide-overlay {
        top: 2%;
    }

    .mob-plr-10 {
        padding-left: 0px;
        padding-right: 0px;
    }

    #mainslider.home .owl-item .item {
        height: 47vh !important;
        margin: 0px 0px;
        position: relative;
        /* add this to enable absolute positioning of the overlay */
    }

    #mainslider.home .owl-item .item::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.3);
        /* semi-transparent black */
        /* or */
        /* background-image: url(your-overlay-image-url.png); */
    }

    .rich-pading-bn {
        margin-bottom: 20px;
    }

    .width-70-resorti-new {
        height: 35vh;
    }

    .width-70-resorti-new .heading-new.font-size-impor {
        margin-bottom: 0px !important;
    }

    .footer-mobile .col-md-12 {
        padding-bottom: 20px;
    }

    .rich-container-pdn-only-t {
        padding-top: 30px;
    }

    .width-70-resort-new-water {
        height: 35vh;
    }

    .bg-gray {

        padding: 0px 15px;
    }

    .form-container.pading-50 {
        padding: 30px 15px;
    }

    .MuiFormControl-root.MuiTextField-root {

        margin: 0px 0px !important;
    }

    .MuiGrid-grid-xs-6 {

        max-width: 100% !important;
        flex-basis: 100% !important;
    }

    .extra-m {
        display: block;
    }

    .form-container .h5heading {
        margin-bottom: 30px !important;
    }

    .width-form {
        padding: 15% 5px;
    }

    .wedding-testimonial .bosspic {
        margin-bottom: 30px;
        margin-top: 65px;
        margin-left: auto;
    }

    .wedding-testimonial .col-12 {
        padding-bottom: 115px;
    }

    /* .expericenimg {
    height: 102px!important;
} */
    .new-bot-resort {
        padding: 0px;
        margin-top: 70px;
    }

    .new-bot-resort .magin-left-right-0 {
        margin: 0px !important;
    }

    .rich-container-pdn-only-tm {
        margin-top: 60px;
    }

    .magn-t20 {
        margin-top: 0px;
    }

    .magin-left-right-0 .col-12 {
        margin-top: 60px;
    }

    .the-expirience .img-widht-100 {
        height: 100% !important;
        width: 100% !important;
    }

    .MasonrySecroom .margn-top-min-new {
        margin-top: 0px;
    }

    .margin-top-rooms {
        margin-top: 60px;
    }

    .offer-header.card-header {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .resort-offer-m {
        margin-top: 0px;
    }

    .MasonrySecroom .row {
        margin: 0px !important;
    }

    .gridrow {
        margin-right: 0px;
        margin-left: 0px;
    }

    .the-expirience .row {
        margin: 0px !important;
    }

    .MuiFormLabel-root {
        line-height: 1.4em !important;
    }

    img#logo {

        padding: 0 0px !important;
    }

    .margn-top-min-new {
        margin-top: 0px;
    }

    .privateIsland-ex .item1 {
        height: 135px !important;
    }

    .privateIsland-ex .item2 {
        height: 170px !important;
    }

    .privateIsland-ex .item3 {
        height: 149px !important;
        margin-top: 28px;
    }

    .privateIsland-ex .item4 {
        height: 160px !important;
    }

    .privateIsland-ex .item5 {
        height: 150px !important;
    }

    .privateIsland-ex .item6 {
        height: 135px !important;
    }

    .privateIsland-ex .item7 {
        height: 140px !important;
    }

    .privateIsland-ex .item8 {
        height: 135px !important;
    }

    .privateIsland-ex .pi-mt {
        margin-top: -65px;
    }

    .BrainhubCarousel__arrows {
        padding: 12px !important;
    }

    .BrainhubCarousel__arrows span {
        padding: 7px !important;
    }

    .BrainhubCarouselItem img {
        border: 4px solid #fff;
    }

    .wedding-grid2new {
        padding: 0px 30px;
    }

    .rich-pading-b-nw {
        margin-bottom: 60px;
    }

    .home_section_space {
        margin-top: 60px;
    }

    .bgcolor-change {
        margin-top: -26px;
    }

    .new-font-size {
        font-size: 26px !important;
    }

    .width-70-moon-new-20 {
        padding-bottom: 6% !important;
    }

    .no-pdn {
        padding: 0px !important;
    }

    .privateIsland-ex .home_mt1 {
        margin-top: -39px;
    }

    .home_mt1 {
        margin-top: 0px;
    }

    .home_mt2 {
        margin-top: 0px;
    }

    .wedding-mt {
        margin-top: 71px;
        margin-bottom: 67px;
    }

    .weddng-mt-m {
        margin-top: 25px;
    }

    .offer-slider.wedding-s #mainslider.home .slide-overlay {
        top: 25%;
    }

    .width-70-resort-newabout {
        height: 35vh;
        padding: 0px !important;
    }

    .h1beforeq {
        display: none;
    }

    .width-70-resort-newabout .text-resort {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .width-70-resort-newabout .text-resort .rich-pading-b {
        margin-bottom: 0px !important;
    }

}

@media only screen and (min-width: 762px) and (max-width: 1020px) {

    #mainslider.home .owl-item .item {
        height: 50vh !important;
    }

    .owl-theme .owl-dots,
    .owl-theme .owl-nav {
        display: none;
    }

    .bgptn {
        display: none;
    }

    .slider-sub-text {
        font-size: 30px;
    }

    .fnt-new-handwritten {
        font-size: 54px;

    }

    .door {
        top: 48%;
    }

    .home-para {
        font-size: 38px;
    }

    .width-400 {
        width: 300px;
    }

    .pdn-right {
        padding-right: 38px;
    }

    .width-70-moon-new {
        height: 40vh;
    }

    .width-70-resortb {
        height: 50vh;
    }

    /* .gridrow > [class*="col-"] {
        padding: 9px;
    } */
    .width-70-resorti-new {
        height: 42vh;
    }

    .img-wrapper div:last-child {
        height: 20vh !important;
    }

    .width-70-resort-new-water {
        height: 50vh;
    }

    .wedding-testimonial .bosspic {
        width: 300px !important;
        height: 300px !important;
        margin-left: 16px !important;
    }

    .pl-new {
        padding-left: 25px !important;
    }

    .width-form .mobile-h {
        display: none;
    }

    .width-form .mob-100 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .imgn {
        top: 54% !important;
        left: -8% !important;
        width: 300px !important;
    }

    .imgn div {
        height: 215px !important;
    }

    .pdn-left {
        padding-left: 55px;
    }

    .imgnr {
        width: 300px !important;
        top: 54% !important;
    }

    .imgnr div {
        height: 215px !important;
    }

    .imgne {
        top: 54% !important;
        left: -8% !important;
        width: 300px !important;
    }

    .imgne div {
        height: 215px !important;
    }

    .imgnv {
        width: 300px !important;
        top: 54% !important;
    }

    .imgnv div {
        height: 215px !important;
    }

    .imgnvl {
        top: 54% !important;
        left: -8% !important;
        width: 300px !important;
    }

    .imgnvl div {
        height: 215px !important;
    }

    .rich-padn-ex {
        padding-bottom: 0px;
    }

    .padn-top-130 {
        padding-top: 125px;
        padding-bottom: 100px;
    }

    .wedding-testimonial .bosspic1.home-img-saf {
        width: 377px;
        margin-left: -50px;
    }

    .testi-sctiontext1 {
        padding-top: 38px;
        padding-left: 20px;
        z-index: 9;
    }

    .width-57 {
        width: 80%;
    }

    .body-new.nobtmborder.rich-inner-title.text-center {
        font-size: 22px;
    }

    .testi-sctiontext {
        padding-top: 21px;
        padding-left: 33px;
    }

    .bosspic1.img-about-team {
        height: 401px !important;
        width: 365px !important;
    }

    .testi-sctiontext12 {
        padding-top: 35px;
        padding-left: 8px;
    }

    .mobile-h.about-us-ptn {
        height: 413px !important;
        width: 287px !important;
    }

    .width-500 {
        width: 330px !important;
    }

    .width-500 div {
        height: 475px !important;
    }

    .about-r ul li:first-child {
        margin-left: 0px !important;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1156px) {

    .pdn-footer-rieve {

        padding: 50px 70px;
    }

    .header-top-row2 .col-md-5 {
        flex: 0 0 20%;
        max-width: 20%;
    }

    .header-top-row2 .col-md-7 {
        flex: 0 0 80%;
        max-width: 80%;
    }

    .nav>li {
        padding-left: 7px;
        padding-right: 7px;
    }
}

.about-r ul li:first-child {
    margin-left: 70px;
}

.mobile-h.about-us-ptn {
    margin-top: -269px;
    margin-left: 60%;
}

.ct-pdn {
    padding: 0px 70px;
}

.search-box {
    position: fixed;
    bottom: 0;
    background-color: #fff;
    pointer-events: all;
    background-color: #FFFFFF;
    -webkit-box-shadow: 0px 0px 0px 1px #eaeaea;
    box-shadow: 0px 0px 0px 1px #eaeaea;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 40px;
    z-index: 99999;
    transition: width 2s;
}


.search-text {
    color: #7E6E64;
    font-family: "Copperplate" !important;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 13px;
    margin-bottom: -5px;
    margin-right: 40px;
    white-space: nowrap;
}



.search-input {
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
    border-bottom: 1px solid #ada5a1 !important;
    transition: width 2s;
}

.search-btn {

    position: relative;
    display: inline-block !important;
    font-size: 13px !important;
    font-family: "Copperplate" !important;
    color: #FFFFFF !important;
    background-color: #7E6E64 !important;
    text-transform: uppercase !important;
    height: 35px !important;
    width: 100%;
    border-radius: 0px !important;
    letter-spacing: 1px !important;
    margin-left: 40px !important;
    margin-top: 5px !important;
    width: 95px !important;
}

.search-input::-webkit-input-placeholder {
    font-size: 15px !important;
    font-family: "Copperplate" !important;
    color: #7e6e6478 !important;
}

.showSearch {
    opacity: 1;
}

.hideSearch {
    opacity: 0;
}

.display-none-btn {
    display: none !important;
}


.search-input:focus {
    outline: none;
}

.aboutUs {
    margin-top: -28px;
}


#search-box .search-result {
    overflow-x: auto;
    height: 170px;
    position: absolute;
    z-index: 9999999999999999;
    width: 471px;
    left: 75px;
    bottom: 90px;
}

#search-box .search-result ul {
    background-color: #fff;
    list-style: none;
    margin-bottom: 0px;


}

#search-box .search-result ul li a {
    font-family: 'Bembo Regular' !important;
    font-size: 16px;
    padding: 13px 5px;
}

#search-box .search-result ul li {
    height: 40px;
}

.close-btnv {
    margin-left: 40px;
    margin-top: 5px;

}

.close-btnv svg {
    -ms-transform: rotate(135deg);
    /* IE 9 */
    transform: rotate(135deg);
}

.border-a-p-none {
    border: none !important;
    padding: 0px !important;
}

@media only screen and (min-width: 100px) and (max-width: 600px) {

    .eid-offer-main-title {
        height: auto;
    }

    .sertified-text-footer {
        font-size: 7px !important;
    }

    body.tawk-mobile,
    html.tawk-mobile {
        display: none !important;
    }

    ._1bpcM {
        position: fixed !important;
        background-color: rgb(79, 206, 93) !important;
        color: rgb(255, 255, 255) !important;
        height: 64px !important;
        width: 64px !important;
        bottom: 0px !important;
        left: 13px !important;
        z-index: 9999999999999999 !important;
    }

    ._2iWL7 {
        position: fixed !important;
        width: 332px !important;
    }

    .fnt-12 {
        font-size: 14px;
    }

    #SnglOfrDesc.new-middle-pdn {
        padding: 30px 25px !important;
    }

    .rich-pdn-resort.mob-plr-10 {
        padding-left: 0px;
        padding-right: 0px;
    }


    .rich-inner-title {
        margin-bottom: 20px;
    }

    .margn-botm-of {
        margin-bottom: 30px;
    }

    .menu li .menu-item-has-children:before {
        padding: 15px;
        right: 0px;
        top: 12px;
        z-index: 9999999999;
    }

    .container.slider-sub-text {
        padding-right: 5px;
        padding-left: 5px;
        margin-top: 180px;
    }

    .padding-0-left {
        padding-left: 50px;
    }

    .padding-0-right {
        padding-right: 50px;
    }

    .rich-inner-title-resort {
        font-size: 20px !important;
    }

    .container.pad-t-20.footer-mobile {
        padding-right: 30px;
        padding-left: 30px;
    }

    .magin-left-right-0 .col-12 {
        padding-right: 0px !important;
        padding-left: 0px !important;
    }

    .magin-left-right-0 .col-12 .expericenimg {
        height: 200px !important;
    }

    .magin-left-right-0 .col-12 #SnglOfrDesc {
        padding: 30px 20px !important;
    }

    .newpd.padding-0-left {
        padding-left: 50px !important;
    }

    .newpd.padding-0-right {
        padding-right: 50px !important;
    }

    .menu.responsive-menu {
        width: 76%;
        top: 0px;
        padding-top: 35px;
        z-index: 888888888;
        padding-left: 30px;
        padding-right: 30px;
        box-shadow: 1px 1px 5px #5c54540d;
    }

    .header-s-icon .social-icon {
        margin: 10px;
        padding: 0px;
    }

    .header-s-icon {
        display: flex;
        width: 100%;
        padding: 16px 0px;
        
    }

    .header-s-icon .social-icon.social-icon-fb {
        margin-left: 0px;

    }

    .m-top-header {
        margin-top: 50px;
    }

    .heder-link-importat a {
        padding: 10px 0px !important;
        
        letter-spacing: 0px !important;
        font-family: 'Copperplate' !important;

        font-size: 14px !important;
    }

    .margin-bottm-logo {
        margin-bottom: 35px;
    }

    .owl-dots {
        margin-top: -25px;
    }

    .owl-theme .owl-dots .owl-dot span {
        border: 1px solid #f8f8f86e;
        background: transparent;
    }

    .owl-theme .owl-dots .owl-dot.active span,
    .owl-theme .owl-dots .owl-dot:hover span {
        background: #e3e3e3;
    }

    .slide-overlay.door.text-uppercase {
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 0 !important;
        transform: translate(0) !important;
        padding: 0px 27px !important;
    }

    .home-title-1 {
        font-size: 26px !important;
        margin-bottom: 15px !important;
    }

    .footer-mobile .white-font-style {
        font-size: 16px;
        line-height: 24px;
    }

    .footer-mobile .col-md-12.text-center {
        padding: 0px !important;
    }

    .footer-mobile .cta-underline-btn {
        margin-top: 12px;
    }

    /* .pading-lr-rich{
    padding: 0px 20px!important;
} */

    .rich-inner-title-resort.mobile-title-rich {
        font-size: 18px !important;
        color: #4c4442 !important;
    }

    .rich-inner-title.mobile-title-rich {
        font-size: 18px !important;
        color: #4c4442 !important;
    }

    .gridrow .col-lg-4 {
        margin: 0px 0px !important;
    }

    .MasonrySecex li:nth-child(12) .item {
        height: 164px !important;
        margin-top: 50px !important;
    }

    .MasonrySecex li:nth-child(1) .item {
        height: 130px !important;

    }

    .MasonrySecex li:nth-child(2) .item {
        height: 164px !important;

    }

    .MasonrySecex li:nth-child(3) .item {
        height: 142px !important;

    }

    .MasonrySecex li:nth-child(4) .item {
        height: 145px !important;

    }

    .MasonrySecex li:nth-child(5) .item {
        height: 138px !important;

    }

    .MasonrySecex li:nth-child(6) .item {
        height: 160px !important;

    }

    .MasonrySecex li:nth-child(7) .item {
        height: 150px !important;

    }

    .MasonrySecex li:nth-child(8) .item {
        height: 165px !important;

    }

    .MasonrySecex li:nth-child(9) .item {
        height: 164px !important;

    }

    .MasonrySecex li:nth-child(10) .item {
        height: 164px !important;

    }

    .MasonrySecex li:nth-child(13) .item {
        height: 138px !important;

    }

    .MasonrySecex li:nth-child(14) .item {
        height: 138px !important;

    }

    .MasonrySecex li:nth-child(15) .item {
        height: 150px !important;

    }

    .MasonrySecex li:nth-child(16) .item {
        height: 138px !important;

    }

    .MasonrySecex li:nth-child(17) .item {
        height: 142px !important;

    }

    .MasonrySecex li:nth-child(18) .item {
        height: 138px !important;

    }

    .MasonrySecex li:nth-child(19) .item {
        height: 195px !important;

    }

    .MasonrySecex li:nth-child(20) .item {
        height: 165px !important;

    }

    .MasonrySecex li:nth-child(11) .item {
        height: 165px !important;
    }

    .mob-80-new {
        padding: 0px 30px;
    }

    .ds-mob-80-new {
        padding: 0px 0px;
    }

    .mobile-header-80 {
        font-size: 28px !important;
    }

    .offer-header .rich-inner-title {
        font-size: 17px !important;
    }


    .new-margin-thexpiriance {
        margin-top: 10px !important;
        margin-bottom: 60px !important;
    }

    .home_mt3 {
        margin-top: -40px;
    }

    .container.rich-padn-ex {
        padding-right: 0px;
        padding-left: 0px;
    }

    .rigt-arrow-link {
        position: relative;
    }

    .rigt-arrow-link:after {
        content: '';
        border: solid #959595;
        border-width: 0 2px 2px 0;
        display: inline-flex;
        padding: 8px;
        /* flex-direction: column; */
        /* align-content: flex-end; */
        /* justify-content: flex-end; */
        position: absolute;
        top: 24px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        right: 6px;
    }

    .home-para {
        font-size: 22px !important;
    }

    .mobile-sub-h {
        margin-bottom: 12px !important;
    }

    .mobile-h3-18 {
        font-size: 18px !important;
    }

    .z-index-new {
        z-index: 999999999999;
    }

    .z-index-new .slide-pane {
        width: 100% !important;
    }

    .z-index-new .slide-pane_from_bottom {
        height: 100vh;
        margin-top: 15vh;
    }

    .z-index-new .slide-pane__close {
        width: 100%;
        text-align: center;
        margin-left: 0px;
    }

    .z-index-new .slide-pane__close svg {
        width: 16px;
    }

    .blogs-grid .MasonrySec .row {
        margin: 0 auto;
    }

    .MasonrySecroom #SnglOfrDesc {
        padding: 30px 0px !important;
    }

    .blogs-grid .pdn-tb {
        padding-bottom: 0px !important;
    }

    .z-index-new .slide-pane__header {
        width: 100%;
        display: block;
    }

    .z-index-new .slide-pane .slide-pane__content {
        padding: 30px;
    }

    .z-index-new .submit-btn Button {
        margin-bottom: 40px;
    }

    #b4etn1a6i4qo1fcsq6er5 iframe {
        bottom: 65px !important;
    }

    .mobile-form-btn {
        position: fixed;
        z-index: 99999;
        bottom: 0px;
        border: none;
        width: 100%;
        background-color: #fff;
        font-size: 14px;
        color: #7e6e64;
        font-family: "Copperplate";
        padding: 22px 30px;
        letter-spacing: 1px !important;
        text-align: left;
        border-top: 2px solid #d1d1d1;
    }

    .benefits-resort div p {
        font-size: 16px !important;
        line-height: 22px !important;
        letter-spacing: 0px !important;
        margin-bottom: -1px !important;
        margin-left: 10px !important;
    }

    .benefits-resort.underline-offer div p {
        text-decoration: underline;
        -webkit-text-decoration-color: #ffe40f;
        text-decoration-color: #ffe40f;
    }

    .benefits-resort div {
        margin-top: 20px;
    }

    .footer-mobile .col-md-12.text-center {
        display: none;
    }

    .cta-footer-action .cta-action-box svg {
        margin-bottom: 0px;
    }

    .cta-footer-action .cta-action-box .cta-underline-btn {
        margin-top: 0px !important;
    }


    #btnFormOpen {
        -webkit-animation: 3s ease 0s normal forwards 1 fadeinbtn;
        animation: 3s ease 0s normal forwards 1 fadeinbtn;
        animation-delay: 3s;
    }

    .width-100p {
        width: 80% !important;
    }

    .heading-new.mob-heading2-about {
        font-size: 16px !important;
        padding: 0 30px !important;
    }

    h1 {
        font-size: 26px !important;
        line-height: 32px !important;
    }

    h2 {
        line-height: 36px !important;
        font-size: 26px !important;
    }

    .row.pr-resortpage {
        width: 100% !important;
        margin: 0px;
    }

    .search-input-resort-page {
        margin: 0px 30px !important;
        margin-bottom: 15px !important;
        margin-top: 17px !important;
    }

    .blog-info-new {
        padding: 9px !important;
        padding-top: 0px !important;
        margin-top: 0px !important;
        font-family: 'Bembo Regular' !important;
        color: #7E6E64 !important;
        font-size: 10px !important;
    }

    .MasonrySecoffergrid .offer-header svg {
        width: 35px;
    }

    .mobile-what-we-love {
        margin-bottom: 10px !important;
        margin-top: 50px !important;
    }

    .offer-pdn-around {
        padding-right: 30px !important;
        padding-left: 30px !important;
    }

    .offer-pdn-around .offer-header svg {
        width: 35px;
    }

    header {
        z-index: 44444 !important;
    }

    .header-s-icon .cta-action-box .cta-underline-btn {

        letter-spacing: 0.5px !important;
        font-size: 9px !important;
    }

    .mobile-pane-padding .slide-pane__content {
        padding: 20px;
    }

    .my-anchor-css-class {
        display: none;
    }

    .overlay-dropdown[type=checkbox]:checked~ul.new-header-mobilemenu {
        height: 200px;
        overflow-y: auto;
    }

    .search-result-resort {
        top: -262% !important;
    }

    .close-iconResort {
        right: 68px !important;
    }

    .header-s-icon .text-center.cta-footer-action {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
    }

    .padng-bot-mobil {
        padding-bottom: 20px !important;
    }

    .wedding-testimonial .react-parallax-bgimage {
        left: 18% !important;
    }

    .rs-picker-daterange-calendar-group {
        height: auto !important;
        min-width: auto !important;
    }

    .rs-picker-daterange-menu .rs-calendar:first-child {
        border-right: none !important;
    }

    .rs-picker-daterange-content {
        width: 300px !important;
        text-align: center !important;
    }

    .rs-picker-toolbar-option {
        font-size: 11px !important;

    }

    .ag-range {
        margin-top: 50px !important;
    }

    .display-inline-flex {
        margin-left: 0px;
    }

    .col-6.padding-right-0 .card-header {
        padding: 0px 0px !important;
    }

    .desktop-btn {
        display: none;
    }

    .benefits-resort div {
        margin-bottom: 33px;
    }

    .mobile-btn {
        margin-top: 25px !important;
    }

    .z-index-new-room .slide-pane_from_bottom {
        height: 75vh;
        margin-top: 25vh;
    }

    .sub-menu-new.resortpage {
        overflow: visible;
    }


    .resortpage.sub-menu-new li a {
        font-family: "Copperplate";
        font-size: 13px !important;
        padding-left: 10px !important;
        padding: 10px 0;
        letter-spacing: 0.5px !important
    }

    .mobile-widtpp .row div {
        padding: 0px !important;
    }

    .mobile-widtpp .row {
        padding: 0px 30px;
        padding-left: 45px;
    }

    .rich-container-title.mobile-hed-p {
        padding-top: 60px;
        padding-bottom: 20px !important;
    }

    .rich-container-title-inner-1 {
        padding-bottom: 20px;
    }

    .mobile-widtpp .row {
        margin: 0px;
    }

    .container.pd {
        padding: 0px 30px !important;
    }

    .rich-container-title-top {
        padding-top: 50px;
    }

    .bg-wight {
        padding: 54px 0px !important;
        background-color: #fff;
    }

    .pdn-contactUS-30 {
        padding: 0px 30px !important;
    }

    .mob-r-sec {
        padding: 20px 0px;
    }

    .pdn-footer-rieve {
        width: 100%;
        margin: 0 auto;
        padding: 0px !important;


    }

    .icon-footer-r {
        width: 40px !important;
        height: 40px !important;

    }

    .footer-txt.font-17 {
        font-size: 15px !important;
    }

    .offer-title-mobile .offer-header .rich-inner-title {
        font-size: 16px !important;
        line-height: 21px !important;
        margin-bottom: -7px !important;
    }

    .offer-title-mobile .btn.btn-link {
        padding-right: 0px;
    }

    .row_about {
        margin-bottom: 40px;
    }

    .Foundation__Inner-sc-11tbro4-1.ciNwZX {
        max-width: 90% !important;
    }

    .review-icon-footer img {
        width: 11px !important;
    }

    .choise-img {
        width: 90px !important;
        margin-top: 3px;
    }

    .choise-imgq {
        width: 90px !important;
        margin-top: 4px !important;
    }

    .new-width-mobile-footer {
        width: 91% !important;
        margin-bottom: 30px !important;
    }
}


@media only screen and (min-width: 600px) and (max-width: 2500px) {
    ._1bpcM {
        display: none !important;
    }

    .new-pdn-top-ab {
        padding-top: 80px !important;
    }

    .reviess-sec {
        padding: 80px 0px !important;
    }

}

@media (max-width: 480px) {

    body.tawk-mobile,
    html.tawk-mobile {

        display: none;
    }

    body #n99ut3atcheo1fcj51itp {
        display: none !important;
    }

    .pdn-footer-rieve {
        justify-content: space-around !important;
    }
}

.my-anchor-css-class {
    font-size: 13px;
    letter-spacing: 0px;
    color: #64635d;
}

@media only screen and (min-width: 600px) and (max-width: 2500px) {
    .owl-dots {
        display: none;
    }

    .display-inline-flex {
        display: inline-flex;
        width: 100%;
        padding: 30px 0px;
        float: none;

    }

    .mobile-btn {
        display: none;
    }

    .mobile-form-btn {
        position: fixed;
        z-index: 99999;
        bottom: 0px;
        border: none;
        left: 0%;
        background-color: #fff;
        -webkit-box-shadow: 0px 0px 0px 1px #eaeaea;
        box-shadow: 0px 0px 0px 1px #eaeaea;
        font-size: 15px;
        color: #7e6e64;
        font-family: "Copperplate";
        padding: 23px 40px;
        letter-spacing: 1px !important;
    }

    .z-index-new-room .slide-pane {
        width: 30% !important;
        height: 75vh;
        margin-top: 25vh;
    }

    .z-index-new-room .sub-menu-new {
        overflow: visible;
    }

    .z-index-new-room .sub-menu-new li a {
        font-family: "Copperplate";
        font-size: 15px !important;
        padding-left: 10px !important;
        padding: 10px 0;
        letter-spacing: 0.5px !important;
    }

    .z-index-new-room .slide-pane__close {
        width: 100%;
        text-align: center;
        margin-left: 0;
    }


}

@keyframes fadeinbtn {
    0% {
        opacity: 0;
    }

    66% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeinbtn {
    0% {
        opacity: 0;
    }

    66% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.rs-btn.rs-btn-default.rs-picker-toggle {
    margin-top: 17px;
    border: none;
    border-bottom: 1px solid #a2a2a2;
    border-radius: 0px;
}

.rs-picker-toggle .rs-picker-toggle-placeholder {
    font-family: 'Bembo Regular' !important;
    color: #4d4d4d !important;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    color: #ffffff;
    border-color: #7e6e64 !important;
    background-color: #7e6e64 !important;
}

.rs-picker-toolbar-right-btn-ok {
    background-color: #7e6e64 !important;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: #1c1c1c !important;
    font-family: 'Bembo Regular' !important;
}



.m-t-rich-h {
    margin-top: 50px !important;
}

.search-input-resort-page {
    width: 100%;
    margin: 0px auto;
    margin-top: 0px;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
    border-bottom: 1px solid #ada5a1 !important;
    padding-bottom: 8px;
    font-family: 'Bembo Regular' !important;
    color: #4C4442 !important;
    font-size: 16px !important;
    position: relative;
    margin: 0px 55px;
    margin-bottom: 25px;
    margin-top: 29px;

}

.close-iconResort {
    position: absolute;
    right: 0px;
}

.close-iconResort svg {
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.search-input-resort-pag:focus {
    outline: none;
}

.search-input-resort-pag {
    font-family: 'Bembo Regular' !important;
    color: #4C4442 !important;
    font-size: 20px !important;
    padding-left: 21px;
    padding-top: 0px;
    padding-bottom: 0px;
    border: none;
    width: 80%;
}

.pr-resortpage {
    position: relative;
}

.search-result-resort {
    position: absolute;
    top: -194%;
    left: 0%;
    background-color: #fff;
    z-index: 999999999;
    width: 100%;
    box-shadow: 0px 0px 0px 1px #eaeaea;
    padding: 25px;
    overflow-x: auto;
    height: 180px;
}

.search-result-resort ul li {
    font-family: 'Bembo Regular' !important;
    padding: 8px 0px;
}

.search-result-resort ul li a {
    font-family: 'Bembo Regular' !important;
    font-size: 16px;

}

.search-result-resort ul {
    list-style: none;
}

.footerButtonOffer {
    position: fixed;
    bottom: 0px;
    background-color: #fff;
    pointer-events: all;
    background-color: #FFFFFF;
    -webkit-box-shadow: 0px 0px 0px 1px #eaeaea;
    box-shadow: 0px 0px 0px 1px #eaeaea;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 45px;
    z-index: 99999;
    font-size: 16px !important;
    left: 0%;
    color: #7E6E64;
    font-family: "Copperplate" !important;
}

.rs-picker-daterange.rs-picker-default {
    max-width: 98%
}


.rs-picker-menu.placement-bottom-start.rs-picker-daterange-menu {
    z-index: 99999999999999 !important;
}

.row.pr-resortpage {
    position: fixed;
    bottom: 0px;
    left: 0px;
    background-color: #fff;
    width: 35%;
    z-index: 999999;
    box-shadow: rgb(234 234 234) 0px 0px 0px 1px;
}

.blog-info-new {
    padding: 30px;
    padding-top: 0px;
    margin-top: -20px;
    font-family: 'Bembo Regular' !important;
    color: #7E6E64 !important;
    font-size: 14px;

}

.blog-info-new span {
    padding-right: 5px;
}

.offer-info-newsec {
    list-style: none;
    margin-bottom: 20px;
}

.padding-right-0 {
    padding-right: 0px;
}

.padding-left-0 {
    padding-left: 0px;
}

.ag-range {
    display: flex;
    align-items: center;
    margin-top: 23px;
    justify-content: center;
}

.ag-range .ag-range-inner {
    padding-right: 20px !important;
    margin: 0px !important;
}

.accordion:nth-last-child(1) {
    border: none !important;
}

.col-6.padding-right-0 .card-header {
    padding: .75rem 0;
}


.highlight.menu-item-has-children:before {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transition: all .2s ease-in-out;

}


.rs-picker-toolbar-ranges {
    display: none !important;
}

.icon-footer-r {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #eeeeee;
    border-radius: 50%;
    margin-right: 20px;
    margin: 9px auto;
}

.review-icon-footer img {
    width: 17px;
}

.review-icon-footer {
    margin-top: 0px;
}

.sertified-text-footer {

    display: block;
    color: #7f7e7d !important;
    text-transform: uppercase;
    text-align: left;
    font-size: 9px;
    padding-bottom: 0;
    margin-top: 0px;
}

.flex-f-i {
    text-align: center;
}

.footer-txt.font-17 {
    font-size: 17px;
}

.pdn-footer-rieve {
    padding: 50px 200px;
    justify-content: space-between;
}

.highlight2 svg {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transition: all .2s ease-in-out;
}

.close-btn-acc svg {
    transition: all .2s ease-in-out !important;
}

.close-btn-acc1 svg {
    transition: all .2s ease-in-out !important;
}

.close-btn-acc2 svg {
    transition: all .2s ease-in-out !important;
}

.close-btn-acc3 svg {
    transition: all .2s ease-in-out !important;
}

.close-btn-acc4 svg {
    transition: all .2s ease-in-out !important;
}

.choise-img {
    width: 115px;
    margin-top: 3px;
}

.choise-imgq {
    margin-top: 1px;
}

.eapps-widget-toolbar {
    display: none !important;
}

.Foundation__Inner-sc-11tbro4-1.ciNwZX {
    max-width: 80% !important;
}

a[href="https://elfsight.com/all-in-one-reviews-widget/?utm_source=websites&utm_medium=clients&utm_content=all-in-one-reviews&utm_term=localhost:3000&utm_campaign=free-widget"] {
    display: none !important;
}

a[href="https://elfsight.com/all-in-one-reviews-widget/?utm_source=websites&utm_medium=clients&utm_content=all-in-one-reviews&utm_term=www.ovholidays.com&utm_campaign=free-widget"] {
    display: none !important;
}




.ggPruH {
    color: rgb(255, 255, 255) !important;
}

z .kJdqZS {
    padding: 15px 0px;
    display: inline-block;
    position: relative;
    font-size: 12px;
    font-family: "Gotham Bold", sans-serif, sans !important;
    text-transform: uppercase;
    color: #7e6e64 !important;
    overflow: hidden;
    margin-top: 30px;
    letter-spacing: 0.5px;
    cursor: pointer;
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color: #795548 !important;
}

.MuiFormControl-root.MuiTextField-root .MuiInputBase-root input:-internal-autofill-selected {
    background-color: #dadada !important;
}


/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.bold-budget {
    font-family: "Gotham Bold", sans-serif, sans;
    color: #2B201A !important;
    text-transform: uppercase;
    text-align: left;
    font-size: 13px;
    padding-bottom: 0;
}

.benefits-resort a {
    font-family: 'Bembo Regular' !important;
}

.MuiFormLabel-root {
    line-height: 1.3em !important;
}

.-active {
    display: none !important;
}

.-activeNew {
    margin-bottom: -251px !important;
}


.mobile-benifit .card-header {
    padding: 0px;

}

.mobile-benifit {
    margin-bottom: 35px;
}

.mobile-benifit .card-body {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.arrow {
    display: inline-block;
    width: 11px;
    height: 11px;
    border-top: 2px solid #7e6e64;
    border-right: 2px solid #7e6e64;
    margin-left: 9px;
    margin-bottom: -5px;
}

.arrow-top {
    transform: rotate(-45deg);
}

.blog-img-mobil {
    height: 269px;
}


.loading-aim {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999997;

}

.loading-aim2 {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blink_me {
    animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}


.exit-intent-popup {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 99;
    background: rgba(33, 33, 33, 0.8);
    visibility: hidden;
    transition: transform 0.3s;
}



.exit-intent-popup.visible {
    visibility: visible;
}

.newsletter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 440px !important;
}
.newsletter  .col-md-12{
    padding-left: 50px !important;
    padding-right: 50px !important;

}

/* Popup-specific styles */
.newsletter {
    background: transparent;
    padding: 10px;
    border-radius: 3px;
}

.close {
    position: absolute;
    top: 60px;
    right: 20px;
    cursor: pointer;
    width: 20px;
    height: 20px;
}

.covid-popup {

    display: flex;
    flex-direction: column;
    text-align: left;
    width: 500px;
    align-items: baseline;
    padding: 30px 30px;
    z-index: 99999;
    background-color: #fff;
    box-shadow: 0px 0px 0px 1px #eaeaea;
}

.nightSelect {
    width: 100%;
    margin-top: 7px;
}

.tel-nomber-sec {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    margin-bottom: 14px;
}

.tel-nomber-sec p {

    height: 100%;
    align-items: center;
    /* width: 45px; */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f1f1f1;
    padding: 0px 13px;
    color: #5a5049;
    font-family: 'Bembo Regular' !important;
    font-size: 17px;
}

#telephone_no {
    padding-left: 7px;
}

.tel-nomber-sec .MuiFormControl-root.MuiTextField-root:after {
    content: '';
    width: 20px;
    height: 33px;
    background-color: #fff;
    display: inline-flex;
    position: absolute;
    right: 0;
}

.budget-value-info {
    text-decoration: underline;
    text-decoration-color: #ffe40f;
    font-size: 18px;

}


.erroe-msg {
    color: #e02a2a !important;
    font-family: 'Bembo Regular' !important;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 13px;
    width: 100%;

}

.erroe-msg li {
    margin: 20px;
}


.button {
    background-color: #008cba;
    /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
}

.button:disabled {
    opacity: 0.5;
}


.spinnerSubmit {
    margin-right: 12px;
}

.spinnerSubmitactive {
    display: block;
    margin-right: 12px;
}

.spinnerSubmitinactive {
    display: none;
}

.MuiMenuItem-root {
    font-family: 'Bembo Regular' !important;
}

.mobilenumberInput .rs-input-number-btn-group-vertical {
    display: none !important;

}

.mobilenumberInput {
    border: none !important;
    border-bottom: 1px solid #9f9d9c !important;
    border-radius: 0px !important;
    font-family: 'Bembo Regular' !important;
}



.mobilenumberInput2 {
    border: none !important;
    border-bottom: 1px solid #9f9d9c !important;
    border-radius: 0px !important;
    margin-right: 20px;
    font-family: 'Bembo Regular' !important;
}

.display-flex-form {
    display: flex;

}

.flex-50-form {
    width: 60%;
    margin-right: 2%;
}

.ag-range {
    margin-top: 34px;
}


@media (max-width: 680px) {


    .row-tearm {
        padding: 20px;
    }

    .row-tearm-upper {
        padding: 20px !important;
    }

    .flex-50-form {
        width: 45%;
        margin-right: 4%;
    }

    .display-flex-form .ag-range {
        margin-top: 23px !important;
    }

    .mobilenumberInput2 {
        margin-right: 0px !important;
    }

    .mr-new-right {}

    .display-flex-form {
        margin-top: 25px;
    }

    .mr-new-right {
        margin-right: 5px !important;
    }

    .left-side-form {
        width: 55%;
    }

    .mr-right-form {
        margin-right: 12px;
    }
}

@media screen and (min-width: 767px) {
    .whatsapp_float {
        display: none !important;
    }

}

/* for desktop */
.whatsapp_float {
    position: fixed;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.whatsapp-icon {
    margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {

    .the-expirience.home-page-s {
        margin-top: 0px !important;
    }

    .whatsapp-icon {}

    .whatsapp_float {


        bottom: 17px;
        left: 44%;
        z-index: 999999999999999999;
        padding: 4px 26px;
        box-shadow: none;
        opacity: 0;


    }


    .whatsapp_float.show-mobile {
        text-transform: uppercase;
        opacity: 1;
        font-size: 14px;
        color: #7e6e64;
        font-family: "Copperplate";
        letter-spacing: 1px;
        color: #7e6e64 !important;
    }
}


.display-flex-form .ag-range {
    margin-top: 23px !important;
}

.left-side-form {
    width: 55%;
}

.mr-right-form {
    margin-right: 12px;
}

.flex-50-form {
    width: 45%;
    margin-right: 4%;
}

.MuiSelect-select:focus {
    background-color: transparent !important;
}


.mobile-one-new .slide-pane__content {
    margin-bottom: 50px !important;
}

.pop-up-img-resort {
    border-radius: 50%;
    top: -10px;
    left: -26px;
}

.popup-mrgn-top {
    margin-top: 8px;
}

.new-tearm .card {

    background-color: transparent !important;

}

.new-tearm {
    padding: 50px 20px;
    margin-top: 50px;
}

.height-rearm {
    height: 50px;
}


.width-40-hard {
    width: 50%;
}

.new-pdn-hard-rock {
    padding-top: 7%;
    padding-bottom: 20%;
}

.end-start-class {
    width: 50%;
    float: right;
    padding: 40px 20px;
    border: 1px dashed #b5b0ad;
}

.justify-center {
    justify-content: center;
}


.resort-page-new-pb {
    padding-bottom: 0px !important;
}


.search-option-resort {
    display: flex;
    align-items: center;
    justify-content: center;
    
    margin-left: 15px;
    margin-right: 15px;


}

.search-offer-resort {}

.search-option-resort .search {
    width: 100%;
    position: relative;
    border-bottom: 1px solid #fff;
}

.search-option-resort .searchInputs {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-right: 40px;
}
.search-option-resort .data{
   
    overflow: initial;
   
}
.search-option-resort .dataResult {
    position: absolute;
    max-height: 160px;
    padding: 25px;
    z-index: 999999999999;
    overflow: auto;
    background-color: #fff;
    width: 160%;
    box-shadow: 0px 89px 68px #2a292826;
    transition: all .4s ease;
    -webkit-transition: all .4s ease;
    right: 0%;
    top: 202%;
}

.search-option-resort .data::after {
    width: 0px;
    content: '';
    position: absolute;
    top: 31px;
    left: 0;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #ffffff transparent;
}
.search-option-resort .data::before {
    content: '';
    position: absolute;
    top: 31px;
    left: 0;
    border-width: 11px;
    border-style: solid;
    border-color: transparent transparent rgba(0,0,0,0.1) transparent;
} 


.search-option-resort .dataResult a {

    font-size: 15px !important;
    padding-left: 10px !important;
    padding: 10px 5px;
    letter-spacing: 0.5px !important;
    transition: all .4s ease;
    -webkit-transition: all .4s ease;
}

.search-option-resort .dataResult a p {
    font-family: "Copperplate" !important;
    color: #8b8976 !important;
}

.search-option-resort .dataResult a p:hover {
   font-weight: bold;
}

.search-option-resort input {
    width: 100%;
    /* height: 50px; */
    border: none;
    outline: none;
    font-family: "Copperplate" !important;
    font-size: 16px;
    background: none !important;
    color: #fff;
    letter-spacing: 1px;
}



.searchIcon{
    color: #fff;
}



.search-option-resort input:focus-visible {
    border: none;
    outline: none;
}

.search-option-resort input:focus:not(:focus-visible) {
    outline: 0;
}

.search-option-resort input::placeholder {
    color: #fff !important;
    font-family: 'Bembo Regular' !important;
    font-size: 18px;
    text-transform: inherit !important;

}

.z-index-resort {

    position: relative;
}

.nav-link-resort {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (min-width:667px) {
    .dextophide {
        display: none;
    }
}

@media (max-width:667px) {

    .eid-button-mg label+.MuiInput-formControl {
        margin-top: 44px !important;
    }

    .only-dextop {
        display: none;
    }

    .search-offer-resort {
        margin-bottom: 70px !important;
    }

    .search-option-resort {
        margin-top: 45px;
        margin-bottom: 44px;
    }

    .mobile-search-pl {
        font-size: 16px !important;
        color: #4c4442 !important;
        font-family: 'Bembo Regular' !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #c9c2be;
        padding: 4px 9px;
        width: 68%;
    }

    .mobile-pdn-lr-blog {
        padding-left: 30px;
        padding-right: 30px;
    }

    .pdn-new-blog .Masonry-item {
        padding: 8px !important;
    }

    .mobile-pdn-lr-blog .pdn-new-blog .item.pos-rela.expericenimg {
        height: 181px !important;
    }

}

.pdn-new-blog .Masonry-item {
    padding: 22px;
}


.blog-date-grid {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    font-family: 'Bembo Regular' !important;
    color: #7e6e64 !important;
    font-size: 14px;
    padding-top: 0px;
    font-style: italic;
    margin-bottom: 12px;
}

.blog-date-grid .MuiSvgIcon-root {
    width: 16px;
    color: #adadad;
    margin-right: 8px;
}

.slidebar-section .right-pdn0 {
    right: 0% !important;
}

.insta-space {
    padding-top: 22px;
    margin-bottom: 80px;
}

.es-free.table-cell {
    display: none !important;
}

.side-img-section {
    width: 85%;
    float: right;

}

.z-index-9 {
    z-index: 9;
}

.display-block-blog {
    display: block;
}

.width-blog-60 {
    width: 60%;
}

.mt-5-blog {
    padding-top: 130px;
}

.clear-both {
    clear: both;
}

.side-img-section2 {
    width: 45%;
    float: right;
    margin-top: -100px;
}

.padding-left-30-blog {
    padding-left: 30px;
}


.side-img-section3 {
    width: 33%;
    float: left;
    margin-top: 130px;

}

.side-img-section6 {
    width: 33%;
    float: left;
}

.side-img-section11 {
    width: 33%;
    float: left;
    margin-top: 50px;
}


.width-70-moon-new-blog {
    height: 350px;
}

.width-70-moon-new-blog1 {
    height: 65vh;
}

.side-img-section4 {
    width: 33%;
    float: right;
    margin-top: 200px;
}

.mt-blog-20 {
    margin-top: 200px;
}

.floating-s-icons {
    position: fixed;
    left: 0;
    top: 50%;
    display: flex;
    flex-direction: column;
    gap: 7px;
    padding-left: 11px;
}

.margin-top-blog-100 {
    margin-top: 110px;
}

.blog-mt-min-100 {
    margin-top: -130px;
    padding: 0px;
}

.blog-0-pdn-min-mt {
    padding: 0px;
    margin-top: -160px;
}

.blog-m-min-top {
    margin-top: -300px;
}

.blog-m-min-top-lt {
    margin-top: -420px;
}

.blog-m-min-top-last {
    margin-top: -400px;
}


@media only screen and (min-width: 1200px) and (max-width: 1640px) {
    .side-img-section {
        width: 90%;

    }

    .blog-single-page .position-rel div:last-child {
        height: 280px !important;
    }

    .blog-0-pdn-min-mt {

        margin-top: -39%;
    }

    .blog-m-min-top {
        margin-top: -33%;
    }

    .side-img-section3 {
        width: 36%;
    }

    .blog-single-page .padd-btm-15 {
        padding-bottom: 20px;
    }

    .blog-single-page .blog-pdn-btm-0 {
        padding-bottom: 0px !important;
    }
}

@media only screen and (min-width: 200px) and (max-width: 600px) {

    div[aria-labelledby="get_budgects_value standard-required"] {
        margin-top: 22px !important;
    }




    .staff-mobile-2 .mob-1 {
        height: 310px !important;
    }

    .staff-mobile-2 .alice-carousel__dots {
        display: block !important;
    }


    .staff-mobile-2 .alice-carousel__dots {
        margin: -30px 38px 5px;
        padding: 0;
        list-style: none;
        text-align: left;
    }

    .staff-mobile-2 {
        margin-bottom: 30px;
    }

    .desktop-space-around {
        padding-top: 50px !important;
        padding-bottom: 50px !important;
    }

    .frensh-section {
        padding-top: 50px !important;
    }



    .alice-carousel__dots-item:not(.__custom):not(:last-child) {
        margin-right: 8px !important;
    }

    .alice-carousel__dots-item:not(.__custom):hover,
    .alice-carousel__dots-item:not(.__custom).__active {
        background-color: #7e6e64 !important;
    }

    .title-bot.mb-5 {
        margin-bottom: 20px !important;
    }

    .ne-h.rich-inner-title {
        font-size: 16px !important;
        padding: 0px 21px;
    }

    .popup-content {
        width: 94% !important;
    }

    .best-offer-landing {
        padding: 0px 30px;
        margin-bottom: 30px;
    }

    .best-offer-landing p {
        padding-bottom: 5px !important;
    }

    .offer_resort_packages .mt-3-new-offer-1 .text-left {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
    }


    .offer_resort_packages .mt-3-new-offer-1 .text-left h1 {
        font-size: 33px !important;
        padding: 0px !important;
        margin-top: 30px !important;
    }


    .offer_resort_listing .mobile-search-pl {
        width: 80%;
    }

    .modal-new {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }


    .girls-blog .mob-100 {
        padding: 30px !important;
    }

    .girls-blog h2 {
        line-height: 1.1em !important;
    }


    .padding-mt-30 {
        padding: 30px !important;
    }

    .col-12.blog-map-center.mb-5 {
        padding-right: 0px !important;
        padding-left: 20px !important;
        display: block;
        margin-top: 30px !important;
    }

    .about-us .row {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .about-us .rich-pading-bnew {
        margin-bottom: 8px !important;
    }

    .about-us .new-font-style.space-h1 {
        text-align: left !important;
        padding: 10px 0px !important;
    }

    .about-us .about-us-headers:nth-child(3):after {
        display: none !important;
    }

    .about-us .about-us-headers:nth-child(1) {
        padding-left: 42px !important;
    }

    .about-us .about-us-headers:nth-child(2) {
        padding-left: 80px !important;
    }

    .about-us .about-us-headers:nth-child(3) {
        padding-left: 126px !important;
    }

    .about-us .about-us-headers:nth-child(4) {
        padding-left: 50px !important;
    }

    .about-us .about-us-headers:nth-child(5) {
        padding-left: 30px !important;
    }

    .mob-80-new .col-md-5 div {
        height: 230px !important;
    }

    .mob-80-new .col-md-6 {
        margin-top: 40px !important;
    }

    .flex-order-2-about {
        display: flex;
        flex-direction: column;
    }

    .flex-order-2-about .col-md-6 {
        order: 2;
    }

    .about-us .mb-5-ab {
        margin-bottom: 3em;
    }

    .mobile-about-top-secnd {
        margin-top: 40px;
    }

    .rich-container-pdn-top-carosol {
        padding-top: 8px !important;
    }

    .about-us .pdn-mobile-30 {
        padding: 10px 40px !important;
    }

    .mt-5-about {
        margin-top: -10px !important;
        margin-bottom: 30px;
    }

    .about-partner .new-carousol {
        padding: 0 !important;
    }

    .react-multi-carousel-item {
        padding: 0 !important;
    }

    .pdn-mobile-30 div {
        height: 380px !important;
    }

    .img-wrapper.mt-3-new-offer {
        margin-top: 60px !important;
    }

    .mt-3-new-offer-min {
        margin-top: 0px !important;
    }

    .new-offer-price.new-font-style {
        text-align: left !important;
    }


    .holiday-offer .mobile-widtpp .row {
        padding: 0px 1px;
        padding-left: 0px;
    }


    .holiday-offer .resort-page-new-pb {

        padding-bottom: 70px !important;
    }

    .container.rich-container-offer {
        padding-top: 70px !important;
    }

    .m-pdn-20 {
        padding: 20px;
    }

    .thank-you-content {
        padding: 20px;
        margin: 0px;
    }

    .holidy-m {
        margin-top: 80px !important;
    }

    .holidy-col-m {
        margin-top: 30px;
    }

    .holiday-offer .search-option-resort {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .holiday-offer button {
        padding: 0px;
    }

    .holiday-offer button svg {
        width: 36px;
    }

    .eid-early-blog .col-5 {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }

    .eid-early-blog .col-2 {
        display: none !important;
    }


    .new-font-style-eid {
        color: #4C4442 !important;
        font-size: 30px !important;
        line-height: 1.4em !important;
    }

    .eid-early-blog .col-6 {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }


    .blog-single-page .col-7 {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }

    .blog-single-page .col-8 {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }

    .blog-single-page .col-5 {
        flex: 0 0 100% !important;
        max-width: 100% !important;
        justify-content: center;
        align-items: center;
        display: flex;
    }

    .side-img-section {
        width: 100% !important;
        float: none !important;
    }

    .blog-single-page .blog-header-page {
        align-items: center;
        justify-content: center;
        padding: 26px 0px;
    }

    .blog-single-page .flex-order {
        display: flex;


    }

    .blog-single-page .flex-order .col-7 {
        order: 2;
    }

    .blog-single-page .new-header-description {
        font-size: 20px;
        line-height: 28px;
        padding: 0px 17px;
        margin-bottom: 20px !important;
        margin-top: 38px !important;
    }

    .blog-single-page .fnt-15.body-new {
        padding: 0px 20px !important;
    }

    .blog-single-page .rich-container-pdn {
        padding: 50px 0px !important;
    }

    .blog-single-page-mobile .mobile-header-80 {
        margin: 50px auto;
    }

    .margin-top-blog-100 {
        margin-top: 50px;
    }

    .blog-0-pdn-min-mt {
        margin-top: 50px;
    }

    .blog-100-0 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .blog-mt-min-100 {
        margin-top: 100px;
    }

    .blog-single-page-mobile .new-header-description {
        font-size: 20px;
        line-height: 26px;
        padding: 0px 30px;
    }

    .blog-single-page-mobile .fnt-15.marg-btm-5.padd-btm-15.body-new {
        padding: 32px;
        padding-top: 0px;
    }

    .blog-single-page-mobile .side-img-section3 {
        width: 90%;
        float: left;
        margin-top: 31px;
    }

    .blog-single-page-mobile .width-70-moon-new-blog1 {
        height: 36vh;
    }

    .blog-single-page-mobile .width-70-moon-new-blog {
        height: 265px;
    }

    .blog-single-page-mobile .blog-m-min-top-last {
        margin-top: 70px;
    }

    .blog-single-page-mobile .side-img-section4 {
        width: 0%;

    }

    .blog-single-page-mobile .side-img-section11 {
        width: 92%;
    }

    .flex-mobile-100-col .col-4 {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 25px !important;
    }

    .flex-mobile-100-col .blog-midl-min {
        margin-top: 3% !important;
    }

    .blog-middle-video {
        margin-top: 0px;
    }


    .eid-blog-en .row {
        margin-left: 0px !important;
        margin-right: 0px !important;

    }

    .eid-blog-en .header-top .col-6 {
        max-width: 100%;
        flex: 0 0 100%;


    }

    .eid-blog-en .header-top {
        padding: 0px !important;
    }

    .eid-blog-en .header-top .mint-read {
        margin-top: 50px;
    }

    .eid-blog-en .mobile-text {
        font-size: 22px !important;
        line-height: 127% !important;

    }

    .eid-blog-en .col-3.nazira-img {
        position: relative;
        margin-top: 0px !important;
        flex: 0 0 100%;
        max-width: 89%;
        padding-left: 0px;

    }

    .eid-blog-en .col-9 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .eid-blog-en.blog-single-page-mobile .new-header-description {
        font-size: 26px;
        line-height: 123%;
        margin-top: 25px;
    }

    .eid-blog-en.blog-single-page-mobile .width-70-moon-new-new-blog-3 {
        height: 300px;
    }


    .eid-blog-en .rich-inner-title {
        font-size: 22px !important;
        margin-top: 0px;
        margin-bottom: 10px !important;
        line-height: 137% !important;
    }




}

.arb-style-none {
    list-style: none;
}


.blog-header-new-img {
    margin: 0;
    padding: 0;
    margin-left: 0px;
    text-align: right;
    float: right;
}

h3.fst-l:first-letter {
    color: #7e6e64;
    float: left;
    font-family: Georgia;
    font-size: 75px;
    line-height: 60px;
    padding-top: 4px;
    padding-right: 8px;
    padding-left: 3px;
}

.new-field-width .MuiFormControl-root {
    width: 98% !important;
}


.commnet-butn {

    border-radius: 0px;
    color: #fff;
    background-color: #493737;
    border: 0px;
    font-family: 'Bembo Regular' !important;
    box-shadow: none;
    text-transform: uppercase;
    padding: 7px 30px;
    letter-spacing: 1px;
    margin-top: 20px;
    margin-bottom: 30px;

}

.comment-name {

    width: 100%;
    border: none;
    padding-left: 10px;
    border-bottom: 1px solid #7e6e64;
    margin-bottom: 24px;
    font-size: 16px;
    font-family: 'Bembo Regular' !important;
    outline: none;

}


.comment-area {
    width: 100%;
    padding-left: 10px;
    font-size: 16px;
    margin-bottom: 24px;
    font-family: 'Bembo Regular' !important;
    min-height: 200px;
    outline: none;
    margin-top: 13px;
}



@media only screen and (min-width: 768px) and (max-width: 1640px) {

    .frensh-section {
        margin-top: 80px;
    }

    .web-margin-top-ex {
        margin-top: 130px;
    }

    .web-margin-top-ex-home {
        margin-top: 80px;
    }


    .home-page-ex .p-15 {
        padding: 0px !important;
    }


    .blog-mt-min-100 {
        margin-top: 0px !important;
    }

    .blog-mt-min-100 {
        margin-top: 50px !important;
    }

    .blog-0-pdn-min-mt {
        margin-top: 0px !important;
    }
}

.blog-page-links {
    text-decoration: underline;
}

.blog-flex-center {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    text-align: left;
}

.blog-single-page-mobile .new-header-description {
    font-size: 42px;
    line-height: 123%;
}

.blog-time-text {
    font-family: "Copperplate" !important;
    color: #998f89 !important;
    margin-bottom: 19px;
}

.width-70-moon-new-nazira {
    height: 400px;
}

.nazira-img {
    margin-top: -25%;
    z-index: -1;
    position: absolute;
}

.width-70-moon-new-new-blog-3 {

    height: 400px;

}

.blog-single-page-mobile .bg-new-honeymoon {
    background-color: #c39188;
}


.blog-middle-video {
    padding: 0px 15px;
    margin-top: 40px;
}


.width-70-moon-new-blog-main {
    height: 450px;
}

.width-70-moon-new-blog-main1 {
    height: 250px;
}

.blog-midl-min {
    margin-top: -30% !important;
}

.mt-min-2 {
    margin-top: -12%;
}

.mt-min-3 {
    margin-top: -15%;
}

.bg-gray-blog {
    background-color: #544b45;
    height: 450px;
    margin-top: -20%;
}

.bg-gray-img-top {
    height: 600px;
}

.font-white-blog {
    color: #fff !important;
}

.blog-ligh-h {
    line-height: 120%;
}

.offer-hide-sec:nth-child(2) .offer-sec-hide.accordion {
    display: none !important;
}

.offer-sec-hide .toggle-button {
    background: none !important;
    text-align: start;

}

.blog-mt-2 {
    margin-top: 300px;
}

.blog-content-width {
    width: 75%;
}

.blog-mt-cu {
    margin-top: 170px;
}

.eid-blog-en #loadMore {
    display: none;
}

.p-t-0-i {
    padding-top: 0px !important;
}


.offer-heading-img {
    height: 150px;
}

.close-btn-popup {
    position: absolute;
    right: 0;
    background-color: transparent;
    color: #fff;
    transform: rotate(45deg);
    z-index: 9;
}

.pop-up-content {
    padding: 40px;
}


.floating-s-icons button svg circle {
    fill: #7e6e64 !important;
}




@media screen and (min-width: 1600px) {

    .home-page-ex .p-15 {
        padding: 0px !important;
    }

    .web-margin-top-ex {
        margin-top: 120px;
    }

    .web-margin-top-ex-home {
        margin-top: 80px;
    }

    .nazira-img {
        margin-top: -15% !important;
    }

    .nazira-img img {
        width: 350px !important;
    }

    .mrg-min-blog {
        margin-top: -13% !important;
    }

    .mrg-min-blog-3 {
        margin-top: -10% !important;
    }

}

.mt-5-eid {
    margin-top: 90px;
}

.box-1p img {

    width: 173px !important;

}

.box-3p img {
    left: 92% !important;
}

.box-1p .imgn {
    top: 50% !important;
    left: -0% !important;
}


.imgnvl {

    top: 41% !important;

}

.h-s-img {
    margin-top: -11%;
    margin-left: -18px;
}

.h-s-img img {
    left: 13% !important;
}

.h-s-img1 img {
    left: 21% !important;
}

.h-s-img1 .imgnv {
    top: 41% !important;
}

.box-2p .imgnr {
    top: 43% !important;
}

.box-3p .imgne {
    top: 47% !important;
}

.box-11p img {
    width: 173px !important;
    left: 73% !important;
}

.wedding-testimonial.padn-top-130 {
    padding-bottom: 70px !important;
}


.private-resident img {
    width: 173px !important;
}


/**START  Eid blog page css**/

.img-container2 {
    width: 620px;
    height: 80vh;
    position: relative;
    overflow: hidden;
}


.img-container2 img {
    width: 100%;
    position: absolute;
}


.new-font-style-eid {
    color: #4C4442 !important;
    font-size: 50px;
    line-height: 120%;
}

.flex-eid {
    display: flex;
    flex-direction: column;
    align-items: end;
}

.side-img-1 {
    width: 230px;
    position: relative;
}

.side-img-1 img {
    position: absolute;
    width: 100%;
}

.m-min-15 {
    margin-top: -15%;
}

.side-img-2end {
    margin-top: 130px;
    margin-bottom: -122px;
    padding-right: opx;
}

/** END Eid blog page css**/


.fade.modal.show {
    opacity: 1 !important;
}

.modal-header .close {
    padding: 1rem 3rem !important;
}

.modal-content {
    border-radius: 0px !important
}

.modal-body {
    padding: 0px !important;
}

.popup-content-new {
    padding: 30px 40px;
}

.align-center-flex {
    align-items: center;
}

.holidy-m {
    margin-top: 160px;
}

.mrg-min-blog {
    margin-top: -23%;
}

.mrg-min-blog2 {
    margin-top: -46%;
}

.mrg-min-blog-3 {
    margin-top: -12%;
}


.marg-min-bot {
    margin-top: -6%;
    margin-bottom: 8%;
}

.bg-new-honeymoon-new {
    background-color: #d1b19d;
}


.flex-align-center {
    align-items: center;
}

.mrgin-top-girl {
    margin-top: -16%;
}


.home-min {
    text-align: right;
    margin-top: -52px;
}


.about-us .space-h1 {
    padding: 19px 0px;

}


.about-us .about-us-headers:nth-child(1) {
    padding-left: 0px;
}

.about-us .about-us-headers:nth-child(2) {
    padding-left: 120px;
}

.about-us .about-us-headers:nth-child(3) {
    padding-left: 270px;
    position: relative;
}

.about-us .about-us-headers:nth-child(4) {
    padding-left: 120px;

}

.about-us .about-us-headers:nth-child(4) h1 {

    font-style: italic;
    color: #B2ACAA !important;
}

.about-us .about-us-headers:nth-child(5) {
    padding-left: 0px;
}

.about-us .about-us-headers:nth-child(6) {
    padding-left: 40px;
}

.about-us .about-us-headers:nth-child(6) h1 {
    font-style: italic;
    color: #B2ACAA !important;
}

.about-us .about-us-headers:nth-child(7) {
    padding-left: 130px;
}

.about-us .about-us-headers:nth-child(8) {
    padding-left: 200px;
}

.about-us .about-us-headers:nth-child(8) h1 {
    border: none !important;
}



.about-us .about-us-headers:nth-child(3):after {
    width: 180px;
    content: 'An Invitation to The Hidden Beats';
    font-size: 16px;
    position: absolute;
    height: 25px;
    right: 0px;
    /* top: 10px; */
    bottom: 20px;
    font-family: "Copperplate";
    display: block;
    color: #7e6e64 !important;
    text-transform: uppercase;
    text-align: center;
}

.mb-5-ab {
    margin-bottom: 7em;
}

.p-new-offer p {

    font-family: 'Bembo Regular' !important;
    line-height: 1.5em;
    color: #7E6E64 !important;
    font-size: 18px;
    letter-spacing: 0px;
    margin-bottom: 10px;
}

.carosol-offer .BrainhubCarouselItem img {
    border: 0px !important;
}

.carosol-offer .BrainhubCarousel__arrows.BrainhubCarousel__arrowLeft {
    display: none !important;
}

.carosol-offer .BrainhubCarousel__arrows.BrainhubCarousel__arrowRight {
    display: none !important;
}

.rich-container-offer {
    padding-top: 130px !important;

}

.room-type-font {
    font-size: 15px !important;
    font-family: 'Bembo Regular' !important;
    line-height: 1.5em;
    color: #7E6E64 !important;
}

.offer-price-new.rich-inner-title {
    margin-top: 7px;
    margin-bottom: 4px;
}

.min-height-100 {
    min-height: 80px !important;
}

.min-height-170 {
    min-height: 130px !important;
}

.mt-3-new-offer {
    margin-top: 130px;
}

.mt-3-new-offer-min {
    margin-top: -200px;
}

.video-sec {
    align-items: center;
    justify-content: center;
}

.RootLayout__Component-sc-1doisyz-0 .ReviewContainer__Inner-sc-yv0v3c-0.huVKUn {
    font-family: "Bembo Regular" !important;
    color: #4c4442 !important;

}

.ReviewText__Title-sc-1nyg8v7-1.jbtcLo {
    font-family: "Bembo Regular" !important;
    color: #4c4442 !important;
    font-weight: 300 !important;
}

.Card3__InfoReview-sc-5eq22d-1.ctWJji {
    font-family: "Bembo Regular" !important;
    color: #4c4442 !important;
    font-weight: 300 !important;
}

.SimpleShortener__Inner-sc-19xjxqz-1.jGxLwL {
    font-family: "Bembo Regular" !important;
    color: #4c4442 !important;
}

.ReviewAuthorName__Container-sc-11w9fv5-0.kDzCXQ {
    font-weight: 400 !important;
    font-family: "Bembo Regular" !important;
    color: #4c4442 !important;
}

.Tab__Inner-sc-jpvypp-1.fYHkoy {
    font-family: "Bembo Regular" !important;
    color: #4c4442 !important;
}

.aboutUs .react-multi-carousel-item {
    padding: 20px 10px !important;
}


.home-para-blog {
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 78px;
    margin-top: 15px;
    margin-bottom: 15px;
    color: #4C4442 !important;
}

.bg-round-blog {
    position: relative;
    margin-top: 10px;
}

.bg-round-blog:before {
    content: '';
    width: 400px;
    height: 400px;
    position: absolute;
    background-color: #d7aa4f7d;
    display: block;
    bottom: -149px;
    border-radius: 50%;
    left: 23%;
    z-index: 9;
}

.body-blog-text {

    font-family: 'Bembo Regular' !important;
    line-height: 1.5em;
    color: #7E6E64 !important;
    font-size: 19px;
    letter-spacing: 0px;
    text-align: justify;
}

.rotete-text {
    font-size: 54px;
    line-height: 115%;
    text-align: left;
    color: #4c4442;
    font-family: "Bembo Regular" !important;
    transform: rotate(-90deg);
    width: 260px;
    height: 199px;

}

.blog-heading3 {
    font-size: 54px;
    line-height: 115%;
    text-align: left;
    color: #4c4442;
    font-family: "Bembo Regular" !important;
    position: relative;
}

.headign-overlay-flex h3.blog-heading3:after {
    content: '';
    width: 350px;
    height: 350px;
    position: absolute;
    background-color: #d7aa4f;
    display: block;
    border-radius: 50%;
    top: -65px;
    left: 124px;
    z-index: -1;
}

.rotate-text-col {
    display: flex;
    align-items: baseline;
    justify-content: center;
}

.mt-overlay-text {
    margin-top: -35%;
}


.headign-overlay-flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.headign-overlay-flex1 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.headign-overlay-flex1b {
    display: flex;
    justify-content: center;
}


.rich-blog-p-t {
    padding-top: 100px !important;
}

.point-grid {
    display: grid;
    grid-template-columns: auto auto;
    gap: 69px;
}

.flex-end-blog {
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.round-before:before {
    content: '';
    width: 40px;
    height: 40px;
    position: absolute;
    background-color: #d7aa4f;
    display: inline-block;
    border-radius: 50%;
    z-index: -1;
    top: -15px;
    left: -16px;
}

.round-before {
    position: relative;
}

.title-middle:before {
    content: '';
    width: 350px;
    height: 350px;
    position: absolute;
    background-color: #d7aa4f;
    display: block;
    border-radius: 50%;
    top: -41px;
    left: -222px;
    z-index: -1;
}

.mb-41 {
    margin-bottom: 40px;
}

.body-text-different {
    font-family: 'Bembo Regular' !important;
    line-height: 1.5em;
    color: #262524 !important;
    font-size: 24px;
    letter-spacing: 0px;
    text-align: justify;
    font-style: italic;
    text-decoration: underline;
    text-decoration-color: #d7aa4f70;
}

.last-blog-min {
    margin-top: -16%;
}

.blog-map-center {
    align-items: center;
    justify-content: center;
    width: 100%;
    display: flex;
}

.about-partner .new-carousol {
    padding: 9px 36px;

}

.video-sec div div {
    width: 600px !important;
}


.mt-5-about {
    margin-top: 100px;
}

.mt-about-sec-1 {
    margin-top: 130px;
}

.overlay-new {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #fff;
    cursor: pointer;
}

.image-hover:hover .overlay-new {
    opacity: 1;
}

.image-hover:hover .overlay-new2 {
    opacity: 1;
}

.image-hover .imgage-class-about {
    box-shadow: -1px 1px 2px 0 rgb(0 0 0 / 10%), -1px 1px 3px 0 rgb(0 0 0 / 10%), -3px 3px 4px 0 rgb(0 0 0 / 10%);
}

.image-hover:hover .text-new-about {
    padding-bottom: 30px;
}

.image-hover:hover .text-new-about2 {
    padding-top: 30px;
}

.text-new-about {
    color: #000;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    transition: .5s ease;
    width: 100%;
}

.text-new-about2 {
    color: #000;
    font-size: 20px;
    position: absolute;
    top: 70%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    transition: .5s ease;
    width: 100%;


}

.grid-2-min {
    margin-top: -10%;
}

.grid-2-max {
    margin-top: 14%;
}

.imgage-class-about {
    position: relative;
}

.grid-3-min {
    margin-top: -27%;
}

.grid-4-min {
    margin-top: -25%;
}


.grid-middle {
    margin-top: -6%;
    margin-left: -3%;
    margin-right: 4%;
}


.bg-new-honeymoon-crypto {
    background-color: #d7aa4f;
}

.ButtonBase__ButtonContainer-sc-p43e7i-3.Hydsg.LoadMoreButton__StyledButton-sc-zpc339-1.fSpvho {
    padding: 6px 18px;
    display: inline-block;
    /* position: relative; */
    /* font-size: 15px; */
    font-family: "Copperplate" !important;
    text-transform: uppercase;
    color: #7e6e64 !important;
    overflow: hidden;
    margin-top: 30px;
    letter-spacing: 0px;
    cursor: pointer;
    border: 1px solid #ffe40f !important;
    border-radius: 41px !important;
    line-height: 16px;
    font-weight: 400;

}

/* 
.ButtonBase__ButtonContainer-sc-p43e7i-3.Hydsg.LoadMoreButton__StyledButton-sc-zpc339-1.fSpvho:after{
    content: "";
    height: 1px;
    width: 126%;
    background-color: #ffe40f;
    display: block;
    margin-left: -11px;
    margin-top: 3px;
} */

.ButtonBase__ButtonContainer-sc-p43e7i-3.Hydsg.LoadMoreButton__StyledButton-sc-zpc339-1.fSpvho span {
    padding: 2px 5px !important;
}

.ButtonBase__ButtonContainer-sc-p43e7i-3.Hydsg.LoadMoreButton__StyledButton-sc-zpc339-1.fSpvho span:hover {
    background-color: transparent !important;
}

.ButtonBase__ButtonContainer-sc-p43e7i-3.Hydsg.LoadMoreButton__StyledButton-sc-zpc339-1.fSpvho:hover {
    background-color: transparent !important;
}

div[title="Google"].Icon__IconContainer-sc-1ysxs3-0.dosZLJ svg g path {
    fill: #7e6e64 !important;
}

div[title="Facebook"].Icon__IconContainer-sc-1ysxs3-0.dosZLJ svg g circle {
    fill: #7e6e64 !important;
}

div[title="Trustpilot"].Icon__IconContainer-sc-1ysxs3-0.dosZLJ svg g circle {
    fill: #7e6e64 !important;
}

div[title="TripAdvisor"].Icon__IconContainer-sc-1ysxs3-0.dosZLJ svg g circle {
    fill: #7e6e64 !important;
}

.LayoutDefault__Top-sc-1ydej6v-3.jaTsyb {
    margin-bottom: 30px;
}

.rich-container-pdn-top {
    padding-top: 190px;
}

.rich-container-pdn-top-carosol {
    padding-top: 40px;
}

.mt-max-careful {
    margin-top: 50px;
}

.mt-min-careful {
    margin-top: -80px;
}


.hiddn-box {
    display: none;
}

.hover-box-about:hover .hiddn-box {
    display: block;
}


.overlay-new2 {
    position: absolute;
    top: -30%;
    bottom: 0;
    left: -200%;
    right: 0;
    border: 1px solid #e9e9e9;
    width: 700px;
    opacity: 0;
    transition: .5s ease;
    z-index: 99999;
    background-color: #fff;
    padding: 30px 40px;
    align-items: center;
    justify-content: center;
    display: flex;
    box-shadow: 0 89px 68px rgb(42 41 40 / 15%);
}

.text-reavil2 .post-filter2 {
    opacity: 1;

}

.post-filter2 {
    opacity: 0;

}

.post-filter3 {
    opacity: 0;

}

.post-filter4 {
    opacity: 0;

}

.post-filter5 {
    opacity: 0;

}

.post-filter6 {
    opacity: 0;

}

.post-filter7 {
    opacity: 0;

}

.post-filter8 {
    opacity: 0;

}

.post-filter9 {
    opacity: 0;

}

.post-filter10 {
    opacity: 0;

}

.post-filter11 {
    opacity: 0;

}

.post-filter12 {
    opacity: 0;

}

.post-filter13 {
    opacity: 0;

}

.post-filter14 {
    opacity: 0;

}

.post-filter15 {
    opacity: 0;

}

.post-filter22 {
    opacity: 0;

}

.post-filter23 {
    opacity: 0;

}

.post-filter24 {
    opacity: 0;

}

.post-filter25 {
    opacity: 0;

}

.post-filter26 {
    opacity: 0;

}

.post-filter27 {
    opacity: 0;

}

.post-filter28 {
    opacity: 0;

}

.post-filter29 {
    opacity: 0;

}

.post-filter210 {
    opacity: 0;

}

.post-filter211 {
    opacity: 0;

}



.showit {
    opacity: 1 !important;

}

.hideit {
    opacity: 0 !important;

}


.text-reavil {
    position: relative;
    height: 250px;
    flex-direction: column;
}

.post-filter {
    position: absolute;
    top: 0;
    transition: opacity 0.4s;

}

.post-filter2 {
    position: absolute;
    top: 0;
    transition: opacity 0.4s;

}

.post-filter3 {
    position: absolute;
    top: 0;
    transition: opacity 0.4s;

}

.post-filter4 {
    position: absolute;
    top: 0;
    transition: opacity 0.4s;

}

.post-filter5 {
    position: absolute;
    top: 0;
    transition: opacity 0.4s;

}

.post-filter6 {
    position: absolute;
    top: 0;
    transition: opacity 0.4s;

}

.post-filter7 {
    position: absolute;
    top: 0;
    transition: opacity 0.4s;

}

.post-filter8 {
    position: absolute;
    top: 0;
    transition: opacity 0.4s;

}

.post-filter9 {
    position: absolute;
    top: 0;
    transition: opacity 0.4s;

}

.post-filter10 {
    position: absolute;
    top: 0;
    transition: opacity 0.4s;

}

.post-filter11 {
    position: absolute;
    top: 0;
    transition: opacity 0.4s;

}

.post-filter12 {
    position: absolute;
    top: 0;
    transition: opacity 0.4s;

}

.post-filter13 {
    position: absolute;
    top: 0;
    transition: opacity 0.4s;

}

.post-filter14 {
    position: absolute;
    top: 0;
    transition: opacity 0.4s;

}

.post-filter15 {
    position: absolute;
    top: 0;
    transition: opacity 0.4s;

}


.post-filter2 {
    position: absolute;
    top: 0;
    transition: opacity 0.4s;

}

.post-filter22 {
    position: absolute;
    top: 0;
    transition: opacity 0.4s;

}

.post-filter23 {
    position: absolute;
    top: 0;
    transition: opacity 0.4s;

}

.post-filter24 {
    position: absolute;
    top: 0;
    transition: opacity 0.4s;

}

.post-filter25 {
    position: absolute;
    top: 0;
    transition: opacity 0.4s;

}

.post-filter26 {
    position: absolute;
    top: 0;
    transition: opacity 0.4s;

}

.post-filter27 {
    position: absolute;
    top: 0;
    transition: opacity 0.4s;

}

.post-filter28 {
    position: absolute;
    top: 0;
    transition: opacity 0.4s;

}

.post-filter29 {
    position: absolute;
    top: 0;
    transition: opacity 0.4s;

}

.post-filter210 {
    position: absolute;
    top: 0;
    transition: opacity 0.4s;

}

.post-filter211 {
    position: absolute;
    top: 0;
    transition: opacity 0.4s;

}




.overlay-new {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .4s ease;
    background-color: #ffffff73;
}

.closet-image1:hover .overlay-new {
    opacity: 1;
}

.closet-image2:hover .overlay-new {
    opacity: 1;
}

.closet-image3:hover .overlay-new {
    opacity: 1;
}

.closet-image4:hover .overlay-new {
    opacity: 1;
}

.closet-image5:hover .overlay-new {
    opacity: 1;
}

.closet-image6:hover .overlay-new {
    opacity: 1;
}

.closet-image7:hover .overlay-new {
    opacity: 1;
}

.closet-image8:hover .overlay-new {
    opacity: 1;
}


.closet-image9:hover .overlay-new {
    opacity: 1;
}


.closet-image10:hover .overlay-new {
    opacity: 1;
}

.closet-image11:hover .overlay-new {
    opacity: 1;
}

.closet-image12:hover .overlay-new {
    opacity: 1;
}




/* About us page css start*/

.flex-order-upper {
    display: flex;
    flex-direction: column;

}

.flex-order-upper .closet1 {
    order: 2;
}

.flex-order-upper-2 {
    display: flex;
    flex-direction: column;

}

.flex-order-upper-2 .text-reavil {
    margin-top: 15em;
}



.text-reavil {
    display: flex;
    align-items: center;
    justify-content: center;
}

.closet1 {
    display: flex;
    align-items: end;
    justify-content: end;
    position: relative;
}



.closet1 .closet-image1 {
    height: 199px;
    width: 321px;
    position: relative;
}

.closet1 .closet-image2 {
    position: absolute;
    height: 200px;
    width: 141px;
    top: 12em;
    right: 2em;
    z-index: 9;
}

.closet1 .closet-image3 {
    position: absolute;
    width: 184px;
    height: 213px;
    right: 20em;
    top: 5em;
    z-index: 9;
}

.closet1 .closet-image4 {
    position: absolute;
    width: 159px;
    height: 236px;
    right: 16em;
    top: 16em;
    z-index: 9;
}

.closet1 .closet-image5 {
    position: absolute;
    width: 123px;
    height: 190px;
    right: 16em;
    top: 18em;
    z-index: 9;
}


.behind-new-m {
    position: relative;
}

.behind-new-m video {
    z-index: -1;
}



.closet2 {
    display: flex;
    align-items: start;
    justify-content: start;
    position: relative;
}

.closet2 .closet-image1 {
    height: 185px;
    width: 279px;
    position: relative;
    left: 56px;
}

.closet2 .closet-image2 {
    position: absolute;
    height: 200px;
    width: 124px;
    top: 12em;
    left: 0em;
}

.closet2 .closet-image3 {
    position: absolute;
    width: 184px;
    height: 197px;
    left: 17em;
    top: 7em;
}

.closet2 .closet-image4 {
    position: absolute;
    width: 174px;
    height: 231px;
    left: 29em;
    top: 12em;
}

.closet2 .closet-image5 {
    position: absolute;
    width: 151px;
    height: 190px;
    left: 13em;
    top: 18em;
}

.closet2 .closet-image6 {
    position: absolute;
    width: 208px;
    height: 141px;
    left: 41em;
    top: 10em;
}

.closet2 .closet-image7 {
    position: absolute;
    width: 143px;
    height: 170px;
    left: 2em;
    top: 26em;
}

.closet2 .closet-image8 {
    position: absolute;
    width: 99px;
    height: 116px;
    left: 20em;
    top: 21em;
}


.closet2 .closet-image9 {
    position: absolute;
    width: 108px;
    height: 129px;
    left: 48em;
    top: 17em;
}


.closet2 .closet-image10 {
    position: absolute;
    width: 99px;
    height: 116px;
    left: 0em;
    top: 34em;
}




.margin-top-ab-row {
    margin-top: 6em;
}

.margin-top-ab-row1 {
    margin-top: 14em;
}

.margin-top-ab-row11 {
    margin-top: 30em;
}

.margin-top-ab-row-2 {
    margin-top: 10em;
}

.closet5.closet1 .closet-image4 {
    position: absolute;
    width: 159px;
    height: 236px;
    right: 33em;
    top: 8em;
    z-index: 9;

}

.closet5.closet1 .closet-image5 {
    position: absolute;
    width: 123px;
    height: 190px;
    right: 10em;
    top: 16em;
    z-index: 9;
}

.closet5.closet1 .closet-image6 {
    position: absolute;
    width: 206px;
    height: 149px;
    right: 44em;
    top: 3em;
    z-index: 9;
}

.closet5.closet1 .closet-image7 {
    position: absolute;
    width: 140px;
    height: 144px;
    right: 55em;
    top: 12em;
    z-index: 9;
}

.closet5.closet1 .closet-image8 {
    position: absolute;
    width: 129px;
    height: 160px;
    right: 55em;
    top: 44em;
    z-index: 9;
}

.closet5.closet1 .closet-image9 {
    position: absolute;
    width: 185px;
    height: 179px;
    right: 64em;
    top: 46em;
    z-index: 9;
}

.closet5.closet1 .closet-image10 {
    position: absolute;
    width: 237px;
    height: 140px;
    right: 76em;
    top: 41em;
}

.closet5.closet1 .closet-image11 {
    position: absolute;
    width: 113px;
    height: 163px;
    right: 89em;
    top: 32em;
    z-index: 9;
}

.closet5.closet1 .closet-image12 {
    position: absolute;
    width: 137px;
    height: 170px;
    right: 46em;
    top: 46em;
    z-index: 9;
}



.closet1.closet6 .closet-image1 {
    height: 177px;
    width: 252px;
    position: relative;
    right: 2em;
    top: 2em;
}

.closet1.closet6 .closet-image2 {
    height: 177px;
    width: 105px;
    position: relative;
    right: -10em;
    top: 3em;
}

.closet1.closet6 .closet-image3 {
    height: 177px;
    width: 190px;
    position: relative;
    right: 10em;
    top: 5em;
}


/* About us page css end */







.ButtonBase__ButtonContainer-sc-p43e7i-3.Hydsg.LoadMoreButton__StyledButton-sc-zpc339-1.fSpvho span:hover {
    background-color: transparent !important;
}

.ButtonBase__Overlay-sc-p43e7i-4.fMszQs {
    background-color: transparent !important;
}

.ReviewBackground__Container-sc-1jrpbkc-0.evjDXr {
    padding: 22px !important;
}


.carosol-video-top {
    margin-top: -28em;
}



@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {

    .home-para {
        font-size: 36px !important;
    }

    .rich-container-pdn {
        padding: 120px 0px;
    }

    .closet1 .closet-image1 {
        height: 174px;
        width: 259px;
        position: relative;
    }

    .closet1 .closet-image2 {
        position: absolute;
        height: 162px;
        width: 116px;
        top: 8em;
        right: 2em;
        z-index: 9;
    }

    .closet1 .closet-image3 {
        position: absolute;
        width: 133px;
        height: 157px;
        right: 18em;
        top: 3em;
        z-index: 9;
    }

    .closet1 .closet-image4 {
        position: absolute;
        width: 116px;
        height: 184px;
        right: 27em;
        top: 7em;
        z-index: 9;
    }

    .text-reavil {
        height: 193px;
    }

    .closet2 .closet-image1 {
        height: 231px;
        width: 204px;
        position: relative;
        left: 33px;
    }

    .closet2 .closet-image2 {
        position: absolute;
        height: 205px;
        width: 124px;
        top: 10em;
        left: -1em;
    }

    .closet2 .closet-image3 {
        position: absolute;
        width: 126px;
        height: 133px;
        left: 16em;
        top: 3em;
    }

    .closet2 .closet-image7 {
        position: absolute;
        width: 112px;
        height: 148px;
        left: 2em;
        top: 24em;
    }

    .closet2 .closet-image5 {
        position: absolute;
        width: 135px;
        height: 153px;
        left: 11em;
        top: 16em;
    }

    .closet2 .closet-image4 {
        position: absolute;
        width: 120px;
        height: 207px;
        left: 26em;
        top: 9em;
    }

    .closet2 .closet-image6 {
        position: absolute;
        width: 180px;
        height: 167px;
        left: 36em;
        top: 12em;
    }

    .closet1.closet5 .closet-image1 {
        height: 208px;
        width: 266px;
        position: relative;
        z-index: 1;
    }

    .closet1.closet5 .closet-image2 {
        position: absolute;
        height: 162px;
        width: 116px;
        top: 12em;
        right: 2em;
        z-index: 9;
    }

    .closet1.closet5 .closet-image3 {
        position: absolute;
        width: 133px;
        height: 157px;
        right: 18em;
        top: 1em;
        z-index: 9;
    }

    .closet5.closet1 .closet-image4 {
        position: absolute;
        width: 159px;
        height: 157px;
        right: 27em;
        top: 8em;
        z-index: 9;
    }


    .closet5.closet1 .closet-image6 {
        position: absolute;
        width: 136px;
        height: 149px;
        right: 38em;
        top: 0em;
        z-index: 9;
    }

    .closet5.closet1 .closet-image7 {
        position: absolute;
        width: 140px;
        height: 144px;
        right: 35em;
        top: 15em;
        z-index: 9;
    }

}

.react-multi-carousel-dot-list {
    justify-content: start !important;
    bottom: 40px !important;
}

.new-m-top-section-clos {
    margin-top: 18em;
}

.new-m-top-section-clos .text-reavil {
    margin-top: 9em;
}

.new-m-top-section-clos .closet1 .closet-image2 {
    position: absolute;
    height: 162px;
    width: 175px;
    top: 11em;
    right: 2em;
    z-index: 9;
}


@media screen and (min-device-width: 1600px) and (max-device-width: 2500px) and (-webkit-min-device-pixel-ratio: 1) {

    .closet5.closet1 .closet-image11 {
        position: absolute;
        width: 113px;
        height: 163px;
        right: 121em;
        top: 37em;
        z-index: 9;
    }

    .closet5.closet1 .closet-image10 {
        position: absolute;
        width: 237px;
        height: 140px;
        right: 105em;
        top: 48em;
    }

    .closet5.closet1 .closet-image9 {
        position: absolute;
        width: 185px;
        height: 179px;
        right: 91em;
        top: 50em;
        z-index: 9;
    }

    .closet5.closet1 .closet-image8 {
        position: absolute;
        width: 129px;
        height: 160px;
        right: 82em;
        top: 47em;
        z-index: 9;
    }

    .closet5.closet1 .closet-image12 {
        position: absolute;
        width: 137px;
        height: 170px;
        right: 69em;
        top: 50em;
        z-index: 9;
    }

    .closet2 .closet-image1 {
        height: 276px;
        width: 228px;
        position: relative;
        left: 56px;
        top: -52px;
    }

    .closet2 .closet-image8 {
        position: absolute;
        width: 105px;
        height: 120px;
        left: 22em;
        top: 26em;
    }

    .closet1 .closet-image4 {
        position: absolute;
        width: 143px;
        height: 205px;
        right: 33em;
        top: 8em;
        z-index: 9;
    }

    .closet2 .closet-image9 {
        position: absolute;
        width: 108px;
        height: 129px;
        left: 51em;
        top: 18em;
    }

}


.budget-pakage.margin-top-new-inner {
    margin-top: 150px !important;
}



.scrollArea {
    width: 100%;
    height: 200px;
    overflow: auto;
}

.meter {
    width: 100px;
    display: block;
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    top: 0;
}


@keyframes anvil {
    0% {
        transform: scale(1) translateY(0px);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }

    1% {
        transform: scale(0.96) translateY(10px);
        opacity: 0.5;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }

    100% {
        transform: scale(1) translateY(0px);
        opacity: 1;
        box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
}

.popup-content {
    -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

.popup-overlay {
    background: rgb(0 0 0 / 73%) !important;
}

.modal-new {
    padding-left: 40px;
    padding-right: 40px;
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-new .close {
    margin-right: 15px;
    font-size: 30px;
}

.modal-new .content {
    text-align: center;

}

.modal-new .content .improw {
    /* font-weight: 600; */
    color: #211e1d;
    font-style: italic;
    font-size: 18px;
}




/*About us section -our staff*/

.item-img {
    width: 100px;
}

.item-img img {
    border-radius: 50%;
    width: 100%;
    padding: 6px;

    transition: transform 0.4s;

}


.text-section-right {
    margin-top: 10%;
    padding-left: 30px;
}

.item-img img:hover {
    transform: scale(1.2);
    cursor: pointer;
    border: 1px dashed #929090;

}

.owner-img-1 {

    margin-right: 20px;
}

.owner-img-1 img {

    width: 110px !important;
    margin-right: 20px;
}


.bg-befor-box:before {
    content: '';
    width: 300px;
    height: 250px;
    position: absolute;
    background-color: transparent;
    z-index: -1;
    border-radius: 3px;
    border-left: 3px solid #ececec;
    border-bottom: 3px solid #ececec;
    left: 19%;
}

.bg-befor-box2:before {
    content: '';
    width: 300px;
    height: 250px;
    position: absolute;
    background-color: transparent;
    z-index: -1;
    border-radius: 3px;
    border-right: 3px solid #ececec;
    border-bottom: 3px solid #ececec;
    right: 19%;
}

.bg-befor-box4::before {
    content: '';
    width: 220px;
    height: 200px;
    position: absolute;
    background-color: transparent;
    z-index: -1;
    border-radius: 3px;
    border-left: 3px solid #ececec;
    border-top: 3px solid #ececec;
    right: 32%;
    top: 5%;
}

.bg-befor-box5::before {
    content: '';
    width: 193px;
    height: 166px;
    position: absolute;
    background-color: transparent;
    z-index: -1;
    border-radius: 3px;
    border-left: 3px solid #ececec;
    border-top: 3px solid #ececec;
    right: 62%;
    top: 5%;
}


.bg-befor-box6:before {
    content: '';
    width: 204px;
    height: 170px;
    position: absolute;
    background-color: transparent;
    z-index: -1;
    border-radius: 3px;
    border-right: 3px solid #ececec;
    border-bottom: 3px solid #ececec;
    right: 60%;
    top: 5%;
}

.m-r-10-p {
    margin-right: 40%;
}

.text-section-right2 {
    margin-top: 18%;
}

.text-section-right3 {
    margin-top: 14%;
    padding-left: 20px;
}

.text-section-right6 {
    margin-top: 15%;
}

.mt-5-staff {
    margin-top: 13%;
}

.owner-img-1 {
    position: relative;
}

.owner-img-1::after {
    content: '';
    width: 110px;
    height: 110px;
    background-color: transparent;
    position: absolute;
    display: block;
    top: 4px;
    border-radius: 50%;
    z-index: -1;
    border: 1px dashed #7e6e64;
}

@media screen and (min-device-width: 1600px) and (max-device-width: 2600px) and (-webkit-min-device-pixel-ratio: 1) {
    .newsletter {

        width: 50vw !important;
    }

    .svg-bg-line {
        background-position-y: -108% !important;
    }
}

.room-img-in-offer .BrainhubCarousel__arrows {
    display: none !important;
}

.room-img-in-offer .BrainhubCarouselItem img {
    border: none !important;
}

.flex-mobile-img-about {
    display: flex;
    align-items: flex-end;
    justify-content: end;

}

.fade-in-text {
    animation: fadeIn ease-in 1s;
    -webkit-animation: fadeIn ease-in 1s;
    -moz-animation: fadeIn ease-in 1s;
    -o-animation: fadeIn eease-in 1s;
    -ms-animation: fadeIn ease-in 1s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.bg-color-exit-popop {
    background-color: #fff;
    padding: 30px 30px;
    right: 0;
    top: 0px;
    display: flex;
    align-items: center;
}

.dianamic-offer-section .owl-stage .owl-item:nth-child(4) .view-offer {
    display: none !important;
}

.dianamic-offer-section .owl-stage .owl-item:nth-child(2) .view-offer {
    display: none !important;
}

.offer-page-button {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.offer-page-button .text-center {
    margin: 0px 15px;
}

.bg-new-eid {
    background-color: #bda570;
}

.g-blog-pdn {
    padding: 30px;
}

.g-blog-flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.g-pl-5 {
    padding-left: 100px;
    padding-right: 30px;
}

.small-font-n {
    font-size: 14px !important;
}

.get-this-offer-bottom {
    padding: 36px 15px;
    background-color: #f5f5f5;
}

.mt-3-new-offer-1 {
    margin-top: 15%;
}

.pointernone {
    pointer-events: none !important;
}

.pointernoneblock {
    display: none !important;
}

.input-font-new input {
    font-family: "Bembo Regular" !important;
}

input {
    font-family: "Bembo Regular" !important;
}

.MuiButton-textPrimary {
    color: #7e6e64 !important;
}

.p-15 {
    padding: 20px !important;
}

.alice-carousel__dots {
    display: none !important;
}

.alice-carousel__prev-btn {
    position: absolute;
    top: 43%;
    width: 40px !important;
    left: -26px;
    height: 40px !important;
    z-index: 9;
    padding: 0px !important;
    transform: translate(-50%, -50%) rotate(-135deg);
    border: solid;
    border-color: #949393 !important;
    border-width: 2px 2px 0 0 !important;
}

.alice-carousel__prev-btn-item,
.alice-carousel__next-btn-item {
    padding: 22px !important;
    opacity: 0 !important;
}

.alice-carousel__next-btn {
    position: absolute;
    top: 43%;
    width: 40px !important;
    right: -60px !important;
    height: 40px !important;
    z-index: 9;
    /* border-color: #484545!important; */
    /* border-width: 1px 1px 0px 0px!important; */
    padding: 0px !important;
    transform: translate(-50%, -50%) rotate(-316deg);
    border: solid;
    border-color: #949393 !important;
    border-width: 2px 2px 0 0 !important;
}

.desktop-space-around {
    padding-top: 100px;
    padding-bottom: 100px;
}

.pading-expix-sec {
    padding: 10px !important;
}

.alice-carousel__stage-item {
    list-style: none !important;
}

.rich-pading-b-nw-blogin {
    margin-bottom: 50px;
}

.ex-credit-font {

    margin-top: 7px;
    font-family: 'Bembo Regular' !important;
    color: #1c222f !important;
    font-size: 14px;

}

.ex-credit-font span {
    margin-right: 4px;
    margin-top: -2px;
}

.web_staff-section .mob-1 {
    height: 260px !important;
}

.web_staff-section .p-15 {
    padding: 8px !important;
    padding-top: 0px !important;
}

.web_mrgn_top {
    margin-top: 100px;
}

.web_staff-section .alice-carousel__prev-btn {
    /* width: 22px!important;
    left: 0px!important;
    height: 22px!important;
    top: 33%!important; */
    display: none !important;
}

.web_staff-section .alice-carousel__next-btn {
    /* height: 22px!important;
    top: 33%!important;
    width: 22px!important;
    right: -25px!important; */
    display: none !important;
}

.web_staff-section .item-1 {
    display: none !important;
}

.blo-g2 {
    height: 320px;
}

.blo-g1 {
    height: 420px;
}

.blo-g3 {
    height: 340px;
}

.blo-g4 {
    height: 400px;
}

.blo-g5 {
    height: 450px;
}

.blo-g5-header {
    height: 500px;
}

.blo-g5 {
    height: 380px;
}

.m-flip {
    overflow: hidden;
    cursor: pointer;
}

.m-flip_item {
    display: block;
    position: relative;
    top: 0;
    transition: top .2s ease-out 0s;
}

.mtm-5 {
    margin-top: -8%;
}

.blo-g43 {
    height: 320px;
}

.mtm-4 {
    margin-top: -5%;
}

.mtp-2 {
    margin-top: 3%;
}

.flex-center-blog {
    align-items: center;
}

.mtm-7 {
    margin-top: -17%;
}

.mtm-7-blog {
    margin-top: -13%;
}

.mtm-28 {
    margin-top: -28%;
}

@media screen and (max-width: 602px) {

    .Demo__some-network button:after {
        display: none !important;
    }

    .floating-s-icons {
        padding-left: 3px !important;
    }

    .Demo__some-network button svg {
        width: 25px !important;
    }

    .blo-g1 {
        height: 270px !important;
    }

    .blo-g2 {
        height: 270px !important;
    }

    .blo-g3 {
        height: 270px !important;
    }

    .blo-g43 {
        height: 270px !important;
    }

    .blo-g4 {
        height: 270px !important;
    }

    .blo-g5 {
        height: 270px !important;
    }

    .blo-g6 {
        height: 270px !important;
    }

    .blog-middle-video {
        padding: 0px 0px;
        margin-top: 0px;
    }

    .blog-lisitng .flex-mobile-100-col .col-4 {
        padding: 0px 30px !important;
    }

    .mtm-4 {
        margin-top: 10%;
        margin-bottom: -7%;
    }

    .mtm-5 {
        margin-top: 11%;
        margin-bottom: 7%;
    }

    .mtm-7-blog {
        margin-top: 17%;
    }

    .blog-lisitng .rich-inner-title {
        line-height: 1.3em !important;
        margin-top: 15px;
    }

    .mtm-0 {
        margin-top: 12%;
        margin-bottom: 5%;
    }

    .mt-m-6 {
        margin-top: 15% !important;
    }
}

.col-3-con .col-3 {
    right: 0% !important;
}


.pading-top-blog {
    padding-top: 130px;
}

.margin-0 {
    margin: 0px !important;
}

.text-left-blog {
    text-align: left !important;
}

.web_mrgn_topblog {
    margin-top: 170px;
}

.right-0-px {
    right: 0px !important;
}

.kids-blog .lign-height-4 {
    line-height: 1.4em !important;
}

.mt-top-blog {
    margin-top: 130px;
}

.mt-top-blog1 {
    margin-top: 100px;
}

.mb-5-about-n {
    margin-bottom: 130px;
}

.joint-blog #loadMore {
    display: none;
}

.joint-blog .testi-sctiontext {
    padding-top: 100px !important;
}

.Carousel__CarouselArrowControlContainer-sc-1y666a6-3 {
    display: none !important;
}

.Carousel__CarouselArrowControlContainer-sc-1y666a6-3.kfcIfb {
    display: none !important;
}

.joint-blog .bg-new-honeymoon {
    background-color: #b7aaa2 !important;
}

.Demo__some-network button:after {
    content: '';
    width: 0px;
    height: 30px;
    background-color: #e7e1dd;
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 41px;
    left: 33px;
    z-index: -1;
    transition: width 0.3s,
}

.Demo__some-network button:hover:after {
    width: 40px;
    left: 36px;
}

.blog-credit-right {
    text-align: right;
}

.conscious_blog .padd-btm-15 {
    padding-bottom: 5px !important;
}

.submit-btn-footer {
    padding: 13px 20px;
    display: inline-block;
    font-family: "Copperplate" !important;
    text-transform: uppercase;
    color: #7e6e64 !important;
    overflow: hidden;
    margin-left: 50px;
    letter-spacing: 0px;
    cursor: pointer;
    border: 1px solid #ffe40f;
    border-radius: 41px;
    line-height: 9px;
}

.resort-call-to-action {
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    margin-bottom: -50px;
    box-shadow: 0px -5px 47px 3px #40383317;
    border: 1px solid #e1dfdf;
    border-top: 2px solid #dfdad6;
    max-width: 65%;
}

.resort-call-to-action:after {
    content: " ";
    position: absolute;
    right: 30px;
    top: -17px;
    border-top: none;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 15px solid #dfdad6;
}

.width_430 {
    width: 430px;
}

.pos-rela.new-font-style.last-font-title {
    margin: 0px auto;
    text-align: left !important;
    font-size: 90px;
    letter-spacing: 0px;
    line-height: 77%;
    margin-top: -52px;
}

.pos-rela.new-font-style.last-font-title-2 {
    margin: 0px auto;
    text-align: left !important;
    font-size: 90px;
    letter-spacing: 0px;
    line-height: 77%;
    margin-top: -52px;
}

.pos-rela.new-font-style.last-font-title span {
    font-style: italic;
    display: block;
}

.pos-rela.new-font-style.last-font-title-2 span {
    font-style: italic;
    display: block;
}

.villa-title-mrgn-top {
    margin-top: 130px;
}

.our-title-new {
    margin-top: 130px;
}

.min-offer-grid-top {
    margin-top: -11%;
}

.gray-font {
    font-size: 100px;
    font-style: italic;
    color: #bbb7b6;
    margin-right: 15px;
    text-transform: none;
}

.new-font-uppercase {
    text-transform: uppercase;
    font-size: 90px;
}


.margin-top-new-inner.m-bottom-0 {
    margin-bottom: 0px !important;
}

.bg-chritsmex {
    background-color: #f79ea9;
}

.eid-content {
    display: block;
}

.eid-content-mobile {
    display: none;
}

.get-quote-center {
    justify-content: center;
    display: flex;
}

@media screen and (max-width: 580px) {

    .offer-page-button {
        justify-content: space-between;
    }

    .eid-content-mobile .eid-text-content {
        font-size: 10px;
    }

    .eid-content {
        display: none;
    }

    .eid-content-mobile {
        display: flex;
        justify-content: center;
    }

    .tawk-custom-color .tawk-text-bold-3 {
        font-size: 15px !important;
        color: #7e6e64 !important;
        font-family: "Copperplate" !important;
    }

    .gray-font {
        font-size: 28px !important;
        margin-right: 90px;
        display: block;
    }

    .mobile-top-space .new-font-style {
        font-size: 26px !important;
    }

    .mobile-top-space-2 .new-font-style {
        font-size: 26px !important;
    }

    .mobile-top-space-3 .new-font-style {
        font-size: 26px !important;
        line-height: 1.1em !important;
    }

    .mobile-top-space {
        margin-top: 70px;
    }

    .mobile-m-bottom .rich-pading-b-nw {
        margin-bottom: 20px !important;
    }

    .mobile-top-space .rich-pading-b-nw {
        margin-bottom: 10px;
    }
}

.max-width-350 {
    max-width: 360px;
}

.pdn-l-r-30 {
    padding: 0px 30px;

}

.bg-new-crypto {
    background-color: #b1a49b;
}

.header_crypto_page {
    width: 100%;
    margin-bottom: 31px;
}

.padding-30_crypto {
    padding: 0px 30px;
}

.rich-container-pdn-mobile {
    padding-top: 60px;
    padding-bottom: 60px;
}

.mt_130_con {
    margin-top: 130px !important;
}

.taxes_test {
    font-size: 16px;
}

.con_paradise .mobile-pdn-sol {
    padding: 0px 30px !important;
}

@media screen and (max-width: 580px) {

    .offer_inner_space {
        margin-top: 0px !important;
    }


    .con_paradise .mt_130_con {
        margin-top: 0px !important;
    }

    .con_paradise .newpd.padding-0-right {
        padding-right: 0px !important;
    }

    .con_paradise .newpd.padding-0-left {
        padding-left: 0px !important;
    }
}

.bg-new-consis {
    background-color: #bfafa5;
}

.staff_text_mt {
    margin-top: 35%;
}

.col-3.right_o {
    right: 0px !important;
}

.staff_section_mb {
    margin-bottom: 150px;
}

.carosol-sff .react-multiple-carousel__arrow {

    background: #ffffff47 !important;
    border-radius: 50% !important;
}

.footer_24_text {
    text-align: center;
    padding: 4px 0px;
    color: #4C4442 !important;
    font-family: 'Bembo Regular' !important;
    font-size: 16px;
    line-height: 1.4em;
}

@media screen and (max-width: 580px) {

    .home-page-search.search-option-resort {
        margin-top: -70px !important;
    }

    .home-page-search.search-option-resort .mobile-search-pl {
        border: none;
        width: 80%;
        background-color: #fff;
        padding: 8px 14px;
    }

    .mobile_resort_grid li:nth-child(odd) {
        padding-left: 0px !important;
    }

    .new_ex_section_in {
        padding: 0px 30px;
    }

    .mobile_resort_grid li:nth-child(even) {
        padding-right: 30px !important;
    }

    .mobile_resort_grid .benefits-resort li:nth-child(even) {
        padding-right: 0px !important;
    }

    .mobile_resort_grid .benefits-resort li:nth-child(odd) {
        padding-left: 0px !important;
    }

    .resort-page-new-pb.mob-botm-p {
        padding-top: 0px !important;
    }

    .new_ex_section_in {
        margin-bottom: 80px;
        grid-template-columns: 1fr 1fr !important;
    }

    .mobile_hide_new {
        display: none !important;
    }

    .col-3.right_0_m.padin_5_ {
        padding: 0px 5px !important;
    }

    .padin_5_ p {
        padding: 0px 0px !important;
        font-size: 12px !important;
        line-height: 18px !important;

    }

    .icon_mobile_space {
        margin-bottom: 60px;
        padding: 0px 20px;
    }

}

.offer_icons {
    margin: 0 auto;
}

.right_0_m {
    right: 0% !important;
}

.font-size-15 {
    font-size: 15px !important;
}

.min-height-offer1 {
    min-height: 260px;
}

.min-height-offer2 {
    min-height: 200px;
}

.new_ex_section_in {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.new_ex_section_in li {
    list-style: none;
}

.mr-n-top-130 {
    margin-top: 130px;
}

.mt_top_forms {
    margin-top: 130px;
}

.overflow_hide {
    display: none;
}

.down-arrow {
    background-color: transparent;
}


.offer_inner_space {
    margin-top: 130px;
}

.fort_size_privacy {
    font-size: 38px !important;
}

.pading_right_services {
    padding-right: 60px;
}

.text-underline {
    text-decoration: underline;
}

.min-height-home-para {
    min-height: 140px;
}

.home-box-top {
    padding: 20px 20px;
    background-color: #ffffffe3;
}

.box-section-mt-min {
    margin-top: -220px;
}


.home_main_section .rich-inner-title {
    font-size: 18px !important;
}

.align-center-flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}

@media screen and (min-width: 780px) {

    .min-height-body {
        min-height: 137px !important;
    }


    .home_slider_main .home-title-1 {
        display: none !important;
    }

    .home_slider_main .slider-sub-text {
        display: none !important;
    }
}

@media screen and (max-width: 780px) {
    .home_slider_main .home-title-1 {
        font-size: 22px !important;

    }

    .home_main_section {
        margin-top: 100px;
    }

}

.lowrate {
    font-weight: 800;
    color: #4c4442;
}

.lowrate-mobile {
    text-align: initial;
    font-size: 14px !important;
    margin-top: 5px;
}

.lowrate-mobile-bold {
    font-weight: 800;
    color: #4c4442;
}

.tawk-min-container {
    transform: none;
    transform-origin: right bottom;
}

#mainslider .owl-nav {
    display: none;
}

.box .paragraph {
    display: none;
}

.entitled-to {
    padding-bottom: 130px;
}

.eid-offer-main-title {
    height: 200px;
}

.mobile-search-pl-resort {
    font-size: 16px !important;
    color: #4c4442 !important;
    font-family: 'Bembo Regular' !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #c9c2be;
    padding: 4px 9px;
}

section.container.new-eid-resort {
    padding: 10px;
    margin-top: 120px;
}

.offer-cards {
    margin: 0 auto;
}

.eid-text-content {
    font-size: 20px;
}

.eid-content {
    padding-top: 60px;
}

.resort-page-grid {
    width: calc(100vw - 2%);
    margin: auto;
}

.resort-inner-villa-width {
    width: calc(100vw - 2%);
    margin: auto;
}

.special-offer-width {
    width: calc(100vw - 7%);
    margin: auto;
}

.offer-list-width {
    width: calc(100vw - 2%);
    margin: auto;
}

.fluid-widt-fixing {
    width: calc(100vw - 2%);
    margin: auto;
}

/* special offers dropdown start */
.offers-dropdown-content {
    display: flex;
    visibility: hidden;
    opacity: 1;
    transition: visibility 0.11s, opacity 0.3s;
    position: absolute;
    background-color: #ffffff;
    min-width: 450px;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.2), 0px 11px 20px 0px rgb(0 0 0 / 11%);
    z-index: 1;
    padding: 25px 25px 25px 25px;
    margin-top: 25px;
    margin-left: -125px;
}

 .offers-dropdown-content::after {
    width: 0px;
    content: '';
    position: absolute;
    top: -20px;
    left: 146px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #ffffff transparent;
}
   
.offers-dropdown-content::before {
    content: '';
    position: absolute;
    top: -22px;
    left: 145px;
    border-width: 11px;
    border-style: solid;
    border-color: transparent transparent rgba(0,0,0,0.1) transparent;
} 

.offers-dropdown-content .offers-dropdown-link {
    padding: 10px 30px;
    text-decoration: none;
    display: block;
    font-size: 12px;
    font-family: "Copperplate" !important;
    width: auto;
    white-space: nowrap;
}

.offers-dropdown-content .offers-dropdown-link:hover {
    color: black !important;
    font-weight: bold;
}

.dropdown:hover .offers-dropdown-content {
    visibility: visible;
    opacity: 1;
}

.offers-dropdown-content .column {
    flex: 1;
}

.offers-dropdown-content .offers-dropdown-link {
    display: block;
    margin-bottom: 5px;
}

/* sepecial-offer-dropdown-end   */

/* tailored-dropdown-start */
.tailored-dropdown-content {
    display: flex;
    visibility: hidden;
    opacity: 1;
    transition: visibility 0.11s, opacity 0.3s;
    position: absolute;
    background-color: #ffffff;
    min-width: 450px;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.2), 0px 11px 20px 0px rgb(0 0 0 / 11%);
    z-index: 1;
    padding: 25px 25px 25px 25px;
    margin-top: 25px;
    margin-left: -170px;
}


.tailored-dropdown-content::after {
    width: 0px;
    content: '';
    position: absolute;
    top: -20px;
    left: 201px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #ffffff transparent;
}
  
.tailored-dropdown-content::before {
    content: '';
    position: absolute;
    top: -22px;
    left: 200px;
    border-width: 11px;
    border-style: solid;
    border-color: transparent transparent rgba(0,0,0,0.1) transparent;
} 

.tailored-dropdown-content .tailored-dropdown-link {
    padding: 10px 30px;
    text-decoration: none;
    display: block;
    font-size: 12px;
    font-family: "Copperplate" !important;
    width: auto;
    white-space: nowrap;
}

.tailored-dropdown-content .tailored-dropdown-link:hover {
    color: black !important;
}

.dropdown:hover .tailored-dropdown-content {
    visibility: visible;
    opacity: 1;
}

.tailored-dropdown-content .column {
    flex: 1;
}

.tailored-dropdown-content .tailored-dropdown-link {
    display: block;
    margin-bottom: 5px;
}

/* tailored-dropdown-end */

/* luxury-dropdown-start */
.luxury-dropdown-content {
    display: flex;
    visibility: hidden;
    opacity: 1;
    transition: visibility 0.11s, opacity 0.3s;
    position: absolute;
    background-color: #ffffff;
    min-width: 450px;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.2), 0px 11px 20px 0px rgb(0 0 0 / 11%);
    z-index: 1;
    padding: 25px 25px 25px 25px;
    margin-top: 10px;
    margin-left: -196px;
}

.luxury-dropdown-content::after {
    width: 0px;
    content: '';
    position: absolute;
    top: -20px;
    left: 214px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #ffffff transparent;
}

.luxury-dropdown-content::before {
    content: '';
    position: absolute;
    top: -22px;
    left: 213px;
    border-width: 11px;
    border-style: solid;
    border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
}

.luxury-dropdown-content .luxury-dropdown-link {
    padding: 10px 30px;
    text-decoration: none;
    display: block;
    font-size: 12px;
    font-family: "Copperplate" !important;
    width: auto;
    white-space: nowrap;
}

.dropdown:hover .luxury-dropdown-content {
    visibility: visible;
    opacity: 1;
}

.luxury-dropdown-content .column {
    flex: 1;
}

.luxury-dropdown-content .luxury-dropdown-link {
    display: block;
    margin-bottom: 5px;
}

/* luxury-dropdown-end */

.offer-grid-title {
    font-family: 'Bembo Regular' !important;
    color: #7E6E64 !important;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0px;
}

.offer-grid-description {
    font-size: 26px;
    margin-bottom: 30px;
    color: #4c4442 !important;
    line-height: 1.3em;
}

/* offer inner view resort btn */
.secondary-view-resort-btn {
    margin-top: 27px !important;
}

.title-site-sections {
    padding-top: 100px !important;
}

.covid-description {
    padding-bottom: 30px;
}

@media only screen and (min-width: 200px) and (max-width: 760px) {
    .updated-description {
        padding-bottom: 55px !important;
    }

    .updated-header-description {
        font-size: 16px !important;
    }

    .new-description-header-offer-inner {
        font-size: 16px !important;

    }

    .updated-header-description {
        width: calc(100vw - 12%) !important;
    }

    .mt-mb-10 {
        margin-top: 5px !important;
        margin-bottom: 4rem !important;
    }


    .header-sub-description {
        width: calc(100vw - 12%) !important;
        font-size: 16px !important;
    }


    .desc-read-btn {
        height: auto;
        padding-top: 7px;
    }

    .plus-icon {
        font-size: 16px !important;
        padding-left: 1px;
    }

    .minus-button {
        font-size: 16px !important;
        ;

    }

    .readMore-button {
        font-size: 16px !important;
    }

    .secondary-hyperlink-text {
        font-size: 16px !important;
    }
}

/* luxury resorts mobile screen */
@media only screen and (min-width: 200px) and (max-width: 760px) {
    .view-resort-new-btn {
        margin-top: 30px;
    }

    .container-fluid-new {
        width: calc(100% - 11px) !important;
        margin-left: -8px !important;
    }

    .line-h {
        line-height: 1.8rem !important;
    }
}

.enquire-btn {
    margin-top: 30px;
}

@media only screen and (min-width: 200px) and (max-width: 760px) {
    .enquire-btn {
        margin-top: 30px !important;
    }

    .text-left-new {
        /* padding-right: 10px; */
        line-height: 1.7rem !important;
    }

    .new-pd-bt {
        margin-bottom: 3rem;
    }
}

.inner-updated-des {
    padding-left: 30px;
    padding-right: 30px;
}

/* conscious inner page */

.bg-description {
    margin-left: 5px;
    margin-right: 5px;
}

.updated-book-btn {
    margin-top: 20px;
}

.inner-updated-soneva {
    padding-left: 42px;
    padding-right: 42px;
}

.new-view-btn {
    margin-top: 30px;

}

/* 2023-summer-blog */

.new-summer-title {
    padding-top: 20px;
}

.new-summer-p {
    margin-left: 10px;
    display: flex;
    justify-content: center;
}

.web_mrgn_new {
    margin-top: 140px;
}

.new-blog-title {
    font-size: 26px;
    color: #4c4442 !important;
    line-height: 1.3em;
    margin-bottom: 5px;
}

.second-content {
    margin-top: 70px;
    margin-bottom: 6rem;
}

.new-content-b-p {
    padding-left: 90px;
    padding-right: 90px;
    margin-top: 1.5rem;
}

.nmb-1 {
    margin-bottom: 4.5rem;
}

.mt-7 {
    margin-top: 2rem;
}

.mt-8 {
    margin-bottom: 10px !important;
}

.s-blog-title {
    margin-top: 7rem !important;
}

.mb-10 {
    margin-bottom: 7rem;
}

.container-fluid-new {
    width: calc(100vw - 18%);
    margin: auto;
    margin-bottom: 5rem;
}

/* inner offers changes */


/* .inner-bg-image {
    height: 85vh;
    margin-bottom: 6rem;
    position: relative; 
  } */


/* dropdown hover color fix */
.active {
    background-color: #ececec00 !important;
}

/* .dot:hover{
    background-color: #ECECEC !important;
 }

 .carousel-container{
    max-width: 750px !important;
    width: 750px !important;
    background-color: #ECECEC !important;
 }

 .carousel-image{
    width: 750px !important;
 }

 .thumbnails{
    width: 750px !important;
 }

.slide-number{
    font-family: 'Bembo Regular' !important;
    font-size: 20px !important;
    color: #FFFFFF !important;
} */

.text-new-left {
    text-align: left !important;
    margin-top: -25px;
    margin-bottom: 6rem;
    width: 84%;
}

.new-inner-line-text {
    padding-bottom: 0px;
    /* color: #727272 !important; */
    margin-bottom: 15px;
    /* font-weight: bold !important; */
    font-family: 'Bembo Regular' !important;
    line-height: 1.5em !important;
    color: #7E6E64 !important;
    font-size: 18px;
}

.rich-new-inner-title {
    font-size: 26px;
    margin-bottom: 30px;
    color: #585858 !important;
    line-height: 1.3em;
}



.px-7 {
    padding-right: 2.5rem !important;
}

.bg-text1-color {
    color: white !important;
}

.height-inner-new {
    height: 11.5rem !important;
}

.rich-new-inner-offer-title {
    font-size: 22px;
    margin-bottom: 20px;
    margin-top: -17px;
    color: #4c4442 !important;
    line-height: 1.3em;
}

.offer-inner-price {
    font-family: 'Bembo Regular' !important;
    color: #333333 !important;
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    margin-bottom: 1px;
    line-height: 1.5rem;
}

.offer-inner-price-villa-text {
    font-family: 'Bembo Regular' !important;
    color: #979797 !important;
    font-size: 15px;
    line-height: 28px;
    font-weight: normal;
}

.secondary-text-price {
    margin-left: 2px;
    font-family: 'Bembo Regular' !important;
    color: #7E6E64 !important;
    font-size: 18px;
    line-height: 1.5rem;
    font-weight: normal;
}

.secondary-text-price2 {
    margin-left: 2px;
    font-family: 'Bembo Regular' !important;
    color: #7E6E64 !important;
    font-size: 13px;
    font-weight: normal;
}

.secondary-text-price3 {
    margin-left: 2px;
    font-family: 'Bembo Regular' !important;
    color: #7E6E64 !important;
    font-size: 13px;
    font-weight: normal;
    margin-top: -10px;
}

.secondary-text-price1 {
    margin-left: 2px;
    font-family: 'Bembo Regular' !important;
    color: #7E6E64 !important;
    font-size: 18px;
    line-height: 1.5rem;
    font-weight: normal;
    margin-top: 20px;
    font-style: italic;
}


.text-price-strikethrough {
    text-decoration: line-through;
}

.offer-inner-bb-text {
    font-family: 'Bembo Regular' !important;
    color: #333333 !important;
    font-size: 18px;
    line-height: 28px;
    font-weight: bold;
    line-height: 1.5rem;
}

.offer-inner-bb-secondary-text {
    font-family: 'Bembo Regular' !important;
    color: #979797 !important;
    font-size: 15px;
    line-height: 28px;
    font-weight: normal;
}

.new-inner-view-btn {
    padding: 13px 12px;
    display: inline-block;
    font-family: "Copperplate" !important;
    text-transform: uppercase;
    color: #7e6e64;
    overflow: hidden;
    letter-spacing: 0px;
    cursor: pointer;
    line-height: 9px;
    margin-left: 5px;
}

.main-carousel-offer-inner .fade:not(.show) {
    opacity: 1 !important;
}


/* view resort btn */
.underline-arrow-link {
    text-decoration: underline;
    position: relative;
    font-family: "Copperplate";
}

.underline-arrow-link::after {
    content: '\f101';
    font-family: 'FontAwesome';
    position: absolute;
    right: -12px;
    top: 50%;
    transform: translateY(-50%);
}


/* .underline-arrow-link:hover::after {
  right: -14px;
} */


.new-resort-inner-view-btn {
    padding: 14px 12px;
    display: inline-block;
    font-family: "Copperplate" !important;
    text-transform: uppercase;
    color: #7e6e64;
    overflow: hidden;
    letter-spacing: 0px;
    cursor: pointer;
    line-height: 9px;
    margin-left: 10px;
}

.mg-top-offers {
    margin-top: 100px !important;
}


.new-rich-container-pdn {
    padding: 170px 0px !important;
}

.section-with-bg {
    background-size: cover;
    background-position: center center;
}


@media screen and (max-width: 768px) {

    .main-carousel-offer-inner {
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-left: 14px;
    }

    .main-carousel-offer-inner .react-multiple-carousel__arrow {
        display: none !important;
    }

    .main-carousel-offer-inner .react-multi-carousel-dot-list {
        display: none !important;
    }

    .text-new-left {
        text-align: left !important;
        margin-top: 0px;
        margin-bottom: 6rem;

        width: 24rem;
    }

    .inner-imgs {
        width: 100%;
        height: 100%;
    }

    .hv-new-inner-offer {
        height: 14vh !important;
    }

    .carousel-grid-imgs {
        width: 100% !important;
        height: auto !important;
        margin-left: 14px !important;
    }
}

.main-carousel-offer-inner .react-multi-carousel-track {
    list-style: none;
    padding: 0px;
    margin: -15px !important;
}

.main-carousel-offer-inner .react-multiple-carousel__arrow--right {
    right: 0px !important;
    top: 42%;
}

.main-carousel-offer-inner .react-multiple-carousel__arrow--left {
    left: 0px !important;
    top: 42%;
}

.main-carousel-offer-inner .react-multi-carousel-dot-list {
    display: none !important;
}

/* .main-carousel-offer-inner .react-multiple-carousel__arrow {
    background: #00000047 !important;
}
 */

.main-carousel-offer-inner .react-multiple-carousel__arrow {
    background: #00000047 !important;
}

.main-carousel-offer-inner .react-multiple-carousel__arrow:hover {
    background: #00000099 !important;
}


.main-carousel-offer-inner .react-multiple-carousel__arrow::before {
    color: #fdfdfd !important;
}

.px-6 {
    padding-left: 4rem !important;
}

.new-px-6 {
    padding-left: 3rem !important;
}


@media only screen and (min-width: 200px) and (max-width: 768px) {
    .rich-container-new-offer {
        max-height: 284px !important;
    }

    .offer-white-font {
        color: #ebebeb;
        font-size: 16px !important;
        line-height: 28px;
        margin-bottom: -10px;
    }

    .offer-new-font-style {
        color: #f5f5f5 !important;
        font-size: 26px !important;
        line-height: 100%;
    }

    .new-offer-description {
        font-size: 16px !important;
        line-height: 134% !important;
        margin-top: -8px;
        text-align: center;
        color: #e3e3e3 !important;
        font-family: 'Bembo Regular' !important;
    }

    .fonts-offer-mobile {
        font-size: 18px !important;
        color: #4c4442 !important;
    }

    .margin-right-10 {
        margin-right: 0px !important;
    }

    .offer-grid-text-h {
        height: auto !important;
    }

    .offers-resorts-tag {
        min-height: 2rem !important;
        margin-bottom: 5px;
    }

    .carousel-images-inner {
        height: 235px !important;
    }

}

.offer-grid-text-h {
    height: 9rem;
}

.luxury-grid-text-h {
    height: 10rem;
}

.offers-resorts-tag {
    min-height: 42px;
}

.luxury-resorts-tag {
    min-height: 2.3rem;
}

.margin-right-10 {
    margin-right: 25px;
}

.bg-offer-overlay {
    position: relative;
}

.bg-offer-overlay::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 20%);
    /* change the color and opacity as desired */
}

.rich-container-new-offer {
    height: 560px !important;
    padding-top: 70px;
    margin-bottom: 6rem;
}


.offer-white-font {
    color: #ebebeb !important;
    font-size: 18px;
    line-height: 28px;
}


.offer-new-font-style {
    color: #f5f5f5 !important;
    font-size: 58px;
    line-height: 100%;
}

.new-offer-description {
    font-size: 40px;
    line-height: 115%;
    text-align: center;
    color: #d2d2d2;
    font-family: 'Bembo Regular' !important;
}

.img-inner {
    display: inline-block !important;
}

.inner-caro {
    justify-content: center !important;
}

.morecontent span {
    display: none;
}

.morelink {
    display: block;
}

.new-min-h {
    min-height: 36rem !important;
}

.new-offer-min-h {
    min-height: 21rem !important;
}

.new-offer-resort-view-btn {
    text-decoration: underline;
    position: relative;
    font-family: "Copperplate";
    display: inline-block !important;
    position: absolute;
    margin-top: 8px !important;
    margin-left: 31px !important;
    font-size: 15px;
}

.new-offer-resort-view-btn::after {
    text-decoration: none !important;
    display: inline-block !important;
    content: '\f101';
    font-family: 'FontAwesome';
    position: absolute;
    margin-left: 7px !important;
}

.rich-offer-title {
    font-size: 22px;
    margin-bottom: 9px;
    color: #4c4442 !important;
    line-height: 1.3em;
}

.padd-btm-18 {
    padding-bottom: 22px;
}

.resortTag {
    border: 1px solid #7e6e64;
    border-radius: 50px;
    padding: 5px 15px;
    color: #545454;
    font-family: 'Copperplate';
    font-weight: 700;
    margin-right: 10px;
}

.offer-pill-wrapper {
    margin: 10px 0px;
    cursor: pointer;
}

.offer-inner-more-benefits-mb-mt {
    margin-top: 15px;
    max-height: 84px;
    overflow: hidden;
}

.new-left-inner {

    height: 38rem !important;
}

@media screen and (max-width: 768px) {
    .offer-inner-more-benefits-mb-mt {
        margin-top: 10px;
        height: auto;
        overflow: hidden;
        max-height: none;
    }

    .inner-benefits-sec {
        margin-top: 0 !important;
        margin-bottom: 10rem;
    }

    .main-carousel-offer-inner .swiper-button-next {
        display: none !important;
    }

    .main-carousel-offer-inner .swiper-button-prev {
        display: none !important;
    }

    .new-left-inner {
        margin: 10px 30px;
        height: auto !important;
    }

    .new-min-h-sec {
        min-height: 24.5rem !important;
    }

    .mobile-bullet-list {
        color: #4c4442 !important;
        font-size: 18px !important;
    }

    .lux-mobile-bullet-list {
        color: #4c4442 !important;
        font-size: 18px !important;
    }

    .pt21 {
        padding-top: 0px !important;
    }

    .offer-bullet-list {
        font-size: 16px !important;
        line-height: 2rem !important;
        color: #7E6E64 !important;
    }


    .offer-bullet-list li:before {
        left: -8px !important;
    }

    .offer-bullet-list-main {
        font-size: 15px !important;
        line-height: 2rem !important;
        color: #7E6E64 !important;
    }


    .offer-bullet-list-main li:before {
        left: -8px !important;
    }

    .ds-content-title {
        font-style: italic;
        color: #595656 !important;
    }

    .offers-ds-content-title {
        color: #4c4442 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        padding: 0px 20px;
        font-style: italic;
    }

    .inner-offers-ds-content-title {
        color: #4c4442 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        padding: 0px 20px;
        font-style: italic;
        padding-bottom: 20px;
    }

    .offer-ds-content-title {
        color: #4c4442 !important;
        font-size: 16px !important;
        line-height: 24px !important;
    }

    .main-ds-content-title {
        font-style: italic;
        color: #595656 !important;
        margin-bottom: 17px !important;
        padding: 0 20px !important;
    }

    .ds-content-text1 {
        font-family: 'Bembo Regular' !important;
        font-size: 18px;
        color: #595656 !important;
        padding: 0 2px !important;
        color: #4c4442 !important;
        font-size: 18px !important;
        padding: 0 20px !important;
    }

    .ds-content-text2 {
        font-family: 'Bembo Regular' !important;
        font-size: 18px;
        color: #041822 !important;
        padding: 0 2px !important;
        color: #4c4442 !important;
        font-size: 18px !important;
        padding: 0 20px !important;
    }

    .offer-ds-content-text2 {
        font-size: 16px !important;
        line-height: 24px !important;
        font-family: 'Bembo Regular' !important;
        color: #7E6E64 !important;
        padding: 0 25px !important;
    }

    .offer-ds-content-text1 {
        font-size: 16px !important;
        line-height: 24px !important;
        font-family: 'Bembo Regular' !important;
        color: #7E6E64 !important;
    }


    .ds-contact {
        padding: 0 20px !important;
    }

    .offers-ds-contacts {
        padding: 0 20px !important;
    }

    .sec-new-min-h {
        min-height: 24.9rem !important;
    }

    .sec-lists-new-min-h {
        min-height: 20.9rem !important;
    }

    .offers-ds-main-title {
        color: #4c4442 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        font-family: 'Bembo Regular' !important;
        font-style: italic;
    }

    .ds-image-mobile {
        height: 327px !important;
    }

    .review-sec-resorts {
        padding-top: 0px !important;
    }

    .reviews-sec-resorts {
        padding-top: 0px !important;
    }


    .mt-m11 {
        margin-top: -18px !important;
    }

    .ds-min-h {
        min-height: 34.7rem !important;
    }

    .offer-inner-ls {
        line-height: 20px;
    }

    .inner-more-text {
        font-family: 'Bembo Regular' !important;
        color: #7E6E64 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        display: none;
    }


    .mt-19 {
        margin-bottom: 0rem !important;
        margin-top: 2rem !important;
    }

    .mt-21 {
        height: 20.8rem !important;
    }

    .mt-25 {
        height: 24rem !important;
    }

    .mt-22 {
        height: 26rem !important;
    }

    .height-ds-mob {
        height: 37.5rem;
    }

    .ds-image {
        height: 241px !important;

    }

    .h-18 {
        height: 23rem !important;
    }

    .h-19 {
        height: 20rem !important;
    }

    .mbl-height {
        height: 22rem !important;
    }

    .h-21 {
        height: 27rem !important;
    }

    .main-wrapper-grid {
        height: auto !important;
    }

    .main-wrapper-grid1 {
        height: 500px !important;
    }

    .inner-main-wrapper-grid1 {
        height: 500px;
    }

    .main-wrapper-grid2 {
        height: 480px !important;
    }

    .inner-main-wrapper-grid2 {
        height: 400px;
    }

    .main-wrapper-grid3 {
        height: 600px !important;
    }

    .inner-main-wrapper-grid3 {
        height: 600px !important;
    }

    .resort-wrapper-grid {
        height: auto !important;
    }

    .sub-menu-scoll {
        height: 267px !important;
        overflow: auto;
    }

    .resort-wrapper-grid1 {
        height: 500px !important;
    }

    .inner-reviews-sec {
        margin-left: 2rem;
        padding-top: 1rem;
        width: calc(100vw - 10%) !important;
    }

    .inner-benefits-sec {
        margin-left: 2rem;
        width: calc(100vw - 10%) !important;
    }

    .inner-h-19 {
        height: 21.5rem !important;

    }

    .inner-ml1 {
        margin-left: 25px;
    }

    .inner-ds-image {
        height: 182px;
        width: 82%;
    }

    .content-ds-image {
        height: 182px !important;
        width: 100%;
    }

    .ds-card {
        width: calc(100vw) !important;
        height: 215px !important;
    }

    .ds-sym {
        width: 20px !important;
    }

    .inner-ds-text {
        /* width: 15.5rem !important; */
        font-size: 12px;
        padding-top: 24px !important;
    }

    .inner-ds-text-sign {
        font-size: 13px !important;
    }

    .inner-ds-text2 {
        font-size: 12px !important;
    }

    .ds-card-content {
        padding-top: 0px !important;
    }

    .inner-ds-image {
        margin-left: 1.2rem;
    }

    .mbl-ds-content {
        padding-left: 2rem;
    }

    .inner-mbl-left {
        padding-left: 3.3rem;
    }

    .ds-img-sec-pt {
        padding-top: -3rem !important;
    }

    .footer-ds-wrapper {
        width: 120px !important;
        height: 125px !important;
        margin: auto;
    }

    .footer-mobile-wrapper {
        padding: 22px 0px 0px 12px;
    }

    .ds-name-mobile {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .footer-ds-img-wrapper {
        width: 180px;
        height: 200px !important;
    }

    .whatsapp-num-footer {
        font-size: 16px !important;
        margin-bottom: 10px;
        padding-top: 5px;
    }

    .ds-whatsapp-icon {
        display: flex;
        align-items: center;
    }

    .f-mobile-text {
        font-size: 16px;
        text-align: center !important;
        font-weight: 600;
        margin-top: 24px !important;
    }

    .f-mobile-center {
        text-align: center;
        justify-content: center;
    }

    .whatsapp-icon-footer {
        font-size: 22px !important;
        margin-left: 13px !important;
    }

    .f-mobile-chat-btn {
        text-decoration: underline;
        font-family: "Copperplate";
        display: inline-block !important;
        margin-top: 8px !important;
        /* margin-left: 23px !important; */
        font-size: 15px;
    }

    .f-mobile-chat-btn::after {
        text-decoration: none !important;
        display: inline-block !important;
        content: '\f101';
        font-family: 'FontAwesome';
        position: absolute;
        margin-left: 7px !important;
    }

    .mb-mr-footer-mobile {
        margin-bottom: 15px;
        margin-right: 28px;
    }

    .margin-footer-mbl {
        margin-right: 28px;
    }

    .mobile-footer-mt {
        margin-top: 4px;
    }

    .footer-mobile-buttons .mobile-footer-mt .cta-underline-btn {
        font-size: 12px !important;
    }

    .mb-8-footer {
        margin-bottom: 8px;
    }
}

.inner-more-text {
    font-family: 'Bembo Regular' !important;
    color: #7E6E64 !important;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0px;
}

.offer-btn-mt {
    margin-top: 15px !important;
}


.masonary-offers-usd-text {
    text-decoration: line-through;
}

.masonary-offers-bb-text {
    line-height: 28px;
    font-weight: bold;
}

.new-description-header-offer-inner {
    font-size: 18px;
    padding: 0px;
    color: #6e6563;
    line-height: 1.7rem;
    width: calc(100vw - 25%);
    margin: auto;
}

/* for all landing pages header descriptions*/
.updated-header-description {
    font-size: 18px;
    padding: 0px;
    color: #6e6563;
    line-height: 1.7rem;
    width: calc(100vw - 25%);
    margin: auto;
}

.mt-mb-10 {
    margin-top: -36px;
    margin-bottom: 4rem;
}

.rich-container-inner-pdn {
    padding: 120px 0px 44px;
}

.uppercase-text {
    text-transform: uppercase;
}

.luxury-secondary-description-text {
    font-size: 14px;
    color: #7E6E64 !important;
    line-height: 1.7rem;
    font-family: 'Bembo Regular' !important;
    text-align: left;
    line-height: 1.7rem;
    width: calc(100vw - 1%);
    margin: auto;
}

.secondary-hyperlink-text {
    color: #6b6bd6 !important;
    font-size: 21px;
    font-family: 'Bembo Regular' !important;
}

a.secondary-hyperlink-text:hover {
    color: #6b6bd6;
    text-decoration: none;
}


.rich-container-new-pdn {
    padding: 100px 0px 80px;
}

.luxury-grid-text-h .new-line-resort {
    white-space: pre-line !important;
}

.resorts-reviews {
    width: calc(100vw - 25%);
    margin: auto;
    margin-top: 6rem;
    height: 20rem;
}

.reviews-title {
    font-size: 26px;
    color: #4c4442 !important;
    line-height: 1.3em;
    margin-top: 20px;
    margin-bottom: 25px;
}

.assistant-details-title {
    font-size: 26px;
    color: #4c4442 !important;
    line-height: 1.3em;
}

.m-b-15 {
    margin-bottom: 10px;
}

.details-secondary-text {
    font-family: 'Bembo Regular' !important;
    color: #7E6E64 !important;
    font-size: 14px;
}

.text-b {
    font-weight: bold;
    color: #4c4442;
}

.mt-11 {
    margin-top: 10px;
}

.mt-13 {
    margin-top: 30px;
}

.mt-14 {
    margin-top: 24px;
}

.mb-14 {
    margin-bottom: 4.4rem;
}

.ml-mr {
    margin-left: 5px;
    margin-right: 5px;
}

.offers-third-text {
    font-family: 'Bembo Regular' !important;
    line-height: 1.5rem !important;
    color: #7E6E64 !important;
    font-size: 13px;
}

.ds-content-title {
    font-size: 20px;
    color: #000 !important;
    line-height: 1.3rem;
    margin-top: 1px;
    margin-bottom: 3px;
    padding: 0;
    font-style: italic;
    font-weight: 600;
}


.offers-ds-content-title {
    font-family: 'Bembo Regular' !important;
    font-size: 18px;
    color: #4c4442;
    line-height: 1.3rem;
    margin-top: 1px;
    margin-bottom: 3px;
    padding: 0px 14px 0px 19px;
    font-style: italic;
    font-weight: 600;
}

.inner-ds-content-title {
    font-family: 'Bembo Regular' !important;
    font-size: 18px;
    color: #4c4442;
    line-height: 1.3rem;
    margin-top: 1px;
    margin-bottom: 10px;
    padding: 0px 14px 0px 19px;
    font-style: italic;
    font-weight: 600;
}

.main-ds-content-title {
    font-size: 22px;
    color: #041822 !important;
    line-height: 1.3rem;
    margin-top: 23px;
    margin-bottom: 37px;
    padding: 0px 14px 0px 19px;
    font-style: italic;
}

.ds-content-text {
    font-family: 'Bembo Regular' !important;
    font-size: 18px;
    color: #7E6E64 !important;
    line-height: 1.3em;
    margin-bottom: 1rem;
}

.inner-ds-content-text {
    font-family: 'Bembo Regular' !important;
    font-size: 18px;
    color: #7E6E64 !important;
    line-height: 1.3em;
    margin-bottom: 1rem;
    display: inline-block;
}

.inner-ds-content-text2 {
    font-family: 'Bembo Regular' !important;
    font-size: 18px;
    color: #7E6E64 !important;
    line-height: 1.3em;
    margin-bottom: 1rem;
    display: inline-block;
}


.ds-content-text1 {
    font-family: 'Bembo Regular' !important;
    font-size: 18px;
    color: #7E6E64;
    line-height: 1.3em;
    margin-bottom: 10px;
    padding: 0 22px;
}

.ds-content-text2 {
    font-family: 'Bembo Regular' !important;
    font-size: 18px;
    color: #7E6E64;
    line-height: 1.3em;
    margin-bottom: 1rem;
    padding: 0 0 0 22px;
}

.offer-ds-content-text1 {
    font-family: 'Bembo Regular' !important;
    font-size: 18px;
    color: #7E6E64;
    line-height: 1.3em;
    margin-bottom: 1rem;
    padding: 0 25px;
    font-style: italic;
}

.offer-ds-content-text2 {
    font-family: 'Bembo Regular' !important;
    font-size: 18px;
    color: #7E6E64;
    line-height: 1.3em;
    margin-bottom: 1rem;
    padding: 0 0 0 25px;
    font-style: italic;

}

.ds-contact {
    padding: 0 22px;
}

.offers-ds-contacts {
    padding: 0 25px !important;
}

.review-sec-resorts {
    width: 100%;

}



.bullet-list {
    font-family: 'Bembo Regular' !important;
    font-size: 18px;
    color: #7E6E64;
    line-height: 2em;
    margin-bottom: 1rem;
    margin-top: 18px;
    font-style: italic;
}

.lux-bullet-list {
    font-family: 'Bembo Regular' !important;
    font-size: 18px;
    color: #7E6E64;
    line-height: 2.3rem;
    margin-bottom: 1rem;
    margin-top: 18px;
    font-style: italic;
    padding-left: 70px;

}

.pt21 {
    padding-top: 10px;
}

.offer-bullet-list {
    font-family: 'Bembo Regular' !important;
    font-size: 18px;
    color: #041822;
    line-height: 2.3rem;
    margin-bottom: 1rem;
    margin-top: 18px;
    font-style: italic;
    padding-left: 50px;
    list-style: none;
}



.popup_new {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 50px;
    background-color: white;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.popup_new.sticky {
    position: fixed;
}


.close-icon {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    cursor: pointer;
}


.new-min-h-sec {
    min-height: 36.5rem;
}

.sec-new-min-h {
    min-height: 36.9rem;
}

.sec-lists-new-min-h {
    min-height: 36.9rem;
}

.offers-ds-main-title {
    padding: 0px 25px;
    font-style: italic;
}

.inner-ds-main-title {
    font-size: 26px;
    margin-bottom: 15px;
    color: #4c4442 !important;
    line-height: 1.3em;
    font-style: italic;
    padding-left: 25px;
    padding-right: 25px;
}

.mt-m11 {
    margin-top: 0px;
}

.ds-min-h {
    min-height: 36.7rem;
}

.night {
    font-style: italic;
}

.mt-12 {
    margin-top: 15px;
}

.fs-1 {
    font-style: italic;
}

.fs-2 {
    font-style: normal;
}

.ml-10 {
    margin-left: 40px;
}

.mh-15 {
    height: 60px;
}

.mt-15 {
    margin-top: 8.7rem
}

.mt-16 {
    margin-top: 6.6rem;
}

.mt-17 {
    margin-top: -9px;
    margin-left: 21px;
}

.mt-18 {
    margin-top: 1rem;

}


.ml-18 {
    margin-left: 30px;
    margin-bottom: 0px;
}

.mt-19 {
    margin-top: 81px;
    margin-left: 24px;
    margin-bottom: 0px;
}

.mt-20 {
    margin-top: 42px;
    margin-left: 22px;
}

.v-text {
    font-size: 18px;
    font-family: 'Bembo Regular' !important;
    color: #7E6E64 !important;
    font-weight: 100;
}

.new-sections-min-h {
    min-height: 43rem;
}


.h-18 {
    height: 33rem;
}

.h-19 {
    height: 30.2rem;
}

.mbl-height {
    height: 28.6rem;
}

.h-21 {
    height: 32.6rem;
}

.mt-21 {
    height: 34.8rem;
}

.mt-25 {
    height: 34rem;
}

.mt-22 {
    height: 27.8rem;
}

.main-wrapper-grid {
    height: 700px;
}

.main-wrapper-grid1 {
    height: 700px;
}

.inner-main-wrapper-grid1 {
    height: 500px;
}


.main-wrapper-grid2 {
    height: 700px;
}

.inner-main-wrapper-grid2 {
    height: 445px;
}

.main-wrapper-grid3 {
    height: 700px;
}

.inner-main-wrapper-grid3 {
    height: 600px;
}

.resort-wrapper-grid {
    height: 700px;
}

.resort-wrapper-grid1 {
    height: 700px;
}


.ds-card {
    display: flex;
    width: 909px;
    height: auto;
}

.ds-card-img {
    width: 280px;
    height: auto;
    padding-top: 30px;
    margin-left: 6rem;
}


.ds-card-content {
    justify-content: center;
    text-align: center;
    padding-top: 50px;
    padding-left: 28px;

}

.ds-sym {
    width: 55px;
    height: auto;
    float: left;
}

.inner-ds-text {
    width: 34rem;
    padding-top: 64px;
    text-align: end;
    color: #0d1720 !important;
}

.inner-ds-text-sign {
    font-family: "Gatteway Signature" !important;
    font-size: 30px;
    text-align: end;
    font-weight: bold;
    padding-top: 7px;
}

.inner-ds-text2 {
    text-align: end;
    color: #312d2d !important;
    font-family: 'Bembo Regular' !important;
    margin-top: 0px;
}

.ds-inner-content {
    width: 100%;
    margin-left: -6.5rem;
}

.ds-symbol {
    margin-left: 20px;
}


@media screen and (max-width: 1440px) {
    .main-wrapper-grid {
        height: 675px;
    }

    .main-wrapper-grid1 {
        height: 675px;
    }

    .inner-main-wrapper-grid1 {
        height: 508px;
    }

    .main-wrapper-grid2 {
        height: 600px;
    }

    .inner-main-wrapper-grid2 {
        height: 414px;
    }

    .main-wrapper-grid3 {
        height: 600px;
    }

    .inner-main-wrapper-grid3 {
        height: 549px;
    }


    .resort-wrapper-grid {
        height: 600px;
    }

    .resort-wrapper-grid1 {
        height: 600px;
    }

    .h-18 {
        height: 481px;
    }

    .inner-h-18 {
        height: 21.7rem;
    }

    .h-19 {
        height: 25rem;
    }

    .mbl-height {
        height: 25rem;
    }

    .inner-h-19 {
        height: 13rem;
    }

    .h-21 {
        height: 27.4rem;
    }

    .mt-21 {
        height: 465px;
    }

    .mt-25 {
        height: 29.1rem;
    }

    .mt-22 {
        height: 461px;
    }


    .ds-card {
        display: flex;
        padding: 10px;
        width: 676px;
        height: auto;
    }

    .ds-card-img {
        width: 236px;
        height: auto;
        padding-top: 30px;
        margin-left: 44px;
    }

    .inner-ds-img {
        padding-left: 3rem;
    }

    .ds-card-content {
        justify-content: center;
        text-align: center;
        padding-top: 50px;
        padding-left: 28px;

    }

    .ds-sym {
        width: 50px;
        height: auto;
        float: left;
    }

    .inner-ds-text {
        width: 23rem;
        padding-top: 64px;
        text-align: end;
        color: #0d1720 !important;
    }

    .inner-ds-text-sign {
        font-family: "Gatteway Signature" !important;
        font-size: 30px;
        text-align: end;
        font-weight: bold;
        padding-top: 7px;
    }

    .inner-ds-text2 {
        text-align: end;
        color: #312d2d !important;
    }

    .ds-img-sec-pt {
        padding-top: -4rem;
    }

    .ds-inner-content {
        width: 100%;
        margin-left: -5rem;
    }

    .ds-symbol {
        margin-left: 0px;
    }

}

.inner-h-18 {
    height: 20rem;
}

.r-sec {
    float: left;
}

.inner-mt-19 {
    margin-top: 1.7rem;
    margin-left: 24px;
    margin-bottom: 0px;
}

.resortTag.deal-tag {
    background: #ffe307;
    border-color: #c7b62c;
    color: #484848;
}

.resort-name-tag .resortTag.deal-tag {
    font-size: 12px;
    position: relative;
    top: 6px;
    padding: 5px 8px;
    letter-spacing: 0.5px;
    margin-left: 10px;
}

.offer-pill-wrapper.resort-name-tag {
    margin: 0px;
}

.resort-title-tag-main-wrapper {
    display: flex;
    width: 100%;
    justify-content: start;
    align-items: start;
}

.resort-inner-title-tag-wrapper {
    min-width: fit-content;
    text-align: right;
}

.swiper-pagination.swiper-pagination-fraction.swiper-pagination-horizontal {
    position: absolute;
    bottom: 6px;
    text-align: left;
    padding-left: 10px;
    width: fit-content;
    margin-left: 6px;
    padding: 0.25rem 0.5rem;
    border-radius: 2px;
    background-color: rgba(54, 58, 69, 0.8);
    color: rgb(255, 255, 255);
    font-size: 12px;
    line-height: 16px;
}

.swiper-button-next,
.swiper-button-prev {
    background: #00000047 !important;
    color: #fdfdfd !important;
    width: 40px !important;
    height: 40px !important;
    transition: all .5s;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    font-size: 20px !important;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
    background: #00000099 !important;
}

/* new login form start */

@media screen and (max-width: 768px) {

    .login-popup-body {
        left: 0 !important;
        right: 0 !important;
        bottom: 27rem !important;
        width: 388px !important;
        height: 587px !important;
    }

    .login-second-text {
        padding-left: 40px !important;
        padding-right: 42px !important;
        line-height: 1.3 !important;
    }

    .continue-btn {
        margin-top: 20px !important;
    }

    .login-email {
        margin-top: 20px;
    }

    .login-close-icon {
        padding-right: 40px !important;
    }

    .login-logo-icon {
        padding-left: 65px !important;
        padding-bottom: 6px;
    }

    .enquiry-popup-body {
        margin-top: 6px !important;
        height: 660px !important;
        padding: 43px 0 15px !important;
        width: 388px !important;
    }

    .checkbox-enquir-sec3 .MuiFormLabel-root {
        margin-bottom: 7px !important;
    }

    .checkbox-enquiry4 .MuiFormLabel-root {
        margin-bottom: 14px !important;
    }

    .mb-11 {
        margin-bottom: 0px !important;
    }

    .pt-15 {
        padding-top: 15px !important;
    }

    /* .en-form4{
        overflow: hidden  !important;
        height: 480px !important;
    } */

    .p-n-btn {
        margin-left: 24px;
    }

    .bg-c-login {
        background-color: #ffffff !important;
    }

    p.inner-ds-text.footer-ds-section {
        font-size: 15px !important;
        padding-left: 2px;
    }

    .footer-mobile-buttons {
        margin-top: 12px;
    }
    .enquiry-inputs {
        padding-left: 45px;
        padding-right: 45px;
    }


}

/* .login-popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    
} */

.login-popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999999;
}

.login-popup-body {
    /* position: absolute;
    left: 30%;
    right: 30%;
    bottom: 30%;
    top: 47%; */
    text-align: center;
    margin: auto;
    border-color: black;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    min-height: 510px;
    height: 520px;
    width: 440px;
}

.login-close-icon {
    float: right;
    font-size: 25px;
    font-weight: 200;
    cursor: pointer;
    background-color: transparent;
    padding-right: 20px;
    padding-top: 20px;
}

.login-logo-icon {
    padding-top: 40px;
    padding-left: 30px;
}

.login-title {
    color: #4C4442 !important;
    font-size: 22px;
    line-height: 100%;
    font-family: 'Bembo Regular' !important;
    padding-top: 20px;
}

.login-second-text {
    color: #4C4442 !important;
    font-size: 14px;
    line-height: 100%;
    font-family: 'Bembo Regular' !important;
    padding-top: 5px;
}

.login-form-content {
    padding-left: 8%;
    padding-right: 8%;
}

.login-form-label {
    font-size: 16px;
    font-weight: 200;
    color: #4C4442 !important;
    float: left;
    font-family: 'Bembo Regular' !important;
}

.login-form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.continue-btn {
    padding: 6px 35px;
    border-radius: 0px;
    color: #fff;
    background-color: #493737;
    border: 0px;
    font-family: "Copperplate";
    box-shadow: none;
    border-radius: 30px;
    margin-top: 6px;
}

.login-email {
    height: 70px;
}

.login-privacy-text {
    color: #4C4442 !important;
    font-size: 12px;
    line-height: 100%;
    font-family: 'Bembo Regular' !important;
    padding-top: 30px;
    line-height: 16px;
    padding-bottom: 20px;
}

.login-media-icons {
    display: flex;
    justify-content: center;
    padding-top: 5px;
    margin-left: 15px;
}

.login-media-icons span {
    margin-right: 15px;
    cursor: pointer;
}

.login-media-icons span:hover {
    opacity: 1;
}

.login-or-text {
    display: flex;
    align-items: center;
    text-align: center;
    font-family: 'Bembo Regular' !important;
}

.login-text {
    font-family: 'Bembo Regular' !important;
    color: #4C4442 !important;
    font-size: 16px;
}

.login-or-text hr {
    flex-grow: 1;
    border: none;
    height: 1px;
    /* background-color: #cacaca; */
}

.login-or-text p {
    margin: 0 10px;
}

.more-signin-sec {
    padding-top: 20px;
}

.offer-btn-bc {
    background-color: transparent;
}

.r-text {
    font-family: 'Bembo Regular' !important;
    font-weight: bold;
    color: #4C4442 !important;
    padding-left: 6px;
}

.r-login-media-icons {
    display: flex;
    justify-content: center;
    padding-top: 30px;
    cursor: pointer;
}

.en-resort-title {
    font-size: 20px;
    margin-bottom: 5px;
    color: #4c4442 !important;
    line-height: 1.3em;
    font-family: 'Bembo Regular' !important;
    text-align: left;
}

.resort-des-pt {
    padding-top: 7px;
}


.en-resort-name-ml-pt {
    text-align: left;
    margin-bottom: 8px !important;
}

.pt-20 {
    padding-top: 4px;
}

.pr-10 {
    padding-right: 25px;
}

/* new login form end */


/* enquiryForm start */
.enquiry-popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999;

}

.enquiry-popup-body {
    background-color: #fff;
    padding: 43px 0 25px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 440px;
    min-height: 523px;
    /* margin-top: 116px; */
    font-family: 'Bembo Regular' !important;
    /* overflow-y: auto; */
    height: 630px;
}

/* Close button */
.enquiry-close-icon {
    float: right;
    font-size: 25px;
    font-weight: 200;
    cursor: pointer;
    background-color: transparent;
    padding-right: 30px;
    margin-top: -30px;
}

.enquiry-main-title {
    text-align: center;
    font-size: 26px;
    padding-left: 40px;
    padding-right: 40px;
    font-family: 'Bembo Regular' !important;
    color: #4C4442 !important;
    line-height: 100%;
}

.enquiry-main-title2 {
    text-align: center;
    font-size: 26px;
    font-family: 'Bembo Regular' !important;
    color: #4C4442 !important;
    line-height: 100%;
}

.enquiry-ml-mt {
    padding-top: 10px;
}

.ml-pt {
    padding-top: 20px;
}

.mt-23 {
    margin-top: 20px;
}

.mt-24 {
    margin-top: 35px;
}

.margin-top-19 {
    margin-top: 19px;
}

.enquiry-form-label {
    font-size: 16px;
    font-weight: 200;
    color: #4C4442 !important;
    float: left;
    font-family: 'Bembo Regular' !important;
}

.enquiry-inputs {
    padding-left: 50px;
    padding-right: 50px;
}

.enquiry-inputs .MuiFormControl-root.MuiTextField-root {
    width: 100% !important;
    margin: 20px 0px !important;
}


/* Buttons */
.enquiry-buttons button {
    cursor: pointer;
    padding: 0px 35px;
    color: #493737;
    font-family: 'Bembo Regular' !important;
    background-color: #ffffff;
    margin-top: 0 !important;
}

.sec4-btn {
    cursor: pointer;
    padding: 0px 35px;
    color: #493737;
    font-family: 'Bembo Regular' !important;
    background-color: #ffffff;
    margin-top: 30px;
}

.enquiry-buttons button.enquiry-submit-btn {
    padding: 6px 35px;
    border-radius: 0px;
    color: #fff;
    background-color: #584d46;
    border: 0px;
    font-family: "Copperplate" !important;
    box-shadow: none;
    border-radius: 30px;
    text-transform: uppercase;
}

.enquiry-next-btn::after {
    text-decoration: none !important;
    display: inline-block !important;
    content: '\f101';
    font-family: 'FontAwesome';
    position: absolute;
    margin-left: 7px !important;
}

.enquiry-back-btn::before {
    text-decoration: none !important;
    display: inline-block !important;
    content: '\f100';
    font-family: 'FontAwesome';
    margin-right: 7px !important;
}

.date-label-mb {
    margin-bottom: 0px;
}

.travel-details-inputs {
    padding-top: 30px;
}

.num-night {
    padding-top: 8px;
}

.pt-10 {
    padding-top: 14px;
}

.p-top-10 {
    padding-top: 10px;
}

.pt-25 {
    padding-top: 25px;
}

.pt-11 {
    padding-top: 35px;
}

.pt-12 {
    padding-top: 25px;
}

.p-n-btn {
    /* padding-top: 19px; */
    margin-right: 44px;
}

.num-night .MuiFormLabel-root {
    margin-bottom: 1px;
}

.resort-name .MuiFormLabel-root {
    margin-bottom: 1px;
}

.villa-name .MuiFormLabel-root {
    margin-bottom: 1px;
}

.pt-15 {
    padding-top: 8px;
}

.pt-16 {
    padding-top: 5px;
}

.enquiry-dropdown {
    width: 100%;
    height: 38px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    font-family: 'Bembo Regular' !important;
    margin-bottom: 20px;
}

.enquiry-dropdown:focus {
    outline: none;
    border-color: #007bff;
    /* box-shadow: 0 0 15px #ffc107; */
}

.check-box {
    text-align: left;
}

.form-check-input {
    accent-color: #795548 !important;
}


.pax-child .ag-range {
    margin-top: 25px;
}

.num-night .MuiInputBase-input {
    padding: 6px 0 0px;
}

.date-travel .num-night .MuiInputBase-input {
    padding: 0px 0 7px;
}

.date-travel .rs-picker-toggle-wrapper {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
}

.date-travel .rs-btn.rs-btn-default.rs-picker-toggle {
    margin-top: 11px;
}

.date-travel .rs-picker-toggle-value {
    font-size: 15px;
}

.date-travel .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    margin-left: -10px;
}

.date-travel .rs-picker-toggle-placeholder {
    font-family: 'Bembo Regular' !important;
    color: #4d4d4d !important;
    margin-left: -10px;
}

.pax-child .MuiInputBase-input {
    padding: 4px 0 0px;
}

.country-enquiry .MuiInputBase-input {
    padding: 0px 0 0px;
}

.preference {
    /* padding-bottom: 122px; */
    min-height: 364px;
}

.radio-button-form {
    padding-top: 4px;
}

.pt-13 {
    padding-top: 20px;
}

.scroll-down-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: #f1f1f1;
}

.scroll-down-icon span {
    font-size: 14px;
    margin-bottom: 5px;
}

.scroll-down-icon svg {
    width: 24px;
    height: 24px;
    stroke: #555;
}

.enquiry-inputs-check {
    padding-left: 50px;
    padding-right: 50px;
}

.enquiry-sub-title {
    font-size: 16px;
    padding-left: 38px;
    font-family: 'Bembo Regular' !important;
    color: #4C4442 !important;
    line-height: 19px;
    text-align: left;
    padding-top: 10px;
    padding-right: 40px;
}

.checkbox-enquiry4 .MuiFormLabel-root {
    margin-bottom: 2px;
}

.mr-12 {
    margin-right: 28px;
}

.checkbox-enquiry4 label {
    margin-bottom: 0px;
}

.checkbox-enquir-sec3 label {
    margin-bottom: 1rem;
}

.mb-11 {
    margin-bottom: 0px;
}

.radio-button-en-form {
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: #4C4442 !important;
    font-family: 'Bembo Regular' !important;
}

.t-left {
    text-align: left;
}

.pt-22 {
    padding-top: 20px;
}

.pt-17 {
    padding-top: 5px;
}

.pt-40 {
    padding-top: 40px;
}

.pt-18 {
    padding-top: 26px;
}

.en-offer-text1 {
    font-family: 'Bembo Regular' !important;
    line-height: 1.5em !important;
    color: #7E6E64 !important;
    font-size: 17px;
    text-align: left;
    margin-top: 5px;
}

.en-offer-text2 {
    font-family: 'Bembo Regular' !important;
    line-height: 1.5em !important;
    color: #7E6E64 !important;
    font-size: 13px;
    text-align: left;
    margin-top: 0px;
}

.offer-content-sec {
    padding-top: 30px;
    height: 13rem;
}

.en-travel-sec {
    min-height: 30rem;
}

.en-error-msg {
    color: #e02a2a !important;
    font-family: 'Bembo Regular' !important;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    text-align: left;
    margin-top: -12px;
}

.en-error-msg li {
    margin: 20px;
}

.pb-16 {
    padding-bottom: 82px;
}

.pb-104 {
    padding-bottom: 104px;
}

.pt-14 {
    padding-top: 8px;
}

.pt-19 {
    padding-top: 18px;
}

.height-text1 {
    min-height: 42px;
}

.enquiry-buttons {
    display: flex;
    justify-content: center;
    /* padding-top: 20px */
}

/* enquiryForm end */


.readMore-btn-container {
    display: flex;
    justify-content: center;
}

.readMore-button {
    background-color: transparent;
    color: #4c4442 !important;
    font-size: 18px;
    font-family: 'Bembo Regular' !important;
    font-weight: bold;

}

.desc-read-btn {
    height: auto;
    padding-top: 12px;
}

.plus-icon {
    color: #0d1720 !important;
    font-size: 16px;
    font-family: 'Bembo Regular' !important;
    font-weight: 600 !important;
    padding-top: 14px;
    background-color: transparent;
    margin-left: 10px;
}

.minus-button {
    color: #0d1720 !important;
    font-size: 20px;
    font-family: 'Bembo Regular' !important;
    font-weight: 500 !important;
    background-color: transparent;
}

.header-sub-description {
    font-size: 18px;
    padding: 0px;
    color: #6e6563 !important;
    line-height: 1.7rem;
    width: calc(100vw - 25%);
    margin: auto;
}

.sub-des-bold {
    color: #4c4442;
    font-weight: bold;
}

.p-left {
    padding-left: 20px;
}

.rich-new-p {
    padding: 70px;
}

/* exit popup */
.main-image {
    width: 100%;
}

.main-offer-wrapper {
    background: white;
}

.exitpop-main-resort-title {
    justify-content: center !important;
}

.main-offer-details-wrapper {
    border-right: 1px dashed #d1c0b6;
}

.main-offer-details-wrapper {
    border-right: 1px dashed #d1c0b6;
}

.button-wrapper {
    display: flex;
    justify-content: center;
}

.button-wrapper .cta-underline-btn {
    display: block !important;
}

.button-wrapper .new-offer-resort-view-btn {
    display: block !important;
    position: relative;
}

/* inner static content */

.inner-reviews-sec {
    width: calc(100vw - 23%);

}

.inner-benefits-sec {
    width: calc(100vw - 20%);
    margin-top: -5rem;
}

.sec-inner-one {
    display: inline-block;
}

.sec-inner-two {
    display: inline-block;
}

.inner-benefits-btn {
    margin-bottom: 1rem;
    display: inline-block;
}

.inner-ml-1 {
    margin-left: 15px;
}


.inner-mt-14 {
    margin-top: 38px;
}

.footer-button-wrapper {
    margin-top: 16px;
}

p.inner-ds-text.footer-ds-section {
    width: auto;
    text-align: left;
    font-family: 'Bembo Regular' !important;
    font-size: 16px;
}

.footer-ds-wrapper {
    width: 200px;
    height: 200px;
}

.footer-text-wrapper .ds-card-content {
    padding: 0px;
    padding-left: 0px;
}


.footer-text-wrapper p.inner-ds-text-sign {
    text-align: left;
}

.footer-text-wrapper p.inner-ds-text2 {
    text-align: left;
}



.offer-bullet-list li {
    position: relative;
    margin-top: 15px;
}

.offer-bullet-list span {
    margin-left: 15px;
}


.offer-bullet-list-main li {
    position: relative;
    margin-top: 10px;
    margin-bottom: 20px;
}

.offer-bullet-list-main span {
    margin-left: 15px;
}


.consulting-input-filed-prepend {
    display: flex;
}

.see-more-text-lux {
    /* text-decoration: underline; */
    font-family: "Copperplate" !important;
    font-size: 15px;
    background: none !important;
    cursor: pointer;
}

.new-chat-btn {
    text-decoration: underline;
    font-family: "Copperplate";
    display: inline-block !important;
    margin-top: 8px !important;
    margin-left: 23px !important;
    font-size: 15px;
    cursor: pointer;
}

.new-chat-btn::after {
    text-decoration: none !important;
    display: inline-block !important;
    content: '\f101';
    font-family: 'FontAwesome';
    position: absolute;
    margin-left: 7px !important;
}

.whatsapp-icon-footer {
    font-size: 30px;
    margin-left: 28px;
    padding-top: -7px;
}

.whatsapp-icon-footer i {
    color: #0d1720 !important;
}

.whatsapp-num-footer {
    font-weight: 500;
    font-size: 16px;
    font-family: "Copperplate";
    color: #0d1720;
    padding-left: 9px;
    padding-right: 23px;
}

.hint-ds-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 8px;
}

.hint-ds-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hint-sign-text {
    font-family: "Gatteway Signature" !important;
    font-size: 17px;
    font-weight: bold;
    margin-top: 8px;
    text-align: center;
    margin-bottom: 38px;
}


.wrapper-image-sec {
    margin: 10px 0px;
}

.bg-color-none {
    background-color: transparent;
}

.nav-logo-pl {
    padding-left: 18px;
}

.f-ml-11 {
    margin-left: 24px;
}

.f-pt-3 {
    padding-top: 3px;
}

.f-mt-1 {
    margin-top: 7px;
}

.refer-checkbox .MuiFormControlLabel-root {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    -webkit-tap-highlight-color: transparent;
    margin-left: -11px;
    margin-right: 0px;
}

.refer-checkbox label {
    display: inline-block;
    margin-bottom: 0rem;
}

.en-travel-sec .referMail .enquiry-inputs .MuiFormControl-root.MuiTextField-root {
    width: 100% !important;
    margin: 0px 0px !important;
}

.en-travel-sec .referMail .MuiFormControl-root.MuiTextField-root {
    width: 100% !important;
    margin: 0px 0px 0px 0 !important;
}

.en-travel-sec .referMail .MuiFormControl-root {
    width: 93% !important;
    margin: 0px !important;
}

.visiting-again-text {
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: #736262 !important;
    font-family: 'Bembo Regular' !important;
    padding-left: 20px;
    padding-bottom: 1px;
}

.referred-text {
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: #736262 !important;
    font-family: 'Bembo Regular' !important;
    padding-bottom: 7px;
}

.travel-history .referMail label+.MuiInput-formControl {
    margin-top: 16px;
    margin-bottom: 10px;
}



.checkbox-enquir-sec3 .PrivateSwitchBase-root-1 {
    padding:0 9px;
}

.checkbox-enquir-sec3 .MuiFormLabel-root {
    margin-bottom: 7px;
    margin-top: 12px;
}

.enquiry-content-wrapper {
    padding: 4px 50px 0px 50px;
}

.enquiry-content-main-title {
    font-size: 15px;
    color: #4c4442 !important;
    font-family: 'Bembo Regular' !important;
    line-height: 13px;
    font-weight: 600;
}

.enquiry-content-title-text {
    font-size: 14px;
    color: #7E6E64 !important;
    font-family: 'Bembo Regular' !important;
    line-height: 19px;
}

.en-contact-sec .enquiry-inputs .MuiFormControl-root.MuiTextField-root {
    width: 100% !important;
    margin: 17px 0px 18px 0px !important;
}

.pb-37 {
    padding-bottom: 37px;
}

.min-height-sec1 {
    min-height: 390px;
}

.footer-ds-img-wrapper {
    width: 180px;
    height: 244px;
}
.ds-cryp{
    position: absolute;
    top: -332px;
    width: 100%;
}
.ds-name-content {
    width: 60%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding-bottom: 15px;
    padding-right: 25px;
}

.ds-content-text-sign {
    font-family: "Gatteway Signature" !important;
    font-size: 20px;
    text-align: end;
    font-weight: bold;
    padding-top: 7px;
}

/* Official Font Sizes For OV Holidays */

.font-50 {
    font-size: 50px !important;
}

.font-40 {
    font-size: 40px !important;
}

.font-26 {
    font-size: 26px !important;
}

.font-18 {
    font-size: 18px !important;
}

/* .offer-inner-footer-mt {
    margin-top: -240px;
} */


/* Official Element Spacing For OV Holidays */

.cmt-5 {
    margin-top: 5px !important;
}

.cmt-10 {
    margin-top: 10px !important;
}

.cmt-15 {
    margin-top: 15px !important;
}

.cmt-20 {
    margin-top: 20px !important;
}

.cmt-25 {
    margin-top: 25px !important;
}

.cmt-30 {
    margin-top: 30px !important;
}

.cmt-35 {
    margin-top: 35px !important;
}

.cmt-40 {
    margin-top: 40px !important;
}

.cmt-45 {
    margin-top: 45px !important;
}

.cmt-50 {
    margin-top: 50px !important;
}

.cmt-55 {
    margin-top: 55px !important;
}

.cmt-60 {
    margin-top: 60px !important;
}

.cmt-65 {
    margin-top: 65px !important;
}

.cmt-70 {
    margin-top: 70px !important;
}

.cmt-75 {
    margin-top: 75px !important;
}

.cmt-80 {
    margin-top: 80px !important;
}

.cmt-85 {
    margin-top: 85px !important;
}

.cmt-90 {
    margin-top: 90px !important;
}

.cmt-95 {
    margin-top: 95px !important;
}

.cmt-100 {
    margin-top: 100px !important;
}